import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { pathForDate } from '../../../utils/date';
import BoardContainer from '../../BoardContainer';
import DateContainer from '../../DateContainer';
import InboxContainer from '../../InboxContainer';
import NoteContainer from '../../NoteContainer.tsx';
import ProjectContainer from '../../ProjectContainer';
import { AccountSettings } from '../../Settings/AccountSettings';
import { NotificationSettings } from '../../Settings/NotificationSettings';

const Content = (): JSX.Element => {
  return (
    <div className="h-full grow overflow-y-scroll overflow-x-hidden bg-white dark:bg-mauve-dark-1 dark:text-white">
      <Switch>
        <Route exact path="/">
          <Redirect to={pathForDate(new Date())} />
        </Route>

        <Route exact path="/inbox" component={InboxContainer} />

        <Route
          path={['/date/:year/:month/:day']}
          exact
          component={DateContainer}
        />

        <Route
          path={['/projects/:projectId']}
          exact
          component={ProjectContainer}
        />

        <Route path={['/boards/:boardId']} exact component={BoardContainer} />
        <Route path={['/notes/:noteId']} exact component={NoteContainer} />

        <Route path={['/settings/account']} exact component={AccountSettings} />

        <Route
          path={['/settings/notifications']}
          exact
          component={NotificationSettings}
        />
      </Switch>
    </div>
  );
};

export default Content;
