import { Theme } from '@radix-ui/themes';
import React from 'react';

import './RadixTheme.css';

interface RadixThemeProps {
  asChild?: boolean;
  children: React.ReactNode;
}

export const RadixTheme: React.FC<RadixThemeProps> = ({
  asChild = false,
  children,
}) => {
  const [isDarkMode, setIsDarkMode] = React.useState(false);

  React.useEffect(() => {
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');

    setIsDarkMode(darkModeQuery.matches);

    darkModeQuery.addEventListener('change', (event) => {
      setIsDarkMode(event.matches);
    });
  }, [window.matchMedia]);

  return (
    <Theme
      appearance={isDarkMode ? 'dark' : 'light'}
      asChild={asChild}
      color="purple"
      grayColor="mauve"
      radius="medium"
      accentColor="violet"
      panelBackground="translucent"
    >
      {children}
    </Theme>
  );
};
