import { DraggableLocation } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';

import { NoteFragment, TaskFragment } from '../graphql/generated-types';
import { Note, Project, ProjectColumn } from '../types';
import Group from '../types/Group';
import Task, { TaskOrder } from '../types/Task';

import {
  BetaAction,
  CreatorModalMode,
  CurrentDraggingType,
  OrderType,
  Sidebar,
  Toast,
} from './beta-types';

export interface StoreTasksFromServerOptions {
  clearCompletionMetadata?: boolean;
  overrideOrder?: TaskOrder;
}

export type TaskFragmentWithOrders = TaskFragment & {
  orders?: {
    dateOrder?: number | null;
    projectOrder?: number | null;
  };
};

export const removeGroups = (groupIds: string[]): BetaAction => ({
  type: 'REMOVE_GROUPS',
  groupIds,
});

export const removeNotes = (noteIds: string[]): BetaAction => ({
  type: 'REMOVE_NOTES',
  noteIds,
});

export const removeTasks = (taskIds: string[]): BetaAction => ({
  type: 'REMOVE_TASKS',
  taskIds,
});

export const reorderGroups = (
  source: DraggableLocation,
  destination: DraggableLocation
): BetaAction => ({
  type: 'REORDER_GROUPS',
  source,
  destination,
});

export const reorderProjects = (
  source: DraggableLocation,
  destination: DraggableLocation
): BetaAction => ({
  type: 'REORDER_PROJECTS',
  source,
  destination,
});

export const reorderTasks = (
  source: DraggableLocation,
  destination: DraggableLocation,
  orderType: OrderType
): BetaAction => ({
  type: 'REORDER_TASKS',
  source,
  destination,
  orderType,
});

export const reorderTasksResponse = (tasks: TaskFragment[]): BetaAction => ({
  type: 'REORDER_TASKS_RESPONSE',
  tasks,
});

export const setActiveSidebar = (activeSidebar: Sidebar): BetaAction => ({
  type: 'SET_ACTIVE_SIDEBAR',
  activeSidebar,
});

export const setContainerGroups = (containerGroups: Group[]): BetaAction => ({
  type: 'SET_CONTAINER_GROUPS',
  containerGroups,
});

export const setContainerProjectColumns = (
  containerProjectColumns: ProjectColumn[]
): BetaAction => ({
  type: 'SET_CONTAINER_PROJECT_COLUMNS',
  containerProjectColumns,
});

export const setContainerProjects = (
  containerProjects: Project[]
): BetaAction => ({
  type: 'SET_CONTAINER_PROJECTS',
  containerProjects,
});

export const setContainerTasks = (containerTasks: Task[]): BetaAction => ({
  type: 'SET_CONTAINER_TASKS',
  containerTasks,
});

export const setDayCalendarZoomLevel = (
  dayCalendarZoomLevel: number
): BetaAction => ({
  type: 'SET_DAY_CALENDAR_ZOOM_LEVEL',
  dayCalendarZoomLevel,
});

export const setPriorityTasks = (priorityTasks: Task[]): BetaAction => ({
  type: 'SET_PRIORITY_TASKS',
  priorityTasks,
});

export const setCreatorModalMode = (
  creatorModalMode: CreatorModalMode | null
): BetaAction => ({
  type: 'SET_CREATOR_MODAL_MODE',
  creatorModalMode,
});

export const setCurrentDraggingType = (
  currentDraggingType: CurrentDraggingType
): BetaAction => ({
  type: 'SET_CURRENT_DRAGGING_TYPE',
  currentDraggingType,
});

export const setDraggingProjectColumnId = (
  draggingProjectColumnId: string | null
): BetaAction => ({
  type: 'SET_DRAGGING_PROJECT_COLUMN_ID',
  draggingProjectColumnId,
});

export const setDraggingTask = (draggingTask: string | null): BetaAction => ({
  type: 'SET_DRAGGING_TASK',
  draggingTask,
});

export const setFloatingProjectSelectorTaskIds = (
  taskIds: string[]
): BetaAction => ({
  type: 'SET_FLOATING_PROJECT_SELECTOR_TASK_IDS',
  taskIds,
});

export const setFocusedTask = (focusedTask: boolean): BetaAction => ({
  type: 'SET_FOCUSED_TASK',
  focusedTask,
});

export const setHideMobileElements = (
  hideMobileElements: boolean
): BetaAction => ({
  type: 'SET_HIDE_MOBILE_ELEMENTS',
  hideMobileElements,
});

export const setIsDragging = (isDragging: boolean): BetaAction => ({
  type: 'SET_IS_DRAGGING',
  isDragging,
});

export const setIsHoveringTaskDropzone = (
  isHoveringTaskDropzone: boolean
): BetaAction => ({
  type: 'SET_IS_HOVERING_TASK_DROPZONE',
  isHoveringTaskDropzone,
});

export const setLastSelectedTask = (lastSelectedTask: string): BetaAction => ({
  type: 'SET_LAST_SELECTED_TASK',
  lastSelectedTask,
});

export const setMobileSidebarVisible = (
  mobileSidebarVisible: boolean
): BetaAction => ({
  type: 'SET_MOBILE_SIDEBAR_VISIBLE',
  mobileSidebarVisible,
});

export const setMobileToolbarVisible = (
  mobileToolbarVisible: boolean
): BetaAction => ({
  type: 'SET_MOBILE_TOOLBAR_VISIBLE',
  mobileToolbarVisible,
});

export const setOverrideCreationGroup = (
  overrideCreationGroup: string | null
): BetaAction => ({
  type: 'SET_OVERRIDE_CREATION_GROUP',
  overrideCreationGroup,
});

export const setOverrideCreationProjectColumn = (
  overrideCreationProjectColumn: string | null
): BetaAction => ({
  type: 'SET_OVERRIDE_CREATION_PROJECT_COLUMN',
  overrideCreationProjectColumn,
});

export const setPauseContainerStores = (
  pauseContainerStores: boolean
): BetaAction => ({
  type: 'SET_PAUSE_CONTAINER_STORES',
  pauseContainerStores,
});

export const setSearchOpen = (searchOpen: boolean): BetaAction => ({
  type: 'SET_SEARCH_OPEN',
  searchOpen,
});

export const setSelectedDate = (date: string | null): BetaAction => ({
  type: 'SET_SELECTED_DATE',
  date,
});

export const setSelectedProject = (projectId: string | null): BetaAction => ({
  type: 'SET_SELECTED_PROJECT',
  projectId,
});

export const setSelectedTasks = (
  selectedTasks: string[],
  selectedTasksLocation: string | null
): BetaAction => ({
  type: 'SET_SELECTED_TASKS',
  selectedTasks,
  selectedTasksLocation,
});

export const setTimeZone = (timeZone: string): BetaAction => ({
  type: 'SET_TIME_ZONE',
  timeZone,
});

export const setToast = (toast: Toast | null): BetaAction => ({
  type: 'SET_TOAST',
  toast,
});

export const storeNotes = ({ notes }: { notes: Note[] }): BetaAction => ({
  type: 'STORE_NOTES',
  notes,
});

export const storeNotesFromServer = ({
  notes,
}: {
  notes: NoteFragment[];
}): BetaAction => ({
  type: 'STORE_NOTES_FROM_SERVER',
  notes,
});

export const updateContainerTasks = ({
  containerTasks,
}: {
  containerTasks: Task[];
}): BetaAction => ({
  type: 'UPDATE_CONTAINER_TASKS',
  containerTasks,
});
