import { EmojiData, Picker } from 'emoji-mart';
import React from 'react';

import 'emoji-mart/css/emoji-mart.css';
import './EmojiPicker.css';

const EmojiPicker = ({
  onSelect,
}: {
  onSelect: (emoji: EmojiData) => void;
}): JSX.Element => {
  return (
    <Picker
      autoFocus
      theme="auto"
      color="#7C3AED"
      onSelect={onSelect}
      title="Select an emoji"
      emojiTooltip={false}
      showPreview={false}
    />
  );
};

export default EmojiPicker;
