import {
  addHours,
  addMonths,
  isSameDay,
  isToday,
  setDate,
  startOfDay,
  subMonths,
} from 'date-fns';
import React from 'react';

import {
  daysOfCurrentMonth,
  daysOfNextMonth,
  daysOfPreviousMonth,
} from '../Calendar/utils';

const DayPicker = ({
  calendarDate,
  onSelectDate,
  selectedDate,
}: {
  calendarDate: Date;
  onSelectDate: (date: Date) => void;
  selectedDate: Date | null;
}): JSX.Element => {
  const previousMonth = subMonths(calendarDate, 1);

  return (
    <>
      <div className="mb-2 grid grid-cols-7 justify-items-center gap-0.5 border-0 border-b border-solid border-gray-600 pb-2">
        {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((weekDay) => (
          <div
            className="select-none text-sm text-gray-400"
            key={`weekday-${weekDay}`}
          >
            {weekDay}
          </div>
        ))}
      </div>

      <div className="grid h-full grid-cols-7 justify-items-center gap-0.5 md:h-auto">
        {daysOfPreviousMonth(calendarDate, previousMonth).map((day) => (
          <Day
            date={startOfDay(setDate(subMonths(calendarDate, 1), day))}
            day={day}
            key={`previous-day-${day}`}
            classes="text-gray-400"
            onSelectDate={onSelectDate}
          />
        ))}

        {daysOfCurrentMonth(calendarDate).map((day) => (
          <Day
            key={`current-day-${day}`}
            date={startOfDay(setDate(calendarDate, day))}
            selectedDate={selectedDate}
            day={day}
            onSelectDate={onSelectDate}
          />
        ))}

        {daysOfNextMonth(calendarDate).map((day) => (
          <Day
            key={`next-day-${day}`}
            date={startOfDay(setDate(addMonths(calendarDate, 1), day))}
            day={day}
            classes="text-gray-400"
            onSelectDate={onSelectDate}
          />
        ))}
      </div>
    </>
  );
};

const Day = ({
  classes,
  date,
  onSelectDate,
  selectedDate,
}: {
  date: Date;
  classes?: string;
  day: number;
  onSelectDate: (date: Date) => void;
  selectedDate?: Date | null;
}): JSX.Element => {
  const isSelectedDate = selectedDate
    ? isSameDay(date, addHours(selectedDate, 5))
    : false;

  const isCurrentDay = isToday(date);

  return (
    <div
      className={[
        'flex h-4 w-4 cursor-pointer select-none border border-solid border-transparent items-center justify-center rounded-full p-1_5 text-base md:text-sm',
        classes,
        isSelectedDate &&
          'dark:hover:bg-violet-dark-10 bg-violet-9 dark:bg-violet-dark-9 text-white hover:bg-violet-10 hover:text-white',
        !isSelectedDate &&
          'hover:bg-violet-4 hover:dark:bg-violet-dark-4 hover:text-violet-12 dark:hover:text-violet-dark-12',
        isCurrentDay &&
          !isSelectedDate &&
          'text-violet-12 dark:text-violet-dark-12  border-violet-8 dark:border-violet-dark-8 font-semibold',
      ]
        .filter(Boolean)
        .join(' ')}
      onClick={() => onSelectDate(date)}
    >
      {date.getDate()}
    </div>
  );
};

export default DayPicker;
