import { Content, Portal, Root, Trigger } from '@radix-ui/react-popover';
import React from 'react';

import './Popover.css';

export const Popover = ({
  align = 'center',
  alignOffset = 0,
  children,
  collisionPadding = 0,
  content,
  modal = false,
  onOpenAutoFocus,
  onCloseAutoFocus,
  side = 'bottom',
  sideOffset = 10,
}: {
  align?: 'start' | 'center' | 'end';
  alignOffset?: number;
  children: React.ReactNode;
  collisionPadding?: number;
  content: React.ReactNode;
  modal?: boolean;
  onOpenAutoFocus?: () => void;
  onCloseAutoFocus?: () => void;
  side?: 'bottom' | 'top' | 'left' | 'right';
  sideOffset?: number;
}): JSX.Element => {
  return (
    <Root modal={modal}>
      <Trigger
        className="block cursor-pointer border-none bg-transparent p-0"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </Trigger>

      <Portal>
        <Content
          align={align}
          alignOffset={alignOffset}
          avoidCollisions
          className="popover-content pointer-events-auto z-map-modal-dropdown"
          collisionPadding={collisionPadding}
          onCloseAutoFocus={onCloseAutoFocus}
          onOpenAutoFocus={onOpenAutoFocus}
          onInteractOutside={(e) => e.stopPropagation()}
          side={side}
          sideOffset={sideOffset}
        >
          {content}
        </Content>
      </Portal>
    </Root>
  );
};

export default Popover;
