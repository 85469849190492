import * as Select from '@radix-ui/react-select';
import classNames from 'classnames';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import React from 'react';

export interface DropdownOption {
  label: string;
  value: string;
}

export const Dropdown = ({
  fullWidth = false,
  onValueChange,
  options,
  value,
}: {
  fullWidth?: boolean;
  onValueChange: (value: string) => void;
  options: DropdownOption[];
  value: string;
}): JSX.Element => {
  return (
    <>
      <Select.Root value={value} onValueChange={onValueChange}>
        <Select.Trigger
          className={classNames(
            'data-[placeholder]:text-violet-300 justify-between inline-flex h-[35px] cursor-pointer items-center gap-1 rounded border border-solid border-violet-600 bg-transparent px-4 text-sm leading-none text-violet-600 shadow-none outline-none hover:bg-violet-800 hover:bg-opacity-70 hover:text-white dark:text-violet-400',
            {
              'w-full': fullWidth,
            }
          )}
        >
          <Select.Value />

          <ChevronDownIcon size={12} />
        </Select.Trigger>

        <Select.Portal>
          <Select.Content
            className="cursor-pointer overflow-hidden rounded-sm bg-white !bg-opacity-70 shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] z-map-modal-dropdown dark:bg-mauve-dark-3"
            style={{
              WebkitBackdropFilter: 'blur(12px)',
              backdropFilter: 'blur(12px)',
            }}
          >
            <Select.ScrollUpButton className="flex h-[25px] cursor-default items-center justify-center bg-white text-violet-400">
              <ChevronUpIcon />
            </Select.ScrollUpButton>

            <Select.Viewport className="p-[5px]">
              <Select.Group>
                {options.map((option) => (
                  <SelectItem value={option.value} key={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </Select.Group>
            </Select.Viewport>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </>
  );
};

const SelectItem = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: string;
}): JSX.Element => {
  return (
    <Select.Item
      className="data-[highlighted]:outline-none data-[highlighted]:bg-violet-10 data-[highlighted]:bg-opacity-70 data-[highlighted]:text-violet-200 relative flex select-none items-center gap-2 rounded-sm py-2 pr-6 pl-6 text-sm leading-none dark:text-white"
      value={value}
    >
      <Select.ItemText>{children}</Select.ItemText>

      <Select.ItemIndicator className="absolute left-0 inline-flex w-[25px] items-center justify-center">
        <CheckIcon size={14} />
      </Select.ItemIndicator>
    </Select.Item>
  );
};
