import { AlertTriangle, Lock } from 'lucide-react';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { PasswordErrors as LoginPasswordErrors } from '../Login';
import { PasswordErrors as PasswordResetPasswordErrors } from '../PasswordReset';
import { PasswordStrengthIndicator } from '../PasswordStrengthIndicator';

import { MINIMUM_PASSWORD_LENGTH } from './utils';

export const Password = ({
  errors,
  onChange,
  password,
}: {
  errors: LoginPasswordErrors | PasswordResetPasswordErrors;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  password: string;
}): JSX.Element => {
  const [error, setError] = useState<string | null>(null);

  const passwordProgress =
    password.length > 0
      ? Math.min(100, (password.length / MINIMUM_PASSWORD_LENGTH) * 100)
      : 0;

  useEffect(() => {
    if (errors.includes('too_short')) {
      setError(
        `Password must be at least ${MINIMUM_PASSWORD_LENGTH} characters long.`
      );
    } else if (
      (errors as PasswordResetPasswordErrors).includes('token_expired')
    ) {
      setError('Your password reset link has expired.');
    } else {
      setError(null);
    }
  }, [errors]);

  return (
    <>
      <div className="mb-2 flex items-center justify-between">
        <label htmlFor="password" className="text-violet-200">
          Password
        </label>

        {error && <span className="text-xs text-red-500">{error}</span>}
      </div>

      <div className="mt-2 flex w-full rounded border border-solid border-violet-500 bg-gray-900">
        <label
          htmlFor="password"
          className="flex cursor-pointer items-center justify-center p-4"
        >
          <>
            {errors.length > 0 ? (
              <AlertTriangle
                size={20}
                className="drop-shadow-auth-icon-error text-red-500"
              />
            ) : passwordProgress > 0 ? (
              <PasswordStrengthIndicator
                minPasswordLength={MINIMUM_PASSWORD_LENGTH}
                password={password}
                passwordProgress={passwordProgress}
              />
            ) : (
              <Lock
                size={20}
                className="text-violet-600 drop-shadow-auth-icon"
              />
            )}
          </>
        </label>

        <input
          id="password"
          name="password"
          type="password"
          className="w-full border-0 border-l border-solid border-violet-500 bg-transparent p-3 text-lg font-semibold text-white transition-all focus:bg-violet-900 focus:bg-opacity-20 focus:outline-none"
          value={password}
          onChange={onChange}
        />
      </div>
    </>
  );
};
