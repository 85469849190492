import { Label as BaseLabel } from '@radix-ui/react-label';
import classNames from 'classnames';
import { Trash, X } from 'lucide-react';
import React, { ChangeEvent } from 'react';

const SettingsSidebar = ({
  children,
  onClickCloseButton,
  title,
}: {
  children: React.ReactChild;
  onClickCloseButton: () => void;
  title: string;
}): JSX.Element => {
  return (
    <>
      <div className="box-border flex h-full flex-col px-4 pt-2 pb-5">
        <div>
          <div className="flex items-center justify-between">
            <h2 className="ml-2 text-lg">{title}</h2>
            <X
              onClick={onClickCloseButton}
              className="cursor-pointer md:mr-4"
              size={20}
            />
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

export const Button = ({
  centered = false,
  children,
  id,
  onClick,
  withBorder = false,
}: {
  centered?: boolean;
  children: React.ReactChild;
  id: string;
  onClick: () => void;
  withBorder?: boolean;
}): JSX.Element => (
  <button
    id={id}
    className={classNames(
      'mt-1 mb-4 box-border transition w-full cursor-pointer rounded border border-solid bg-transparent px-2 py-1 text-left text-base font-medium text-gray-800 hover:border-gray-600 focus:border-violet-500 focus:outline-none dark:text-white dark:focus:border-gray-200 flex',
      {
        'justify-center items-center': centered,
        'hover:dark:border-white border-gray-600': withBorder,
        'border-transparent': !withBorder,
      }
    )}
    onClick={onClick}
  >
    {children}
  </button>
);

export const TextInput = ({
  id,
  onBlur,
  onChange,
  value,
}: {
  id: string;
  onBlur: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}): JSX.Element => (
  <input
    id={id}
    value={value}
    onBlur={onBlur}
    onChange={onChange}
    className="mt-1 mb-4 box-border w-full rounded border border-solid border-transparent bg-transparent px-2 py-1 text-base font-medium text-gray-800 hover:border-gray-600 focus:border-violet-500 focus:outline-none dark:text-white dark:focus:border-gray-200"
  />
);

export const Label = ({
  htmlFor,
  text,
}: {
  htmlFor?: string;
  text: string;
}): JSX.Element => (
  <BaseLabel
    htmlFor={htmlFor}
    className="ml-2 cursor-pointer text-xs text-violet-600 dark:text-violet-300"
  >
    {text}
  </BaseLabel>
);

export const DeleteButton = ({
  onClick,
  text,
}: {
  onClick: () => void;
  text: string;
}): JSX.Element => (
  <button
    className="group mt-4 flex cursor-pointer items-center justify-center rounded-md border border-solid bg-transparent py-1 text-base transition hover:border-red-500 hover:bg-red-600 hover:text-white dark:border-gray-400 dark:text-white"
    onClick={onClick}
  >
    <Trash
      className="mr-1 text-red-500 transition group-hover:text-white"
      size={16}
    />
    {text}
  </button>
);

export default SettingsSidebar;
