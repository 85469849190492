import React, { useEffect, useState } from 'react';

const Checkbox = ({
  checked,
  id,
  onChange,
  testId,
}: {
  checked: boolean;
  id: string;
  onChange: () => void;
  testId?: string;
}): JSX.Element => {
  const [recentlyClicked, setRecentlyClicked] = useState<boolean>(false);

  useEffect(() => {
    if (recentlyClicked) {
      setTimeout(() => {
        setRecentlyClicked(false);
      }, 500);
    }
  }, [recentlyClicked]);

  return (
    <>
      <input
        id={id}
        className="input-checkbox hidden"
        type="checkbox"
        onChange={() => {
          setRecentlyClicked(true);
          onChange();
        }}
        checked={checked}
        data-testid={testId}
      />

      <label
        className={[
          'checkbox',
          'cursor-pointer',
          'flex',
          'mr-2',
          'select-none',
          recentlyClicked && 'checkbox-recently-clicked',
        ]
          .filter(Boolean)
          .join(' ')}
        htmlFor={id}
      >
        <span
          className={[
            'bg-white',
            'border',
            'border-solid',
            'border-gray-500',
            'rounded',
            'h-5',
            'w-5',
          ].join(' ')}
        >
          <svg
            className={[
              'absolute',
              'delay-100',
              'left-0.5',
              'duration-300',
              'stroke-2',
              'stroke-current',
              'text-white',
              'transition-all',
              'top-1',
            ].join(' ')}
            width="14px"
            height="14px"
            viewBox="0 0 12 12"
          >
            <polyline points="1.5 6 4.5 9 11.5 1" />
          </svg>
        </span>
      </label>
    </>
  );
};

export default Checkbox;
