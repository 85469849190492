import React from 'react';
import { useHistory } from 'react-router';

import { resetAuthentication } from '../../../utils/authentication';

import { SidebarList, SidebarListItem } from './SidebarList';

const SettingsSidebar = (): JSX.Element => {
  const history = useHistory();
  return (
    <div className="mt-18 border-0 border-r border-solid border-violet-100 bg-gray-50 pb-40 shadow-sidebar-gray dark:border-violet-900 dark:bg-mauve-dark-2 dark:text-white dark:shadow-none md:mt-0 md:h-screen md:pb-0">
      <div
        className="fixed top-0 flex h-18 w-full items-center justify-between border-0 border-b border-r border-solid border-violet-100 bg-gray-50 !bg-opacity-70 dark:border-violet-900 dark:bg-mauve-dark-2 md:sticky"
        style={{
          WebkitBackdropFilter: 'blur(12px)',
          backdropFilter: 'blur(12px)',
          boxShadow: '0px 0px 2px rgba(133, 133, 133, 0.32)',
        }}
      >
        <h2 className="my-0 px-5 text-2xl font-semibold">Settings</h2>
      </div>

      <div className="pb-20">
        <SidebarList>
          <SidebarListItem
            text="Account"
            onClick={() => {
              history.push('/settings/account');
            }}
          />

          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {(window as any).electron && (
            <SidebarListItem
              text="Notifications"
              onClick={() => {
                history.push('/settings/notifications');
              }}
            />
          )}

          <SidebarListItem
            onClick={async () => {
              await resetAuthentication();

              location.reload();
            }}
            text="Log out"
          />
        </SidebarList>
      </div>
    </div>
  );
};

export default SettingsSidebar;
