import { Doc, RemirrorRenderer } from '@remirror/react';
import { MarkMap } from '@remirror/react-renderer/dist-types/types';
import { formatDistanceToNow } from 'date-fns';
import orderBy from 'lodash/orderBy';
import { ChevronRight, Pen, Plus } from 'lucide-react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useNotesQuery } from '../../../graphql/generated-types';
import {
  setCreatorModalMode,
  setMobileSidebarVisible,
  storeNotesFromServer,
} from '../../../reducers/actions';
import { BetaState } from '../../../reducers/beta-types';
import { Note } from '../../../types';

import { SidebarList } from './SidebarList';

import './NoteSidebar.css';

const NotesSidebar = (): JSX.Element => {
  const dispatch = useDispatch();

  const [notesData] = useNotesQuery({
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (notesData.data?.notes) {
      dispatch(storeNotesFromServer({ notes: notesData.data.notes }));
    }
  }, [notesData.data]);

  const { notes } = useSelector((state: BetaState) => ({
    notes: state.notes,
  }));

  const history = useHistory();

  return (
    <div className="mt-18 border-0 border-r border-solid border-violet-100 bg-gray-50 pb-40 shadow-sidebar-gray dark:border-violet-900 dark:bg-mauve-dark-2 md:h-screen dark:text-white dark:shadow-none md:mt-0 md:pb-0">
      <div
        className="fixed top-0 flex h-18 w-full items-center justify-between border-0 border-b border-r border-solid border-violet-100 bg-gray-50 !bg-opacity-70 dark:border-violet-900 dark:bg-mauve-dark-2 md:sticky"
        style={{
          WebkitBackdropFilter: 'blur(12px)',
          backdropFilter: 'blur(12px)',
          boxShadow: '0px 0px 2px rgba(133, 133, 133, 0.32)',
        }}
      >
        <h2 className="my-0 pl-5 text-2xl font-semibold">Notes</h2>

        <Plus
          className="mr-5 cursor-pointer hover:text-violet-500"
          onClick={() => dispatch(setCreatorModalMode('note'))}
        />
      </div>

      <div className="pb-20">
        {notes.length > 0 && (
          <SidebarList>
            {orderBy(notes, 'data.updatedAt', 'desc').map((note) => (
              <SidebarListItem
                key={`note-${note.data.id}`}
                onClick={() => {
                  history.push(`/notes/${note.data.id}`);
                  dispatch(setMobileSidebarVisible(false));
                }}
                note={note}
              />
            ))}
          </SidebarList>
        )}
      </div>
    </div>
  );
};

const SidebarListItem = ({
  onClick,
  note,
}: {
  onClick?: () => void;
  note: Note;
}): JSX.Element => {
  const typeMap: MarkMap = {
    blockquote: 'blockquote',
    bulletList: 'span',
    doc: Doc,
    heading: 'span',
    listItem: 'span',
    paragraph: 'span',
    codeBlock: 'span',
    orderedList: 'span',
    text: 'span',
  };

  const markMap: MarkMap = {
    italic: 'span',
    bold: 'span',
    code: 'span',
    link: 'span',
    underline: 'span',
  };

  const parsedJson = JSON.parse(note.data.body || '{}');

  return (
    <li
      className="group flex max-w-full cursor-pointer items-center justify-between border-0 border-b border-solid border-gray-200 bg-white p-5 text-lg hover:bg-violet-200 hover:text-violet-700 dark:border-gray-900 dark:bg-mauve-dark-3 dark:hover:bg-violet-700 dark:hover:bg-opacity-30 dark:hover:text-violet-200"
      onClick={onClick}
    >
      <div className="flex flex-col gap-2 overflow-x-hidden">
        <div className="flex items-center text-base leading-5">
          {note.data.name}
        </div>

        {note.data.body && !note.data.hidePreview && (
          <>
            <span className="note-preview text-sm text-gray-400 line-clamp-2 group-hover:text-violet-400 dark:text-gray-500 group-hover:dark:text-violet-500">
              <RemirrorRenderer
                json={{
                  ...parsedJson,
                  content: parsedJson.content.slice(0, 3),
                }}
                skipUnknownTypes
                markMap={markMap}
                typeMap={typeMap}
              />
            </span>
          </>
        )}

        <div className="flex items-center gap-2">
          <Pen
            size={12}
            className="text-neutral-500 group-hover:text-violet-500"
          />

          <span className="text-sm text-neutral-500 group-hover:text-violet-500">
            {formatDistanceToNow(note.data.updatedAt, { addSuffix: true })}
          </span>
        </div>
      </div>

      <ChevronRight size="16" className="shrink-0" />
    </li>
  );
};

export default NotesSidebar;
