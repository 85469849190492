// @ts-strict-ignore

import {
  DraggableLocation,
  DropResult,
} from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';

export const extractId = (htmlId: string): string => {
  return htmlId.split('-').slice(-1)[0];
};

export const extractLocation = (htmlId: string): string => htmlId.split('-')[0];

export const handleDrag = ({
  onDropTaskOnProject,
  result,
  reorderGroups,
  reorderTasks,
}: {
  onDropTaskOnProject: (taskId: string, projectId: string) => void;
  result: DropResult;
  reorderGroups: (
    source: DraggableLocation,
    destination: DraggableLocation
  ) => void;
  reorderTasks: (
    source: DraggableLocation,
    destination: DraggableLocation
  ) => void;
}): void => {
  const {
    destination,
    destination: { droppableId },
    draggableId,
  } = result;

  if (!destination) {
    return;
  }

  // Dropping a task on to a project
  if (droppableId.match(/project-/) && result.type === 'TASK') {
    const taskId = extractId(draggableId);
    const projectId = extractId(droppableId);

    onDropTaskOnProject(taskId, projectId);
  } else {
    if (result.type === 'GROUP') {
      reorderGroups(result.source, destination);
    } else if (result.type === 'TASK') {
      reorderTasks(result.source, destination);
    }
  }
};
