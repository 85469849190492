import { ContextMenu } from '@radix-ui/themes';
import { addDays, format, isBefore, isSameDay } from 'date-fns';
import { useLiveQuery } from 'dexie-react-hooks';
import React from 'react';
import { useDispatch } from 'react-redux';

import { db } from '../../database';
import { setFloatingProjectSelectorTaskIds } from '../../reducers/actions';
import { Task } from '../../types';
import { dateInUtc } from '../../utils/date';

const TaskContextMenu = ({
  onDeleteTask,
  onOpenDateCalendarModal,
  onOpenDueDateCalendarModal,
  onOpenTaskLinkModal,
  onRemoveDate,
  onTogglePriority,
  onUpdateTaskProject,
  selectedTasks,
  task,
}: {
  onDeleteTask: () => void;
  onOpenDateCalendarModal: () => void;
  onOpenDueDateCalendarModal: () => void;
  onOpenTaskLinkModal: () => void;
  onRemoveDate: () => void;
  onTogglePriority: () => void;
  onUpdateTaskProject: (projectId: string) => void;
  selectedTasks: string[];
  task: Task;
}): JSX.Element => {
  const dispatch = useDispatch();

  const databaseProjects = useLiveQuery(async () => {
    const filterSelectedDate = dateInUtc(new Date());

    return await db.projects
      .where('data.date')
      .belowOrEqual(format(filterSelectedDate, 'yyyy-MM-dd'))
      .and((project) => {
        const startDate = dateInUtc(project.data.date);
        const endDate = dateInUtc(project.data.endDate);

        return (
          isBefore(filterSelectedDate, addDays(endDate, 1)) ||
          (project.data.endDate === null &&
            isSameDay(filterSelectedDate, startDate))
        );
      })
      .toArray();
  }, []);

  return (
    <ContextMenu.Content>
      <ContextMenu.Item onSelect={() => null} shortcut="Space">
        {task.data.completed ? 'Uncomplete' : 'Complete'} task
      </ContextMenu.Item>

      <ContextMenu.Item onSelect={onTogglePriority} shortcut="P">
        {task.order.priority
          ? 'Remove from priorities'
          : `Prioritize task${selectedTasks.length > 1 ? 's' : ''}`}
      </ContextMenu.Item>

      <ContextMenu.Item onSelect={onOpenTaskLinkModal} shortcut="⇧ L">
        {task.data.link ? 'Modify link...' : 'Add link...'}
      </ContextMenu.Item>

      <ContextMenu.Sub>
        <ContextMenu.SubTrigger>Project</ContextMenu.SubTrigger>
        <ContextMenu.SubContent>
          {task.data.project?.id && (
            <>
              <ContextMenu.CheckboxItem checked={true}>
                {task.data.project.name}
              </ContextMenu.CheckboxItem>
              <ContextMenu.Separator />
            </>
          )}

          {databaseProjects?.length > 0 &&
            databaseProjects.filter(
              (project) => project.data.id !== task.data.projectId
            ).length > 0 && (
              <>
                {databaseProjects
                  .filter((project) => project.data.id !== task.data.projectId)
                  .map((project) => (
                    <ContextMenu.Item
                      key={project.data.id}
                      onSelect={() => onUpdateTaskProject(project.data.id)}
                    >
                      {project.data.name}
                    </ContextMenu.Item>
                  ))}
                <ContextMenu.Separator />
              </>
            )}

          <ContextMenu.Item
            shortcut="⇧ P"
            onSelect={() =>
              dispatch(setFloatingProjectSelectorTaskIds(selectedTasks))
            }
          >
            Select project…
          </ContextMenu.Item>
        </ContextMenu.SubContent>
      </ContextMenu.Sub>

      <ContextMenu.Separator />

      <ContextMenu.Item onSelect={onOpenDateCalendarModal} shortcut="D">
        Set date & time...
      </ContextMenu.Item>
      <ContextMenu.Item onSelect={onOpenDueDateCalendarModal} shortcut="⇧ D">
        Set due date...
      </ContextMenu.Item>

      <ContextMenu.Item
        disabled={!task.data.date}
        onSelect={onRemoveDate}
        shortcut="R"
      >
        Remove date
      </ContextMenu.Item>

      <ContextMenu.Separator />
      <ContextMenu.Item onSelect={onDeleteTask} shortcut="⇧ ⌫">
        Delete task{selectedTasks.length > 1 ? 's' : ''}
      </ContextMenu.Item>
    </ContextMenu.Content>
  );
};

export default TaskContextMenu;
