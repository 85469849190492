import {
  DraggableLocation,
  DropResult,
} from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';

export const handleDrag = ({
  reorderDraggable,
  result,
}: {
  reorderDraggable: (
    source: DraggableLocation,
    destination?: DraggableLocation
  ) => void;
  result: DropResult;
}): void => {
  reorderDraggable(result.source, result.destination);
};
