import { ChangeReason, SuggestChangeHandlerProps } from '@remirror/pm/suggest';
import {
  MenuNavigationOptions,
  UseMenuNavigationReturn,
} from '@remirror/react';
import { useExtensionEvent, useHelpers } from '@remirror/react-core';
import { useCallback, useMemo, useState } from 'react';

import { NoteFragment } from '../../../graphql/generated-types';
import {
  NoteEmbedChangeHandler,
  NoteEmbedExtension,
  NoteEmbedNodeAttributes,
} from '../extensions/NoteEmbedExtension';

import { useMenuNavigation } from './use-menu-navigation';

const INITIAL_INDEX = 1;

export interface NoteEmbedState<
  Data extends NoteEmbedNodeAttributes = NoteEmbedNodeAttributes
> extends Pick<SuggestChangeHandlerProps, 'name' | 'query' | 'text' | 'range'> {
  /**
   * The reason for the change.
   */
  reason: ChangeReason;

  /**
   * A command that will update the current matching region with the provided
   * attributes. To see what can be accomplished please inspect the type of the attrs which should be passed through.
   */
  command: (attrs: Data) => void;
}

export interface UseNoteEmbedReturn<
  Data extends NoteEmbedNodeAttributes = NoteEmbedNodeAttributes
> extends UseMenuNavigationReturn<Data> {
  state: NoteEmbedState<Data> | null;
}

/**
 * A hook that provides the state for social mention atoms that responds to
 * keybindings and key-presses from the user.
 *
 * The difference between this and the `useMention` is that `useMention` creates
 * editable mentions that can be changed over an over again. This creates atom
 * mention which are inserted into the editor as non editable nodes. Backspacing
 * into this node will delete the whole mention.
 *
 * In order to properly support keybindings you will need to provide a list of
 * data that is to be shown to the user. This allows for the user to press the
 * arrow up and arrow down key.
 *
 * You can also add other supported attributes which will be added to the
 * mention node, like `href` and whatever you decide upon.
 *
 * @param props - the props that can be passed through to the mention atom.
 */
export function useNoteEmbed<
  Data extends NoteEmbedNodeAttributes = NoteEmbedNodeAttributes
>(props: UseNoteEmbedProps<Data>): UseNoteEmbedReturn<Data> {
  const { items, direction, dismissKeys, focusOnClick, submitKeys } = props;
  const [state, setState] = useState<NoteEmbedState<Data> | null>(null);
  const helpers = useHelpers();
  const isOpen = !!state;

  const onDismiss = useCallback(() => {
    if (!state) {
      return false;
    }

    const { range, name } = state;

    // Ignore the current mention so that it doesn't show again for this
    // matching area
    helpers
      .getSuggestMethods()
      .addIgnored({ from: range.from, name, specific: true });

    // Remove the matches.
    setState(null);

    return true;
  }, [helpers, state]);

  const onSubmit = useCallback(
    async (item: Data) => {
      if (!state) {
        // When there is no state, defer to the next keybinding.
        return false;
      }

      if (item.id === 'new') {
        const newNote = await props.onNewNoteSelected(state.query.full);

        if (!newNote) {
          return true;
        }

        const newNoteItem = {
          ...item,
          id: newNote.id,
          label: newNote.name,
        };

        state.command(newNoteItem);

        return true;
      }

      // TODO add option to override the submission here. Return true to
      // completely override.

      // Call the command with the item (including all the provided attributes
      // which it includes).
      state.command(item);

      return true;
    },
    [state]
  );

  const menu = useMenuNavigation<Data>({
    initialIndex: INITIAL_INDEX,
    items,
    isOpen,
    onDismiss,
    onSubmit,
    direction,
    dismissKeys,
    focusOnClick,
    submitKeys,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);
  const { setIndex } = menu;

  /**
   * The is the callback for when a suggestion is changed.
   */
  const onChange: NoteEmbedChangeHandler = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (props: any, command: any) => {
      const { query, range, name, exitReason, changeReason, text } = props;

      // By default the mention atom will not automatically create the mention
      // for you.
      if (exitReason) {
        setState(null);

        return;
      }

      // This really should never be the case.
      if (!changeReason) {
        return;
      }

      if (changeReason !== ChangeReason.Move) {
        setIndex(INITIAL_INDEX);
      }

      if (
        items.length === 1 &&
        state?.query.full &&
        state?.query.full.length >= 2
      ) {
        setIndex(0);
      }

      // Reset the active index so that the dropdown is back to the top.
      setState({
        query,
        range,
        name,
        reason: changeReason,
        text,
        command: (attrs) => {
          command(attrs);
          setState(null);
        },
      });
    },
    [setIndex, items, state?.query.full]
  );

  // Add the handlers to the `MentionExtension`
  useExtensionEvent(NoteEmbedExtension, 'onChange', onChange);

  return useMemo(() => ({ ...menu, state }), [menu, state]);
}

export interface UseNoteEmbedProps<
  Data extends NoteEmbedNodeAttributes = NoteEmbedNodeAttributes
> extends MenuNavigationOptions {
  items: Data[];
  onNewNoteSelected: (text: string) => Promise<NoteFragment | undefined>;
}
