import { AlertTriangle } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { Link } from 'react-router-dom';

import { useUnauthenticatedRoute } from '../../hooks/use-authenticated-route';

import { MINIMUM_PASSWORD_LENGTH } from './fields/utils';
import { AuthLayout } from './AuthLayout';
import { Email, Password } from './fields';

import './Auth.css';

interface LoginSuccessResponse {
  code: 'success';
  message: string;
  status: 200;
}

interface InvalidCodeResponse {
  code: 'invalid_otp';
  message: string;
  status: 401;
}

interface OtpRequiredResponse {
  code: 'otp_required';
  message: string;
  status: 200;
}

interface LoginFailureResponse {
  code: 'error';
  message: string;
  status: 401;
}

export type EmailErrors = Array<'invalid_credentials'>;
export type OtpErrors = Array<'invalid_otp'>;
export type PasswordErrors = Array<'invalid_credentials' | 'too_short'>;

interface Errors {
  email: EmailErrors;
  otpAttempt?: OtpErrors;
  password: PasswordErrors;
}

type LoginResponse =
  | LoginSuccessResponse
  | LoginFailureResponse
  | OtpRequiredResponse
  | InvalidCodeResponse;

export const Login = (): JSX.Element => {
  useUnauthenticatedRoute();

  const AuthCodetRef = useRef<AuthCodeRef>(null);

  const [mode, setMode] = useState<'login' | 'otp'>('login');
  const [email, setEmail] = useState<string>('');
  const [otpAttempt, setOtpAttempt] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<Errors>({ email: [], password: [] });

  const loginUser = async ({
    email,
    otpAttempt,
    password,
  }: {
    email: string;
    otpAttempt?: string;
    password: string;
  }): Promise<LoginResponse> => {
    return await fetch('/api/auth/sign_in', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, otp_attempt: otpAttempt, password }),
    }).then(async (response) => await response.json());
  };

  const onSubmit = async (event?: React.FormEvent): Promise<void> => {
    event?.preventDefault();

    if (password.length < MINIMUM_PASSWORD_LENGTH) {
      setErrors({
        email: [],
        password: ['too_short'],
      });

      return;
    }

    await loginUser({ email, otpAttempt, password }).then((data) => {
      if (data.code === 'success') {
        window.location.href = '/';
      } else if (data.code === 'otp_required') {
        setMode('otp');
      } else if (data.code === 'invalid_otp') {
        AuthCodetRef.current?.clear();

        setErrors({
          email: [],
          otpAttempt: ['invalid_otp'],
          password: [],
        });
      } else {
        setErrors({
          email: ['invalid_credentials'],
          password: ['invalid_credentials'],
        });
      }
    });
  };

  useEffect(() => {
    if (otpAttempt.length === 6) {
      void onSubmit();
    }
  }, [otpAttempt]);

  return (
    <AuthLayout
      footer={
        mode === 'login' ? (
          <Link to="/" className="text-violet-200 no-underline">
            Join waitlist
          </Link>
        ) : undefined
      }
    >
      <form
        onSubmit={onSubmit}
        className="my-9 flex w-full sm:w-[485px] flex-col items-center rounded-lg border border-solid border-violet-200 border-opacity-20 bg-slate-900 bg-opacity-60 p-10 shadow-auth-box"
      >
        {mode === 'login' && (
          <>
            <h1 className="mt-0 mb-8 text-2xl font-semibold text-violet-300 drop-shadow-auth-header">
              Sign in to Blips
            </h1>

            <div className="mb-6 w-full">
              <Email
                email={email}
                errors={errors.email}
                onChange={(event) => {
                  setEmail(event.target.value);
                  setErrors({ email: [], password: [] });
                }}
              />
            </div>

            <div className="w-full">
              <Password
                errors={errors.password}
                onChange={(event) => {
                  setPassword(event.target.value);
                  setErrors({ email: [], password: [] });
                }}
                password={password}
              />

              <div className="flex justify-end pt-5">
                <Link
                  to="/forgot-password"
                  className="text-violet-200 no-underline"
                >
                  Forgot password?
                </Link>
              </div>
            </div>

            <button
              type="submit"
              disabled={!email || !password}
              className="mt-12 flex w-[292px] cursor-pointer items-center justify-center rounded-lg border border-solid border-violet-500 bg-violet-900 bg-opacity-50 py-3 text-base font-semibold text-white transition-all hover:drop-shadow-auth-icon"
            >
              Sign in
            </button>
          </>
        )}

        {mode === 'otp' && (
          <div className="flex flex-col gap-8 items-center">
            {errors.otpAttempt?.includes('invalid_otp') ? (
              <p className="text-red-500 flex items-center gap-2 m-0 text-sm">
                <AlertTriangle size={14} />
                Invalid code provided. Try again.
              </p>
            ) : (
              <p className="text-violet-300 text-sm m-0">
                Enter the six digit code from your authenticator app.
              </p>
            )}

            <AuthCode
              ref={AuthCodetRef}
              onChange={async (value) => {
                setOtpAttempt(value);

                if (value.length === 1) {
                  setErrors({ email: [], password: [] });
                }
              }}
              containerClassName="totp-container"
              inputClassName="totp-input"
              autoFocus
              allowedCharacters="numeric"
            />
          </div>
        )}
      </form>
    </AuthLayout>
  );
};
