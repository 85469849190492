import classNames from 'classnames';
import {
  Calendar,
  Flag,
  LayoutList,
  LucideProps,
  PenTool,
  Search,
} from 'lucide-react';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  setActiveSidebar,
  setMobileSidebarVisible,
  setSearchOpen,
} from '../../../reducers/actions';
import { BetaState, Sidebar } from '../../../reducers/beta-types';
import Tooltip from '../../library/Tooltip';

const ICON_SIZE = 18;

const Toolbar = (): JSX.Element => {
  const dispatch = useDispatch();

  const { activeSidebar, hideMobileElements } = useSelector(
    (state: BetaState) => ({
      activeSidebar: state.activeSidebar,
      hideMobileElements: state.hideMobileElements,
    })
  );

  return (
    <div
      className={classNames(
        'fixed bottom-0 left-0 right-0 flex h-18 shrink-0 items-center justify-evenly bg-violet-9 dark:bg-violet-dark-9 z-map-toolbar',
        { hidden: hideMobileElements }
      )}
    >
      <ToolbarIcon
        activeSidebar={activeSidebar}
        controlsSidebar={Sidebar.Priorities}
        Icon={Flag}
        text="Priorities"
      />

      <ToolbarIcon
        activeSidebar={activeSidebar}
        controlsSidebar={Sidebar.Calendar}
        Icon={Calendar}
        text="Calendar"
      />

      <ToolbarIcon
        activeSidebar={activeSidebar}
        controlsSidebar={Sidebar.Boards}
        Icon={LayoutList}
        text="Boards"
      />

      <ToolbarIcon
        activeSidebar={activeSidebar}
        controlsSidebar={Sidebar.Notes}
        Icon={PenTool}
        text="Notes"
      />

      <ToolbarIcon
        activeSidebar={activeSidebar}
        controlsSidebar={Sidebar.Search}
        onClick={() => dispatch(setSearchOpen(true))}
        Icon={Search}
        text="Search"
      />
    </div>
  );
};

const ToolbarIcon = ({
  activeSidebar,
  controlsSidebar,
  Icon,
  onClick = undefined,
  text,
}: {
  activeSidebar: Sidebar;
  controlsSidebar: Sidebar;
  Icon: FC<LucideProps>;
  onClick?: () => void;
  text: string;
}): JSX.Element => {
  const isActive = activeSidebar === controlsSidebar;
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Tooltip text={text}>
      <div
        className={classNames(
          'cursor-pointer',
          'relative',
          'h-full',
          'flex',
          'items-center',
          'px-2',
          'transition',
          'group',
          { 'hover:bg-white hover:bg-opacity-5': !isActive }
        )}
        role="button"
        onClick={
          onClick ||
          (() => {
            dispatch(setActiveSidebar(controlsSidebar));
            dispatch(setMobileSidebarVisible(true));
          })
        }
        onDoubleClick={() => {
          if (controlsSidebar === Sidebar.Calendar) {
            dispatch(setMobileSidebarVisible(false));
            history.push('/');
          }
        }}
      >
        <div className="flex flex-col items-center px-4">
          <Icon
            className={[
              'transition',
              isActive
                ? 'text-white'
                : 'text-violet-300 group-hover:text-violet-100',
            ].join(' ')}
            size={ICON_SIZE}
            data-testid={`toolbar-${controlsSidebar}`}
          />
        </div>

        <div
          className={[
            isActive
              ? 'border-0 border-t-4 border-solid border-opacity-100'
              : 'border-opacity-0',
            'absolute',
            'border-white',
            'h-full',
            'inset-0',
            'transition',
            'w-full',
          ]
            .filter(Boolean)
            .join(' ')}
        />
      </div>
    </Tooltip>
  );
};

export default Toolbar;
