import {
  Calendar,
  Flag,
  LayoutList,
  LucideProps,
  PenTool,
  Search,
  Settings,
  Sun,
} from 'lucide-react';
import React, { Dispatch, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useUserQuery } from '../../../graphql/generated-types';
import { setActiveSidebar, setSearchOpen } from '../../../reducers/actions';
import { BetaState, Sidebar } from '../../../reducers/beta-types';
import { hasAlphaFeatures } from '../../../utils/feature-flags';
import Tooltip from '../../library/Tooltip';

import NavigationButtons from './NavigationButtons';

const ICON_SIZE = 28;

const Toolbar = (): JSX.Element => {
  const activeSidebar = useSelector((state: BetaState) => state.activeSidebar);
  const dispatch = useDispatch();
  const [userData] = useUserQuery();

  return (
    <div className="flex select-none flex-col justify-between bg-violet-9 dark:bg-violet-dark-9">
      <div className="mt-6 flex w-full flex-col items-center">
        <ToolbarIcon
          activeSidebar={activeSidebar}
          controlsSidebar={Sidebar.Priorities}
          dispatch={dispatch}
          Icon={Flag}
          text="Priorities"
        />

        <ToolbarIcon
          activeSidebar={activeSidebar}
          controlsSidebar={Sidebar.Calendar}
          dispatch={dispatch}
          Icon={Calendar}
          text="Calendar"
        />

        {userData?.data?.currentUser &&
          hasAlphaFeatures(userData.data.currentUser) && (
            <ToolbarIcon
              activeSidebar={activeSidebar}
              controlsSidebar={Sidebar.DayView}
              dispatch={dispatch}
              Icon={Sun}
              text="Day View"
            />
          )}

        <ToolbarIcon
          activeSidebar={activeSidebar}
          controlsSidebar={Sidebar.Boards}
          dispatch={dispatch}
          Icon={LayoutList}
          text="Boards"
        />

        <ToolbarIcon
          activeSidebar={activeSidebar}
          controlsSidebar={Sidebar.Notes}
          dispatch={dispatch}
          Icon={PenTool}
          text="Notes"
        />

        <ToolbarIcon
          activeSidebar={activeSidebar}
          controlsSidebar={Sidebar.Search}
          dispatch={dispatch}
          onClick={() => dispatch(setSearchOpen(true))}
          Icon={Search}
          text="Search"
        />
      </div>

      <div className="mb-2">
        <ToolbarIcon
          activeSidebar={activeSidebar}
          controlsSidebar={Sidebar.Settings}
          dispatch={dispatch}
          Icon={Settings}
          text="Settings"
        />

        <NavigationButtons />
      </div>
    </div>
  );
};

const ToolbarIcon = ({
  activeSidebar,
  controlsSidebar,
  dispatch,
  onClick = undefined,
  Icon,
  text,
}: {
  activeSidebar: Sidebar;
  controlsSidebar: Sidebar;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
  onClick?: () => void;
  Icon: FC<LucideProps>;
  text: string;
}): JSX.Element => {
  const isActive = activeSidebar === controlsSidebar;
  const history = useHistory();

  return (
    <Tooltip text={text} side="right">
      <div
        className={[
          !isActive && 'hover:bg-white hover:bg-opacity-5',
          'cursor-pointer',
          'py-6',
          'relative',
          'w-full',
          'transition',
          'group',
        ]
          .filter(Boolean)
          .join(' ')}
        role="button"
        onClick={onClick || (() => dispatch(setActiveSidebar(controlsSidebar)))}
        onDoubleClick={() => {
          if (controlsSidebar === Sidebar.Calendar) {
            history.push('/');
          }
        }}
      >
        <div className="flex flex-col items-center">
          <Icon
            className={[
              'transition',
              isActive
                ? 'text-white'
                : 'text-violet-300 group-hover:text-violet-100',
            ].join(' ')}
            size={ICON_SIZE}
            data-testid={`toolbar-${controlsSidebar}`}
          />
        </div>

        <div
          className={[
            isActive
              ? 'border-0 border-l-4 border-solid border-opacity-100'
              : 'border-opacity-0',
            'absolute',
            'border-white',
            'h-full',
            'inset-0',
            'transition',
            'w-full',
          ]
            .filter(Boolean)
            .join(' ')}
        />
      </div>
    </Tooltip>
  );
};

export default Toolbar;
