import React from 'react';

export const SettingContainer = ({
  children,
  subtitle,
  title,
}: {
  children: React.ReactNode;
  subtitle?: string;
  title: string;
}): JSX.Element => {
  return (
    <div className="border-0 border-t border-solid gap-4 lg:gap-16 border-violet-600 dark:border-opacity-70 flex-col lg:flex-row flex py-8">
      <div className="lg:w-1/3 xl:w-1/4">
        <h4 className="mt-0 mb-2 text-sm font-semibold">{title}</h4>

        {subtitle && (
          <p className="text-xs dark:text-gray-100 text-gray-600">{subtitle}</p>
        )}
      </div>

      {children}
    </div>
  );
};
