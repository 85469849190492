// @ts-strict-ignore

import * as ActionCable from '@rails/actioncable';
import { SubscriptionOperation } from '@urql/core/dist/types/exchanges/subscription';
import { DocumentNode, Kind, parse } from 'graphql';
import Observable from 'zen-observable-ts';

export class ActionCableExchange {
  cable: ActionCable.Cable;
  channelName: string;
  actionName: string;
  connectionParams: object;

  constructor(options: {
    cable: ActionCable.Cable;
    channelName?: string;
    actionName?: string;
    connectionParams?: object;
  }) {
    this.cable = options.cable;
    this.channelName = options.channelName || 'GraphqlChannel';
    this.actionName = options.actionName || 'execute';
    this.connectionParams = options.connectionParams || {};
  }

  getOperationName(query: DocumentNode): string | undefined {
    for (let i = 0, l = query.definitions.length; i < l; i++) {
      const node = query.definitions[i];
      if (node.kind === Kind.OPERATION_DEFINITION && node.name) {
        return node.name.value;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request(operation: SubscriptionOperation): Observable<any> {
    return new Observable((observer) => {
      const channelId = Math.round(
        Date.now() + Math.random() * 100000
      ).toString(16);
      const actionName = this.actionName;
      const operationName = this.getOperationName(parse(operation.query));
      const subscription = this.cable.subscriptions.create(
        Object.assign(
          {},
          {
            channel: this.channelName,
            channelId,
          },
          this.connectionParams
        ),
        {
          connected: function () {
            this.perform(actionName, {
              query: operation.query ? operation.query : null,
              variables: operation.variables,
              // TODO: add this to support persisted queries. But we need to get the operationName at first
              // operationId: (operation as { operationId?: string }).operationId,
              operationName,
            });
          },
          received: function (payload) {
            if (payload.result.data || payload.result.errors) {
              observer.next(payload.result);
            }

            if (!payload.more) {
              observer.complete();
            }
          },
        }
      );

      // Make the ActionCable subscription behave like an Apollo subscription
      return Object.assign(subscription, { closed: false });
    });
  }
}
