import { ContextMenu } from '@radix-ui/themes';
import classnames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import omit from 'lodash/omit';
import React, { memo, MouseEvent, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';

import { taskCombiner } from '../../combiners/task-combiner';
import { db } from '../../database';
import {
  prioritizeTasksInDatabase,
  storeTasksFromServerInDatabase,
  storeTasksInDatabase,
  unprioritizeTasksInDatabase,
} from '../../database/actions';
import {
  TaskFragment,
  useCompleteTaskMutation,
  useDeleteTasksMutation,
  usePrioritizeTasksMutation,
  useUncompleteTaskMutation,
  useUnprioritizeTasksMutation,
  useUpdateTaskMutation,
  useUpdateTasksProjectMutation,
} from '../../graphql/generated-types';
import useBreakpoints from '../../hooks/use-breakpoints';
import {
  removeTasks,
  setFloatingProjectSelectorTaskIds,
  setFocusedTask,
  setLastSelectedTask,
  setSelectedTasks,
} from '../../reducers/actions';
import TaskType from '../../types/Task';
import { timeToLuxon } from '../../utils/date';
import { interopWithIos } from '../../utils/interop-with-ios';
import DateCalendarModal from '../DateCalendarModal';

import FocusedTask from './FocusedTask';
import TaskContextMenu from './TaskContextMenu';
import TaskHeader from './TaskHeader';
import TaskLinkModal from './TaskLinkModal';
import TaskSwiper from './TaskSwiper';

export interface Option {
  value: string;
  displayValue: string;
}

export const taskVariants = {
  enter: {
    opacity: 0,
  },
  entered: (i: number) => ({
    opacity: 1,
    transition: {
      delay: i * 0.03,
      duration: 0.7,
    },
  }),
  exit: {
    opacity: 0,
    height: 0,
    transition: { duration: 0.3 },
  },
};

const focusedTaskVariants = {
  enter: {
    opacity: 0,
    height: '20px',
    transition: { duration: 0.3 },
  },
  entered: {
    opacity: 1,
    height: 'fit-content',
  },
  exit: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
    transition: { opacity: { duration: 0.2 } },
  },
};

interface TaskProps {
  draggingTask: string | null;
  isDragging: boolean;
  isHoveringTaskDropzone: boolean;
  isNextTaskSelected: boolean;
  isPreviousTaskSelected: boolean;
  isSelected: boolean;
  onClickTaskHeader: (
    id: string,
    taskLocation: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: MouseEvent<HTMLElement, any>
  ) => void;
  parentId: string;
  selectedDate?: string | null;
  selectedProject?: string | null;
  selectedTasks: string[];
  showDate?: boolean;
  showDue?: boolean;
  showPriority?: boolean;
  showProject?: boolean;
  showStartTime?: boolean;
  task: TaskType;
  taskLocation: string;
  textClass?: string;
  timeZone: string;
}

const Task = ({
  draggingTask,
  isDragging,
  isHoveringTaskDropzone,
  isNextTaskSelected,
  isPreviousTaskSelected,
  isSelected,
  onClickTaskHeader,
  parentId,
  selectedDate,
  selectedProject,
  selectedTasks,
  showDate = true,
  showPriority = true,
  showProject = true,
  showStartTime = false,
  task,
  taskLocation,
  textClass = 'text-black',
  timeZone,
}: TaskProps): JSX.Element => {
  const dispatch = useDispatch();

  const [, completeTask] = useCompleteTaskMutation();
  const [, uncompleteTask] = useUncompleteTaskMutation();
  const [, prioritizeTasks] = usePrioritizeTasksMutation();
  const [, unprioritizeTasks] = useUnprioritizeTasksMutation();
  const [, updateTask] = useUpdateTaskMutation();
  const [, updateTasksProject] = useUpdateTasksProjectMutation();
  const [, deleteTasks] = useDeleteTasksMutation();

  const [dateCalendarModalOpen, setDateCalendarModalOpen] =
    useState<boolean>(false);
  const [dueDateCalendarModalOpen, setDueDateCalendarModalOpen] =
    useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [taskLinkModalOpen, setTaskLinkModalOpen] = useState(false);

  const taskRef = useRef(null);

  // There's some weird hijinks with updating the task name / saving it before the task unfocuses.
  // Instead we're storing the name in a ref to help persist it across the task.
  const nameRef = useRef<string>(task.data.name);

  useEffect(() => {
    if (task.meta.recentlyCompleted) {
      setTimeout(async () => {
        await db.tasks.update(task.data.id, {
          'meta.recentlyCompleted': false,
        });
      }, 1000);
    }
  }, [task.meta.recentlyCompleted]);

  const focusTask = (): void => {
    if (taskLocation === 'priorityTask') {
      return;
    }

    setFocusedTask(true);
    setIsFocused(true);
  };

  const unfocusTask = (): void => {
    setFocusedTask(false);
    setIsFocused(false);
  };

  const onUpdateTask = async <K extends keyof TaskFragment>(
    key: K,
    value: TaskFragment[K] | Option
  ): Promise<void> => {
    let updateValue;
    let updateKey: string;

    if (key === 'project') {
      const projectValue = value as Option;
      updateValue = projectValue.value;
      updateKey = 'projectId';

      await storeTasksInDatabase([
        {
          ...task,
          data: {
            ...task.data,
            projectId: projectValue.value,
            project: {
              id: projectValue.value,
              name: projectValue.displayValue,
            },
          },
        },
      ]);
    } else {
      updateValue = value;
      updateKey = key;

      await db.tasks
        .where('data.id')
        .equals(task.data.id)
        .modify({ [`data.${updateKey}`]: updateValue });
    }

    await updateTask({ taskId: task.data.id, [updateKey]: updateValue });
  };

  const onSelectDate = async (
    date: Date | null,
    time: string | null,
    isStartTimeLocked: boolean
  ): Promise<void> => {
    const newDate = date?.toISOString() || null;

    const newTime = time
      ? timeToLuxon({
          time,
          inputFormat: 'HH:mm',
          inputZone: timeZone,
          outputZone: 'utc',
        }).toFormat('HH:mm:ss')
      : null;

    setDateCalendarModalOpen(false);

    interopWithIos({ type: 'successHaptic' });

    await db.tasks.where('data.id').equals(task.data.id).modify({
      'data.date': newDate,
      'data.startTime': newTime,
      'data.isStartTimeLocked': isStartTimeLocked,
    });

    await updateTask({
      taskId: task.data.id,
      date: newDate,
      startTime: newTime,
      isStartTimeLocked,
    });
  };

  const onSelectDueDate = async (date: Date | null): Promise<void> => {
    const newDate = date?.toISOString() || null;

    setDueDateCalendarModalOpen(false);

    interopWithIos({ type: 'successHaptic' });
    await onUpdateTask('dueDate', newDate);
  };

  const onCompletionToggled = async (): Promise<void> => {
    interopWithIos({ type: 'successHaptic' });

    if (task.data.completed) {
      await storeTasksInDatabase([
        {
          ...task,
          data: {
            ...task.data,
            completed: false,
            completedAt: null,
            priorityOrder: null,
          },
          order: {
            ...task.order,
            priority: null,
          },
          meta: {
            ...task.meta,
            recentlyCompleted: false,
          },
        },
      ]);

      await uncompleteTask({ taskId: task.data.id }).then(async (response) => {
        if (response.data) {
          await storeTasksInDatabase([
            taskCombiner(response.data.uncompleteTask, task),
          ]);
        }
      });
    } else {
      await storeTasksInDatabase([
        {
          ...task,
          data: {
            ...task.data,
            completed: true,
            completedAt: new Date().getTime(),
            priorityOrder: null,
          },
          order: {
            ...task.order,
            date: task.order.date ? task.order.date + 0.1 : task.order.date,
          },
          meta: {
            ...task.meta,
            awaitingSpringTask: task.data.spring,
            recentlyCompleted: true,
          },
        },
      ]);

      await completeTask({ taskId: task.data.id }).then(async (response) => {
        if (response.data) {
          await storeTasksFromServerInDatabase(response.data.completeTask, {
            clearCompletionMetadata: true,
            overrideOrder: task.order,
          });
        }
      });
    }
  };

  const onDeleteTasks = async (): Promise<void> => {
    dispatch(removeTasks(selectedTasks));

    await deleteTasks({ taskIds: selectedTasks });
  };

  const onTogglePriority = async (): Promise<void> => {
    interopWithIos({ type: 'successHaptic' });

    const targetTaskIds = isFocused ? [task.data.id] : selectedTasks;

    if (task.order.priority) {
      await unprioritizeTasksInDatabase(targetTaskIds);

      await unprioritizeTasks({ ids: targetTaskIds }).then(async (response) => {
        if (response.data) {
          const { unprioritizeTasks } = response.data;

          await storeTasksInDatabase(
            unprioritizeTasks.map((unprioritizeTask) =>
              taskCombiner(unprioritizeTask, task)
            )
          );
        }
      });
    } else {
      await prioritizeTasksInDatabase(targetTaskIds);

      await prioritizeTasks({ ids: targetTaskIds }).then(async (response) => {
        if (response.data) {
          const { prioritizeTasks } = response.data;

          await storeTasksInDatabase(
            prioritizeTasks.map((prioritizeTask) =>
              taskCombiner(prioritizeTask, task)
            )
          );
        }
      });
    }
  };

  const onRemoveDate = async () => await onUpdateTask('date', null);

  useHotkeys(
    'shift+backspace',
    () => void onDeleteTasks(),
    { enabled: isSelected },
    [isSelected]
  );

  useHotkeys(
    'd',
    () => setDateCalendarModalOpen(true),
    { enabled: isSelected },
    [isSelected, task.data.date]
  );

  useHotkeys(
    'shift+d',
    () => setDueDateCalendarModalOpen(true),
    { enabled: isSelected },
    [isSelected, task.data.date]
  );

  useHotkeys(
    'shift+l',
    () => setTaskLinkModalOpen(true),
    { enabled: isSelected },
    [isSelected, task.data.link]
  );

  useHotkeys('p', () => void onTogglePriority(), { enabled: isSelected }, [
    isSelected,
    task.order.priority,
  ]);

  useHotkeys(
    'shift+p',
    () => {
      dispatch(setFloatingProjectSelectorTaskIds(selectedTasks));
    },
    { enabled: isSelected },
    [isSelected]
  );

  useHotkeys(
    'r',
    () => void onRemoveDate(),
    { enabled: isSelected && task.data.date },
    [isSelected, task.data.date]
  );

  useHotkeys(
    'space',
    () => void onCompletionToggled(),
    { enabled: isSelected },
    [isSelected, task.data.completed]
  );

  const selectTask = (): void => {
    if (!selectedTasks.includes(task.data.id)) {
      dispatch(setSelectedTasks([task.data.id], taskLocation));
      dispatch(setLastSelectedTask(task.data.id));
    }
  };

  const { breakpoint } = useBreakpoints();
  const isMobile = ['xs', 'sm'].includes(breakpoint);

  const isGhosting =
    isSelected && Boolean(draggingTask) && draggingTask !== task.data.id;

  return (
    <ContextMenu.Root onOpenChange={() => selectTask()}>
      <TaskSwiper
        onOpenCalendar={() => setDateCalendarModalOpen(true)}
        onTogglePriority={onTogglePriority}
        selectTask={() => {
          interopWithIos({ type: 'selectionHaptic' });

          dispatch(setSelectedTasks([task.data.id], taskLocation));
          dispatch(setLastSelectedTask(task.data.id));
        }}
        task={task}
        taskRef={taskRef}
      >
        {({ isSwiping }) => (
          <ContextMenu.Trigger disabled={isMobile}>
            <div
              className={classnames({ hidden: isFocused })}
              onClick={(e) => {
                if (e.type === 'contextmenu') {
                  selectTask();
                }
              }}
            >
              <TaskHeader
                focusTask={focusTask}
                isBeingSwiped={isSwiping}
                isDragging={isDragging}
                isGhosting={isGhosting}
                isHoveringTaskDropzone={isHoveringTaskDropzone}
                isNextTaskSelected={isNextTaskSelected}
                isPreviousTaskSelected={isPreviousTaskSelected}
                isSelected={isSelected}
                multiselectCount={selectedTasks.length}
                nameRef={nameRef}
                onClickTaskHeader={onClickTaskHeader}
                onCompletionToggled={onCompletionToggled}
                parentId={parentId}
                selectedDate={selectedDate}
                selectedProject={selectedProject}
                showDate={showDate}
                showPriority={showPriority}
                showProject={showProject}
                showStartTime={showStartTime}
                task={task}
                taskLocation={taskLocation}
                taskRef={taskRef}
                textClass={textClass}
                timeZone={timeZone}
              />
            </div>
          </ContextMenu.Trigger>
        )}
      </TaskSwiper>

      <AnimatePresence>
        {isFocused && (
          <motion.div
            variants={focusedTaskVariants}
            initial="enter"
            animate="entered"
            exit="exit"
          >
            <FocusedTask
              nameRef={nameRef}
              onCompletionToggled={onCompletionToggled}
              onDeleteTask={async () => {
                dispatch(removeTasks([task.data.id]));

                await deleteTasks({ taskIds: [task.data.id] });
              }}
              onOpenDateCalendarModal={() => setDateCalendarModalOpen(true)}
              onOpenDueDateCalendarModal={() =>
                setDueDateCalendarModalOpen(true)
              }
              onOpenTaskLinkModal={() => setTaskLinkModalOpen(true)}
              onTogglePriority={onTogglePriority}
              onUpdateTask={onUpdateTask}
              task={task}
              timeZone={timeZone}
              unfocusTask={unfocusTask}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <TaskContextMenu
        onDeleteTask={onDeleteTasks}
        onOpenDateCalendarModal={() => setDateCalendarModalOpen(true)}
        onOpenDueDateCalendarModal={() => setDueDateCalendarModalOpen(true)}
        onOpenTaskLinkModal={() => setTaskLinkModalOpen(true)}
        onUpdateTaskProject={async (projectId) => {
          await updateTasksProject({
            projectId,
            taskIds: selectedTasks,
          }).then((response) => {
            if (response.data?.updateTasksProject) {
              void storeTasksFromServerInDatabase(
                response.data.updateTasksProject
              );
            }
          });
        }}
        onTogglePriority={onTogglePriority}
        onRemoveDate={onRemoveDate}
        selectedTasks={selectedTasks}
        task={task}
      />

      {dateCalendarModalOpen && (
        <DateCalendarModal
          includeTimeOption
          onSave={onSelectDate}
          open={dateCalendarModalOpen}
          initialDate={task.data.date ? new Date(task.data.date) : null}
          initialLockedTime={task.data.isStartTimeLocked}
          initialTime={
            task.data.startTime
              ? timeToLuxon({
                  time: task.data.startTime,
                  inputZone: 'utc',
                  outputZone: timeZone,
                }).toFormat('HH:mm')
              : null
          }
          setIsOpen={setDateCalendarModalOpen}
          title="Set task date"
        />
      )}

      {dueDateCalendarModalOpen && (
        <DateCalendarModal
          onSave={onSelectDueDate}
          open={dueDateCalendarModalOpen}
          initialDate={task.data.dueDate ? new Date(task.data.dueDate) : null}
          setIsOpen={setDueDateCalendarModalOpen}
          title="Set task due date"
        />
      )}

      {taskLinkModalOpen && (
        <TaskLinkModal
          link={task.data.link}
          onSubmit={(link: string | null) => onUpdateTask('link', link)}
          open={taskLinkModalOpen}
          setIsOpen={setTaskLinkModalOpen}
        />
      )}
    </ContextMenu.Root>
  );
};

// This component can really hamper app performance if there are too many re-renders.
// This will only re-render the component with prop updates we actually care about in
// normal application usage.
const compareProps = (prevProps: TaskProps, nextProps: TaskProps): boolean => {
  const taskId = prevProps.task.data.id;

  const omitKeys = ['isDragging', 'onClickTaskHeader'];

  const filteredPrevProps = omit(prevProps, omitKeys);
  const filteredNextProps = omit(nextProps, omitKeys);

  const prevPropsWithoutSelectedTasks = omit(filteredPrevProps, [
    'selectedTasks',
    'selectedTasksLocation',
  ]);

  const nextPropsWithoutSelectedTasks = omit(filteredNextProps, [
    'selectedTasks',
    'selectedTasksLocation',
  ]);

  if (
    (isEqual(prevPropsWithoutSelectedTasks, nextPropsWithoutSelectedTasks) &&
      !prevProps.selectedTasks.includes(taskId) &&
      nextProps.selectedTasks.includes(taskId)) ||
    (prevProps.selectedTasks.includes(taskId) &&
      !nextProps.selectedTasks.includes(taskId))
  ) {
    return false;
  }

  return isEqual(filteredPrevProps, filteredNextProps);
};

export default memo(Task, compareProps);
