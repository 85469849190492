export type RecurrenceKind = 'completion' | 'schedule' | 'never';
export type RecurrenceRule = 'days' | 'hours' | 'months' | 'weeks' | 'years';

export interface Recurrence {
  friday: boolean;
  kind: RecurrenceKind;
  monday: boolean;
  rule: RecurrenceRule;
  saturday: boolean;
  separation: number;
  sunday: boolean;
  thursday: boolean;
  tuesday: boolean;
  wednesday: boolean;
}

const RECURRENCE_FRAGMENT = `
  friday
  kind
  monday
  rule
  saturday
  separation
  sunday
  thursday
  tuesday
  wednesday
`;

export default RECURRENCE_FRAGMENT;
