import { Layers, SquareDot, Trash } from 'lucide-react';
import React from 'react';
import { ContextMenu, MenuItem } from 'react-contextmenu';

import { Project } from '../../types';

const ProjectContextMenu = ({
  onDeleteProject,
  onDuplicateProject,
  onMoveProject,
  project,
}: {
  onDeleteProject: () => void;
  onDuplicateProject: () => void;
  onMoveProject: () => void;
  project: Project;
}): JSX.Element => {
  return (
    <ContextMenu
      id={`board-container-${project.data.id}`}
      className="rounded-md bg-white !bg-opacity-70 text-gray-900 dark:bg-mauve-dark-2 dark:text-white"
    >
      <MenuItem onClick={onMoveProject}>
        <SquareDot size={14} />
        Move Project
      </MenuItem>

      <MenuItem onClick={onDuplicateProject}>
        <Layers size={14} />
        Duplicate Project
      </MenuItem>

      <MenuItem onClick={onDeleteProject}>
        <Trash size={14} />
        Delete Project
      </MenuItem>
    </ContextMenu>
  );
};

export default ProjectContextMenu;
