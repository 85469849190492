import { format, isPast, isThisYear, isToday, isTomorrow } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { dateInUtc } from '../../utils/date';

export const formattedDate = (dateString: string): string => {
  const date = zonedTimeToUtc(
    dateString,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const dateFormat = isThisYear(date) ? 'MMMM dd' : 'MMMM dd, yyyy';

  return format(date, dateFormat);
};

export const dueDateIconColor = (dateString: string): string | undefined => {
  const dueDate = dateInUtc(dateString);

  if (isToday(dueDate) || isPast(dueDate)) {
    return 'text-red-500';
  }

  if (isTomorrow(dueDate)) {
    return 'text-orange-600 dark:text-orange-500';
  }
};

export const formattedDueDate = (
  dueDateString: string | undefined
): string | undefined => {
  if (dueDateString) {
    const dueDate = dateInUtc(dueDateString);

    if (isToday(dueDate)) {
      return 'Due today';
    }

    if (isTomorrow(dueDate)) {
      return 'Due tomorrow';
    }

    if (new Date(dueDate) < new Date()) {
      return 'Past due';
    }

    return `Due ${formattedDate(dueDateString)}`;
  }
};
