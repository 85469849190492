// @ts-strict-ignore

import React, { FormEvent } from 'react';

import { storeGroupsFromServerInDatabase } from '../../database/actions';
import {
  ContainerTypeEnum,
  useCreateGroupsMutation,
} from '../../graphql/generated-types';

import { CreatorForm } from './CreatorForm';
import { CreatorFormProps } from './CreatorModal';

export const GroupCreatorForm = (
  props: CreatorFormProps & {
    containerId: string | undefined;
    containerType: ContainerTypeEnum | undefined;
    handleModalChange: (isOpen: boolean) => void;
    refetchContainerData?: () => void;
  }
): JSX.Element => {
  const {
    containerId,
    containerType,
    createMultiple,
    handleModalChange,
    itemName,
    itemsToCreate,
  } = props;

  const [, createGroups] = useCreateGroupsMutation();

  const onCreateGroups = async (event?: FormEvent): Promise<void> => {
    event?.preventDefault();

    let names: string[];

    if (createMultiple) {
      names = itemsToCreate.filter(Boolean);
    } else {
      names = [itemName];
    }

    void createGroups({
      containerId,
      containerType,
      names,
    }).then(async ({ data }) => {
      await storeGroupsFromServerInDatabase(data.createGroups);
    });

    handleModalChange(false);
  };

  return (
    <CreatorForm
      {...props}
      onCreate={onCreateGroups}
      placeholder="Group title"
      submitButtonText={
        createMultiple
          ? `Create ${itemsToCreate.filter(Boolean).length} group${
              itemsToCreate.filter(Boolean).length === 1 ? '' : 's'
            }`
          : 'Create group'
      }
    />
  );
};
