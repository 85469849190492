import flatten from 'lodash/flatten';
import omit from 'lodash/omit';

import { db } from '../../database';
import {
  GroupFragment,
  TaskWithOrderEdge,
} from '../../graphql/generated-types';
import { Group, GroupType, Task, TaskOrder } from '../../types';

import { storeGroupsInDatabase } from './store-groups';
import { storeTasksInDatabase } from './store-tasks';
import { isTask } from './utils';

export const storeGroupsFromServerInDatabase = async (
  groups: GroupFragment[]
): Promise<void> => {
  const groupsWithState: Group[] = groups.map((group) => {
    const groupType = (): GroupType => {
      if (group.projectId) {
        return 'project';
      }

      if (group.date) {
        return 'date';
      }

      return 'inbox';
    };

    return {
      data: omit(group, ['tasks']),
      meta: {
        type: groupType(),
      },
    };
  });

  const tasks: Task[] = flatten(
    groups.map((group) => {
      return (group?.tasks?.edges || [])
        .map((edge: TaskWithOrderEdge) => {
          if (!edge?.node) {
            return undefined;
          }

          return {
            data: {
              ...edge.node,
              projectId: edge.node.project?.id,
            },
            order: buildOrder({
              edge,
              group: groupsWithState.find((g) => g.data.id === group.id),
            }),
            meta: {
              awaitingSpringTask: false,
              recentlyCompleted: false,
            },
          };
        })
        .filter(isTask);
    })
  );

  const existingTaskIds = tasks.map((task) => task.data.id);
  const existingTasks = await db.tasks.bulkGet(existingTaskIds);

  const newTasks = tasks.map((task) => {
    const matchingExistingTask = existingTasks.find(
      (existingTask) => existingTask?.data.id === task.data.id
    );

    if (!matchingExistingTask) {
      return task;
    }

    // Preserve order of any existing tasks that are being overwritten
    return {
      ...task,
      order: {
        ...task.order,
        inbox: task.order.inbox ?? matchingExistingTask.order.inbox,
        project: task.order.project ?? matchingExistingTask.order.project,
        date: task.order.date ?? matchingExistingTask.order.date,
        priority: matchingExistingTask.order.priority,
      },
      meta: {
        ...task.meta,
        recentlyCompleted: matchingExistingTask.meta.recentlyCompleted,
      },
    };
  });

  // If we query for a group we want to check the task table to see if there were any tasks
  // previously in that group (but may have been moved to another date or project or deleted).
  // If we find any we can assume they are out of date and invalidate the tasks in the database
  await db.tasks
    .where('data.groupIds')
    .anyOf(groups.map((group) => group.id))
    .and((task) => !newTasks.map((task) => task.data.id).includes(task.data.id))
    .delete();

  await storeGroupsInDatabase(groupsWithState);
  await storeTasksInDatabase(newTasks);
};

const buildOrder = ({
  edge,
  group,
}: {
  edge: Omit<TaskWithOrderEdge, 'cursor'>;
  group: Group | undefined;
}): TaskOrder => {
  let order = {
    priority: edge?.node?.priorityOrder,
    date: null,
    inbox: null,
    project: null,
  };

  if (group?.meta) {
    order = {
      ...order,
      [group.meta.type]: edge.order,
    };
  }

  return order;
};
