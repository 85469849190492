import Cookies from 'js-cookie';

import { db } from '../database';

export const isLoggedIn = (): boolean => {
  return !!Cookies.get('user_session');
};

export const resetAuthentication = async (): Promise<void> => {
  Cookies.remove('user_session');

  await db.delete();
};
