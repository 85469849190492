import * as RadixTooltip from '@radix-ui/react-tooltip';
import React from 'react';

import './Tooltip.css';

type TextSize = 'xs' | 'sm' | 'md' | 'lg';

const Tooltip = ({
  children,
  delayDuration = 500,
  maxWidth = 'fit-content',
  side = 'top',
  sideOffset = 0,
  skipDelayDuration = 1000,
  text,
  textSize = 'md',
}: {
  children: React.ReactNode;
  delayDuration?: number;
  maxWidth?: string | number;
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  skipDelayDuration?: number;
  text: string | JSX.Element | undefined;
  textSize?: TextSize;
}): JSX.Element => (
  <RadixTooltip.Provider
    delayDuration={delayDuration}
    skipDelayDuration={skipDelayDuration}
  >
    <RadixTooltip.Root>
      <RadixTooltip.Trigger
        className="border-0 bg-transparent p-0 focus-visible:outline-none"
        asChild
      >
        {children}
      </RadixTooltip.Trigger>

      <RadixTooltip.Portal>
        <RadixTooltip.Content
          style={{ maxWidth }}
          className={`tooltip-content bg-neutral-900 ${textSizeClass(
            textSize
          )}`}
          side={side}
          sideOffset={sideOffset}
        >
          {text}
          <RadixTooltip.Arrow
            className="tooltip-arrow fill-neutral-900"
            height={6}
          />
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  </RadixTooltip.Provider>
);

const textSizeClass = (textSize: TextSize): string => {
  switch (textSize) {
    case 'xs':
      return 'text-xs';
    case 'sm':
      return 'text-sm';
    case 'md':
      return 'text-base';
    case 'lg':
      return 'text-lg';
    default:
      return 'text-base';
  }
};

export default Tooltip;
