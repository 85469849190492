import { AlertTriangle, AtSign } from 'lucide-react';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { EmailErrors as LoginEmailErrors } from '../Login';
import { EmailErrors as SignUpEmailErrors } from '../SignUp';

export const Email = ({
  errors,
  email,
  onChange,
}: {
  errors: LoginEmailErrors | SignUpEmailErrors;
  email: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}): JSX.Element => {
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if ((errors as LoginEmailErrors).includes('invalid_credentials')) {
      setError('The provided email or password are invalid.');
    } else if (
      (errors as SignUpEmailErrors).includes('account_already_exists')
    ) {
      setError('An account already exists with this email.');
    } else {
      setError(null);
    }
  }, [errors]);

  return (
    <>
      <div className="mb-2 flex items-center justify-between">
        <label htmlFor="email" className="text-violet-200">
          Email
        </label>

        {error && <span className="text-xs text-red-500">{error}</span>}
      </div>

      <div className="mt-2 flex w-full rounded border border-solid border-violet-500 bg-gray-900">
        <label
          htmlFor="email"
          className="flex cursor-pointer items-center justify-center p-4"
        >
          <>
            {(errors?.length || 0) > 0 ? (
              <AlertTriangle
                size={20}
                className="text-red-500 drop-shadow-auth-icon-error"
              />
            ) : (
              <AtSign
                size={20}
                className="text-violet-600 drop-shadow-auth-icon"
              />
            )}
          </>
        </label>

        <input
          id="email"
          autoFocus
          name="email"
          type="email"
          value={email}
          onChange={onChange}
          className="w-full border-0 border-l border-solid border-violet-500 bg-gray-900 bg-transparent p-3 text-lg font-semibold text-white transition-all focus:bg-violet-900 focus:bg-opacity-20 focus:outline-none"
        />
      </div>
    </>
  );
};
