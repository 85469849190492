import { Button, Text } from '@radix-ui/themes';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  TimeZoneChangeRuleEnum,
  useUpdateTimeZoneMutation,
  useUserQuery,
} from '../../graphql/generated-types';
import { setTimeZone } from '../../reducers/actions';
import Modal from '../Modal';

export const TimeZoneChecker = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch();

  const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [userData] = useUserQuery();
  const [, updateTimeZone] = useUpdateTimeZoneMutation();

  const [changeTimeZoneModalOpen, setChangeTimeZoneModalOpen] = useState(false);

  useEffect(() => {
    const userSettings = userData.data?.currentUser?.settings;

    dispatch(setTimeZone(userSettings?.timeZone || detectedTimeZone));

    if (userSettings && !userSettings.timeZone) {
      updateTimeZone({
        timeZone: detectedTimeZone,
      });
    }

    if (
      userSettings &&
      userSettings.timeZone !== detectedTimeZone &&
      userSettings.timeZoneChangeRule ===
        TimeZoneChangeRuleEnum.AskBeforeUpdating
    ) {
      setChangeTimeZoneModalOpen(true);
    }
  }, [userData?.data?.currentUser?.settings?.timeZone, detectedTimeZone]);

  return (
    <>
      <Modal
        open={changeTimeZoneModalOpen}
        setIsOpen={setChangeTimeZoneModalOpen}
        title="Time zone change detected"
      >
        <>
          <Text as="p" size="2" className="px-4" mb="5">
            A change in your time zone has been detected. Would you like to
            update your time zone to be set to{' '}
            <strong>
              {detectedTimeZone} (GMT{' '}
              {DateTime.fromJSDate(new Date(), {
                zone: detectedTimeZone,
              }).toFormat('ZZ')}
              )
            </strong>
            ?
          </Text>

          <div className="flex items-center justify-end gap-2 border-0 border-t border-solid border-gray-200 px-2 py-3 dark:border-gray-600">
            <Button
              color="gray"
              variant="soft"
              onClick={() => setChangeTimeZoneModalOpen(false)}
            >
              Keep Current Time Zone
            </Button>

            <Button
              variant="solid"
              onClick={async () => {
                updateTimeZone({
                  timeZone: detectedTimeZone,
                });

                setChangeTimeZoneModalOpen(false);
              }}
            >
              Update Time Zone
            </Button>
          </div>
        </>
      </Modal>
      {children}
    </>
  );
};
