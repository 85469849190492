import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** ISO Date. */
  Date: any;
  /** Epoch time stamp. */
  DateTime: any;
  /** Formatted Time object */
  Time: any;
};

export enum BadgeCountModeEnum {
  Due = 'DUE',
  None = 'NONE',
  Priority = 'PRIORITY',
  PriorityDue = 'PRIORITY_DUE'
}

export type Board = {
  __typename?: 'Board';
  archivedAt?: Maybe<Scalars['DateTime']>;
  emoji?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastViewedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  projectColumns: Array<ProjectColumn>;
  projectCompletedProjectColumnId?: Maybe<Scalars['ID']>;
  taskCompletedProjectColumnId?: Maybe<Scalars['ID']>;
};

export type Container = {
  collapseCompleted: Scalars['Boolean'];
  containerType: ContainerTypeEnum;
  groups: Array<Group>;
  id: Scalars['ID'];
  noteBody?: Maybe<Scalars['String']>;
  state?: Maybe<DiaryStateEnum>;
  supportsNotes: Scalars['Boolean'];
  tasks: TaskConnection;
};


export type ContainerTasksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum ContainerTypeEnum {
  Diary = 'DIARY',
  Inbox = 'INBOX',
  Project = 'PROJECT'
}

export type CreateTaskGroupsInput = {
  diaryGroupId?: Maybe<Scalars['ID']>;
  projectGroupId?: Maybe<Scalars['ID']>;
};

export type Diary = Container & Notable & {
  __typename?: 'Diary';
  collapseCompleted: Scalars['Boolean'];
  containerType: ContainerTypeEnum;
  date: Scalars['Date'];
  groups: Array<Group>;
  id: Scalars['ID'];
  noteBody?: Maybe<Scalars['String']>;
  notes: Array<Note>;
  state?: Maybe<DiaryStateEnum>;
  supportsNotes: Scalars['Boolean'];
  tasks: TaskConnection;
  user: User;
};


export type DiaryTasksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum DiaryStateEnum {
  Fresh = 'FRESH',
  Planned = 'PLANNED'
}

/** The result of duplicating a project */
export type DuplicateProjectResult = Project | ProjectNotFound;

/** The result of an attempt to enable OTP */
export type EnableOtpResult = InvalidOtpAttempt | OtpEnabled | UserAlreadyHasOtp;

/** The result of generating a new OTP */
export type GenerateNewOtpResult = NewOtpGenerated | UserAlreadyHasOtp;

export type Group = {
  __typename?: 'Group';
  collapsed?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  keepTasks: Scalars['Boolean'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['ID']>;
  tasks: TaskConnection;
  user: User;
};


export type GroupTasksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type Inbox = Container & {
  __typename?: 'Inbox';
  collapseCompleted: Scalars['Boolean'];
  containerType: ContainerTypeEnum;
  groups: Array<Group>;
  id: Scalars['ID'];
  noteBody?: Maybe<Scalars['String']>;
  state?: Maybe<DiaryStateEnum>;
  supportsNotes: Scalars['Boolean'];
  tasks: TaskConnection;
};


export type InboxTasksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type InvalidOtpAttempt = {
  __typename?: 'InvalidOtpAttempt';
  message: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  archiveBoard: Array<Board>;
  completeProject: Project;
  completeTask: Array<Task>;
  createBoard: Board;
  createBoards: Array<Board>;
  createGroups: Array<Group>;
  createNote: Note;
  createProject: Project;
  createProjectColumn: ProjectColumn;
  createProjects: Array<Project>;
  createTaskSchedule: TaskSchedule;
  createTasks: Array<Task>;
  deleteBoard: Board;
  deleteGroup: Group;
  deleteNote: Note;
  deleteProject: Project;
  deleteTask: Task;
  deleteTaskSchedule: TaskSchedule;
  deleteTasks: Array<Task>;
  duplicateProject: DuplicateProjectResult;
  enableOtp: EnableOtpResult;
  generateNewOtp: GenerateNewOtpResult;
  moveTasks: Array<Task>;
  persistGroupOrder: Array<Group>;
  persistPriorityOrder: Array<Task>;
  persistProjectColumnOrder: Array<ProjectColumn>;
  persistProjectOrder: Array<ProjectColumn>;
  persistTaskOrder: Array<Task>;
  prioritizeTasks: Array<Task>;
  registerUser: LoginResponse;
  springProject: Project;
  tagTask: Task;
  unarchiveBoard: Array<Board>;
  uncompleteProject: Project;
  uncompleteTask: Task;
  unprioritizeTasks: Array<Task>;
  unspringProject: Project;
  updateBoard: Board;
  updateContainer: Container;
  updateDiary: Diary;
  updateGroup: Group;
  updateNote: UpdateNoteResult;
  updateProject: Project;
  updateProjectColumn: ProjectColumn;
  updateTask: Task;
  updateTaskSchedule: TaskSchedule;
  updateTasksProject: Array<Task>;
  updateTimeZone: UpdateTimeZoneResult;
  updateUserSettings: User;
};


export type MutationArchiveBoardArgs = {
  boardId: Scalars['ID'];
};


export type MutationCompleteProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationCompleteTaskArgs = {
  taskId: Scalars['ID'];
};


export type MutationCreateBoardsArgs = {
  names: Array<Scalars['String']>;
};


export type MutationCreateGroupsArgs = {
  containerId: Scalars['ID'];
  containerType: ContainerTypeEnum;
  names: Array<Scalars['String']>;
};


export type MutationCreateNoteArgs = {
  date?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
};


export type MutationCreateProjectArgs = {
  boardId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  projectColumnId?: Maybe<Scalars['ID']>;
  sourceTaskId?: Maybe<Scalars['ID']>;
};


export type MutationCreateProjectColumnArgs = {
  boardId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationCreateProjectsArgs = {
  boardId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  names: Array<Scalars['String']>;
  projectColumnId?: Maybe<Scalars['ID']>;
};


export type MutationCreateTaskScheduleArgs = {
  taskId: Scalars['ID'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
};


export type MutationCreateTasksArgs = {
  after?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  groups?: Maybe<CreateTaskGroupsInput>;
  link?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  names: Array<Scalars['String']>;
  prioritized?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
  newProject?: Maybe<NewProjectTaskInput>;
  tagSlug?: Maybe<Scalars['String']>;
};


export type MutationDeleteBoardArgs = {
  boardId: Scalars['ID'];
};


export type MutationDeleteGroupArgs = {
  deleteTasks?: Maybe<Scalars['Boolean']>;
  groupId: Scalars['ID'];
};


export type MutationDeleteNoteArgs = {
  noteId: Scalars['ID'];
};


export type MutationDeleteProjectArgs = {
  deleteTasks?: Maybe<Scalars['Boolean']>;
  projectId: Scalars['ID'];
};


export type MutationDeleteTaskArgs = {
  taskId: Scalars['ID'];
};


export type MutationDeleteTaskScheduleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTasksArgs = {
  taskIds: Array<Scalars['ID']>;
};


export type MutationDuplicateProjectArgs = {
  projectId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type MutationEnableOtpArgs = {
  otpAttempt: Scalars['String'];
  otpSecret: Scalars['String'];
};


export type MutationMoveTasksArgs = {
  date: Scalars['Date'];
  taskIds: Array<Scalars['ID']>;
};


export type MutationPersistGroupOrderArgs = {
  order?: Maybe<Array<OrderInput>>;
};


export type MutationPersistPriorityOrderArgs = {
  order?: Maybe<Array<OrderInput>>;
};


export type MutationPersistProjectColumnOrderArgs = {
  order?: Maybe<Array<OrderInput>>;
};


export type MutationPersistProjectOrderArgs = {
  order?: Maybe<Array<OrderInput>>;
};


export type MutationPersistTaskOrderArgs = {
  taskOrder?: Maybe<Array<OrderInput>>;
};


export type MutationPrioritizeTasksArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationRegisterUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  secretCode: Scalars['String'];
};


export type MutationSpringProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationTagTaskArgs = {
  tagId: Scalars['ID'];
  taskId: Scalars['ID'];
};


export type MutationUnarchiveBoardArgs = {
  boardId: Scalars['ID'];
};


export type MutationUncompleteProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationUncompleteTaskArgs = {
  taskId: Scalars['ID'];
};


export type MutationUnprioritizeTasksArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUnspringProjectArgs = {
  projectId: Scalars['ID'];
};


export type MutationUpdateBoardArgs = {
  emoji?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  boardId: Scalars['ID'];
  projectCompletedProjectColumnId?: Maybe<Scalars['ID']>;
  taskCompletedProjectColumnId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateContainerArgs = {
  collapseCompleted?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
  noteBody?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  state?: Maybe<DiaryStateEnum>;
};


export type MutationUpdateDiaryArgs = {
  date: Scalars['Date'];
  noteBody: Scalars['String'];
};


export type MutationUpdateGroupArgs = {
  collapsed?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
  groupId: Scalars['ID'];
  keepTasks?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdateNoteArgs = {
  body?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  hidePreview?: Maybe<Scalars['Boolean']>;
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  noteId: Scalars['ID'];
  projectId?: Maybe<Scalars['ID']>;
};


export type MutationUpdateProjectArgs = {
  boardId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
};


export type MutationUpdateProjectColumnArgs = {
  collapsed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  projectColumnId: Scalars['ID'];
  sortDirection?: Maybe<ProjectColumnSortDirectionEnum>;
  sortType?: Maybe<ProjectColumnSortTypeEnum>;
};


export type MutationUpdateTaskArgs = {
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  link?: Maybe<Scalars['String']>;
  isStartTimeLocked?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  recurrence?: Maybe<RecurrenceInput>;
  startTime?: Maybe<Scalars['Time']>;
  taskId: Scalars['ID'];
};


export type MutationUpdateTaskScheduleArgs = {
  id: Scalars['ID'];
  startAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
};


export type MutationUpdateTasksProjectArgs = {
  projectId: Scalars['ID'];
  taskIds: Array<Scalars['ID']>;
};


export type MutationUpdateTimeZoneArgs = {
  timeZone: Scalars['String'];
};


export type MutationUpdateUserSettingsArgs = {
  badgeCountMode?: Maybe<BadgeCountModeEnum>;
};

export type NewOtpGenerated = {
  __typename?: 'NewOtpGenerated';
  darkQrCode: Scalars['String'];
  lightQrCode: Scalars['String'];
  otpSecret: Scalars['String'];
};

export type NewProjectTaskInput = {
  name: Scalars['String'];
  boardId: Scalars['ID'];
};

export type Notable = {
  notes: Array<Note>;
};

export type Note = {
  __typename?: 'Note';
  body?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  hidePreview: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  project?: Maybe<Project>;
  updatedAt: Scalars['DateTime'];
};

export type NoteUpdateOutdated = {
  __typename?: 'NoteUpdateOutdated';
  note: Note;
};

export type NoteUpdated = {
  __typename?: 'NoteUpdated';
  note: Note;
};

export type OrderInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
  destinationGroupId?: Maybe<Scalars['ID']>;
  sourceGroupId?: Maybe<Scalars['ID']>;
  projectColumnId?: Maybe<Scalars['ID']>;
};

export type OtpEnabled = {
  __typename?: 'OtpEnabled';
  message: Scalars['String'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Project = Container & Notable & {
  __typename?: 'Project';
  collapseCompleted: Scalars['Boolean'];
  completed: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  containerType: ContainerTypeEnum;
  date?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  groups: Array<Group>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  noteBody?: Maybe<Scalars['String']>;
  notes: Array<Note>;
  order?: Maybe<Scalars['Int']>;
  projectColumn: ProjectColumn;
  springEnabled: Scalars['Boolean'];
  state?: Maybe<DiaryStateEnum>;
  supportsNotes: Scalars['Boolean'];
  tasks: TaskConnection;
};


export type ProjectTasksArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProjectColumn = {
  __typename?: 'ProjectColumn';
  board: Board;
  collapsed: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  projects?: Maybe<Array<Project>>;
  sortDirection?: Maybe<ProjectColumnSortDirectionEnum>;
  sortType: ProjectColumnSortTypeEnum;
};

export enum ProjectColumnSortDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum ProjectColumnSortTypeEnum {
  CompletedAt = 'COMPLETED_AT',
  CreatedAt = 'CREATED_AT',
  EndDate = 'END_DATE',
  Manual = 'MANUAL',
  Name = 'NAME',
  Progress = 'PROGRESS',
  StartDate = 'START_DATE'
}

export type ProjectNotFound = {
  __typename?: 'ProjectNotFound';
  projectId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  board?: Maybe<Board>;
  boards: Array<Board>;
  container: Container;
  currentUser: User;
  diary: Diary;
  me?: Maybe<User>;
  note?: Maybe<Note>;
  notes?: Maybe<Array<Note>>;
  project?: Maybe<Project>;
  projectColumns?: Maybe<Array<ProjectColumn>>;
  projects?: Maybe<Array<Project>>;
  search: Search;
  tags?: Maybe<Array<Tag>>;
  taskSchedules?: Maybe<Array<TaskSchedule>>;
  tasks?: Maybe<Array<Task>>;
};


export type QueryBoardArgs = {
  boardId?: Maybe<Scalars['ID']>;
};


export type QueryContainerArgs = {
  date?: Maybe<Scalars['Date']>;
  inbox?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
};


export type QueryDiaryArgs = {
  date: Scalars['Date'];
};


export type QueryNoteArgs = {
  noteId: Scalars['ID'];
};


export type QueryNotesArgs = {
  date?: Maybe<Scalars['Date']>;
  projectId?: Maybe<Scalars['ID']>;
  query?: Maybe<Scalars['String']>;
};


export type QueryProjectArgs = {
  projectId: Scalars['ID'];
};


export type QueryProjectsArgs = {
  date?: Maybe<Scalars['Date']>;
  limit?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  boardId?: Maybe<Scalars['ID']>;
};


export type QuerySearchArgs = {
  query: Scalars['String'];
};


export type QueryTaskSchedulesArgs = {
  date: Scalars['Date'];
};


export type QueryTasksArgs = {
  completed?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
  dueDate?: Maybe<Scalars['Date']>;
  focus?: Maybe<Scalars['Boolean']>;
  inbox?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
  taskIds?: Maybe<Array<Scalars['ID']>>;
};

export type Recurrence = {
  __typename?: 'Recurrence';
  friday: Scalars['Boolean'];
  id: Scalars['ID'];
  kind: Scalars['String'];
  monday: Scalars['Boolean'];
  rule?: Maybe<Scalars['String']>;
  saturday: Scalars['Boolean'];
  separation?: Maybe<Scalars['Int']>;
  sunday: Scalars['Boolean'];
  thursday: Scalars['Boolean'];
  tuesday: Scalars['Boolean'];
  wednesday: Scalars['Boolean'];
};

export type RecurrenceInput = {
  id?: Maybe<Scalars['ID']>;
  friday?: Maybe<Scalars['Boolean']>;
  kind: Scalars['String'];
  monday?: Maybe<Scalars['Boolean']>;
  rule?: Maybe<Scalars['String']>;
  separation?: Maybe<Scalars['Int']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
};

export type Search = {
  __typename?: 'Search';
  boards: Array<Board>;
  notes: Array<Note>;
  projects: Array<Project>;
  tasks: Array<TaskSearchResult>;
};

export type Subscription = {
  __typename?: 'Subscription';
  groupsUpdated: Array<Group>;
  tasksCreated: Array<Task>;
  tasksUpdated: Array<Task>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Task = {
  __typename?: 'Task';
  completed: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  groupIds: Array<Scalars['ID']>;
  /** @deprecated No longer needed, only using group_ids */
  groups: Array<Group>;
  id: Scalars['ID'];
  isRecurring: Scalars['Boolean'];
  isStartTimeLocked: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orders: TaskOrder;
  priorityOrder?: Maybe<Scalars['Int']>;
  project?: Maybe<Project>;
  recurrence?: Maybe<Recurrence>;
  spring: Scalars['Boolean'];
  startTime?: Maybe<Scalars['Time']>;
  tags?: Maybe<Array<Tag>>;
};

/** The connection type for Task. */
export type TaskConnection = {
  __typename?: 'TaskConnection';
  completedCount: Scalars['Int'];
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TaskWithOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Task>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TaskOrder = {
  __typename?: 'TaskOrder';
  dateOrder?: Maybe<Scalars['Int']>;
  projectOrder?: Maybe<Scalars['Int']>;
};

export type TaskSchedule = {
  __typename?: 'TaskSchedule';
  endAt: Scalars['DateTime'];
  id: Scalars['ID'];
  startAt: Scalars['DateTime'];
  task: Task;
};

export type TaskSearchResult = {
  __typename?: 'TaskSearchResult';
  score: Scalars['Float'];
  task: Task;
};

/** An edge in a connection. */
export type TaskWithOrderEdge = {
  __typename?: 'TaskWithOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Task>;
  order?: Maybe<Scalars['Int']>;
};

export enum TimeZoneChangeRuleEnum {
  AskBeforeUpdating = 'ASK_BEFORE_UPDATING',
  Manual = 'MANUAL'
}

export type TimeZoneUpdated = {
  __typename?: 'TimeZoneUpdated';
  user: User;
};

export type UnsupportedTimeZone = {
  __typename?: 'UnsupportedTimeZone';
  timeZone: Scalars['String'];
};

/** The result of updating a note */
export type UpdateNoteResult = NoteUpdateOutdated | NoteUpdated;

/** The result of updating the user's time zone */
export type UpdateTimeZoneResult = TimeZoneUpdated | UnsupportedTimeZone;

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  isMfaEnabled: Scalars['Boolean'];
  settings: UserSetting;
};

export type UserAlreadyHasOtp = {
  __typename?: 'UserAlreadyHasOtp';
  message: Scalars['String'];
};

export type UserSetting = {
  __typename?: 'UserSetting';
  badgeCountMode: BadgeCountModeEnum;
  timeZone?: Maybe<Scalars['String']>;
  timeZoneChangeRule: TimeZoneChangeRuleEnum;
};

export type BoardFragment = (
  { __typename?: 'Board' }
  & Pick<Board, 'id' | 'archivedAt' | 'emoji' | 'lastViewedAt' | 'name' | 'projectCompletedProjectColumnId' | 'taskCompletedProjectColumnId'>
  & { projectColumns: Array<(
    { __typename?: 'ProjectColumn' }
    & ProjectColumnFragment
  )> }
);

type Container_Diary_Fragment = (
  { __typename?: 'Diary' }
  & Pick<Diary, 'id' | 'collapseCompleted' | 'containerType' | 'noteBody' | 'supportsNotes'>
  & { groups: Array<(
    { __typename?: 'Group' }
    & GroupFragment
  )> }
);

type Container_Inbox_Fragment = (
  { __typename?: 'Inbox' }
  & Pick<Inbox, 'id' | 'collapseCompleted' | 'containerType' | 'noteBody' | 'supportsNotes'>
  & { groups: Array<(
    { __typename?: 'Group' }
    & GroupFragment
  )> }
);

type Container_Project_Fragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'collapseCompleted' | 'containerType' | 'noteBody' | 'supportsNotes'>
  & { groups: Array<(
    { __typename?: 'Group' }
    & GroupFragment
  )> }
);

export type ContainerFragment = Container_Diary_Fragment | Container_Inbox_Fragment | Container_Project_Fragment;

export type GroupFragment = (
  { __typename?: 'Group' }
  & Pick<Group, 'id' | 'collapsed' | 'date' | 'keepTasks' | 'name' | 'order' | 'projectId'>
  & { tasks: (
    { __typename?: 'TaskConnection' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'TaskWithOrderEdge' }
      & Pick<TaskWithOrderEdge, 'order'>
      & { node?: Maybe<(
        { __typename?: 'Task' }
        & TaskFragment
      )> }
    )>>> }
  ) }
);

export type NoteFragment = (
  { __typename?: 'Note' }
  & Pick<Note, 'id' | 'body' | 'hidePreview' | 'name' | 'updatedAt'>
);

export type ProjectColumnFragment = (
  { __typename?: 'ProjectColumn' }
  & Pick<ProjectColumn, 'id' | 'name' | 'order' | 'collapsed' | 'sortDirection' | 'sortType'>
  & { board: (
    { __typename?: 'Board' }
    & Pick<Board, 'id'>
  ), projects?: Maybe<Array<(
    { __typename?: 'Project' }
    & ProjectFragment
  )>> }
);

export type ProjectFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'endDate' | 'completed' | 'completedAt' | 'date' | 'name' | 'order' | 'springEnabled'>
  & { projectColumn: (
    { __typename?: 'ProjectColumn' }
    & Pick<ProjectColumn, 'id'>
    & { board: (
      { __typename?: 'Board' }
      & Pick<Board, 'id' | 'name'>
    ) }
  ), tasks: (
    { __typename?: 'TaskConnection' }
    & Pick<TaskConnection, 'completedCount' | 'totalCount'>
  ) }
);

export type TaskFragment = (
  { __typename?: 'Task' }
  & Pick<Task, 'id' | 'completed' | 'completedAt' | 'date' | 'description' | 'dueDate' | 'groupIds' | 'isRecurring' | 'isStartTimeLocked' | 'link' | 'name' | 'priorityOrder' | 'spring' | 'startTime'>
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
  )>, recurrence?: Maybe<(
    { __typename?: 'Recurrence' }
    & Pick<Recurrence, 'friday' | 'kind' | 'monday' | 'rule' | 'saturday' | 'separation' | 'sunday' | 'thursday' | 'tuesday' | 'wednesday'>
  )> }
);

export type TaskScheduleFragment = (
  { __typename?: 'TaskSchedule' }
  & Pick<TaskSchedule, 'id' | 'startAt' | 'endAt'>
  & { task: (
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name'>
  ) }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'isMfaEnabled'>
  & { settings: (
    { __typename?: 'UserSetting' }
    & Pick<UserSetting, 'badgeCountMode' | 'timeZone' | 'timeZoneChangeRule'>
  ) }
);

export type ArchiveBoardMutationVariables = Exact<{
  boardId: Scalars['ID'];
}>;


export type ArchiveBoardMutation = (
  { __typename?: 'Mutation' }
  & { archiveBoard: Array<(
    { __typename?: 'Board' }
    & BoardFragment
  )> }
);

export type CompleteProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type CompleteProjectMutation = (
  { __typename?: 'Mutation' }
  & { completeProject: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type CompleteTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type CompleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { completeTask: Array<(
    { __typename?: 'Task' }
    & TaskFragment
  )> }
);

export type CreateBoardsMutationVariables = Exact<{
  names: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateBoardsMutation = (
  { __typename?: 'Mutation' }
  & { createBoards: Array<(
    { __typename?: 'Board' }
    & BoardFragment
  )> }
);

export type CreateGroupsMutationVariables = Exact<{
  containerId: Scalars['ID'];
  containerType: ContainerTypeEnum;
  names: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateGroupsMutation = (
  { __typename?: 'Mutation' }
  & { createGroups: Array<(
    { __typename?: 'Group' }
    & GroupFragment
  )> }
);

export type CreateNoteMutationVariables = Exact<{
  date?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type CreateNoteMutation = (
  { __typename?: 'Mutation' }
  & { createNote: (
    { __typename?: 'Note' }
    & NoteFragment
  ) }
);

export type CreateProjectColumnMutationVariables = Exact<{
  boardId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type CreateProjectColumnMutation = (
  { __typename?: 'Mutation' }
  & { createProjectColumn: (
    { __typename?: 'ProjectColumn' }
    & ProjectColumnFragment
  ) }
);

export type CreateProjectMutationVariables = Exact<{
  boardId?: Maybe<Scalars['ID']>;
  projectColumnId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
}>;


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createProject: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type CreateProjectsMutationVariables = Exact<{
  boardId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  projectColumnId?: Maybe<Scalars['ID']>;
  names: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateProjectsMutation = (
  { __typename?: 'Mutation' }
  & { createProjects: Array<(
    { __typename?: 'Project' }
    & ProjectFragment
  )> }
);

export type CreateTaskScheduleMutationVariables = Exact<{
  taskId: Scalars['ID'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
}>;


export type CreateTaskScheduleMutation = (
  { __typename?: 'Mutation' }
  & { createTaskSchedule: (
    { __typename: 'TaskSchedule' }
    & TaskScheduleFragment
  ) }
);

export type CreateTasksMutationVariables = Exact<{
  after?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['Date']>;
  groups?: Maybe<CreateTaskGroupsInput>;
  link?: Maybe<Scalars['String']>;
  names: Array<Scalars['String']> | Scalars['String'];
  prioritized?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
  newProject?: Maybe<NewProjectTaskInput>;
}>;


export type CreateTasksMutation = (
  { __typename?: 'Mutation' }
  & { createTasks: Array<(
    { __typename?: 'Task' }
    & TaskFragment
  )> }
);

export type DeleteBoardMutationVariables = Exact<{
  boardId: Scalars['ID'];
}>;


export type DeleteBoardMutation = (
  { __typename?: 'Mutation' }
  & { deleteBoard: (
    { __typename?: 'Board' }
    & BoardFragment
  ) }
);

export type DeleteGroupMutationVariables = Exact<{
  groupId: Scalars['ID'];
}>;


export type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroup: (
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
  ) }
);

export type DeleteNoteMutationVariables = Exact<{
  noteId: Scalars['ID'];
}>;


export type DeleteNoteMutation = (
  { __typename?: 'Mutation' }
  & { deleteNote: (
    { __typename?: 'Note' }
    & NoteFragment
  ) }
);

export type DeleteProjectMutationVariables = Exact<{
  deleteTasks?: Maybe<Scalars['Boolean']>;
  projectId: Scalars['ID'];
}>;


export type DeleteProjectMutation = (
  { __typename?: 'Mutation' }
  & { deleteProject: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type DeleteTaskScheduleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTaskScheduleMutation = (
  { __typename?: 'Mutation' }
  & { deleteTaskSchedule: (
    { __typename?: 'TaskSchedule' }
    & TaskScheduleFragment
  ) }
);

export type DeleteTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type DeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { deleteTask: (
    { __typename?: 'Task' }
    & TaskFragment
  ) }
);

export type DeleteTasksMutationVariables = Exact<{
  taskIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteTasksMutation = (
  { __typename?: 'Mutation' }
  & { deleteTasks: Array<(
    { __typename?: 'Task' }
    & TaskFragment
  )> }
);

export type DuplicateProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
}>;


export type DuplicateProjectMutation = (
  { __typename?: 'Mutation' }
  & { duplicateProject: (
    { __typename: 'Project' }
    & ProjectFragment
  ) | (
    { __typename: 'ProjectNotFound' }
    & Pick<ProjectNotFound, 'projectId'>
  ) }
);

export type EnableOtpMutationVariables = Exact<{
  otpAttempt: Scalars['String'];
  otpSecret: Scalars['String'];
}>;


export type EnableOtpMutation = (
  { __typename?: 'Mutation' }
  & { enableOtp: { __typename: 'InvalidOtpAttempt' } | { __typename: 'OtpEnabled' } | { __typename: 'UserAlreadyHasOtp' } }
);

export type GenerateNewOtpMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateNewOtpMutation = (
  { __typename?: 'Mutation' }
  & { generateNewOtp: (
    { __typename?: 'NewOtpGenerated' }
    & Pick<NewOtpGenerated, 'darkQrCode' | 'otpSecret' | 'lightQrCode'>
  ) | (
    { __typename?: 'UserAlreadyHasOtp' }
    & Pick<UserAlreadyHasOtp, 'message'>
  ) }
);

export type MoveTasksMutationVariables = Exact<{
  taskIds: Array<Scalars['ID']> | Scalars['ID'];
  date: Scalars['Date'];
}>;


export type MoveTasksMutation = (
  { __typename?: 'Mutation' }
  & { moveTasks: Array<(
    { __typename?: 'Task' }
    & TaskFragment
  )> }
);

export type PersistPriorityOrderMutationVariables = Exact<{
  order?: Maybe<Array<OrderInput> | OrderInput>;
}>;


export type PersistPriorityOrderMutation = (
  { __typename?: 'Mutation' }
  & { persistPriorityOrder: Array<(
    { __typename?: 'Task' }
    & TaskFragment
  )> }
);

export type PersistProjectColumnOrderMutationVariables = Exact<{
  order?: Maybe<Array<OrderInput> | OrderInput>;
}>;


export type PersistProjectColumnOrderMutation = (
  { __typename?: 'Mutation' }
  & { persistProjectColumnOrder: Array<(
    { __typename?: 'ProjectColumn' }
    & ProjectColumnFragment
  )> }
);

export type PrioritizeTasksMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type PrioritizeTasksMutation = (
  { __typename?: 'Mutation' }
  & { prioritizeTasks: Array<(
    { __typename?: 'Task' }
    & TaskFragment
  )> }
);

export type SpringProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type SpringProjectMutation = (
  { __typename?: 'Mutation' }
  & { springProject: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type UnarchiveBoardMutationVariables = Exact<{
  boardId: Scalars['ID'];
}>;


export type UnarchiveBoardMutation = (
  { __typename?: 'Mutation' }
  & { unarchiveBoard: Array<(
    { __typename?: 'Board' }
    & BoardFragment
  )> }
);

export type UncompleteProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type UncompleteProjectMutation = (
  { __typename?: 'Mutation' }
  & { uncompleteProject: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type UncompleteTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
}>;


export type UncompleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { uncompleteTask: (
    { __typename?: 'Task' }
    & TaskFragment
  ) }
);

export type UnprioritizeTasksMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UnprioritizeTasksMutation = (
  { __typename?: 'Mutation' }
  & { unprioritizeTasks: Array<(
    { __typename?: 'Task' }
    & TaskFragment
  )> }
);

export type UnspringProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type UnspringProjectMutation = (
  { __typename?: 'Mutation' }
  & { unspringProject: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type UpdateBoardMutationVariables = Exact<{
  boardId: Scalars['ID'];
  emoji?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  projectCompletedProjectColumnId?: Maybe<Scalars['ID']>;
  taskCompletedProjectColumnId?: Maybe<Scalars['ID']>;
}>;


export type UpdateBoardMutation = (
  { __typename?: 'Mutation' }
  & { updateBoard: (
    { __typename?: 'Board' }
    & BoardFragment
  ) }
);

export type UpdateContainerMutationVariables = Exact<{
  date?: Maybe<Scalars['Date']>;
  noteBody?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type UpdateContainerMutation = (
  { __typename?: 'Mutation' }
  & { updateContainer: (
    { __typename?: 'Diary' }
    & Container_Diary_Fragment
  ) | (
    { __typename?: 'Inbox' }
    & Container_Inbox_Fragment
  ) | (
    { __typename?: 'Project' }
    & Container_Project_Fragment
  ) }
);

export type UpdateGroupMutationVariables = Exact<{
  collapsed?: Maybe<Scalars['Boolean']>;
  date?: Maybe<Scalars['Date']>;
  groupId: Scalars['ID'];
  keepTasks?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
}>;


export type UpdateGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateGroup: (
    { __typename?: 'Group' }
    & GroupFragment
  ) }
);

export type UpdateNoteMutationVariables = Exact<{
  body?: Maybe<Scalars['String']>;
  hidePreview?: Maybe<Scalars['Boolean']>;
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  noteId: Scalars['ID'];
}>;


export type UpdateNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateNote: (
    { __typename: 'NoteUpdateOutdated' }
    & { note: (
      { __typename?: 'Note' }
      & NoteFragment
    ) }
  ) | (
    { __typename: 'NoteUpdated' }
    & { note: (
      { __typename?: 'Note' }
      & NoteFragment
    ) }
  ) }
);

export type UpdateProjectColumnMutationVariables = Exact<{
  collapsed?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  projectColumnId: Scalars['ID'];
  sortDirection?: Maybe<ProjectColumnSortDirectionEnum>;
  sortType?: Maybe<ProjectColumnSortTypeEnum>;
}>;


export type UpdateProjectColumnMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectColumn: (
    { __typename?: 'ProjectColumn' }
    & ProjectColumnFragment
  ) }
);

export type UpdateProjectMutationVariables = Exact<{
  boardId?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
}>;


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateProject: (
    { __typename?: 'Project' }
    & ProjectFragment
  ) }
);

export type UpdateTaskScheduleMutationVariables = Exact<{
  id: Scalars['ID'];
  startAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateTaskScheduleMutation = (
  { __typename?: 'Mutation' }
  & { updateTaskSchedule: (
    { __typename: 'TaskSchedule' }
    & TaskScheduleFragment
  ) }
);

export type UpdateTaskMutationVariables = Exact<{
  taskId: Scalars['ID'];
  date?: Maybe<Scalars['Date']>;
  dueDate?: Maybe<Scalars['Date']>;
  isStartTimeLocked?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  recurrence?: Maybe<RecurrenceInput>;
  startTime?: Maybe<Scalars['Time']>;
}>;


export type UpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateTask: (
    { __typename?: 'Task' }
    & TaskFragment
  ) }
);

export type UpdateTasksProjectMutationVariables = Exact<{
  projectId: Scalars['ID'];
  taskIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UpdateTasksProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateTasksProject: Array<(
    { __typename?: 'Task' }
    & TaskFragment
  )> }
);

export type UpdateTimeZoneMutationVariables = Exact<{
  timeZone: Scalars['String'];
}>;


export type UpdateTimeZoneMutation = (
  { __typename?: 'Mutation' }
  & { updateTimeZone: (
    { __typename: 'TimeZoneUpdated' }
    & { user: (
      { __typename?: 'User' }
      & { settings: (
        { __typename?: 'UserSetting' }
        & Pick<UserSetting, 'timeZone'>
      ) }
    ) }
  ) | (
    { __typename: 'UnsupportedTimeZone' }
    & Pick<UnsupportedTimeZone, 'timeZone'>
  ) }
);

export type UpdateUserSettingsMutationVariables = Exact<{
  badgeCountMode?: Maybe<BadgeCountModeEnum>;
}>;


export type UpdateUserSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type BoardQueryVariables = Exact<{
  boardId: Scalars['ID'];
}>;


export type BoardQuery = (
  { __typename?: 'Query' }
  & { board?: Maybe<(
    { __typename?: 'Board' }
    & BoardFragment
  )> }
);

export type BoardsQueryVariables = Exact<{ [key: string]: never; }>;


export type BoardsQuery = (
  { __typename?: 'Query' }
  & { boards: Array<(
    { __typename?: 'Board' }
    & BoardFragment
  )> }
);

export type ContainerQueryVariables = Exact<{
  date?: Maybe<Scalars['Date']>;
  inbox?: Maybe<Scalars['Boolean']>;
  projectId?: Maybe<Scalars['ID']>;
}>;


export type ContainerQuery = (
  { __typename?: 'Query' }
  & { container: (
    { __typename?: 'Diary' }
    & Container_Diary_Fragment
  ) | (
    { __typename?: 'Inbox' }
    & Container_Inbox_Fragment
  ) | (
    { __typename?: 'Project' }
    & Container_Project_Fragment
  ) }
);

export type NoteQueryVariables = Exact<{
  noteId: Scalars['ID'];
}>;


export type NoteQuery = (
  { __typename?: 'Query' }
  & { note?: Maybe<(
    { __typename?: 'Note' }
    & NoteFragment
  )> }
);

export type NotesQueryVariables = Exact<{
  date?: Maybe<Scalars['Date']>;
  projectId?: Maybe<Scalars['ID']>;
  query?: Maybe<Scalars['String']>;
}>;


export type NotesQuery = (
  { __typename?: 'Query' }
  & { notes?: Maybe<Array<(
    { __typename?: 'Note' }
    & NoteFragment
  )>> }
);

export type PrioritiesQueryVariables = Exact<{
  date?: Maybe<Scalars['Date']>;
  includedTaskIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;


export type PrioritiesQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'User' }
    & { settings: (
      { __typename?: 'UserSetting' }
      & Pick<UserSetting, 'badgeCountMode'>
    ) }
  ), prioritizedTasks?: Maybe<Array<(
    { __typename?: 'Task' }
    & TaskFragment
  )>>, dueTasks?: Maybe<Array<(
    { __typename?: 'Task' }
    & TaskFragment
  )>>, includedTasks?: Maybe<Array<(
    { __typename?: 'Task' }
    & TaskFragment
  )>> }
);

export type ProjectQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & ProjectFragment
  )> }
);

export type ProjectsQueryVariables = Exact<{
  date?: Maybe<Scalars['Date']>;
  query?: Maybe<Scalars['String']>;
  boardId?: Maybe<Scalars['ID']>;
}>;


export type ProjectsQuery = (
  { __typename?: 'Query' }
  & { projects?: Maybe<Array<(
    { __typename?: 'Project' }
    & ProjectFragment
  )>> }
);

export type BoardResultFragment = (
  { __typename: 'Board' }
  & Pick<Board, 'id' | 'name'>
);

export type NoteResultFragment = (
  { __typename: 'Note' }
  & Pick<Note, 'id' | 'name'>
);

export type ProjectResultFragment = (
  { __typename: 'Project' }
  & Pick<Project, 'id' | 'name'>
);

export type TaskResultFragment = (
  { __typename?: 'TaskSearchResult' }
  & Pick<TaskSearchResult, 'score'>
  & { task: (
    { __typename: 'Task' }
    & Pick<Task, 'id' | 'completed' | 'date' | 'name'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name'>
    )> }
  ) }
);

export type SearchQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { search: (
    { __typename: 'Search' }
    & { boards: Array<(
      { __typename?: 'Board' }
      & BoardResultFragment
    )>, notes: Array<(
      { __typename?: 'Note' }
      & NoteResultFragment
    )>, projects: Array<(
      { __typename?: 'Project' }
      & ProjectResultFragment
    )>, tasks: Array<(
      { __typename?: 'TaskSearchResult' }
      & TaskResultFragment
    )> }
  ) }
);

export type TaskSchedulesQueryVariables = Exact<{
  date: Scalars['Date'];
}>;


export type TaskSchedulesQuery = (
  { __typename?: 'Query' }
  & { taskSchedules?: Maybe<Array<(
    { __typename?: 'TaskSchedule' }
    & TaskScheduleFragment
  )>> }
);

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { currentUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type GroupsUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GroupsUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { groupsUpdated: Array<(
    { __typename?: 'Group' }
    & GroupFragment
  )> }
);

export type TasksUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TasksUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { tasksUpdated: Array<(
    { __typename?: 'Task' }
    & TaskFragment
  )> }
);

export type TasksCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TasksCreatedSubscription = (
  { __typename?: 'Subscription' }
  & { tasksCreated: Array<(
    { __typename?: 'Task' }
    & { orders: (
      { __typename?: 'TaskOrder' }
      & Pick<TaskOrder, 'dateOrder' | 'projectOrder'>
    ) }
    & TaskFragment
  )> }
);

export const ProjectFragmentDoc = gql`
    fragment Project on Project {
  id
  endDate
  completed
  completedAt
  date
  name
  order
  projectColumn {
    id
    board {
      id
      name
    }
  }
  springEnabled
  tasks {
    completedCount
    totalCount
  }
}
    `;
export const ProjectColumnFragmentDoc = gql`
    fragment ProjectColumn on ProjectColumn {
  id
  board {
    id
  }
  name
  order
  collapsed
  projects {
    ...Project
  }
  sortDirection
  sortType
}
    ${ProjectFragmentDoc}`;
export const BoardFragmentDoc = gql`
    fragment Board on Board {
  id
  archivedAt
  emoji
  lastViewedAt
  name
  projectColumns {
    ...ProjectColumn
  }
  projectCompletedProjectColumnId
  taskCompletedProjectColumnId
}
    ${ProjectColumnFragmentDoc}`;
export const TaskFragmentDoc = gql`
    fragment Task on Task {
  id
  completed
  completedAt
  date
  description
  dueDate
  groupIds
  isRecurring
  isStartTimeLocked
  link
  name
  priorityOrder
  project {
    id
    name
  }
  spring
  recurrence {
    friday
    kind
    monday
    rule
    saturday
    separation
    sunday
    thursday
    tuesday
    wednesday
  }
  startTime
}
    `;
export const GroupFragmentDoc = gql`
    fragment Group on Group {
  id
  collapsed
  date
  keepTasks
  name
  order
  projectId
  tasks {
    edges {
      order
      node {
        ...Task
      }
    }
  }
}
    ${TaskFragmentDoc}`;
export const ContainerFragmentDoc = gql`
    fragment Container on Container {
  id
  collapseCompleted
  containerType
  groups {
    ...Group
  }
  noteBody
  supportsNotes
}
    ${GroupFragmentDoc}`;
export const NoteFragmentDoc = gql`
    fragment Note on Note {
  id
  body
  hidePreview
  name
  updatedAt
}
    `;
export const TaskScheduleFragmentDoc = gql`
    fragment TaskSchedule on TaskSchedule {
  id
  startAt
  endAt
  task {
    id
    name
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
  isMfaEnabled
  settings {
    badgeCountMode
    timeZone
    timeZoneChangeRule
  }
}
    `;
export const BoardResultFragmentDoc = gql`
    fragment BoardResult on Board {
  __typename
  id
  name
}
    `;
export const NoteResultFragmentDoc = gql`
    fragment NoteResult on Note {
  __typename
  id
  name
}
    `;
export const ProjectResultFragmentDoc = gql`
    fragment ProjectResult on Project {
  __typename
  id
  name
}
    `;
export const TaskResultFragmentDoc = gql`
    fragment TaskResult on TaskSearchResult {
  task {
    __typename
    id
    completed
    date
    name
    project {
      id
      name
    }
  }
  score
}
    `;
export const ArchiveBoardDocument = gql`
    mutation ArchiveBoard($boardId: ID!) {
  archiveBoard(boardId: $boardId) {
    ...Board
  }
}
    ${BoardFragmentDoc}`;

export function useArchiveBoardMutation() {
  return Urql.useMutation<ArchiveBoardMutation, ArchiveBoardMutationVariables>(ArchiveBoardDocument);
};
export const CompleteProjectDocument = gql`
    mutation CompleteProject($projectId: ID!) {
  completeProject(projectId: $projectId) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export function useCompleteProjectMutation() {
  return Urql.useMutation<CompleteProjectMutation, CompleteProjectMutationVariables>(CompleteProjectDocument);
};
export const CompleteTaskDocument = gql`
    mutation CompleteTask($taskId: ID!) {
  completeTask(taskId: $taskId) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

export function useCompleteTaskMutation() {
  return Urql.useMutation<CompleteTaskMutation, CompleteTaskMutationVariables>(CompleteTaskDocument);
};
export const CreateBoardsDocument = gql`
    mutation CreateBoards($names: [String!]!) {
  createBoards(names: $names) {
    ...Board
  }
}
    ${BoardFragmentDoc}`;

export function useCreateBoardsMutation() {
  return Urql.useMutation<CreateBoardsMutation, CreateBoardsMutationVariables>(CreateBoardsDocument);
};
export const CreateGroupsDocument = gql`
    mutation CreateGroups($containerId: ID!, $containerType: ContainerTypeEnum!, $names: [String!]!) {
  createGroups(
    containerId: $containerId
    containerType: $containerType
    names: $names
  ) {
    ...Group
  }
}
    ${GroupFragmentDoc}`;

export function useCreateGroupsMutation() {
  return Urql.useMutation<CreateGroupsMutation, CreateGroupsMutationVariables>(CreateGroupsDocument);
};
export const CreateNoteDocument = gql`
    mutation CreateNote($date: Date, $name: String, $projectId: ID) {
  createNote(date: $date, name: $name, projectId: $projectId) {
    ...Note
  }
}
    ${NoteFragmentDoc}`;

export function useCreateNoteMutation() {
  return Urql.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument);
};
export const CreateProjectColumnDocument = gql`
    mutation CreateProjectColumn($boardId: ID!, $name: String!) {
  createProjectColumn(boardId: $boardId, name: $name) {
    ...ProjectColumn
  }
}
    ${ProjectColumnFragmentDoc}`;

export function useCreateProjectColumnMutation() {
  return Urql.useMutation<CreateProjectColumnMutation, CreateProjectColumnMutationVariables>(CreateProjectColumnDocument);
};
export const CreateProjectDocument = gql`
    mutation CreateProject($boardId: ID, $projectColumnId: ID, $name: String) {
  createProject(boardId: $boardId, projectColumnId: $projectColumnId, name: $name) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export function useCreateProjectMutation() {
  return Urql.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument);
};
export const CreateProjectsDocument = gql`
    mutation CreateProjects($boardId: ID, $date: Date, $projectColumnId: ID, $names: [String!]!) {
  createProjects(
    boardId: $boardId
    date: $date
    projectColumnId: $projectColumnId
    names: $names
  ) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export function useCreateProjectsMutation() {
  return Urql.useMutation<CreateProjectsMutation, CreateProjectsMutationVariables>(CreateProjectsDocument);
};
export const CreateTaskScheduleDocument = gql`
    mutation CreateTaskSchedule($taskId: ID!, $startAt: DateTime!, $endAt: DateTime!) {
  createTaskSchedule(taskId: $taskId, startAt: $startAt, endAt: $endAt) {
    __typename
    ...TaskSchedule
  }
}
    ${TaskScheduleFragmentDoc}`;

export function useCreateTaskScheduleMutation() {
  return Urql.useMutation<CreateTaskScheduleMutation, CreateTaskScheduleMutationVariables>(CreateTaskScheduleDocument);
};
export const CreateTasksDocument = gql`
    mutation CreateTasks($after: ID, $date: Date, $description: String, $dueDate: Date, $groups: CreateTaskGroupsInput, $link: String, $names: [String!]!, $prioritized: Boolean, $projectId: ID, $newProject: NewProjectTaskInput) {
  createTasks(
    after: $after
    date: $date
    description: $description
    dueDate: $dueDate
    groups: $groups
    link: $link
    names: $names
    prioritized: $prioritized
    projectId: $projectId
    newProject: $newProject
  ) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

export function useCreateTasksMutation() {
  return Urql.useMutation<CreateTasksMutation, CreateTasksMutationVariables>(CreateTasksDocument);
};
export const DeleteBoardDocument = gql`
    mutation DeleteBoard($boardId: ID!) {
  deleteBoard(boardId: $boardId) {
    ...Board
  }
}
    ${BoardFragmentDoc}`;

export function useDeleteBoardMutation() {
  return Urql.useMutation<DeleteBoardMutation, DeleteBoardMutationVariables>(DeleteBoardDocument);
};
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($groupId: ID!) {
  deleteGroup(groupId: $groupId) {
    id
  }
}
    `;

export function useDeleteGroupMutation() {
  return Urql.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument);
};
export const DeleteNoteDocument = gql`
    mutation DeleteNote($noteId: ID!) {
  deleteNote(noteId: $noteId) {
    ...Note
  }
}
    ${NoteFragmentDoc}`;

export function useDeleteNoteMutation() {
  return Urql.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument);
};
export const DeleteProjectDocument = gql`
    mutation DeleteProject($deleteTasks: Boolean, $projectId: ID!) {
  deleteProject(deleteTasks: $deleteTasks, projectId: $projectId) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export function useDeleteProjectMutation() {
  return Urql.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument);
};
export const DeleteTaskScheduleDocument = gql`
    mutation DeleteTaskSchedule($id: ID!) {
  deleteTaskSchedule(id: $id) {
    ...TaskSchedule
  }
}
    ${TaskScheduleFragmentDoc}`;

export function useDeleteTaskScheduleMutation() {
  return Urql.useMutation<DeleteTaskScheduleMutation, DeleteTaskScheduleMutationVariables>(DeleteTaskScheduleDocument);
};
export const DeleteTaskDocument = gql`
    mutation DeleteTask($taskId: ID!) {
  deleteTask(taskId: $taskId) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

export function useDeleteTaskMutation() {
  return Urql.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument);
};
export const DeleteTasksDocument = gql`
    mutation DeleteTasks($taskIds: [ID!]!) {
  deleteTasks(taskIds: $taskIds) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

export function useDeleteTasksMutation() {
  return Urql.useMutation<DeleteTasksMutation, DeleteTasksMutationVariables>(DeleteTasksDocument);
};
export const DuplicateProjectDocument = gql`
    mutation DuplicateProject($projectId: ID!, $name: String) {
  duplicateProject(projectId: $projectId, name: $name) {
    __typename
    ... on Project {
      ...Project
    }
    ... on ProjectNotFound {
      projectId
    }
  }
}
    ${ProjectFragmentDoc}`;

export function useDuplicateProjectMutation() {
  return Urql.useMutation<DuplicateProjectMutation, DuplicateProjectMutationVariables>(DuplicateProjectDocument);
};
export const EnableOtpDocument = gql`
    mutation EnableOtp($otpAttempt: String!, $otpSecret: String!) {
  enableOtp(otpAttempt: $otpAttempt, otpSecret: $otpSecret) {
    __typename
  }
}
    `;

export function useEnableOtpMutation() {
  return Urql.useMutation<EnableOtpMutation, EnableOtpMutationVariables>(EnableOtpDocument);
};
export const GenerateNewOtpDocument = gql`
    mutation GenerateNewOtp {
  generateNewOtp {
    ... on NewOtpGenerated {
      darkQrCode
      otpSecret
      lightQrCode
    }
    ... on UserAlreadyHasOtp {
      message
    }
  }
}
    `;

export function useGenerateNewOtpMutation() {
  return Urql.useMutation<GenerateNewOtpMutation, GenerateNewOtpMutationVariables>(GenerateNewOtpDocument);
};
export const MoveTasksDocument = gql`
    mutation MoveTasks($taskIds: [ID!]!, $date: Date!) {
  moveTasks(taskIds: $taskIds, date: $date) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

export function useMoveTasksMutation() {
  return Urql.useMutation<MoveTasksMutation, MoveTasksMutationVariables>(MoveTasksDocument);
};
export const PersistPriorityOrderDocument = gql`
    mutation PersistPriorityOrder($order: [OrderInput!]) {
  persistPriorityOrder(order: $order) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

export function usePersistPriorityOrderMutation() {
  return Urql.useMutation<PersistPriorityOrderMutation, PersistPriorityOrderMutationVariables>(PersistPriorityOrderDocument);
};
export const PersistProjectColumnOrderDocument = gql`
    mutation PersistProjectColumnOrder($order: [OrderInput!]) {
  persistProjectColumnOrder(order: $order) {
    ...ProjectColumn
  }
}
    ${ProjectColumnFragmentDoc}`;

export function usePersistProjectColumnOrderMutation() {
  return Urql.useMutation<PersistProjectColumnOrderMutation, PersistProjectColumnOrderMutationVariables>(PersistProjectColumnOrderDocument);
};
export const PrioritizeTasksDocument = gql`
    mutation PrioritizeTasks($ids: [ID!]!) {
  prioritizeTasks(ids: $ids) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

export function usePrioritizeTasksMutation() {
  return Urql.useMutation<PrioritizeTasksMutation, PrioritizeTasksMutationVariables>(PrioritizeTasksDocument);
};
export const SpringProjectDocument = gql`
    mutation SpringProject($projectId: ID!) {
  springProject(projectId: $projectId) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export function useSpringProjectMutation() {
  return Urql.useMutation<SpringProjectMutation, SpringProjectMutationVariables>(SpringProjectDocument);
};
export const UnarchiveBoardDocument = gql`
    mutation UnarchiveBoard($boardId: ID!) {
  unarchiveBoard(boardId: $boardId) {
    ...Board
  }
}
    ${BoardFragmentDoc}`;

export function useUnarchiveBoardMutation() {
  return Urql.useMutation<UnarchiveBoardMutation, UnarchiveBoardMutationVariables>(UnarchiveBoardDocument);
};
export const UncompleteProjectDocument = gql`
    mutation UncompleteProject($projectId: ID!) {
  uncompleteProject(projectId: $projectId) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export function useUncompleteProjectMutation() {
  return Urql.useMutation<UncompleteProjectMutation, UncompleteProjectMutationVariables>(UncompleteProjectDocument);
};
export const UncompleteTaskDocument = gql`
    mutation UncompleteTask($taskId: ID!) {
  uncompleteTask(taskId: $taskId) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

export function useUncompleteTaskMutation() {
  return Urql.useMutation<UncompleteTaskMutation, UncompleteTaskMutationVariables>(UncompleteTaskDocument);
};
export const UnprioritizeTasksDocument = gql`
    mutation UnprioritizeTasks($ids: [ID!]!) {
  unprioritizeTasks(ids: $ids) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

export function useUnprioritizeTasksMutation() {
  return Urql.useMutation<UnprioritizeTasksMutation, UnprioritizeTasksMutationVariables>(UnprioritizeTasksDocument);
};
export const UnspringProjectDocument = gql`
    mutation UnspringProject($projectId: ID!) {
  unspringProject(projectId: $projectId) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export function useUnspringProjectMutation() {
  return Urql.useMutation<UnspringProjectMutation, UnspringProjectMutationVariables>(UnspringProjectDocument);
};
export const UpdateBoardDocument = gql`
    mutation UpdateBoard($boardId: ID!, $emoji: String, $name: String, $projectCompletedProjectColumnId: ID, $taskCompletedProjectColumnId: ID) {
  updateBoard(
    boardId: $boardId
    emoji: $emoji
    name: $name
    projectCompletedProjectColumnId: $projectCompletedProjectColumnId
    taskCompletedProjectColumnId: $taskCompletedProjectColumnId
  ) {
    ...Board
  }
}
    ${BoardFragmentDoc}`;

export function useUpdateBoardMutation() {
  return Urql.useMutation<UpdateBoardMutation, UpdateBoardMutationVariables>(UpdateBoardDocument);
};
export const UpdateContainerDocument = gql`
    mutation UpdateContainer($date: Date, $noteBody: String, $projectId: ID) {
  updateContainer(date: $date, noteBody: $noteBody, projectId: $projectId) {
    ...Container
  }
}
    ${ContainerFragmentDoc}`;

export function useUpdateContainerMutation() {
  return Urql.useMutation<UpdateContainerMutation, UpdateContainerMutationVariables>(UpdateContainerDocument);
};
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($collapsed: Boolean, $date: Date, $groupId: ID!, $keepTasks: Boolean, $name: String) {
  updateGroup(
    collapsed: $collapsed
    date: $date
    groupId: $groupId
    name: $name
    keepTasks: $keepTasks
  ) {
    ...Group
  }
}
    ${GroupFragmentDoc}`;

export function useUpdateGroupMutation() {
  return Urql.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument);
};
export const UpdateNoteDocument = gql`
    mutation UpdateNote($body: String, $hidePreview: Boolean, $lastUpdatedAt: DateTime, $name: String, $noteId: ID!) {
  updateNote(
    body: $body
    hidePreview: $hidePreview
    lastUpdatedAt: $lastUpdatedAt
    name: $name
    noteId: $noteId
  ) {
    __typename
    ... on NoteUpdated {
      note {
        ...Note
      }
    }
    ... on NoteUpdateOutdated {
      note {
        ...Note
      }
    }
  }
}
    ${NoteFragmentDoc}`;

export function useUpdateNoteMutation() {
  return Urql.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(UpdateNoteDocument);
};
export const UpdateProjectColumnDocument = gql`
    mutation UpdateProjectColumn($collapsed: Boolean, $name: String, $projectColumnId: ID!, $sortDirection: ProjectColumnSortDirectionEnum, $sortType: ProjectColumnSortTypeEnum) {
  updateProjectColumn(
    collapsed: $collapsed
    name: $name
    projectColumnId: $projectColumnId
    sortDirection: $sortDirection
    sortType: $sortType
  ) {
    ...ProjectColumn
  }
}
    ${ProjectColumnFragmentDoc}`;

export function useUpdateProjectColumnMutation() {
  return Urql.useMutation<UpdateProjectColumnMutation, UpdateProjectColumnMutationVariables>(UpdateProjectColumnDocument);
};
export const UpdateProjectDocument = gql`
    mutation UpdateProject($boardId: ID, $date: Date, $endDate: Date, $name: String, $projectId: ID!) {
  updateProject(
    boardId: $boardId
    date: $date
    endDate: $endDate
    name: $name
    projectId: $projectId
  ) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export function useUpdateProjectMutation() {
  return Urql.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument);
};
export const UpdateTaskScheduleDocument = gql`
    mutation UpdateTaskSchedule($id: ID!, $startAt: DateTime, $endAt: DateTime) {
  updateTaskSchedule(id: $id, startAt: $startAt, endAt: $endAt) {
    __typename
    ...TaskSchedule
  }
}
    ${TaskScheduleFragmentDoc}`;

export function useUpdateTaskScheduleMutation() {
  return Urql.useMutation<UpdateTaskScheduleMutation, UpdateTaskScheduleMutationVariables>(UpdateTaskScheduleDocument);
};
export const UpdateTaskDocument = gql`
    mutation UpdateTask($taskId: ID!, $date: Date, $dueDate: Date, $isStartTimeLocked: Boolean, $link: String, $projectId: ID, $name: String, $description: String, $recurrence: RecurrenceInput, $startTime: Time) {
  updateTask(
    taskId: $taskId
    date: $date
    dueDate: $dueDate
    isStartTimeLocked: $isStartTimeLocked
    link: $link
    projectId: $projectId
    name: $name
    description: $description
    recurrence: $recurrence
    startTime: $startTime
  ) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

export function useUpdateTaskMutation() {
  return Urql.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument);
};
export const UpdateTasksProjectDocument = gql`
    mutation UpdateTasksProject($projectId: ID!, $taskIds: [ID!]!) {
  updateTasksProject(projectId: $projectId, taskIds: $taskIds) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

export function useUpdateTasksProjectMutation() {
  return Urql.useMutation<UpdateTasksProjectMutation, UpdateTasksProjectMutationVariables>(UpdateTasksProjectDocument);
};
export const UpdateTimeZoneDocument = gql`
    mutation UpdateTimeZone($timeZone: String!) {
  updateTimeZone(timeZone: $timeZone) {
    __typename
    ... on TimeZoneUpdated {
      user {
        settings {
          timeZone
        }
      }
    }
    ... on UnsupportedTimeZone {
      timeZone
    }
  }
}
    `;

export function useUpdateTimeZoneMutation() {
  return Urql.useMutation<UpdateTimeZoneMutation, UpdateTimeZoneMutationVariables>(UpdateTimeZoneDocument);
};
export const UpdateUserSettingsDocument = gql`
    mutation UpdateUserSettings($badgeCountMode: BadgeCountModeEnum) {
  updateUserSettings(badgeCountMode: $badgeCountMode) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useUpdateUserSettingsMutation() {
  return Urql.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(UpdateUserSettingsDocument);
};
export const BoardDocument = gql`
    query Board($boardId: ID!) {
  board(boardId: $boardId) {
    ...Board
  }
}
    ${BoardFragmentDoc}`;

export function useBoardQuery(options: Omit<Urql.UseQueryArgs<BoardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BoardQuery>({ query: BoardDocument, ...options });
};
export const BoardsDocument = gql`
    query Boards {
  boards {
    ...Board
  }
}
    ${BoardFragmentDoc}`;

export function useBoardsQuery(options: Omit<Urql.UseQueryArgs<BoardsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BoardsQuery>({ query: BoardsDocument, ...options });
};
export const ContainerDocument = gql`
    query Container($date: Date, $inbox: Boolean, $projectId: ID) {
  container(date: $date, inbox: $inbox, projectId: $projectId) {
    ...Container
  }
}
    ${ContainerFragmentDoc}`;

export function useContainerQuery(options: Omit<Urql.UseQueryArgs<ContainerQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ContainerQuery>({ query: ContainerDocument, ...options });
};
export const NoteDocument = gql`
    query Note($noteId: ID!) {
  note(noteId: $noteId) {
    ...Note
  }
}
    ${NoteFragmentDoc}`;

export function useNoteQuery(options: Omit<Urql.UseQueryArgs<NoteQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<NoteQuery>({ query: NoteDocument, ...options });
};
export const NotesDocument = gql`
    query Notes($date: Date, $projectId: ID, $query: String) {
  notes(date: $date, projectId: $projectId, query: $query) {
    ...Note
  }
}
    ${NoteFragmentDoc}`;

export function useNotesQuery(options: Omit<Urql.UseQueryArgs<NotesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<NotesQuery>({ query: NotesDocument, ...options });
};
export const PrioritiesDocument = gql`
    query Priorities($date: Date, $includedTaskIds: [ID!]) {
  currentUser {
    settings {
      badgeCountMode
    }
  }
  prioritizedTasks: tasks(date: $date, focus: true) {
    ...Task
  }
  dueTasks: tasks(dueDate: $date) {
    ...Task
  }
  includedTasks: tasks(taskIds: $includedTaskIds) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

export function usePrioritiesQuery(options: Omit<Urql.UseQueryArgs<PrioritiesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PrioritiesQuery>({ query: PrioritiesDocument, ...options });
};
export const ProjectDocument = gql`
    query Project($projectId: ID!) {
  project(projectId: $projectId) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export function useProjectQuery(options: Omit<Urql.UseQueryArgs<ProjectQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ProjectQuery>({ query: ProjectDocument, ...options });
};
export const ProjectsDocument = gql`
    query Projects($date: Date, $query: String, $boardId: ID) {
  projects(date: $date, query: $query, boardId: $boardId) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

export function useProjectsQuery(options: Omit<Urql.UseQueryArgs<ProjectsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ProjectsQuery>({ query: ProjectsDocument, ...options });
};
export const SearchDocument = gql`
    query Search($query: String!) {
  search(query: $query) {
    __typename
    boards {
      ...BoardResult
    }
    notes {
      ...NoteResult
    }
    projects {
      ...ProjectResult
    }
    tasks {
      ...TaskResult
    }
  }
}
    ${BoardResultFragmentDoc}
${NoteResultFragmentDoc}
${ProjectResultFragmentDoc}
${TaskResultFragmentDoc}`;

export function useSearchQuery(options: Omit<Urql.UseQueryArgs<SearchQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SearchQuery>({ query: SearchDocument, ...options });
};
export const TaskSchedulesDocument = gql`
    query TaskSchedules($date: Date!) {
  taskSchedules(date: $date) {
    ...TaskSchedule
  }
}
    ${TaskScheduleFragmentDoc}`;

export function useTaskSchedulesQuery(options: Omit<Urql.UseQueryArgs<TaskSchedulesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TaskSchedulesQuery>({ query: TaskSchedulesDocument, ...options });
};
export const UserDocument = gql`
    query User {
  currentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useUserQuery(options: Omit<Urql.UseQueryArgs<UserQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UserQuery>({ query: UserDocument, ...options });
};
export const GroupsUpdatedDocument = gql`
    subscription GroupsUpdated {
  groupsUpdated {
    ...Group
  }
}
    ${GroupFragmentDoc}`;

export function useGroupsUpdatedSubscription<TData = GroupsUpdatedSubscription>(options: Omit<Urql.UseSubscriptionArgs<GroupsUpdatedSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<GroupsUpdatedSubscription, TData>) {
  return Urql.useSubscription<GroupsUpdatedSubscription, TData, GroupsUpdatedSubscriptionVariables>({ query: GroupsUpdatedDocument, ...options }, handler);
};
export const TasksUpdatedDocument = gql`
    subscription TasksUpdated {
  tasksUpdated {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

export function useTasksUpdatedSubscription<TData = TasksUpdatedSubscription>(options: Omit<Urql.UseSubscriptionArgs<TasksUpdatedSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<TasksUpdatedSubscription, TData>) {
  return Urql.useSubscription<TasksUpdatedSubscription, TData, TasksUpdatedSubscriptionVariables>({ query: TasksUpdatedDocument, ...options }, handler);
};
export const TasksCreatedDocument = gql`
    subscription TasksCreated {
  tasksCreated {
    ...Task
    orders {
      dateOrder
      projectOrder
    }
  }
}
    ${TaskFragmentDoc}`;

export function useTasksCreatedSubscription<TData = TasksCreatedSubscription>(options: Omit<Urql.UseSubscriptionArgs<TasksCreatedSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<TasksCreatedSubscription, TData>) {
  return Urql.useSubscription<TasksCreatedSubscription, TData, TasksCreatedSubscriptionVariables>({ query: TasksCreatedDocument, ...options }, handler);
};