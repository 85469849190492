import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  NoteFragment,
  useUpdateNoteMutation,
} from '../../graphql/generated-types';
import { storeNotes } from '../../reducers/actions';
import { Note } from '../../types';
import SettingsSidebar, {
  DeleteButton,
  Label,
  TextInput,
} from '../SettingsSidebar';
import Switch from '../Switch';

import DeleteNoteModal from './DeleteNoteModal';

const NoteSettings = ({
  onClickCloseButton,
  note,
}: {
  onClickCloseButton: () => void;
  note: Note;
}): JSX.Element | null => {
  if (!note) {
    return null;
  }

  const dispatch = useDispatch();

  const [, updateNote] = useUpdateNoteMutation();

  const [name, setName] = useState<string>(note.data.name);

  const [deleteNoteModalOpen, setDeleteNoteModalOpen] =
    useState<boolean>(false);

  const onUpdateNote = async <K extends keyof NoteFragment>(
    key: K,
    value: NoteFragment[K]
  ): Promise<void> => {
    dispatch(
      storeNotes({
        notes: [
          {
            ...note,
            data: {
              ...note.data,
              [key]: value,
            },
          },
        ],
      })
    );

    await updateNote({ noteId: note.data.id, [key]: value });
  };

  return (
    <SettingsSidebar
      onClickCloseButton={onClickCloseButton}
      title="Note Settings"
    >
      <>
        <div className="flex h-full flex-col justify-between pb-30 md:pb-0">
          <div>
            <Label htmlFor="name" text="Name" />

            <TextInput
              id="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              onBlur={async () => await onUpdateNote('name', name)}
            />

            <div className="flex items-center px-2 pt-1">
              <Switch
                checked={note.data.hidePreview}
                id="hide-preview"
                onCheckedChange={async () =>
                  await updateNote({
                    hidePreview: !note.data.hidePreview,
                    noteId: note.data.id,
                  })
                }
              />
              <label
                htmlFor="hide-preview"
                className="ml-2 text-base font-medium"
              >
                Hide preview
              </label>
            </div>
          </div>

          <DeleteButton
            onClick={() => setDeleteNoteModalOpen(true)}
            text="Delete note..."
          />
        </div>

        <DeleteNoteModal
          note={note}
          open={deleteNoteModalOpen}
          setIsOpen={setDeleteNoteModalOpen}
        />
      </>
    </SettingsSidebar>
  );
};

export default NoteSettings;
