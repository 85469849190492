import format from 'date-fns/format';
import React from 'react';
import { useSelector } from 'react-redux';

import { BetaState } from '../../../reducers/beta-types';
import { DayCalendar } from '../../DayCalendar';

export const DayViewSidebar = () => {
  const { selectedDate } = useSelector((state: BetaState) => ({
    selectedDate: state.selectedDate,
  }));

  return (
    <div className="h-full flex flex-col select-none border-0 border-r border-solid border-violet-100 bg-gray-50 shadow-sidebar-gray dark:border-violet-900 dark:bg-mauve-dark-2 dark:text-white md:h-screen overflow-clip dark:shadow-none">
      <div className="flex items-center justify-between px-5 fixed top-0 h-18 w-full border-0 border-b border-r border-solid border-violet-100 bg-gray-50 !bg-opacity-70 dark:border-violet-900 dark:bg-mauve-dark-2 md:sticky">
        <h2 className="my-0 text-2xl font-semibold">
          {format(
            selectedDate ? new Date(selectedDate) : new Date(),
            'MMMM yyyy'
          )}
        </h2>
      </div>

      <DayCalendar />
    </div>
  );
};
