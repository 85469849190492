import { Box, Button, Flex, Grid } from '@radix-ui/themes';
import { addMonths, format, startOfMonth, subMonths } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { Info, Lock, Unlock } from 'lucide-react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import React, { useEffect, useState } from 'react';

import DayPicker from '../DayPicker';
import ActionButton from '../library/ActionButton';
import { TimePicker } from '../library/TimePicker';
import Tooltip from '../library/Tooltip';
import Modal from '../Modal';
import Switch from '../Switch';

const startTimeVariants = {
  enter: {
    opacity: 0,
    height: 0,
    transition: { duration: 0.2 },
  },
  entered: {
    opacity: 1,
    height: 'fit-content',
  },
  exit: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
    transition: { opacity: { duration: 0.2 } },
  },
};

const DateCalendarModal = ({
  includeTimeOption = false,
  initialDate,
  initialLockedTime = false,
  initialTime = null,
  onSave,
  open,
  setIsOpen,
  title,
}: {
  includeTimeOption?: boolean;
  initialDate: Date | null;
  initialLockedTime?: boolean;
  initialTime?: string | null;
  onSave: (
    date: Date | null,
    time?: string | null,
    isTimeLocked?: boolean
  ) => void;
  open: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
}): JSX.Element => {
  const [calendarDate, setCalendarDate] = useState<Date>(
    initialDate || new Date()
  );

  const [time, setTime] = useState<string>(initialTime || null);

  const [displayTime, setDisplayTime] = useState(!!initialTime);
  const [isTimeLocked, setIsTimeLocked] = useState(initialLockedTime);

  const calendarMonth = startOfMonth(calendarDate);
  const nextMonth = addMonths(calendarMonth, 1);

  const onPreviousMonthClick = (): void =>
    setCalendarDate(subMonths(calendarDate, 1));

  const onNextMonthClick = (): void =>
    setCalendarDate(addMonths(calendarDate, 1));

  useEffect(() => {
    if (!displayTime) {
      setTime(null);
    }
  }, [displayTime]);

  return (
    <Modal open={open} setIsOpen={setIsOpen} title={title}>
      <Flex direction="column" justify="between">
        <Grid columns={{ initial: '1', md: '2' }} gapX="8" px="8" mt="3">
          <Box>
            <div className="aspect-square w-full md:aspect-auto md:w-auto">
              <div className="mb-4 flex h-5 items-center justify-between">
                <span className="block select-none font-medium">
                  {format(calendarMonth, 'MMMM yyyy')}
                </span>

                <div className="block md:hidden">
                  <ChevronLeft
                    onClick={onPreviousMonthClick}
                    role="button"
                    className="cursor-pointer select-none hover:text-violet-500"
                  />
                  <ChevronRight
                    onClick={onNextMonthClick}
                    role="button"
                    className="cursor-pointer select-none hover:text-violet-500"
                  />
                </div>
              </div>

              <DayPicker
                calendarDate={calendarMonth}
                selectedDate={calendarDate}
                onSelectDate={(selectedDate) => setCalendarDate(selectedDate)}
              />
            </div>
          </Box>

          <Box>
            <div className="hidden md:block">
              <div className="mb-4 flex h-5 items-center justify-between">
                <span className="block select-none font-medium">
                  {format(nextMonth, 'MMMM yyyy')}
                </span>

                <div>
                  <ChevronLeft
                    onClick={onPreviousMonthClick}
                    role="button"
                    className="cursor-pointer select-none hover:text-violet-500"
                  />
                  <ChevronRight
                    onClick={onNextMonthClick}
                    role="button"
                    className="cursor-pointer select-none hover:text-violet-500"
                  />
                </div>
              </div>
              <DayPicker
                calendarDate={nextMonth}
                selectedDate={calendarDate}
                onSelectDate={(selectedDate) => setCalendarDate(selectedDate)}
              />
            </div>
          </Box>
        </Grid>
        <div className="block justify-around py-5 px-4 md:flex md:py-2 md:px-0"></div>

        <AnimatePresence>
          {displayTime && (
            <motion.div
              variants={startTimeVariants}
              initial="enter"
              animate="entered"
              exit="exit"
            >
              <div className="flex justify-center items-center gap-4 mb-4">
                <div className="flex justify-center items-center gap-2">
                  <TimePicker setValue={setTime} value={time} />
                </div>

                <div className="flex justify-center items-center gap-2">
                  <ActionButton
                    Icon={isTimeLocked ? Lock : Unlock}
                    isActive={isTimeLocked}
                    onClick={() => setIsTimeLocked(!isTimeLocked)}
                    text={
                      isTimeLocked ? 'Locked Start Time' : 'Unlocked Start Time'
                    }
                  />
                  <Tooltip
                    delayDuration={500}
                    maxWidth={300}
                    text="A locked start time will carry over when this task is moved to or repeated on another day. Use locked start times for tasks that can only be completed after a certain time on any day."
                  >
                    <Info size={16} className="cursor-help" />
                  </Tooltip>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        <div className="flex justify-between py-2 px-4 mt-2 border-0 border-t border-solid border-mauve-6 dark:border-mauve-dark-6">
          <div className="flex items-center gap-2">
            {includeTimeOption && (
              <>
                <Switch
                  checked={displayTime}
                  id="include-start-time"
                  onCheckedChange={() => setDisplayTime(!displayTime)}
                />

                <label htmlFor="include-start-time" className="text-sm">
                  Include start time
                </label>
              </>
            )}
          </div>

          <div className="flex gap-2">
            <Button
              color="gray"
              variant="soft"
              onClick={() => {
                setDisplayTime(false);
                onSave(null, null);
              }}
            >
              Clear Date
            </Button>

            <Button
              variant="solid"
              onClick={() => {
                setDisplayTime(false);
                onSave(calendarDate, time, isTimeLocked);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Flex>
    </Modal>
  );
};

export default DateCalendarModal;
