import { storeTasksFromServerInDatabase } from '../database/actions';
import { Group } from '../graphql/group-fragment';
import PERSIST_GROUP_ORDER_MUTATION, {
  PersistGroupOrderData,
} from '../graphql/persist-group-order-mutation';
import {
  PERSIST_PROJECT_ORDER_MUTATION_2,
  PersistProjectOrderData,
} from '../graphql/persist-project-order-mutation';
import PERSIST_TASK_ORDER_MUTATION, {
  PersistTaskOrderData,
} from '../graphql/persist-task-order-mutation';
import { ProjectColumn } from '../graphql/project-column-fragment';
import { Task } from '../graphql/task-fragment';
import { OrderInput, ProjectOrderInput } from '../graphql/types';
import { fetchGraph } from '../utils/graphql';

export const persistOrder = async (
  taskOrder: OrderInput[]
): Promise<Task[] | undefined> => {
  return await fetchGraph<PersistTaskOrderData>(PERSIST_TASK_ORDER_MUTATION, {
    taskOrder,
  }).then((response) => {
    storeTasksFromServerInDatabase(response.data?.persistTaskOrder || []);

    return response.data?.persistTaskOrder;
  });
};

export const persistGroupOrder = async (
  order: OrderInput[]
): Promise<Group[] | undefined> => {
  return await fetchGraph<PersistGroupOrderData>(PERSIST_GROUP_ORDER_MUTATION, {
    order,
  }).then((response) => response.data?.persistGroupOrder);
};

export const persistProjectOrder = async (
  order: ProjectOrderInput[]
): Promise<ProjectColumn[] | undefined> => {
  return await fetchGraph<PersistProjectOrderData>(
    PERSIST_PROJECT_ORDER_MUTATION_2,
    {
      order,
    }
  ).then((response) => response.data?.persistProjectOrder);
};
