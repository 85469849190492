import { Text } from '@radix-ui/themes';
import classNames from 'classnames';
import { Icon as IconType, X } from 'lucide-react';
import React, { forwardRef } from 'react';

interface ActionButtonProps {
  collapseOnMobile?: boolean;
  isActive: boolean;
  iconColor?: string;
  noShrink?: boolean;
  grow?: boolean;
  onClick?: () => void;
  onClear?: () => void;
  singleLine?: boolean;
  Icon: IconType;
  text?: string | React.ReactNode;
  textSize?: 'xs' | 'sm' | 'base';
}

const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  (props: ActionButtonProps, ref): JSX.Element => {
    const {
      collapseOnMobile = true,
      grow = false,
      iconColor,
      isActive,
      noShrink = false,
      onClick,
      onClear,
      singleLine = true,
      Icon,
      text,
    } = props;

    return (
      <button
        {...props}
        ref={ref}
        className={classNames(
          `group-action-button flex w-fit items-center gap-2 justify-between rounded-md border border-solid px-2 py-0 transition hover:shadow-sm`,
          {
            'text-gray-500 hover:text-mauve-10 bg-mauve-3 hover:bg-mauve-4 dark:bg-mauve-dark-3 dark:text-gray-400 dark:hover:bg-mauve-dark-4 dark:hover:border-mauve-dark-8 hover:border-mauve-8 border-mauve-7 dark:border-mauve-dark-7 dark:hover:text-mauve-dark-10':
              !isActive,
            'text-violet-11 border-violet-9 dark:text-white bg-violet-5 hover:bg-violet-4 dark:hover:bg-violet-dark-4 dark:bg-violet-dark-5 dark:border-violet-dark-9 hover:border-violet-10 dark:hover:border-violet-dark-10':
              isActive,
            'min-w-fit shrink-0': noShrink,
            'h-7': singleLine,
            'flex-grow': grow,
          }
        )}
        onClick={onClick}
        type="button"
      >
        <div className="flex items-center gap-2 flex-grow">
          <Icon
            className={classNames('flex-shrink-0', {
              'text-violet-600 dark:text-white': isActive && !iconColor,
              'text-gray-600 group-action-button-hover:text-mauve-dark-10 dark:text-gray-400':
                !isActive,
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
              [iconColor as string]: !!iconColor,
            })}
            size={12}
          />

          {text && (
            <Text
              as="span"
              size="1"
              className={classNames('inline-block text-left', {
                hidden: collapseOnMobile,
                'line-clamp-1': singleLine,
                'flex-grow': grow,
              })}
            >
              {text}
            </Text>
          )}
        </div>

        {onClear && isActive && (
          <div
            className="rounded-full h-4 w-4 flex items-center justify-center bg-opacity-40 hover:bg-opacity-40 bg-violet-9 hover:bg-violet-10 dark:bg-violet-dark-9 hover:dark:bg-violet-dark-10"
            onClick={(e) => {
              e.stopPropagation();
              onClear();
            }}
          >
            <X size={10} />
          </div>
        )}
      </button>
    );
  }
);
ActionButton.displayName = 'ActionButton';

export default ActionButton;
