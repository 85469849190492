import React from 'react';

import { useUnauthenticatedRoute } from '../../hooks/use-authenticated-route';

export const WaitlistJoined = (): JSX.Element => {
  useUnauthenticatedRoute();

  return (
    <div className="waitlist-background flex items-center justify-center">
      <div className="flex-col flex items-center justify-center px-4 md:px-0 max-w-lg">
        <h1 className="tagline-1 text-3xl md:text-5xl mb-0">
          You&#8217;ve made the list
        </h1>
        <p className="text-white text-opacity-90 text-center text-base md:text-lg mb-8">
          Your daily radar is still being calibrated. We&#8217;ll give you a
          holler when it&#8217;s ready for you.
        </p>

        <p className="text-white text-opacity-90 text-center text-base md:text-lg">
          Want to help get things ready?
        </p>

        <div className="flex gap-8 mt-4">
          <a
            className="flex flex-col gap-2 text-white opacity-70 hover:opacity-100 no-underline transition-opacity duration-200"
            href="https://discord.gg/666cBEvk47"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="h-6"
              src="https://dtqddwmz0j63d.cloudfront.net/discord.svg"
            />
            Join the Discord
          </a>

          <a
            className="flex flex-col gap-2 text-white opacity-70 hover:opacity-100 no-underline transition-opacity duration-200"
            href="https://twitter.com/blips_planner"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="h-6"
              src="https://dtqddwmz0j63d.cloudfront.net/twitter.svg"
            />
            Follow on Twitter
          </a>
        </div>
      </div>
    </div>
  );
};
