import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useDispatch } from 'react-redux';

import { setIsHoveringTaskDropzone } from '../../reducers/actions';

import CalendarGrid from './CalendarGrid';
import CalendarHeader from './CalenderHeader';

import './Calendar.css';

const calendarVariants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 200 : -200,
      display: 'block',
      opacity: 1,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    display: 'block',
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 200 : -200,
      display: 'none',
      opacity: 0,
    };
  },
};

const swipeConfidenceThreshold = 10000;

const swipePower = (offset: number, velocity: number): number => {
  return Math.abs(offset) * velocity;
};

const Calendar = ({
  calendarDate,
  calendarDirection,
  onNextMonthClick,
  onPreviousMonthClick,
}: {
  calendarDate: Date;
  calendarDirection: number;
  onNextMonthClick: () => void;
  onPreviousMonthClick: () => void;
}): JSX.Element => {
  const dispatch = useDispatch();

  return (
    <div className="flex w-full flex-nowrap overflow-x-hidden">
      <AnimatePresence initial={false} onExitComplete={() => null}>
        <motion.div
          key={`calendar-${calendarDate.toDateString()}`}
          custom={calendarDirection}
          variants={calendarVariants}
          initial="enter"
          animate="center"
          exit="exit"
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          onDragEnd={(_e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              onNextMonthClick();
            } else if (swipe > swipeConfidenceThreshold) {
              onPreviousMonthClick();
            }
          }}
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30, duration: 0.3 },
            opacity: { duration: 0.1 },
          }}
          className="w-full flex-grow"
        >
          <div
            className="grid grid-cols-7 justify-items-center gap-0.5"
            onDragEnter={() => dispatch(setIsHoveringTaskDropzone(true))}
            onDragLeave={() => dispatch(setIsHoveringTaskDropzone(false))}
            onDragEnd={() => dispatch(setIsHoveringTaskDropzone(false))}
            onDragOver={() => dispatch(setIsHoveringTaskDropzone(true))}
          >
            <CalendarHeader />

            <CalendarGrid calendarDate={calendarDate} />
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Calendar;
