import { Text } from '@radix-ui/themes';
import { Command } from 'cmdk';
import { useLiveQuery } from 'dexie-react-hooks';
import { ChevronRight } from 'lucide-react';
import React, { useEffect } from 'react';

import { db } from '../../database';
import { storeBoardsFromServerInDatabase } from '../../database/actions';
import { useBoardsQuery } from '../../graphql/generated-types';

interface BoardSelectorProps {
  loading: boolean;
  onSelect: (boardId: string) => void;
  setLoading: (loading: boolean) => void;
}

export const BoardSelector: React.FC<BoardSelectorProps> = ({
  loading,
  onSelect,
  setLoading,
}) => {
  const [boardsData] = useBoardsQuery();

  useEffect(() => {
    if (boardsData?.data) {
      setLoading(false);
      void storeBoardsFromServerInDatabase(boardsData.data.boards);
    }
  }, [boardsData]);

  const boards = useLiveQuery(async () => {
    return await db.boards.filter((board) => !board.data.archivedAt).toArray();
  });

  return (
    <Command.Group>
      {loading && !boards && <Command.Loading>Loading...</Command.Loading>}

      {boards?.map((board) => (
        <Command.Item
          value={board.data.name.replace(/"/g, '')}
          key={board.data.id}
          onSelect={() => onSelect(board.data.id)}
          className="flex justify-between items-center"
        >
          <Text as="span" className="flex items-center">
            {board.data.emoji} {board.data.name}
          </Text>
          <ChevronRight size={16} />
        </Command.Item>
      ))}
    </Command.Group>
  );
};
