import { format, isPast, isThisYear } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
  Calendar,
  CalendarPlus,
  CalendarX2,
  LucideIcon,
  Zap,
} from 'lucide-react';
import React, { useState } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';
import { Link } from 'react-router-dom';

import { storeProjectsInDatabase } from '../../database/actions';
import {
  ProjectFragment,
  useUpdateProjectMutation,
} from '../../graphql/generated-types';
import { Project as ProjectType } from '../../types';
import DateCalendarModal from '../DateCalendarModal';
import { FloatingBoardSelector } from '../FloatingSelector/FloatingBoardSelector';
import Tooltip from '../library/Tooltip';
import DeleteProjectModal from '../ProjectContainer/DeleteProjectModal';
import { DuplicateProjectModal } from '../ProjectContainer/DuplicateProjectModal';
import ProjectProgress from '../ProjectProgress';

import ProjectContextMenu from './ProjectContextMenu';

const Project = ({ project }: { project: ProjectType }): JSX.Element => {
  const [, updateProject] = useUpdateProjectMutation();

  const completionProgress =
    (project.data.tasks.completedCount / project.data.tasks.totalCount) * 100;

  const [deleteProjectModalOpen, setDeleteProjectModalOpen] = useState(false);
  const [moveProjectModalOpen, setMoveProjectModalOpen] = useState(false);

  const [duplicateProjectModalOpen, setDuplicateProjectModalOpen] =
    useState(false);

  const [startDateCalendarModalOpen, setStartDateCalendarModalOpen] =
    useState(false);

  const [endDateCalendarModalOpen, setEndDateCalendarModalOpen] =
    useState(false);

  const onUpdateProject = async <K extends keyof ProjectFragment>(
    key: K | 'boardId',
    value: ProjectFragment[K]
  ): Promise<void> => {
    await storeProjectsInDatabase([
      {
        ...project,
        data: {
          ...project.data,
          [key]: value,
        },
      },
    ]);

    await updateProject({ projectId: project.data.id, [key]: value });
  };

  return (
    <>
      <ContextMenuTrigger
        id={`board-container-${project.data.id}`}
        holdToDisplay={-1}
      >
        <div className="flex items-center justify-between pr-4">
          <div className="flex items-center py-2">
            <ProjectProgress
              completionProgress={completionProgress}
              project={project}
            />

            <Link
              to={`/projects/${project.data.id}`}
              className="text-lg text-gray-900 no-underline hover:text-violet-12 dark:hover:text-violet-dark-12  dark:text-white md:text-base"
            >
              {project.data.name}

              {project.data.springEnabled && (
                <Zap size={14} className="ml-1 text-violet-400" />
              )}
            </Link>
          </div>

          <div className="hidden items-center md:flex">
            <span className="inline-block w-25">
              <Tooltip
                side="right"
                text={project.data.date ? 'Start date' : 'Add start date'}
              >
                <DateButton
                  Icon={project.data.date ? undefined : CalendarPlus}
                  onClick={() => setStartDateCalendarModalOpen(true)}
                  text={
                    project.data.date
                      ? formattedDate(project.data.date)
                      : undefined
                  }
                />
              </Tooltip>
            </span>

            <span className="inline-block w-25">
              <Tooltip
                side="right"
                text={
                  project.data.date || project.data.completedAt
                    ? 'End date'
                    : 'Add end date'
                }
              >
                <DateButton
                  Icon={
                    project.data.completed
                      ? undefined
                      : !project.data.endDate
                      ? CalendarPlus
                      : isPast(new Date(project.data.endDate))
                      ? CalendarX2
                      : Calendar
                  }
                  iconClasses={
                    project.data.endDate &&
                    isPast(new Date(project.data.endDate))
                      ? 'text-red-400'
                      : undefined
                  }
                  onClick={() => setEndDateCalendarModalOpen(true)}
                  text={
                    project.data.endDate || project.data.completedAt
                      ? formattedDate(
                          project.data.endDate || project.data.completedAt
                        )
                      : undefined
                  }
                />
              </Tooltip>
            </span>
          </div>
        </div>
      </ContextMenuTrigger>

      <ProjectContextMenu
        onDuplicateProject={() => setDuplicateProjectModalOpen(true)}
        onDeleteProject={() => setDeleteProjectModalOpen(true)}
        onMoveProject={() => setMoveProjectModalOpen(true)}
        project={project}
      />

      {startDateCalendarModalOpen && (
        <DateCalendarModal
          open={startDateCalendarModalOpen}
          onSave={async (date) => {
            void onUpdateProject('date', date?.toISOString() || null);
            setStartDateCalendarModalOpen(false);
          }}
          initialDate={project.data.date ? new Date(project.data.date) : null}
          setIsOpen={setStartDateCalendarModalOpen}
          title="Set project start date"
        />
      )}

      {moveProjectModalOpen && (
        <FloatingBoardSelector
          onSelect={(boardId) => {
            void onUpdateProject('boardId', boardId);
            setMoveProjectModalOpen(false);
          }}
          open={moveProjectModalOpen}
          setOpen={setMoveProjectModalOpen}
        />
      )}

      {endDateCalendarModalOpen && (
        <DateCalendarModal
          open={endDateCalendarModalOpen}
          onSave={async (date) => {
            void onUpdateProject('endDate', date?.toISOString() || null);
            setEndDateCalendarModalOpen(false);
          }}
          initialDate={
            project.data.endDate ? new Date(project.data.endDate) : null
          }
          setIsOpen={setEndDateCalendarModalOpen}
          title="Set project end date"
        />
      )}

      {deleteProjectModalOpen && (
        <DeleteProjectModal
          open={deleteProjectModalOpen}
          project={project}
          setIsOpen={setDeleteProjectModalOpen}
        />
      )}

      {duplicateProjectModalOpen && (
        <DuplicateProjectModal
          open={duplicateProjectModalOpen}
          project={project}
          setIsOpen={setDuplicateProjectModalOpen}
        />
      )}
    </>
  );
};

const DateButton = ({
  Icon,
  iconClasses,
  onClick,
  text,
}: {
  Icon?: LucideIcon;
  iconClasses?: string;
  onClick: () => void;
  text?: string;
}): JSX.Element => {
  return (
    <button
      className="flex cursor-pointer items-center gap-1 rounded-md border-0 bg-transparent p-1 text-sm hover:bg-opacity-70 dark:text-gray-300 dark:hover:bg-mauve-dark-4"
      onClick={onClick}
    >
      {Icon && <Icon className={iconClasses} size={14} />}

      {text}
    </button>
  );
};

const formattedDate = (dateString: string): string => {
  const date = zonedTimeToUtc(
    dateString,
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const dateFormat = isThisYear(date) ? 'MMM dd' : 'MMM yyyy';

  return format(date, dateFormat);
};

export default Project;
