type IosMessage =
  | { type: 'impactHaptic' }
  | {
      type: 'successHaptic';
    }
  | {
      type: 'selectionHaptic';
    };

export const interopWithIos = (message: IosMessage): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const thisWindow = window as any;

  if (!thisWindow?.webkit?.messageHandlers) {
    return;
  }

  switch (message.type) {
    case 'impactHaptic':
      thisWindow.webkit.messageHandlers.impactHaptic.postMessage({});
      break;
    case 'selectionHaptic':
      thisWindow.webkit.messageHandlers.selectionHaptic.postMessage({});
      break;
    case 'successHaptic':
      thisWindow.webkit.messageHandlers.successHaptic.postMessage({});
      break;
    default:
  }
};
