import { Box, Flex, Select, Text } from '@radix-ui/themes';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { Book, CheckCircle, Grid, Icon, List, PenTool } from 'lucide-react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContainerTypeEnum } from '../../graphql/generated-types';
import {
  setCreatorModalMode,
  setMobileToolbarVisible,
  setOverrideCreationGroup,
} from '../../reducers/actions';
import { BetaState, CreatorModalMode } from '../../reducers/beta-types';
import { Project } from '../../types';
import Modal from '../Modal';

import { BoardCreatorForm } from './BoardCreatorForm';
import { GroupCreatorForm } from './GroupCreatorForm';
import { NoteCreatorForm } from './NoteCreatorForm';
import { ProjectCreatorForm } from './ProjectCreatorForm';
import { TaskCreatorForm } from './TaskCreatorForm';

export interface CreatorFormProps {
  createMultiple: boolean;
  itemName: string;
  itemsToCreate: string[];
  setCreateMultiple: (createMultiple: boolean) => void;
  setItemName: (itemName: string) => void;
  setItemsToCreate: (itemsToCreate: string[]) => void;
  setShowingDeleteItem: (showingDeleteItem: number | null) => void;
  showingDeleteItem: number | null;
}

const CreatorModal = ({
  boardId,
  containerId,
  containerType,
  groupId,
  date,
  project,
  projectColumnId,
  refetchContainerData,
}: {
  boardId?: string;
  containerId?: string;
  containerType?: ContainerTypeEnum;
  date?: string;
  groupId?: string | null;
  project?: Project;
  projectColumnId?: string;
  refetchContainerData?: () => void;
}): JSX.Element | null => {
  const dispatch = useDispatch();

  const { creatorModalMode } = useSelector((state: BetaState) => ({
    creatorModalMode: state.creatorModalMode,
  }));

  const [itemsToCreate, setItemsToCreate] = useState<string[]>([]);
  const [itemName, setItemName] = useState('');
  const [createMultiple, setCreateMultiple] = useState(false);
  const [showingDeleteItem, setShowingDeleteItem] = useState<number | null>(
    null
  );

  const handleModalChange = (isOpen: boolean): void => {
    setItemsToCreate([]);
    setItemName('');
    setCreateMultiple(false);
    setShowingDeleteItem(null);
    dispatch(setCreatorModalMode(null));

    if (!isOpen) {
      dispatch(setOverrideCreationGroup(null));
      dispatch(setMobileToolbarVisible(true));
    }
  };

  const creatorFormProps: CreatorFormProps = {
    createMultiple,
    itemName,
    itemsToCreate,
    setCreateMultiple,
    setItemName,
    setItemsToCreate,
    setShowingDeleteItem,
    showingDeleteItem,
  };

  if (!creatorModalMode) {
    return null;
  }

  return (
    <Modal
      noTitleBorder
      open={creatorModalMode !== null}
      setIsOpen={handleModalChange}
      title={
        <Select.Root
          value={creatorModalMode}
          onValueChange={(value: CreatorModalMode) =>
            dispatch(setCreatorModalMode(value))
          }
        >
          <Select.Trigger variant="surface" />

          <Select.Content className="z-map-modal-dropdown">
            <SelectItem
              selectedValue={creatorModalMode}
              IndicatorIcon={CheckCircle}
              value="task"
              displayValue="Create tasks"
            />

            <SelectItem
              selectedValue={creatorModalMode}
              IndicatorIcon={PenTool}
              value="note"
              displayValue="Create note"
            />

            <SelectItem
              selectedValue={creatorModalMode}
              IndicatorIcon={Book}
              value="project"
              displayValue="Create projects"
            />

            {containerId && (
              <SelectItem
                selectedValue={creatorModalMode}
                IndicatorIcon={List}
                value="group"
                displayValue="Create groups"
              />
            )}

            <SelectItem
              selectedValue={creatorModalMode}
              IndicatorIcon={Grid}
              value="board"
              displayValue="Create boards"
            />
          </Select.Content>
        </Select.Root>
      }
    >
      <AnimatePresence exitBeforeEnter>
        {creatorModalMode === 'task' && (
          <TaskCreatorForm
            date={date}
            groupId={groupId}
            handleModalChange={handleModalChange}
            project={project}
            refetchContainerData={refetchContainerData}
            key="task-creator-form"
            {...creatorFormProps}
          />
        )}

        {creatorModalMode === 'project' && (
          <ProjectCreatorForm
            boardId={boardId}
            projectColumnId={projectColumnId}
            date={date}
            handleModalChange={handleModalChange}
            key="project-creator-form"
            {...creatorFormProps}
          />
        )}

        {creatorModalMode === 'group' && (
          <GroupCreatorForm
            containerId={containerId}
            containerType={containerType}
            handleModalChange={handleModalChange}
            refetchContainerData={refetchContainerData}
            key="group-creator-form"
            {...creatorFormProps}
          />
        )}

        {creatorModalMode === 'board' && (
          <BoardCreatorForm
            {...creatorFormProps}
            handleModalChange={handleModalChange}
            key="board-creator-form"
          />
        )}

        {creatorModalMode === 'note' && (
          <NoteCreatorForm
            {...creatorFormProps}
            handleModalChange={handleModalChange}
            key="note-creator-form"
          />
        )}
      </AnimatePresence>
    </Modal>
  );
};

const SelectItem = ({
  IndicatorIcon,
  selectedValue,
  value,
  displayValue,
}: {
  IndicatorIcon: Icon;
  selectedValue: CreatorModalMode;
  value: CreatorModalMode;
  displayValue: string;
}): JSX.Element => {
  const isSelected = selectedValue === value;

  return (
    <Select.Item
      value={value}
      className="group override-NoIndicator cursor-pointer"
    >
      <Flex display="inline-flex" gap="2" align="center">
        <IndicatorIcon
          size={12}
          className={classNames(
            ' dark:group-hover:text-white dark:group-focus:text-white',
            {
              'text-violet-9 dark:text-violet-dark-9 group-hover:dark:text-violet-dark-10 group-hover:text-violet-8':
                isSelected,
              'group-hover:text-violet-8 text-mauve-9 dark:text-mauve-dark-9 group-hover:dark:text-mauve-dark-10':
                !isSelected,
            }
          )}
        />

        <Text as="span" size="1">
          {displayValue}
        </Text>
      </Flex>
    </Select.Item>
  );
};

export default CreatorModal;
