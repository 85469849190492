import React from 'react';

import Content from '../Content';
import Sidebar from '../Sidebar';

import Toolbar from './Toolbar';

const DesktopFrame = (): JSX.Element => {
  return (
    <div className="hidden h-screen w-screen grid-cols-desktop md:grid">
      <Toolbar />
      <Sidebar />
      <Content />
    </div>
  );
};

export default DesktopFrame;
