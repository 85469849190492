import {
  Draggable,
  Droppable,
} from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import flattenDeep from 'lodash/flattenDeep';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import { Plus } from 'lucide-react';
import React, { memo, MouseEvent } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';

import {
  setCreatorModalMode,
  setLastSelectedTask,
  setMobileToolbarVisible,
  setSelectedTasks,
} from '../../reducers/actions';
import { BetaState } from '../../reducers/beta-types';
import { Task } from '../../types';
import { interopWithIos } from '../../utils/interop-with-ios';

import Group from './Group';

const GroupList = ({
  containerTasks,
  selectedDate = null,
}: {
  containerTasks: Task[];
  projectId?: string | null;
  selectedDate?: string | null;
}): JSX.Element => {
  const dispatch = useDispatch();

  const {
    containerGroups,
    currentDraggingType,
    draggingTask,
    isDragging,
    isHoveringTaskDropzone,
    isTaskFocused,
    lastSelectedTask,
    selectedProject,
    selectedTasks,
    selectedTasksLocation,
    timeZone,
  } = useSelector((state: BetaState) => ({
    containerGroups: state.containerGroups,
    currentDraggingType: state.currentDraggingType,
    draggingTask: state.draggingTask,
    isDragging: state.isDragging,
    isTaskFocused: !!state.focusedTask,
    isHoveringTaskDropzone: state.isHoveringTaskDropzone,
    lastSelectedTask: state.lastSelectedTask,
    selectedProject: state.selectedProject,
    selectedTasks: state.selectedTasks,
    selectedTasksLocation: state.selectedTasksLocation,
    timeZone: state.timeZone,
  }));

  const onClickAddTask = (): void => {
    dispatch(setCreatorModalMode('task'));
    dispatch(setMobileToolbarVisible(false));
  };

  const onClickTaskHeader = (
    id: string,
    taskLocation: string,
    event: MouseEvent
  ): void => {
    interopWithIos({ type: 'selectionHaptic' });

    if (
      (!event.shiftKey && !event.metaKey) ||
      selectedTasksLocation !== taskLocation
    ) {
      dispatch(setLastSelectedTask(id));
      dispatch(setSelectedTasks([id], taskLocation));

      return;
    }

    const flattenedTasksList: Task[] = flattenDeep(
      containerGroups.map((group) => {
        const [completedTasks, uncompletedTasks] = partition(
          containerTasks.filter((task) =>
            task.data.groupIds.includes(group.data.id)
          ),
          (task) => task.data.completed && !task.meta.recentlyCompleted
        );

        return [
          ...orderBy(uncompletedTasks, `order.${group.meta.type}`),
          ...orderBy(completedTasks, 'data.completedAt'),
        ];
      })
    );

    const clickedTaskIndex = flattenedTasksList.findIndex(
      (task) => task.data.id === id
    );

    const lastSelectedTaskIndex = flattenedTasksList.findIndex(
      (task) => task.data.id === lastSelectedTask
    );

    if (event.shiftKey) {
      const newSelectedTasks = flattenedTasksList
        .slice(
          Math.min(clickedTaskIndex, lastSelectedTaskIndex),
          Math.max(clickedTaskIndex, lastSelectedTaskIndex) + 1
        )
        .map((task) => task.data.id);

      dispatch(setSelectedTasks(newSelectedTasks, taskLocation));

      return;
    }

    if (event.metaKey) {
      if (selectedTasks.includes(id)) {
        dispatch(
          setSelectedTasks(
            selectedTasks.filter((taskId) => taskId !== id),
            taskLocation
          )
        );
      } else {
        dispatch(setSelectedTasks([...selectedTasks, id], taskLocation));
      }
    }
  };

  if (!containerGroups.length) {
    return (
      <div className="mx-[14px] mb-2">
        <button
          className="flex border-0 bg-transparent p-0 transition-all duration-200 cursor-pointer hover:text-purple-500 items-center gap-2 dark:text-gray-400 text-gray-500"
          onClick={onClickAddTask}
        >
          <Plus size={20} /> Add Task
        </button>
      </div>
    );
  }

  return (
    <Droppable
      droppableId={'container-group-list'}
      isDropDisabled={isHoveringTaskDropzone || currentDraggingType !== 'group'}
    >
      {(provided) => (
        <div ref={provided.innerRef}>
          {orderBy(containerGroups, 'data.order').map((group, index) => (
            <Draggable
              key={`group-${group.data.id}`}
              index={index}
              draggableId={`group-${group.data.id}`}
              disableInteractiveElementBlocking
            >
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} style={{ ...provided.draggableProps.style, opacity: "revert" }}>
                  <Group
                    containerTasks={containerTasks}
                    currentDraggingType={currentDraggingType}
                    draggingTask={draggingTask}
                    isDragging={isDragging}
                    isGroupDragging={snapshot.isDragging}
                    isHoveringTaskDropzone={isHoveringTaskDropzone}
                    isTaskFocused={isTaskFocused}
                    group={group}
                    groupDraggableProvided={provided}
                    onClickTaskHeader={onClickTaskHeader}
                    selectedDate={selectedDate}
                    selectedProject={selectedProject}
                    selectedTasks={selectedTasks}
                    selectedTasksLocation={selectedTasksLocation}
                    timeZone={timeZone}
                  />
                </div>
              )}
            </Draggable>
          ))}

          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default memo(GroupList, isEqual);
