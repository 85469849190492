import * as Select from '@radix-ui/react-select';
import { useActive, useAttrs, useChainedCommands } from '@remirror/react';
import classNames from 'classnames';
import {
  Bold,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Code,
  CurlyBraces,
  Heading1,
  Heading2,
  Heading3,
  Italic,
  Link,
  List,
  ListOrdered,
  Quote,
  Strikethrough,
  Type,
  Underline,
  Unlink,
  X,
} from 'lucide-react';
import React, { FormEvent, forwardRef, useCallback, useState } from 'react';
import { isWebUri } from 'valid-url';

import Tooltip from '../library/Tooltip';

type EditorControlMode = 'controls' | 'link';

type TextType =
  | 'blockquote'
  | 'bullet-list'
  | 'code-block'
  | 'text'
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'ordered-list';

export const EditorControls = (): JSX.Element => {
  const active = useActive();
  const chain = useChainedCommands();

  const [mode, setMode] = useState<EditorControlMode>('controls');

  const activeTextType = useCallback((): TextType => {
    if (active.blockquote()) return 'blockquote';
    if (active.bulletList()) return 'bullet-list';
    if (active.codeBlock()) return 'code-block';
    if (active.heading({ level: 1 })) return 'heading1';
    if (active.heading({ level: 2 })) return 'heading2';
    if (active.heading({ level: 3 })) return 'heading3';
    if (active.orderedList()) return 'ordered-list';

    return 'text';
  }, [active]);

  return (
    <div className="flex bg-neutral-900 z-map-editor-controls">
      {mode === 'controls' && (
        <>
          <TextTypeDropdown value={activeTextType()} />

          <EditorControl
            isActive={active.bold()}
            onClick={() => {
              chain.toggleBold().focus().run();
            }}
            shortcut="⌘B"
            tooltip="Bold"
          >
            <Bold size={18} />
          </EditorControl>

          <EditorControl
            isActive={active.italic()}
            onClick={() => {
              chain.toggleItalic().focus().run();
            }}
            shortcut="⌘I"
            tooltip="Italic"
          >
            <Italic size={18} />
          </EditorControl>

          <EditorControl
            isActive={active.underline()}
            onClick={() => {
              chain.toggleUnderline().focus().run();
            }}
            shortcut="⌘U"
            tooltip="Underline"
          >
            <Underline size={18} />
          </EditorControl>

          <EditorControl
            isActive={active.strike()}
            onClick={() => {
              chain.toggleStrike().focus().run();
            }}
            shortcut="⇧⌘X"
            tooltip="Strikethrough"
          >
            <Strikethrough size={18} />
          </EditorControl>

          <EditorControl
            isActive={active.code()}
            onClick={() => {
              chain.toggleCode().focus().run();
            }}
            shortcut="⌘E"
            tooltip="Inline code"
          >
            <Code size={18} />
          </EditorControl>

          <EditorControl
            isActive={active.link()}
            onClick={() => {
              if (active.link()) {
                chain.selectLink().focus().run();
              }

              setMode('link');
            }}
            shortcut="⌘K"
            tooltip="Link"
          >
            <Link size={18} />
          </EditorControl>
        </>
      )}

      {mode === 'link' && <LinkManagement setMode={setMode} />}
    </div>
  );
};

const LinkManagement = ({
  setMode,
}: {
  setMode: (mode: EditorControlMode) => void;
}): JSX.Element => {
  const active = useActive();
  const chain = useChainedCommands();

  const [link, setLink] = useState<string>(
    (useAttrs().link()?.href as string) ?? ''
  );

  const onSubmit = (event: FormEvent): void => {
    event.preventDefault();

    if (isWebUri(link)) {
      chain.updateLink({ href: link, auto: false }).focus().run();
      setMode('controls');
    }
  };

  return (
    <form className="flex items-center" onSubmit={onSubmit}>
      <input
        className="h-full w-50 border-none bg-transparent px-2 py-1 text-sm text-white hover:bg-neutral-700 hover:bg-opacity-60 focus:bg-neutral-700 focus:bg-opacity-60 focus-visible:outline-none"
        value={link}
        autoFocus
        autoComplete="off"
        type="search"
        onChange={(e) => setLink(e.target.value)}
      />

      <div>
        {active.link() && (
          <EditorControl
            isActive={false}
            onClick={() => {
              chain.removeLink().focus().run();
              setMode('controls');
            }}
            tooltip="Remove link"
          >
            <Unlink size={18} />
          </EditorControl>
        )}

        {!active.link() && (
          <EditorControl
            isActive={false}
            onClick={() => {
              setMode('controls');
            }}
            tooltip="Cancel"
          >
            <X size={18} />
          </EditorControl>
        )}

        <EditorControl
          isActive={false}
          tooltip={active.link() ? 'Update link' : 'Add link'}
          type="submit"
          shortcut="⏎"
        >
          <Link size={18} />
        </EditorControl>
      </div>
    </form>
  );
};

const EditorControl = ({
  children,
  isActive,
  onClick,
  shortcut,
  tooltip,
  type = 'button',
}: {
  children: React.ReactNode;
  isActive: boolean;
  onClick?: () => void;
  shortcut?: string;
  tooltip: string | JSX.Element;
  type?: 'button' | 'submit';
}): JSX.Element => {
  return (
    <Tooltip
      text={
        <div className="flex gap-2">
          <span>{tooltip}</span>
          {shortcut && <span className="text-neutral-400">{shortcut}</span>}
        </div>
      }
      textSize="xs"
    >
      <button
        className={classNames(
          'border-0 bg-transparent px-3 pt-2 pb-1 text-neutral-300 hover:text-white',
          {
            'bg-violet-600 !text-white': isActive,
            'hover:bg-neutral-700 hover:bg-opacity-60': !isActive,
          }
        )}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    </Tooltip>
  );
};

const TextTypeDropdown = ({ value }: { value: TextType }): JSX.Element => {
  const chain = useChainedCommands();

  const onValueChange = (newValue: TextType): void => {
    // Remove old styling
    switch (value) {
      case 'blockquote':
        chain.toggleBlockquote().focus().run();
        break;
      case 'bullet-list':
        chain.toggleBulletList().focus().run();
        break;
      case 'code-block':
        chain.toggleCodeBlock().focus().run();
        break;
      case 'heading1':
      case 'heading2':
      case 'heading3':
        chain.toggleHeading().focus().run();
        break;
      case 'ordered-list':
        chain.toggleOrderedList().focus().run();
        break;
    }

    // Apply new styling
    switch (newValue) {
      case 'blockquote':
        chain.toggleBlockquote().focus().run();
        break;
      case 'bullet-list':
        chain.toggleBulletList().focus().run();
        break;
      case 'code-block':
        chain.toggleCodeBlock().focus().run();
        break;
      case 'heading1':
        chain.toggleHeading({ level: 1 }).focus().run();
        break;
      case 'heading2':
        chain.toggleHeading({ level: 2 }).focus().run();
        break;
      case 'heading3':
        chain.toggleHeading({ level: 3 }).focus().run();
        break;
      case 'ordered-list':
        chain.toggleOrderedList().focus().run();
        break;
    }
  };

  return (
    <Select.Root value={value} onValueChange={onValueChange}>
      <Select.Trigger
        className="flex items-center gap-2 border-0 bg-transparent pl-4 text-sm text-neutral-300 hover:bg-neutral-700 hover:bg-opacity-60 hover:text-white focus-visible:outline-none"
        aria-label="Text type"
      >
        <Select.Value />
        <Select.Icon className="flex items-center">
          <ChevronDownIcon size={12} />
        </Select.Icon>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content
          className="min-w-[100px] rounded-md bg-neutral-900 p-1 text-neutral-300"
          style={{ zIndex: 101 }}
        >
          <Select.ScrollUpButton className="flex justify-center">
            <ChevronUpIcon size={14} />
          </Select.ScrollUpButton>

          <Select.Viewport>
            <Select.Group>
              <SelectItem Icon={Type} value="text">
                Text
              </SelectItem>

              <SelectItem Icon={Heading1} value="heading1">
                Header 1
              </SelectItem>

              <SelectItem Icon={Heading2} value="heading2">
                Header 2
              </SelectItem>

              <SelectItem Icon={Heading3} value="heading3">
                Header 3
              </SelectItem>

              <SelectItem Icon={List} value="bullet-list">
                Bullet list
              </SelectItem>

              <SelectItem Icon={ListOrdered} value="ordered-list">
                Numbered list
              </SelectItem>

              <SelectItem Icon={CurlyBraces} value="code-block">
                Code block
              </SelectItem>

              <SelectItem Icon={Quote} value="blockquote">
                Quote
              </SelectItem>
            </Select.Group>
          </Select.Viewport>

          <Select.ScrollDownButton className="flex justify-center">
            <ChevronDownIcon size={14} />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

// eslint-disable-next-line react/display-name
const SelectItem = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ children, className, Icon, ...props }: any, forwardedRef) => {
    return (
      <Select.Item
        className={classNames(
          'flex cursor-default justify-between gap-2 py-1 px-2 text-sm hover:bg-neutral-700 hover:bg-opacity-60 hover:text-white focus-visible:outline-none',
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>
          <div className="flex items-center gap-2">
            <Icon size={14} />

            {children}
          </div>
        </Select.ItemText>

        <Select.ItemIndicator className="flex items-center">
          <CheckIcon size={14} />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);
