import classNames from 'classnames';
import {
  addDays,
  addMonths,
  format,
  isSameDay,
  startOfWeek,
  subMonths,
} from 'date-fns';
import { useLiveQuery } from 'dexie-react-hooks';
import {
  ArrowRightCircle,
  Calendar as CalendarIcon,
  ChevronLeft,
  ChevronRight,
  Inbox,
  LucideProps,
  Sunset,
} from 'lucide-react';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';

import { db } from '../../../database';
import { storeGroupsFromServerInDatabase } from '../../../database/actions';
import { useContainerQuery } from '../../../graphql/generated-types';
import {
  setMobileSidebarVisible,
  setSelectedDate,
} from '../../../reducers/actions';
import { pathForDate } from '../../../utils/date';
import Calendar from '../../Calendar';

const CalendarSidebar = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [calendarDate, setCalendarDate] = useState(new Date());
  const [calendarDirection, setCalendarDirection] = useState<number>(0);

  const todayDate = new Date();
  const tomorrowDate = addDays(todayDate, 1);
  const nextWeekDate = startOfWeek(addDays(todayDate, 7), { weekStartsOn: 1 });
  const nextWeekIsTomorrow = isSameDay(tomorrowDate, nextWeekDate);

  const [containerData] = useContainerQuery({
    variables: { inbox: true },
    requestPolicy: 'cache-and-network',
  });

  const inboxTasks = useLiveQuery(async () => {
    return await db.tasks
      .filter(
        (task) =>
          task.data.projectId === null &&
          !task.data.completed &&
          task.data.date === null
      )
      .toArray();
  });

  useEffect(() => {
    if (containerData.data?.container) {
      const { groups } = containerData.data.container;

      void storeGroupsFromServerInDatabase(groups);
    }
  }, [containerData.data]);

  const todayMatch = useRouteMatch({
    path: pathForDate(todayDate),
    exact: true,
  });

  const tomorrowMatch = useRouteMatch({
    path: pathForDate(tomorrowDate),
    exact: true,
  });

  const nextWeekMatch = useRouteMatch({
    path: pathForDate(nextWeekDate),
    exact: true,
  });

  const inboxMatch = useRouteMatch({
    path: '/inbox',
    exact: true,
  });

  const onNextMonthClick = (): void => {
    setCalendarDirection(1);
    setCalendarDate(addMonths(calendarDate, 1));
  };

  const onPreviousMonthClick = (): void => {
    setCalendarDirection(-1);
    setCalendarDate(subMonths(calendarDate, 1));
  };

  // Re-render the component every minute. This way, when the day transitions to the next day the
  // calendar component will (relatively) quickly transition to display it. This solves an issue
  // where users will leave the app open over night and it still displays yesterday as the current
  // day.
  const [, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="h-full select-none border-0 border-r border-solid border-violet-100 bg-gray-50 shadow-sidebar-gray dark:border-violet-900 dark:bg-mauve-dark-2 dark:text-white dark:shadow-none">
      <div className="flex h-18 items-center justify-between px-5">
        <h2 className="my-0 text-2xl font-semibold">
          {format(calendarDate, 'MMMM yyyy')}
        </h2>

        <div className="flex items-center">
          <ChevronLeft
            className="cursor-pointer transition hover:text-violet-500"
            data-testid="previous-month-button"
            role="button"
            size={28}
            onClick={onPreviousMonthClick}
          />

          <ChevronRight
            className="cursor-pointer transition hover:text-violet-500"
            data-testid="next-month-button"
            role="button"
            size={28}
            onClick={onNextMonthClick}
          />
        </div>
      </div>

      <div className="border-0 border-b border-t border-solid border-gray-300 bg-white px-8 pt-8 pb-4 shadow-calendar-light dark:border-violet-900 dark:bg-mauve-dark-3 dark:shadow-calendar-dark">
        <Calendar
          calendarDate={calendarDate}
          calendarDirection={calendarDirection}
          onNextMonthClick={onNextMonthClick}
          onPreviousMonthClick={onPreviousMonthClick}
        />
      </div>

      <div className="px-3 py-5">
        <CalendarShortcut
          Icon={CalendarIcon}
          isActive={!!todayMatch}
          text="Today"
          onClick={() => {
            dispatch(setMobileSidebarVisible(false));
            dispatch(setSelectedDate(todayDate.toDateString()));
            history.push(pathForDate(todayDate));
            setCalendarDate(todayDate);
          }}
        />

        <CalendarShortcut
          Icon={Sunset}
          isActive={!!tomorrowMatch}
          text="Tomorrow"
          onClick={() => {
            dispatch(setMobileSidebarVisible(false));
            dispatch(setSelectedDate(tomorrowDate.toDateString()));
            history.push(pathForDate(tomorrowDate));
          }}
        />

        {!nextWeekIsTomorrow && (
          <CalendarShortcut
            Icon={ArrowRightCircle}
            isActive={!!nextWeekMatch}
            text="Next Week"
            onClick={() => {
              dispatch(setMobileSidebarVisible(false));
              dispatch(setSelectedDate(nextWeekDate.toDateString()));
              history.push(pathForDate(nextWeekDate));
            }}
          />
        )}

        <CalendarShortcut
          Icon={Inbox}
          indicatorCount={inboxTasks?.length}
          isActive={!!inboxMatch}
          text="Inbox"
          onClick={() => {
            dispatch(setMobileSidebarVisible(false));
            history.push('/inbox');
          }}
        />
      </div>
    </div>
  );
};

const CalendarShortcut = ({
  Icon,
  indicatorCount,
  isActive = false,
  onClick,
  text,
}: {
  Icon: FC<LucideProps>;
  indicatorCount?: number;
  isActive?: boolean;
  onClick?: () => void;
  text: string;
}): JSX.Element => {
  return (
    <div className="group cursor-pointer" role="button" onClick={onClick}>
      <div
        className={classNames(
          'mb-0.5 flex items-center justify-between rounded border border-solid border-transparent px-4 py-2 transition group-hover:border-violet-8 dark:group-hover:border-violet-dark-8 group-hover:bg-violet-10 dark:bg-opacity-30 dark:group-hover:bg-violet-dark-10 dark:group-hover:bg-opacity-30',
          {
            '!border-violet-7 dark:!border-violet-dark-7 bg-violet-200 dark:bg-violet-dark-9':
              isActive,
          }
        )}
      >
        <div className="flex items-center">
          <Icon
            className="mr-4 text-gray-700 group-hover:text-violet-700 dark:text-violet-50 dark:group-hover:text-violet-200"
            size={24}
          />

          <span className="text-sm font-semibold text-gray-700 group-hover:text-violet-700 dark:text-violet-50 dark:shadow dark:group-hover:text-violet-200">
            {text}
          </span>
        </div>

        {indicatorCount && indicatorCount > 0 && (
          <span className="flex h-6 w-6 items-center justify-center rounded-full bg-violet-800 text-xs text-white group-hover:bg-violet-900">
            {indicatorCount}
          </span>
        )}
      </div>
    </div>
  );
};

export default CalendarSidebar;
