import { Dialog, Flex, IconButton, Inset } from '@radix-ui/themes';
import { X } from 'lucide-react';
import React from 'react';

import { RadixTheme } from '../RadixTheme';

const Modal = ({
  children,
  open,
  setIsOpen,
  title,
}: {
  children: React.ReactChild;
  fitContentWidth?: boolean;
  noPadding?: boolean;
  noTitleBorder?: boolean;
  open: boolean;
  setIsOpen: (open: boolean) => void;
  title: string | JSX.Element;
}): JSX.Element => {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={() => {
        if (open) {
          setIsOpen(false);
        }
      }}
    >
      <RadixTheme>
        <Dialog.Content size="1">
          <Inset>
            <Flex align="center" justify="between" ml="2" mr="3" mt="3" mb="3">
              {typeof title === 'string' ? (
                <Dialog.Title as="h2" ml="3" mt="2" mb="0">
                  {title}
                </Dialog.Title>
              ) : (
                <>{title}</>
              )}

              <Dialog.Close>
                <IconButton variant="ghost" radius="full" size="1">
                  <X size={14} />
                </IconButton>
              </Dialog.Close>
            </Flex>

            {children}
          </Inset>
        </Dialog.Content>
      </RadixTheme>
    </Dialog.Root>
  );
};

export default Modal;
