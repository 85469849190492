import {
  Content,
  Item,
  ItemText,
  Root,
  Trigger,
  Viewport,
} from '@radix-ui/react-select';
import { useLiveQuery } from 'dexie-react-hooks';
import orderBy from 'lodash/orderBy';
import { ArrowRight, Box } from 'lucide-react';
import React, { FormEvent, useEffect, useState } from 'react';

import { db } from '../../database';
import {
  storeBoardsFromServerInDatabase,
  storeProjectColumnsFromServerInDatabase,
  storeProjectsFromServerInDatabase,
} from '../../database/actions';
import {
  useBoardQuery,
  useBoardsQuery,
  useCreateProjectsMutation,
} from '../../graphql/generated-types';
import ActionButton from '../library/ActionButton';

import { CreatorForm } from './CreatorForm';
import { CreatorFormProps } from './CreatorModal';

export const ProjectCreatorForm = (
  props: CreatorFormProps & {
    boardId?: string;
    date?: string;
    handleModalChange: (isOpen: boolean) => void;
    projectColumnId?: string;
  }
): JSX.Element => {
  const {
    boardId: initialBoardId,
    createMultiple,
    handleModalChange,
    itemsToCreate,
    itemName,
    projectColumnId: initialProjectColumnId,
  } = props;

  const [boardId, setBoardId] = useState(initialBoardId);

  const [projectColumnId, setProjectColumnId] = useState(
    initialProjectColumnId
  );

  const [, createProjects] = useCreateProjectsMutation();

  const [boardsData] = useBoardsQuery();

  const projectColumns = useLiveQuery(async () => {
    if (boardId) {
      return await db.projectColumns
        .where('data.board.id')
        .equals(boardId)
        .toArray();
    } else {
      return [];
    }
  }, [boardId]);

  const boards = useLiveQuery(async () => {
    return await db.boards.toArray();
  });

  const orderedBoards = orderBy(boards, 'data.lastViewedAt', 'desc');
  const orderedProjectColumns = orderBy(projectColumns, 'data.order');

  const selectedBoard = orderedBoards.find(
    (board) => board.data.id === boardId
  );

  const selectedProjectColumn =
    orderedProjectColumns.find(
      (projectColumn) =>
        projectColumn.data.board.id === boardId &&
        projectColumn.data.id === projectColumnId
    ) || orderedProjectColumns[0];

  const [boardData] = useBoardQuery({
    variables: { boardId: selectedBoard?.data?.id ?? '' },
    pause: !selectedBoard,
  });

  useEffect(() => {
    if (boardsData?.data) {
      void storeBoardsFromServerInDatabase(boardsData.data.boards);

      if (!boardId) {
        setBoardId(boardsData.data.boards[0].id);
      }
    }
  }, [boardsData.data]);

  useEffect(() => {
    if (boardData?.data?.board) {
      void storeProjectColumnsFromServerInDatabase(
        boardData.data.board.projectColumns
      );
    }
  }, [boardData.data]);

  const onCreateProjects = async (event?: FormEvent): Promise<void> => {
    event?.preventDefault();

    let names: string[];

    if (createMultiple) {
      names = itemsToCreate.filter(Boolean);
    } else {
      names = [itemName];
    }

    void createProjects({
      boardId: selectedBoard?.data?.id,
      projectColumnId: selectedProjectColumn?.data?.id,
      names,
    }).then(async ({ data }) => {
      if (data) {
        await storeProjectsFromServerInDatabase(data.createProjects);
      }
    });

    handleModalChange(false);
  };

  return (
    <CreatorForm
      {...props}
      onCreate={onCreateProjects}
      placeholder="Project title"
      submitButtonText={
        createMultiple
          ? `Create ${itemsToCreate.filter(Boolean).length} project${
              itemsToCreate.filter(Boolean).length === 1 ? '' : 's'
            }`
          : 'Create project'
      }
    >
      <div className="flex flex-wrap items-center gap-2 px-2 pb-3">
        {orderedBoards && selectedBoard && (
          <div className="flex items-center rounded-md border border-solid border-gray-300 p-1 dark:border-gray-700 dark:bg-mauve-dark-3">
            <Root
              value={selectedBoard.data.id}
              onValueChange={(value: string) => setBoardId(value)}
            >
              <Trigger className="border-0 bg-transparent p-0">
                <ActionButton
                  collapseOnMobile={false}
                  isActive={true}
                  Icon={Box}
                  text={selectedBoard.data.name}
                />
              </Trigger>

              <Content className="rounded-md border border-solid border-transparent bg-white p-1 drop-shadow-xl z-map-modal-dropdown dark:border-gray-700 dark:bg-mauve-dark-3">
                <Viewport>
                  <span className="my-2 inline-block pl-4 text-sm text-gray-500 dark:text-gray-400">
                    Set board
                  </span>

                  {orderedBoards.map((board) => (
                    <Item
                      key={`board-dropdown-${board.data.id}`}
                      value={board.data.id}
                      className="min-w-50 max-w-100 cursor-pointer rounded-sm py-1_5 pl-4 pr-4 text-sm leading-4 hover:bg-violet-500 hover:text-white focus:bg-violet-500 focus:text-white focus:outline-none dark:text-white"
                    >
                      <ItemText>
                        {[board.data.emoji, board.data.name].join(' ')}
                      </ItemText>
                    </Item>
                  ))}
                </Viewport>
              </Content>
            </Root>

            {selectedProjectColumn && (
              <>
                <ArrowRight size={16} className="mx-2 shrink-0" />

                <Root
                  value={selectedProjectColumn.data.id}
                  onValueChange={(value: string) => setProjectColumnId(value)}
                >
                  <Trigger className="border-0 bg-transparent p-0">
                    <ActionButton
                      collapseOnMobile={false}
                      isActive={true}
                      Icon={Box}
                      text={selectedProjectColumn.data.name}
                    />
                  </Trigger>

                  <Content className="rounded-md border border-solid border-transparent bg-white p-1 drop-shadow-xl z-map-modal-dropdown dark:border-gray-700 dark:bg-mauve-dark-3">
                    <Viewport>
                      <span className="my-2 inline-block pl-4 text-sm text-gray-500 dark:text-gray-400">
                        Set board
                      </span>

                      {orderBy(projectColumns, 'data.order').map(
                        (projectColumn) => (
                          <Item
                            key={`board-dropdown-${projectColumn.data.id}`}
                            value={projectColumn.data.id}
                            className="min-w-50 max-w-100 cursor-pointer rounded-sm py-1_5 pl-4 pr-4 text-sm leading-4 hover:bg-violet-500 hover:text-white focus:bg-violet-500 focus:text-white focus:outline-none dark:text-white"
                          >
                            <ItemText>{projectColumn.data.name}</ItemText>
                          </Item>
                        )
                      )}
                    </Viewport>
                  </Content>
                </Root>
              </>
            )}
          </div>
        )}
      </div>
    </CreatorForm>
  );
};
