import {
  BulletListExtension,
  OrderedListExtension,
} from '@remirror/extension-list';
import {
  EditorComponent,
  FloatingToolbar,
  OnChangeJSON,
  Remirror,
  useEditorEvent,
  useRemirror,
} from '@remirror/react';
import classNames from 'classnames';
import { PenTool } from 'lucide-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import jsx from 'refractor/lang/jsx.js';
import ruby from 'refractor/lang/ruby.js';
import typescript from 'refractor/lang/typescript.js';
import { RemirrorJSON } from 'remirror';
import {
  BlockquoteExtension,
  BoldExtension,
  CodeBlockExtension,
  CodeExtension,
  HeadingExtension,
  ItalicExtension,
  LinkExtension,
  StrikeExtension,
  TrailingNodeExtension,
  UnderlineExtension,
} from 'remirror/extensions';

import { setMobileToolbarVisible } from '../../reducers/actions';

import { NoteEmbedExtension } from './extensions/NoteEmbedExtension';
import { EditorControls } from './EditorControls';
import { NoteSuggester } from './NoteSuggester';

import 'remirror/styles/all.css';
import './Notepad.css';

const useDebounce = (
  fn: () => void,
  ms: number = 0,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  args: any[] = []
): void => {
  useEffect(() => {
    const handle = setTimeout(fn.bind(null, args), ms);

    return () => {
      // if args change then clear timeout
      clearTimeout(handle);
    };
  }, [args, fn, ms]);
};

export const Notepad = ({
  content,
  displayHeader = true,
  editable = true,
  isSaving,
  onChange,
}: {
  content: string | null | undefined;
  displayHeader?: boolean;
  editable?: boolean;
  isSaving: boolean;
  onChange: (content: string) => Promise<void>;
}): JSX.Element => {
  const dispatch = useDispatch();

  const [contentToPersist, setContentToPersist] = useState<RemirrorJSON>();
  const [contentChanged, setContentChanged] = useState<boolean>(false);
  const [editorFocused, setEditorFocused] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = useCallback(({ json, invalidContent, transformers }: any) => {
    // Automatically remove all invalid nodes and marks.
    return transformers.remove(json, invalidContent);
  }, []);

  const linkExtension = new LinkExtension({ autoLink: true });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  linkExtension.addHandler('onClick', (_: any, data: any) => {
    window.open(data.href, '_blank');
    return true;
  });

  const noteEmbedExtension = new NoteEmbedExtension({
    matchers: [
      {
        name: 'note-embed',
        char: '/note ',
        appendText: ' ',
        supportedCharacters: /[^\s][\w\d_ ]+/,
      },
    ],
  });

  const { manager } = useRemirror({
    extensions: () => [
      new BlockquoteExtension(),
      new BoldExtension(),
      new BulletListExtension(),
      new CodeBlockExtension({
        supportedLanguages: [jsx, ruby, typescript],
        syntaxTheme: 'override',
      }),
      new CodeExtension(),
      new HeadingExtension(),
      new ItalicExtension(),
      linkExtension,
      noteEmbedExtension,
      new OrderedListExtension(),
      new StrikeExtension(),
      new TrailingNodeExtension(),
      new UnderlineExtension(),
    ],
    onError,
  });

  const [initialContent] = useState<RemirrorJSON | undefined>(
    content ? JSON.parse(content) : undefined
  );

  useDebounce(
    async () => {
      if (!contentChanged) return;

      await onChange(JSON.stringify(contentToPersist));
      setContentChanged(false);
    },
    500,
    [contentChanged, contentToPersist]
  );

  const handleEditorChange = useCallback((json: RemirrorJSON) => {
    setContentChanged(true);
    setContentToPersist(json);
  }, []);

  return (
    <Remirror
      editable={editable}
      manager={manager}
      initialContent={initialContent}
      placeholder="Leave note..."
    >
      <div
        className={classNames(
          'm-0 !mb-25 rounded-none border border-none border-gray-200 bg-white shadow-container-section-gray transition-all dark:border-violet-500 dark:border-opacity-25 dark:bg-mauve-dark-3 dark:shadow-container-section-purple md:m-4 md:rounded-lg md:border-solid',
          {
            '!border-violet-500': editorFocused,
          }
        )}
      >
        {displayHeader && (
          <div className="flex cursor-pointer select-none items-center justify-between px-4 pt-4 pb-3 md:pb-0">
            <div className="flex items-center">
              <PenTool
                size="18"
                className={classNames(
                  'dark:text-violet-400',
                  'mr-2',
                  'text-violet-700'
                )}
                data-testid="container-icon"
                strokeWidth="3"
              />

              <span className="text-sm font-semibold text-violet-700 dark:text-violet-400">
                Notes
              </span>
            </div>

            {isSaving && (
              <>
                <div className="hidden dark:flex">
                  <ClipLoader color="#8b4fe" size="14px" />
                </div>

                <div className="flex dark:hidden">
                  <ClipLoader color="#4C1D95" size="14px" />
                </div>
              </>
            )}
          </div>
        )}

        <div className="px-4">
          <EditorComponent />
        </div>

        {editable && (
          <FloatingToolbar>
            <EditorControls />
          </FloatingToolbar>
        )}

        <NoteSuggester />

        <EditorEventListener
          onBlur={() => {
            setEditorFocused(false);
            dispatch(setMobileToolbarVisible(true));
          }}
          onFocus={() => {
            setEditorFocused(true);
            dispatch(setMobileToolbarVisible(false));
          }}
        />
        <OnChangeJSON onChange={handleEditorChange} />
      </div>
    </Remirror>
  );
};

const EditorEventListener = ({
  onBlur,
  onFocus,
}: {
  onBlur: () => void;
  onFocus: () => void;
}): null => {
  const handleEditorBlur = useCallback(() => {
    onBlur();

    return false;
  }, [onBlur]);

  const handleEditorFocus = useCallback(() => {
    onFocus();

    return false;
  }, [onFocus]);

  useEditorEvent('blur', handleEditorBlur);
  useEditorEvent('focus', handleEditorFocus);

  return null;
};
