import { TaskFragment } from '../graphql/generated-types';
import Task from '../types/Task';

export const taskCombiner = (
  taskFragment: TaskFragment,
  previousTask: Task | null
): Task => {
  if (previousTask === null) {
    return {
      data: {
        ...taskFragment,
        projectId: taskFragment.project?.id,
      },
      order: {
        date: null,
        inbox: null,
        priority: taskFragment.priorityOrder,
        project: null,
      },
      meta: {
        awaitingSpringTask: false,
        recentlyCompleted: false,
      },
    };
  }

  return {
    ...previousTask,
    data: {
      ...taskFragment,
      projectId: taskFragment.project?.id,
    },
    order: {
      priority: taskFragment.priorityOrder,
      inbox: previousTask.order.inbox,
      date: previousTask.order.date,
      project: previousTask.order.project,
    },
  };
};
