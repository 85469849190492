// @ts-strict-ignore

import { createConsumer } from '@rails/actioncable';
import { refocusExchange } from '@urql/exchange-refocus';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  createClient,
  dedupExchange,
  defaultExchanges,
  fetchExchange,
  Provider as UrqlProvider,
  subscriptionExchange,
} from 'urql';

import { ActionCableExchange } from '../utils/actioncable';
import { isLoggedIn } from '../utils/authentication';
import { csrfToken } from '../utils/graphql';

import { ForgotPassword } from './Auth/ForgotPassword';
import { PasswordReset } from './Auth/PasswordReset';
import { SignUp } from './Auth/SignUp';
import { WaitlistJoined } from './MarketingSite/WaitlistJoined';
import ApplicationFrame from './ApplicationFrame';
import { Login } from './Auth';
import { MarketingSite } from './MarketingSite';

const GridContainer = (): JSX.Element => {
  const cable = createConsumer();
  const actionCableClient = new ActionCableExchange({ cable });

  const client = createClient({
    url: '/query',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fetchOptions: (): Record<string, any> => {
      return {
        headers: {
          'X-CSRF-Token': csrfToken(),
        },
      };
    },
    exchanges: [
      ...defaultExchanges,
      dedupExchange,
      refocusExchange(),
      fetchExchange,
      subscriptionExchange({
        forwardSubscription: (operation) => {
          return actionCableClient.request(operation);
        },
      }),
    ],
  });

  return (
    <UrqlProvider value={client}>
      <div className="electron-title-bar" />

      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password/:token" component={PasswordReset} />
        <Route path="/sign-up/:code" component={SignUp} />
        <Route path="/waitlist-joined" component={WaitlistJoined} />

        {isLoggedIn() ? <ApplicationFrame /> : <MarketingSite />}
      </Switch>
    </UrqlProvider>
  );
};

export default GridContainer;
