import * as RadixToast from '@radix-ui/react-toast';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { setToast } from '../../reducers/actions';
import { BetaState } from '../../reducers/beta-types';

export const Toast = (): JSX.Element => {
  const dispatch = useDispatch();

  const timeoutRef = useRef<NodeJS.Timeout>();

  const { toast } = useSelector((state: BetaState) => ({
    toast: state.toast,
  }));

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (toast) {
      setOpen(true);

      timeoutRef.current = setTimeout(() => {
        setOpen(false);
      }, 5000);
    } else {
      setOpen(false);
    }

    return () => {
      if (timeoutRef.current) {
        return clearTimeout(timeoutRef.current);
      }
    };
  }, [JSON.stringify(toast)]);

  useEffect(() => {
    if (!open) {
      dispatch(setToast(null));
    }
  }, [open]);

  return (
    <RadixToast.Provider swipeDirection="right">
      <RadixToast.Root
        className="data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=end]:animate-swipeOut border-opacity-20 border border-solid border-gray-400 !bg-opacity-50 bg-white dark:bg-mauve-dark-3 md:rounded-md shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] p-[15px] grid [grid-template-areas:_'title_action'_'description_action'] grid-cols-[auto_max-content] gap-x-[15px] items-center data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out]"
        style={{
          WebkitBackdropFilter: 'blur(12px)',
          backdropFilter: 'blur(12px)',
        }}
        onMouseEnter={() => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
        }}
        onMouseLeave={() => {
          timeoutRef.current = setTimeout(() => {
            setOpen(false);
          }, 2000);
        }}
        open={open}
        onOpenChange={setOpen}
      >
        <RadixToast.Title className="text-gray-800 dark:text-white [grid-area:_title] mb-[5px] font-semibold line-clamp-1 text-[15px]">
          {toast?.title}
        </RadixToast.Title>
        <RadixToast.Description asChild>
          <span className="text-slate-800 line-clamp-1 dark:text-gray-400 [grid-area:_description] m-0 text-[13px] leading-[1.3]">
            {toast?.subtitle}
          </span>
        </RadixToast.Description>

        {toast?.path && (
          <RadixToast.Action
            className="[grid-area:_action]"
            asChild
            altText="View"
          >
            <Link
              to={toast.path}
              className="text-violet-500 dark:text-violet-300 no-underline font-semibold text-base"
            >
              View
            </Link>
          </RadixToast.Action>
        )}
      </RadixToast.Root>
      <RadixToast.Viewport className="max-w-screen fixed bottom-0 right-0 flex flex-col pb-18 md:p-4 gap-[10px] w-full md:w-[390px] m-0 list-none z-map-toast outline-none" />
    </RadixToast.Provider>
  );
};
