import { DraggableProvided } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import classNames from 'classnames';
import { GripVertical, Pen, Save } from 'lucide-react';
import React, { useState } from 'react';

import { storeProjectColumnsFromServerInDatabase } from '../../database/actions';
import { useUpdateProjectColumnMutation } from '../../graphql/generated-types';
import { ProjectColumn } from '../../types';
import Button, { ButtonColor, ButtonSize } from '../Button';

type Mode = 'edit' | 'view';

export const ProjectColumnSetting = ({
  draggableProvided,
  initialMode = 'view',
  onCancel,
  onSave,
  projectColumn,
}: {
  draggableProvided?: DraggableProvided;
  initialMode?: Mode;
  onCancel?: () => void;
  onSave?: (name: string) => void;
  projectColumn: ProjectColumn | undefined;
}): JSX.Element => {
  const [name, setName] = useState<string>(projectColumn?.data.name || '');
  const [mode, setMode] = useState<Mode>(initialMode);

  const [, updateProjectColumn] = useUpdateProjectColumnMutation();

  return (
    <div
      onDoubleClick={() => setMode('edit')}
      className={classNames(
        'group bg-white dark:bg-mauve-dark-2 bg-opacity-70 flex gap-2 px-1 items-center justify-between rounded-md border border-solid border-gray-300 text-sm text-gray-800 dark:text-gray-200 dark:border-gray-700',
        {
          'py-1.5': mode === 'view',
        }
      )}
      ref={draggableProvided?.innerRef}
      {...draggableProvided?.draggableProps}
      {...draggableProvided?.dragHandleProps}
      style={{
        ...draggableProvided?.draggableProps?.style,
        WebkitBackdropFilter: 'blur(12px)',
        backdropFilter: 'blur(12px)',
      }}
    >
      {mode === 'view' && (
        <div className="flex w-full items-center justify-between pr-1 py-1">
          <div className="flex gap-0.5 items-center text-sm">
            <GripVertical size={14} className="text-gray-400 cursor-grab" />
            <span className="line-clamp-1">{name}</span>
          </div>

          <div className="hidden group-hover:flex gap-1.5">
            <Pen
              size={12}
              className="cursor-pointer"
              onClick={() => setMode('edit')}
            />
            {/* <Trash size={12} className="cursor-pointer" /> */}
          </div>
        </div>
      )}

      {mode === 'edit' && (
        <div className="py-1 flex gap-2">
          <input
            autoFocus
            value={name}
            placeholder="Group name"
            onChange={(event) => setName(event.target.value)}
            className="focus:border-violet-500 focus:outline-none box-border pl-4 pr-1 w-full rounded border border-solid border-transparent bg-transparent py-1 text-sm font-medium text-gray-800 hover:border-gray-600 dark:text-white dark:placeholder:text-gray-400 placeholder:text-gray-600 placeholder:text-opacity-50"
          />

          <Button
            size={ButtonSize.Small}
            color={ButtonColor.Gray}
            onClick={() => {
              if (onCancel) {
                onCancel();
                return;
              }

              setMode('view');
              setName(projectColumn?.data.name || '');
            }}
          >
            Cancel
          </Button>

          <Button
            size={ButtonSize.Small}
            color={ButtonColor.Primary}
            onClick={async () => {
              if (onSave) {
                onSave(name);
                return;
              }

              if (projectColumn) {
                await updateProjectColumn({
                  name,
                  projectColumnId: projectColumn.data.id,
                }).then(({ data }) => {
                  if (data?.updateProjectColumn) {
                    void storeProjectColumnsFromServerInDatabase([
                      data.updateProjectColumn,
                    ]);
                  }
                });
              }

              setMode('view');
            }}
          >
            <Save size={14} />
          </Button>
        </div>
      )}
    </div>
  );
};
