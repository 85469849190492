import { db } from '../../database';
import { Task } from '../../types';

export const unprioritizeTasksInDatabase = async (
  taskIds: string[]
): Promise<void> => {
  const tasks = await db.tasks.where('data.id').anyOf(taskIds).toArray();

  const unprioritizedTasks: Task[] = tasks.map((task) => ({
    ...task,
    data: {
      ...task.data,
      priorityOrder: null,
    },
    order: {
      ...task.order,
      priority: null,
    },
  }));

  await db.tasks.bulkPut(unprioritizedTasks);
};
