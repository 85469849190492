import {
  Box,
  Button,
  Flex,
  IconButton,
  Switch,
  Text,
  TextArea,
  TextField,
} from '@radix-ui/themes';
import { AnimatePresence, motion } from 'framer-motion';
import { Circle } from 'lucide-react';
import { CornerDownLeft, Icon, Plus, X } from 'lucide-react';
import React, { FormEvent, useEffect, useRef, useState } from 'react';

import { CreatorFormProps } from './CreatorModal';

const enterItemVariants = {
  visible: {
    opacity: 1,
    width: 'fit-content',
  },
  invisible: { opacity: 0, width: 0 },
};

const heightAppearVariants = {
  visible: {
    opacity: 1,
    height: 'fit-content',
  },
  invisible: { opacity: 0, height: 0 },
};

const opacityVariants = {
  visible: {
    opacity: 1,
  },
  invisible: { opacity: 0 },
};

export const CreatorForm = ({
  allowCreateMultiple = true,
  children,
  createMultiple,
  itemName,
  itemsToCreate,
  onCreate,
  placeholder,
  setCreateMultiple,
  setItemName,
  setItemsToCreate,
  setShowingDeleteItem,
  showingDeleteItem,
  submitButtonText,
}: {
  allowCreateMultiple?: boolean;
  children?: React.ReactNode;
  onCreate: (event?: FormEvent) => void;
  placeholder: string;
  submitButtonText: string;
} & CreatorFormProps): JSX.Element => {
  const [EnterMultipleIcon, setEnterMultipleIcon] =
    useState<Icon>(CornerDownLeft);

  const itemsToCreateDisplayRef = useRef<HTMLUListElement>(null);

  const manuallyCreateMultiple = (): void => {
    if (itemName) {
      setItemsToCreate([...itemsToCreate, itemName]);
      setItemName('');
    }
  };

  const removeItem = (index: number): void => {
    setItemsToCreate(itemsToCreate.filter((_, i) => i !== index));

    if (showingDeleteItem === index) {
      setShowingDeleteItem(null);
    }
  };

  useEffect(() => {
    if (itemsToCreateDisplayRef.current) {
      itemsToCreateDisplayRef.current.scrollTop =
        itemsToCreateDisplayRef.current.scrollHeight;
    }
  }, [itemsToCreate, itemsToCreateDisplayRef]);

  return (
    <motion.form
      onSubmit={onCreate}
      transition={{ duration: 0.3 }}
      initial="invisible"
      animate="visible"
      exit="invisible"
      variants={opacityVariants}
    >
      <Box
        className="border-0 border-t border-solid border-mauve-6 dark:border-mauve-dark-6"
        mt="2"
      >
        <AnimatePresence>
          {createMultiple && itemsToCreate.length > 0 && (
            <motion.div
              className="px-2"
              transition={{ duration: 0.3 }}
              initial={false}
              animate="visible"
              exit="invisible"
              variants={heightAppearVariants}
            >
              <ul
                ref={itemsToCreateDisplayRef}
                className="m-0 max-h-96 list-none overflow-y-scroll rounded-sm border border-solid border-mauve-6 bg-mauve-2 px-2 py-1 dark:border-mauve-dark-6 dark:bg-mauve-dark-3 mt-2"
              >
                <AnimatePresence>
                  {itemsToCreate.map((taskToCreate, index) => {
                    return (
                      <motion.li
                        key={index}
                        transition={{ duration: 0.3 }}
                        initial={false}
                        animate="visible"
                        exit="invisible"
                        variants={heightAppearVariants}
                      >
                        <div className="flex items-center pb-0.5">
                          <Flex grow="1" asChild>
                            <TextField.Root className="override-TransparentInput">
                              <TextField.Slot>
                                <Circle
                                  size={18}
                                  className="text-mauve-11 dark:text-mauve-dark-11"
                                />
                              </TextField.Slot>
                              <TextField.Input
                                size="2"
                                value={taskToCreate}
                                variant="soft"
                                onChange={(event) => {
                                  itemsToCreate[index] = event.target.value;
                                  setItemsToCreate([...itemsToCreate]);
                                }}
                              />

                              <TextField.Slot>
                                <IconButton
                                  onClick={(event) => {
                                    event.preventDefault();

                                    return removeItem(index);
                                  }}
                                  size="1"
                                  variant="ghost"
                                >
                                  <X size={12} />
                                </IconButton>
                              </TextField.Slot>
                            </TextField.Root>
                          </Flex>
                        </div>
                      </motion.li>
                    );
                  })}
                </AnimatePresence>
              </ul>
            </motion.div>
          )}
        </AnimatePresence>

        <div className="p-2">
          <div className="flex items-center">
            <div className="flex-grow">
              <TextArea
                autoFocus
                placeholder={placeholder}
                size="3"
                style={{ height: '42px', minHeight: '42px' }}
                value={itemName}
                onChange={(event) => {
                  const textAreaNames = event.target.value.split('\n');

                  if (
                    !createMultiple &&
                    event.target.value.endsWith('\n') &&
                    textAreaNames.length === 2
                  ) {
                    let isFirstInput = false;

                    if (itemName === '') {
                      isFirstInput = true;
                    }

                    const firstItem = textAreaNames.shift();

                    setItemName(firstItem);

                    // Prevent instantly closing the modal / creating the item if the user
                    // pastes in a newline at the end of their task name. If they hit enter
                    // when content already exists though, create the item.
                    if (
                      !isFirstInput &&
                      event.target.value === `${firstItem}\n`
                    ) {
                      onCreate();
                    }
                  } else if (textAreaNames.length > 1) {
                    // If the user pastes in multiple items then we will move into multiple
                    // creation mode automatically.
                    if (!createMultiple) {
                      setCreateMultiple(true);
                    }

                    const lastName = textAreaNames.pop();

                    setItemsToCreate(
                      [...itemsToCreate, ...textAreaNames]
                        .map((name) => name.trim())
                        .filter(Boolean)
                    );

                    setItemName(lastName);
                  } else {
                    setItemName(event.target.value);
                  }
                }}
              />
            </div>

            <AnimatePresence>
              {createMultiple && (
                <motion.button
                  className="group ml-2 cursor-pointer rounded-full border-0 bg-transparent p-1 pb-0 transition-colors duration-200 hover:bg-violet-11 focus:bg-violet-10 focus:outline-violet-300"
                  transition={{ duration: 0.3 }}
                  initial="invisible"
                  animate="visible"
                  exit="invisible"
                  variants={enterItemVariants}
                  onFocus={() => setEnterMultipleIcon(Plus)}
                  onBlur={() => setEnterMultipleIcon(CornerDownLeft)}
                  onMouseEnter={() => setEnterMultipleIcon(Plus)}
                  onMouseLeave={(event) => {
                    if (event.currentTarget !== document.activeElement) {
                      setEnterMultipleIcon(CornerDownLeft);
                    }
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    manuallyCreateMultiple();
                  }}
                >
                  <EnterMultipleIcon
                    className="text-mauve-11 dark:text-mauve-dark-11 group-hover:text-white group-focus:text-white"
                    size={16}
                  />
                </motion.button>
              )}
            </AnimatePresence>
          </div>
        </div>

        {children}
      </Box>

      <div className="flex items-center justify-between border-0 border-t border-solid border-mauve-6 px-2 py-3 dark:border-mauve-dark-6">
        {allowCreateMultiple && (
          <Text as="label" size="2">
            <Flex gap="2">
              <Switch
                checked={createMultiple}
                onCheckedChange={() => setCreateMultiple(!createMultiple)}
              />
              Create multiple
            </Flex>
          </Text>
        )}

        <Button
          disabled={
            (createMultiple && itemsToCreate.filter(Boolean).length === 0) ||
            (!createMultiple && !itemName)
          }
          type="submit"
        >
          {submitButtonText}
        </Button>
      </div>
    </motion.form>
  );
};
