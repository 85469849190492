// @ts-strict-ignore

import {
  BeforeCapture,
  DragDropContext,
  DragStart,
  DropResult,
} from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import keys from 'lodash/keys';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';
import { DateTime } from 'luxon';
import React from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';

import { taskCombiner } from '../../combiners/task-combiner';
import { db } from '../../database';
import {
  storeGroupsFromServerInDatabase,
  storeTasksFromServerInDatabase,
  storeTasksInDatabase,
} from '../../database/actions';
import {
  TaskFragment,
  useMoveTasksMutation,
  usePersistPriorityOrderMutation,
  usePersistProjectColumnOrderMutation,
  useUpdateGroupMutation,
  useUpdateTaskMutation,
} from '../../graphql/generated-types';
import {
  reorderGroups,
  reorderProjects,
  reorderTasks,
  setContainerGroups,
  setContainerProjectColumns,
  setCurrentDraggingType,
  setDraggingProjectColumnId,
  setDraggingTask,
  setIsDragging,
  setPriorityTasks,
  setSelectedTasks,
  setToast,
} from '../../reducers/actions';
import { BetaState, Toast } from '../../reducers/beta-types';
import { reorderDraggables } from '../../utils/beta/draggable';
import { dateInUtc, pathForDate } from '../../utils/date';
import { extractId, extractLocation } from '../../utils/drag';
import { interopWithIos } from '../../utils/interop-with-ios';
import { formattedDate } from '../BetaTask/utils';

import { handleDrag } from './Content/utils';

const DragDropProvider = ({
  children,
}: {
  children: React.ReactChild;
}): JSX.Element => {
  const dispatch = useDispatch();

  const {
    containerGroups,
    containerProjectColumns,
    containerProjects,
    containerTasks,
    priorityTasks,
    selectedTaskIds,
    selectedPriorityTasks,
    selectedTasksLocation,
  } = useSelector(
    (state: BetaState) => ({
      containerGroups: state.containerGroups,
      containerProjectColumns: orderBy(
        state.containerProjectColumns,
        'data.order'
      ),
      containerProjects: state.containerProjects,
      containerTasks: state.containerTasks,
      priorityTasks: orderBy(state.priorityTasks, 'order.priority'),
      selectedTaskIds: state.selectedTasks,
      selectedPriorityTasks: orderBy(
        state.priorityTasks,
        'order.priority'
      ).filter((task) => state.selectedTasks.includes(task.data.id)),
      selectedTasksLocation: state.selectedTasksLocation,
    }),
    isEqual
  );

  const handleBeforeCapture = (before: BeforeCapture): void => {
    const { draggableId } = before;

    if (
      draggableId.match(/^groupListTask-/) ||
      draggableId.match(/^priorityTask-/)
    ) {
      const taskId = extractId(draggableId);
      const taskLocation = extractLocation(draggableId);

      if (
        taskLocation !== selectedTasksLocation ||
        !selectedTaskIds.includes(taskId)
      ) {
        dispatch(setSelectedTasks([taskId], taskLocation));
      }
    }
  };

  const handleDragStart = (start: DragStart): void => {
    dispatch(setIsDragging(true));

    const { draggableId, source } = start;

    if (
      draggableId.match(/^groupListTask-/) ||
      draggableId.match(/^priorityTask-/)
    ) {
      dispatch(setCurrentDraggingType('task'));

      const taskId = extractId(draggableId);

      dispatch(setDraggingTask(taskId));
    } else if (draggableId.match(/^group-/)) {
      dispatch(setCurrentDraggingType('group'));
    } else if (draggableId.match(/^draggable-project-/)) {
      const projectId = extractId(source.droppableId);

      dispatch(setDraggingProjectColumnId(projectId));
    }
  };

  const [, moveTasks] = useMoveTasksMutation();
  const [, updateGroup] = useUpdateGroupMutation();
  const [, updateTask] = useUpdateTaskMutation();
  const [, persistPriorityOrder] = usePersistPriorityOrderMutation();
  const [, persistProjectColumnOrder] = usePersistProjectColumnOrderMutation();

  const handleDragEnd = async (result: DropResult): Promise<void> => {
    dispatch(setCurrentDraggingType(null));
    dispatch(setIsDragging(false));
    dispatch(setDraggingTask(null));
    dispatch(setDraggingProjectColumnId(null));

    if (!result.destination) {
      return;
    }

    const { droppableId } = result.destination;
    const { draggableId } = result;

    if (droppableId.match(/^day./)) {
      const dateString = droppableId.split('.')[1];
      const date = DateTime.fromISO(dateString);

      interopWithIos({ type: 'impactHaptic' });

      if (draggableId.match(/^groupListTask-/)) {
        const movingTasks = containerTasks
          .filter((task) => selectedTaskIds.includes(task.data.id))
          .map((task) => ({
            ...task,
            data: { ...task.data, date: date.toISODate() },
          }));

        await db.tasks.bulkPut(movingTasks);

        await moveTasks({
          taskIds: selectedTaskIds,
          date: date.toISODate(),
        }).then(async (response) => {
          if (response.data) {
            await storeTasksFromServerInDatabase(response.data.moveTasks);

            dispatch(
              setToast(
                movedTasksToast(response.data.moveTasks, dateInUtc(dateString))
              )
            );
          }
        });
      } else if (draggableId.match(/^group-/)) {
        const groupId = extractId(draggableId);

        dispatch(
          setContainerGroups(
            containerGroups.map((group) => {
              if (group.data.id === groupId) {
                return {
                  ...group,
                  data: { ...group.data, collapsed: true },
                };
              }

              return group;
            })
          )
        );

        await updateGroup({
          groupId,
          date: date.toISODate(),
        }).then(async (response) => {
          if (response?.data?.updateGroup) {
            await storeGroupsFromServerInDatabase([response.data.updateGroup]);

            const unmovedTaskIds = response.data.updateGroup.tasks.edges.map(
              (task) => task.node.id
            );

            await db.tasks
              .where('data.groupIds')
              .equals(groupId)
              .modify((task) => {
                if (!unmovedTaskIds.includes(task.data.id)) {
                  task.data.date = date.toISODate();
                }
              });
          }
        });
      }
    } else if (droppableId.match(/^project\./)) {
      const projectId = droppableId.split('.')[1];
      const taskId = extractId(result.draggableId);
      const task = containerTasks.find((task) => task.data.id === taskId);
      const project = containerProjects.find(
        (project) => project.data.id === projectId
      );

      if (!projectId) {
        return;
      }

      if (project) {
        await storeTasksInDatabase([
          {
            ...task,
            data: {
              ...task.data,
              projectId: project.data.id,
              project: {
                id: project.data.id,
                name: project.data.name,
              },
            },
          },
        ]);
      }

      await updateTask({ taskId, projectId }).then(async (response) => {
        if (response.data) {
          await storeTasksInDatabase([
            taskCombiner(response.data.updateTask, task),
          ]);
        }
      });
    } else if (droppableId.match(/^group-/)) {
      interopWithIos({ type: 'impactHaptic' });

      const group = containerGroups.find(
        (group) => group.data.id === extractId(droppableId)
      );

      handleDrag({
        reorderDraggable: (source, destination) =>
          dispatch(reorderTasks(source, destination, group.meta.type)),
        result,
      });
    } else if (droppableId.match(/^project-column-settings-/)) {
      const reorderedProjectColumns = reorderDraggables(
        containerProjectColumns,
        [result.source.index],
        result.destination.index
      ).map((projectColumn, index) => ({
        ...projectColumn,
        data: { ...projectColumn.data, order: index },
      }));

      dispatch(setContainerProjectColumns(reorderedProjectColumns));

      const order = reorderedProjectColumns.map((projectColumn, index) => ({
        id: projectColumn.data.id,
        order: index,
      }));

      await persistProjectColumnOrder({ order });
    } else if (droppableId === 'priorities') {
      const draggedTask = priorityTasks.find((task) => {
        return task.data.id === extractId(result.draggableId);
      });

      let prioritizedTasks = priorityTasks;

      if (draggedTask.order.priority) {
        const draggedIndices = keys(
          pickBy(priorityTasks, (task) =>
            selectedTaskIds.includes(task.data.id)
          )
        ).map((index) => Number(index));

        prioritizedTasks = reorderDraggables(
          prioritizedTasks,
          draggedIndices,
          result.destination.index,
          'priority'
        );
      } else {
        prioritizedTasks.splice(
          result.destination.index,
          0,
          ...selectedPriorityTasks
        );
      }

      const newTasks = prioritizedTasks.map((task, index) => ({
        ...task,
        order: {
          ...task.order,
          priority: index + 1,
        },
      }));

      interopWithIos({ type: 'impactHaptic' });
      dispatch(setPriorityTasks(newTasks));

      const order = newTasks.map((task) => ({
        id: task.data.id,
        order: task.order.priority,
      }));

      await persistPriorityOrder({ order }).then(async (response) => {
        if (response.data) {
          await storeTasksFromServerInDatabase(
            response.data.persistPriorityOrder
          );
        }
      });
    } else if (droppableId.match(/^project-column-/)) {
      handleDrag({
        reorderDraggable: (source, destination) =>
          dispatch(reorderProjects(source, destination)),
        result,
      });
    } else if (droppableId === 'container-group-list') {
      interopWithIos({ type: 'impactHaptic' });
      handleDrag({
        reorderDraggable: (source, destination) =>
          dispatch(reorderGroups(source, destination)),
        result,
      });
    }
  };

  return (
    <DragDropContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onBeforeCapture={handleBeforeCapture}
    >
      {children}
    </DragDropContext>
  );
};

const movedTasksToast = (tasks: TaskFragment[], date: Date): Toast => {
  const firstTask = tasks[0];

  const title = tasks.length === 1 ? firstTask.name : `${tasks.length} tasks`;
  const subtitle = `Moved to ${formattedDate(date.toISOString())}`;
  const path = pathForDate(dateInUtc(date));

  return {
    title,
    subtitle,
    path,
  };
};

export default DragDropProvider;
