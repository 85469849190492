// @ts-strict-ignore

import { useLiveQuery } from 'dexie-react-hooks';
import { BaseEmoji } from 'emoji-mart';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import { Plus } from 'lucide-react';
import { Archive, ArrowLeft } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { db } from '../../../database';
import { storeBoardsFromServerInDatabase } from '../../../database/actions';
import {
  useBoardsQuery,
  useUpdateBoardMutation,
} from '../../../graphql/generated-types';
import {
  setCreatorModalMode,
  setMobileSidebarVisible,
} from '../../../reducers/actions';
import { Board } from '../../../types';
import EmojiDisplay from '../../EmojiDisplay';
import EmojiPicker from '../../EmojiPicker';
import Popover from '../../library/Popover';

import { SidebarList, SidebarListItem } from './SidebarList';

const BoardsSidebar = (): JSX.Element => {
  const dispatch = useDispatch();

  const [mode, setMode] = useState<'active' | 'archived'>('active');

  const [boardsData, refetchBoards] = useBoardsQuery({
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (boardsData.data) {
      void storeBoardsFromServerInDatabase(boardsData.data.boards);
    }
  }, [boardsData.data]);

  const boards = useLiveQuery(async () => {
    return await db.boards.toArray();
  });

  const [activeBoards, archivedBoards] = partition(
    boards,
    (board) => !board.data.archivedAt
  );

  const history = useHistory();

  return (
    <div className="mt-18 border-0 border-r border-solid border-violet-100 bg-gray-50 pb-40 shadow-sidebar-gray dark:border-violet-900 dark:bg-mauve-dark-2 dark:text-white dark:shadow-none md:mt-0 md:h-screen md:pb-0">
      <div
        className="fixed top-0 flex h-18 w-full items-center justify-between border-0 border-b border-r border-solid border-violet-100 bg-gray-50 !bg-opacity-70 dark:border-violet-900 dark:bg-mauve-dark-2 md:sticky"
        style={{
          WebkitBackdropFilter: 'blur(12px)',
          backdropFilter: 'blur(12px)',
          boxShadow: '0px 0px 2px rgba(133, 133, 133, 0.32)',
        }}
      >
        <h2 className="my-0 px-5 text-2xl font-semibold">Boards</h2>

        <Plus
          className="mr-5 cursor-pointer hover:text-violet-500"
          onClick={() => dispatch(setCreatorModalMode('board'))}
        />
      </div>

      {mode === 'active' && (
        <div className="pb-20">
          {activeBoards && (
            <>
              <SidebarList>
                <>
                  {orderBy(activeBoards, 'lastViewedAt', 'desc').map(
                    (board) => (
                      <SidebarListItem
                        key={`active-board-${board.data.id}`}
                        Icon={
                          <EmojiPopover
                            board={board}
                            onSelectionSuccess={refetchBoards}
                          />
                        }
                        onClick={() => {
                          history.push(`/boards/${board.data.id}`);
                          dispatch(setMobileSidebarVisible(false));
                        }}
                        text={board.data.name}
                      />
                    )
                  )}
                </>
              </SidebarList>

              {archivedBoards.length > 0 && (
                <button
                  onClick={() => setMode('archived')}
                  className="mx-6 my-2 flex cursor-pointer items-center gap-4 border-0 bg-transparent py-2 text-base text-gray-900 shadow-none dark:text-gray-400"
                >
                  <Archive size={14} /> View archived boards
                </button>
              )}
            </>
          )}
        </div>
      )}

      {mode === 'archived' && (
        <div className="pb-20">
          <button
            onClick={() => setMode('active')}
            className="mx-6 my-2 flex cursor-pointer items-center gap-4 border-0 bg-transparent py-2 text-base text-gray-900 shadow-none dark:text-gray-400"
          >
            <ArrowLeft size={14} /> Active Boards
          </button>

          {archivedBoards && (
            <>
              <SidebarList>
                <>
                  {orderBy(archivedBoards, 'archivedAt', 'asc').map((board) => (
                    <SidebarListItem
                      key={`archived-board-${board.data.id}`}
                      Icon={
                        <EmojiPopover
                          board={board}
                          onSelectionSuccess={refetchBoards}
                        />
                      }
                      onClick={() => {
                        history.push(`/boards/${board.data.id}`);
                        dispatch(setMobileSidebarVisible(false));
                      }}
                      text={board.data.name}
                    />
                  ))}
                </>
              </SidebarList>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const EmojiPopover = ({
  board,
  onSelectionSuccess,
}: {
  board: Board;
  onSelectionSuccess: () => void;
}): JSX.Element => {
  const [, updateBoard] = useUpdateBoardMutation();

  const onSelectEmoji =
    (boardId: string) =>
    async (emoji: BaseEmoji): Promise<void> => {
      await updateBoard({ boardId, emoji: emoji.native }).then((response) => {
        if (response.data) {
          onSelectionSuccess();
        }
      });
    };

  return (
    <div
      className="mr-2 cursor-pointer"
      onClick={(event) => event.stopPropagation()}
    >
      <Popover
        content={<EmojiPicker onSelect={onSelectEmoji(board.data.id)} />}
        side="right"
      >
        <EmojiDisplay emoji={board.data.emoji} />
      </Popover>
    </div>
  );
};

export default BoardsSidebar;
