import {
  DraggableProvidedDragHandleProps,
  DroppableProvided,
} from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import { AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronRight, Settings } from 'lucide-react';
import React, { SyntheticEvent } from 'react';

export const GroupFrame = ({
  children,
  collapsed,
  collapsedCount,
  draggableHeaderProps,
  name,
  onClickHeader,
  onClickSettings,
}: {
  children: JSX.Element;
  collapsed: boolean;
  collapsedCount: number;
  draggableHeaderProps?: DraggableProvidedDragHandleProps;
  droppableProvided?: DroppableProvided;
  name: string;
  onClickHeader: () => Promise<void> | void;
  onClickSettings?: (event: SyntheticEvent) => void;
}) => {
  const Chevron = collapsed ? ChevronRight : ChevronDown;

  return (
    <>
      <button
        className="bg-mauve-3 mb-px flex w-full cursor-pointer items-center justify-between border-0 border-t border-b border-solid border-gray-300 border-opacity-50 bg-opacity-10 px-3.5 py-2 text-sm text-gray-700 outline-none dark:border-gray-700 dark:border-opacity-70 dark:bg-mauve-dark-5 dark:text-violet-100 md:px-4"
        onClick={onClickHeader}
        {...draggableHeaderProps}
      >
        <div className="flex items-center">
          <Chevron
            size={18}
            className="mr-2 text-gray-700 dark:text-violet-100"
          />

          {name}

          <span className={['ml-2', 'text-gray-400', 'text-xs'].join(' ')}>
            {collapsedCount}
          </span>
        </div>

        {onClickSettings && (
          <Settings
            className="cursor-pointer transition-all hover:scale-120 hover:text-violet-500 [@media(pointer:fine)]:opacity-0 [@media(pointer:fine)]:group-hover:opacity-100"
            size={14}
            onClick={onClickSettings}
          />
        )}
      </button>

      <AnimatePresence key={`later-tasks-${collapsed}`}>
        {collapsed ? null : children}
      </AnimatePresence>
    </>
  );
};
