import { format } from 'date-fns';
import { useLiveQuery } from 'dexie-react-hooks';
import sum from 'lodash/sum';
import { useEffect } from 'react';

import { db } from '../../database';
import { storeTasksFromServerInDatabase } from '../../database/actions';
import { usePrioritiesQuery } from '../../graphql/generated-types';
import { dateInUtc } from '../../utils/date';
import { setBadgeCount } from '../../utils/set-badge-count';

export const BadgeCountProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const todayDate = new Date().toDateString();

  const dueTasks =
    useLiveQuery(
      async () =>
        await db.tasks
          .where('data.dueDate')
          .belowOrEqual(format(dateInUtc(new Date()), 'yyyy-MM-dd'))
          .and((task) => task && !task.data.completed && !task.order.priority)
          .toArray()
    ) || [];

  const [prioritiesData, refetchPriorities] = usePrioritiesQuery({
    variables: {
      date: todayDate,
      includedTaskIds: dueTasks.map((task) => task.data.id),
    },
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  useEffect(() => {
    if (prioritiesData.fetching) return;

    const timerId = setTimeout(() => {
      refetchPriorities({ requestPolicy: 'network-only' });
    }, 5000);

    return () => clearTimeout(timerId);
  }, [prioritiesData.fetching, refetchPriorities]);

  useEffect(() => {
    if (prioritiesData.data) {
      const { prioritizedTasks, dueTasks, currentUser, includedTasks } =
        prioritiesData.data;

      if (prioritizedTasks) {
        void storeTasksFromServerInDatabase(prioritizedTasks);
      }

      if (dueTasks) {
        void storeTasksFromServerInDatabase(dueTasks);
      }

      if (includedTasks) {
        void storeTasksFromServerInDatabase(includedTasks);
      }

      let badgeCount;

      const { badgeCountMode } = currentUser.settings;

      if (badgeCountMode === 'DUE') {
        badgeCount = dueTasks?.length || 0;
      } else if (badgeCountMode === 'PRIORITY') {
        badgeCount = prioritizedTasks?.length || 0;
      } else if (badgeCountMode === 'PRIORITY_DUE') {
        badgeCount = sum([
          prioritizedTasks?.length || 0,
          dueTasks?.length || 0,
        ]);
      } else {
        badgeCount = 0;
      }

      setBadgeCount(badgeCount);
    }
  }, [prioritiesData.data]);

  return children;
};
