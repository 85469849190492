import {
  ApplySchemaAttributes,
  command,
  ErrorConstant,
  extension,
  ExtensionTag,
  Handler,
  invariant,
  isElementDomNode,
  isString,
  kebabCase,
  NodeExtension,
  NodeExtensionSpec,
  NodeSpecOverride,
  omitExtraAttributes,
  pick,
  ProsemirrorAttributes,
  replaceText,
  Static,
} from '@remirror/core';
import {
  DEFAULT_SUGGESTER,
  MatchValue,
  RangeWithCursor,
  SuggestChangeHandlerProps,
  Suggester,
} from '@remirror/pm/suggest';
import { FileText } from 'lucide-react';
import React, { ComponentType, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useNoteQuery } from '../../../graphql/generated-types';
import { storeNotesFromServer } from '../../../reducers/actions';
import { BetaState } from '../../../reducers/beta-types';

/**
 * Options available to the [[`NoteEmbedExtension`]].
 */
export interface NoteEmbedOptions
  extends Pick<
    Suggester,
    | 'invalidNodes'
    | 'validNodes'
    | 'invalidMarks'
    | 'validMarks'
    | 'isValidPosition'
  > {
  /**
   * Provide a custom tag for the mention
   */
  mentionTag?: Static<string>;

  /**
   * Provide the custom matchers that will be used to match mention text in the
   * editor.
   *
   * TODO - add customized tags here.
   */
  matchers: Static<NoteEmbedExtensionMatcher[]>;

  /**
   * Text to append after the mention has been added.
   *
   * **NOTE**: If it seems that your editor is swallowing  up empty whitespace,
   * make sure you've imported the core css from the `@remirror/styles` library.
   *
   * @defaultValue ' '
   */
  appendText?: string;

  /**
   * Tag for the prosemirror decoration which wraps an active match.
   *
   * @defaultValue 'span'
   */
  suggestTag?: string;

  /**
   * When true, decorations are not created when this mention is being edited.
   */
  disableDecorations?: boolean;

  /**
   * Called whenever a suggestion becomes active or changes in any way.
   *
   * @remarks
   *
   * It receives a parameters object with the `reason` for the change for more
   * granular control.
   */
  onChange?: Handler<NoteEmbedChangeHandler>;

  /**
   * Listen for click events to the mention atom extension.
   */
  onClick?: Handler<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: MouseEvent, nodeWithPosition: any) => boolean | undefined
  >;
}

/**
 * This is the atom version of the `MentionExtension`
 * `@remirror/extension-mention`.
 *
 * It provides mentions as atom nodes which don't support editing once being
 * inserted into the document.
 */
@extension<NoteEmbedOptions>({
  defaultOptions: {
    mentionTag: 'span' as const,
    matchers: [],
    appendText: ' ',
    suggestTag: 'span' as const,
    disableDecorations: false,
    invalidMarks: [],
    invalidNodes: [],
    isValidPosition: () => true,
    validMarks: null,
    validNodes: null,
  },
  handlerKeyOptions: { onClick: { earlyReturnValue: true } },
  handlerKeys: ['onChange', 'onClick'],
  staticKeys: ['mentionTag', 'matchers'],
})
export class NoteEmbedExtension extends NodeExtension<NoteEmbedOptions> {
  get name(): string {
    return 'mentionAtom' as const;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createTags(): any[] {
    return [ExtensionTag.InlineNode, ExtensionTag.Behavior];
  }

  ReactComponent: ComponentType<{
    node: { attrs: { id: string; value: string } };
  }> = ({ node }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const { id } = node.attrs;

    const { note } = useSelector((state: BetaState) => ({
      note: state.notes.find((note) => note.data.id === id),
    }));

    const [noteData] = useNoteQuery({ variables: { noteId: id } });

    useEffect(() => {
      if (noteData.data?.note) {
        dispatch(storeNotesFromServer({ notes: [noteData.data.note] }));
      }
    }, [noteData.data]);

    if (!note) {
      return null;
    }
    return (
      <a
        className="inline-flex items-center gap-1"
        onClick={() => history.push(`/notes/${id}`)}
      >
        <FileText size={12} />
        {note.data.name}
      </a>
    );
  };

  createNodeSpec(
    extra: ApplySchemaAttributes,
    override: NodeSpecOverride
  ): NodeExtensionSpec {
    const dataAttributeId = 'data-note-embed-id';
    const dataAttributeName = 'data-note-embed-name';

    return {
      inline: true,
      marks: '',
      selectable: true,
      draggable: false,
      atom: true,
      ...override,
      attrs: {
        ...extra.defaults(),
        id: {},
        label: {},
        name: {},
      },
      parseDOM: [
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...this.options.matchers.map((matcher: any) => ({
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          tag: `${
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            matcher.mentionTag ?? this.options.mentionTag
          }[${dataAttributeId}]`,
          getAttrs: (node: string | Node) => {
            if (!isElementDomNode(node)) {
              return false;
            }

            const id = node.getAttribute(dataAttributeId);
            const name = node.getAttribute(dataAttributeName);
            const label = node.textContent;
            return { ...extra.parse(node), id, label, name };
          },
        })),
        ...(override.parseDOM ?? []),
      ],
      toDOM: (node) => {
        const { label, id, name } = omitExtraAttributes(
          node.attrs,
          extra
        ) as NamedNoteEmbedNodeAttributes;
        const matcher = this.options.matchers.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (matcher: any) => matcher.name === name
        );

        const mentionClassName = matcher
          ? matcher.mentionClassName ?? DEFAULT_MATCHER.mentionClassName
          : DEFAULT_MATCHER.mentionClassName;

        const attrs = {
          ...extra.dom(node),
          class: name
            ? `${mentionClassName as string} ${
                mentionClassName as string
              }-${kebabCase(name)}`
            : mentionClassName,
          [dataAttributeId]: id,
          [dataAttributeName]: name,
        };

        return [matcher?.mentionTag ?? this.options.mentionTag, attrs, label];
      },
    };
  }

  /**
   * Creates a mention atom at the  the provided range.
   *
   * A variant of this method is provided to the `onChange` handler for this
   * extension.
   *
   * @param details - the range and name of the mention to be created.
   * @param attrs - the attributes that should be passed through. Required
   * values are `id` and `label`.
   */
  @command()
  createNoteEmbed(
    details: CreateNoteEmbed,
    attrs: NoteEmbedNodeAttributes
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): any {
    const { name, range } = details;
    const validNameExists = this.options.matchers.some(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (matcher: any) => name === matcher.name
    );

    // Check that the name is valid.
    invariant(validNameExists, {
      code: ErrorConstant.EXTENSION,
      message: `Invalid name '${name}' provided when creating a mention. Please ensure you only use names that were configured on the matchers when creating the \`NoteEmbedExtension\`.`,
    });

    const { appendText, ...rest } = attrs;

    return replaceText({
      type: this.type,
      appendText: getAppendText(appendText, this.options.appendText),
      attrs: { name, ...rest },
      range,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);
  }

  /**
   * Track click events passed through to the editor.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createEventHandlers(): any {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      click: (event: any, clickState: any) => {
        // Check if this is a direct click which must be the case for atom
        // nodes.
        if (!clickState.direct) {
          return;
        }

        const nodeWithPosition = clickState.getNode(this.type);

        if (!nodeWithPosition) {
          return;
        }

        return this.options.onClick(event, nodeWithPosition);
      },
    };
  }

  createSuggesters(): Suggester[] {
    const options = pick(this.options, [
      'invalidMarks',
      'invalidNodes',
      'isValidPosition',
      'validMarks',
      'validNodes',
      'suggestTag',
      'disableDecorations',
      'appendText',
    ]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.options.matchers.map<Suggester>((matcher: any) => {
      return {
        ...DEFAULT_MATCHER,
        ...options,
        ...matcher,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange: (props: any) => {
          const { name, range } = props;
          const { createNoteEmbed } = this.store.commands;

          function command(attrs: NoteEmbedNodeAttributes): void {
            createNoteEmbed({ name, range }, attrs);
          }

          this.options.onChange(props, command);
        },
      };
    });
  }
}

/**
 * The default matcher to use when none is provided in options
 */
const DEFAULT_MATCHER = {
  ...pick(DEFAULT_SUGGESTER, [
    'startOfLine',
    'supportedCharacters',
    'validPrefixCharacters',
    'invalidPrefixCharacters',
  ]),
  appendText: '',
  matchOffset: 1,
  suggestClassName: 'suggestion',
  mentionClassName: 'embed',
};

export interface OptionalNoteEmbedExtensionProps {
  /**
   * The text to append to the replacement.
   *
   * @defaultValue ''
   */
  appendText?: string;

  /**
   * The type of replacement to use. By default the command will only replace text up the the cursor position.
   *
   * To force replacement of the whole match regardless of where in the match the cursor is placed set this to
   * `full`.
   *
   * @defaultValue 'full'
   */
  replacementType?: keyof MatchValue;
}

export interface CreateNoteEmbed {
  /**
   * The name of the matcher used to create this mention.
   */
  name: string;

  /**
   * The range of the current selection
   */
  range: RangeWithCursor;
}

/**
 * The attrs that will be added to the node.
 * ID and label are plucked and used while attributes like href and role can be assigned as desired.
 */
export type NoteEmbedNodeAttributes = ProsemirrorAttributes<
  OptionalNoteEmbedExtensionProps & {
    /**
     * A unique identifier for the suggesters node
     */
    id: string;

    /**
     * The text to be placed within the suggesters node
     */
    label: string;
  }
>;

export type NamedNoteEmbedNodeAttributes = NoteEmbedNodeAttributes & {
  /**
   * The name of the matcher used to create this mention.
   */
  name: string;
};

/**
 * This change handler is called whenever there is an update in the matching
 * suggester. The second parameter `command` is available to automatically
 * create the mention with the required attributes.
 */
export type NoteEmbedChangeHandler = (
  handlerState: SuggestChangeHandlerProps,
  command: (attrs: NoteEmbedNodeAttributes) => void
) => void;

/**
 * The options for the matchers which can be created by this extension.
 */
export interface NoteEmbedExtensionMatcher
  extends Pick<
    Suggester,
    | 'char'
    | 'name'
    | 'startOfLine'
    | 'supportedCharacters'
    | 'validPrefixCharacters'
    | 'invalidPrefixCharacters'
    | 'suggestClassName'
  > {
  /**
   * See [[``Suggester.matchOffset`]] for more details.
   *
   * @defaultValue 1
   */
  matchOffset?: number;

  /**
   * Provide customs class names for the completed mention.
   */
  mentionClassName?: string;

  /**
   * An override for the default mention tag. This allows different mentions to
   * use different tags.
   */
  mentionTag?: string;
}

/**
 * Get the append text value which needs to be handled carefully since it can
 * also be an empty string.
 */
function getAppendText(
  preferred: string | undefined,
  fallback: string | undefined
): string {
  if (isString(preferred)) {
    return preferred;
  }

  if (isString(fallback)) {
    return fallback;
  }

  return DEFAULT_MATCHER.appendText;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Remirror {
    interface AllExtensions {
      noteEmbedAtom: NoteEmbedExtension;
    }
  }
}
