import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
} from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import intersection from 'lodash/intersection';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import { Plus } from 'lucide-react';
import React, { CSSProperties, memo, MouseEvent, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch } from 'react-redux';

import {
  storeGroupsFromServerInDatabase,
  storeGroupsInDatabase,
} from '../../database/actions';
import { useUpdateGroupMutation } from '../../graphql/generated-types';
import {
  setCreatorModalMode,
  setMobileToolbarVisible,
  setOverrideCreationGroup,
  setPauseContainerStores,
} from '../../reducers/actions';
import { CurrentDraggingType } from '../../reducers/beta-types';
import { Group as GroupType } from '../../types';
import TaskType from '../../types/Task';
import Task, { taskVariants } from '../BetaTask';
import { GroupFrame } from '../library/GroupFrame';

import { EditingGroupModal } from './EditingGroupModal';

import './Task.css';

interface GroupProps {
  containerTasks: TaskType[];
  currentDraggingType: CurrentDraggingType;
  draggingTask: string | null;
  group: GroupType;
  isDragging: boolean;
  isGroupDragging: boolean;
  isHoveringTaskDropzone: boolean;
  isTaskFocused: boolean;
  onClickTaskHeader: (
    id: string,
    taskLocation: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: MouseEvent<HTMLElement, any>
  ) => void;
  groupDraggableProvided: DraggableProvided;
  selectedDate?: string | null;
  selectedProject?: string | null;
  selectedTasks: string[];
  selectedTasksLocation: string | null;
  timeZone: string;
}

const groupVariants = {
  enter: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
  },
  entered: {
    opacity: 1,
    height: 'auto',
  },
  exit: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
    transition: { opacity: { duration: 0.3 } },
  },
};

const Group = ({
  containerTasks,
  currentDraggingType,
  draggingTask,
  isDragging,
  isGroupDragging,
  isHoveringTaskDropzone,
  isTaskFocused,
  group,
  groupDraggableProvided,
  onClickTaskHeader,
  selectedDate,
  selectedProject,
  selectedTasks,
  selectedTasksLocation,
  timeZone,
}: GroupProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const [, updateGroup] = useUpdateGroupMutation();

  const [isEditingGroupModalOpen, setIsEditingGroupModalOpen] = useState(false);

  const { collapsed, id: groupId, name } = group.data;

  const groupTasks = containerTasks.filter((task) =>
    task.data.groupIds.includes(groupId)
  );

  const [completedTasks, uncompletedTasks] = partition(
    groupTasks,
    (task) =>
      task.data.completed &&
      !task.meta.recentlyCompleted &&
      !task.meta.awaitingSpringTask
  );

  const toggleGroupVisibility = async (group: GroupType): Promise<void> => {
    const newCollapsedValue = !collapsed;

    dispatch(setPauseContainerStores(true));

    await storeGroupsInDatabase([
      { ...group, data: { ...group.data, collapsed: newCollapsedValue } },
    ]);

    await updateGroup({ collapsed: newCollapsedValue, groupId }).then(
      async (response) => {
        if (response.data) {
          const groups = [response.data.updateGroup];

          dispatch(setPauseContainerStores(false));

          await storeGroupsFromServerInDatabase(groups);
        }
      }
    );
  };

  const onClickAddTask = (): void => {
    dispatch(setOverrideCreationGroup(group.data.id));
    dispatch(setCreatorModalMode('task'));
    dispatch(setMobileToolbarVisible(false));
  };

  const getStyle = (
    style: CSSProperties | undefined,
    snapshot: DraggableStateSnapshot
  ): CSSProperties | undefined => {
    if (
      !snapshot.isDropAnimating ||
      !isHoveringTaskDropzone ||
      !snapshot.dropAnimation
    ) {
      return style;
    }

    const { curve } = snapshot.dropAnimation;

    return {
      ...style,
      opacity: 0,
      transition: `all ${curve}`,
      transitionDuration: '10ms',
    };
  };

  const taskLocation = 'groupListTask';

  const orderedUncompletedTasks = orderBy(
    uncompletedTasks,
    `order.${group.meta.type}`
  );
  const orderedCompletedTasks = orderBy(completedTasks, 'data.completedAt');

  return (
    <Droppable
      droppableId={`group-${group.data.id}`}
      isDropDisabled={isHoveringTaskDropzone || currentDraggingType === 'group'}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          className={classNames('group', {
            'rounded-lg border border-solid bg-opacity-60 bg-white dark:bg-mauve-dark-2 border-gray-500 border-opacity-50 drop-shadow-xl dark:border-gray-900':
              isGroupDragging,
          })}
          style={
            isGroupDragging
              ? {
                  WebkitBackdropFilter: 'blur(10px)',
                  backdropFilter: 'blur(10px)',
                }
              : undefined
          }
        >
          <GroupFrame
            collapsed={!!group.data.collapsed}
            collapsedCount={uncompletedTasks.length}
            draggableHeaderProps={groupDraggableProvided.dragHandleProps}
            name={name}
            onClickHeader={async () => await toggleGroupVisibility(group)}
            onClickSettings={(e) => {
              e.stopPropagation();

              setIsEditingGroupModalOpen(true);
            }}
          >
            <motion.div
              variants={groupVariants}
              initial={false}
              animate="entered"
              exit="exit"
              transition={{
                x: {
                  type: 'spring',
                  stiffness: 300,
                  damping: 30,
                  duration: 0.3,
                },
              }}
            >
              <>
                <ul className="mb-0 mt-3 list-none pl-0">
                  <AnimatePresence>
                    {orderedUncompletedTasks.map((task, index) => (
                      <motion.div
                        variants={taskVariants}
                        custom={index}
                        initial="enter"
                        exit="exit"
                        animate="entered"
                        key={`task-${task.data.id}`}
                      >
                        <Draggable
                          draggableId={`${taskLocation}-${task.data.id}`}
                          index={index}
                          isDragDisabled={isTaskFocused}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                data-task-id={task.data.id}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="focus:outline-none"
                                style={getStyle(
                                  provided.draggableProps.style,
                                  snapshot
                                )}
                              >
                                <Task
                                  draggingTask={draggingTask}
                                  isDragging={isDragging}
                                  isHoveringTaskDropzone={
                                    isHoveringTaskDropzone
                                  }
                                  isNextTaskSelected={
                                    orderedUncompletedTasks[index + 1] &&
                                    selectedTasks.includes(
                                      orderedUncompletedTasks[index + 1].data.id
                                    )
                                  }
                                  isPreviousTaskSelected={
                                    orderedUncompletedTasks[index - 1] &&
                                    selectedTasks.includes(
                                      orderedUncompletedTasks[index - 1].data.id
                                    )
                                  }
                                  isSelected={
                                    selectedTasks.includes(task.data.id) &&
                                    selectedTasksLocation === taskLocation
                                  }
                                  key={`group-list-task-${task.data.id}`}
                                  onClickTaskHeader={onClickTaskHeader}
                                  parentId={groupId}
                                  selectedDate={selectedDate}
                                  selectedProject={selectedProject}
                                  selectedTasks={selectedTasks}
                                  task={task}
                                  taskLocation={taskLocation}
                                  timeZone={timeZone}
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      </motion.div>
                    ))}
                  </AnimatePresence>

                  {provided.placeholder}
                </ul>

                <ul className="mt-0 list-none pl-0">
                  <AnimatePresence initial={false}>
                    {orderedCompletedTasks.map((task, index) => (
                      <motion.div
                        variants={taskVariants}
                        initial="enter"
                        animate="entered"
                        exit="exit"
                        key={`group-list-task-${task.data.id}`}
                        transition={{ duration: 0.3 }}
                      >
                        <Task
                          draggingTask={draggingTask}
                          isDragging={isDragging}
                          isHoveringTaskDropzone={isHoveringTaskDropzone}
                          isNextTaskSelected={
                            orderedCompletedTasks[index + 1] &&
                            selectedTasks.includes(
                              orderedCompletedTasks[index + 1].data.id
                            )
                          }
                          isPreviousTaskSelected={
                            orderedCompletedTasks[index - 1] &&
                            selectedTasks.includes(
                              orderedCompletedTasks[index - 1].data.id
                            )
                          }
                          isSelected={
                            selectedTasks.includes(task.data.id) &&
                            selectedTasksLocation === 'group-list'
                          }
                          onClickTaskHeader={onClickTaskHeader}
                          parentId={groupId}
                          selectedDate={selectedDate}
                          selectedProject={selectedProject}
                          selectedTasks={selectedTasks}
                          task={task}
                          taskLocation="group-list"
                          timeZone={timeZone}
                        />
                      </motion.div>
                    ))}
                  </AnimatePresence>

                  <div className="ml-4 mt-2">
                    <button
                      className="flex border-0 bg-transparent p-0 transition-all duration-200 cursor-pointer hover:text-purple-500 items-center gap-2 dark:text-gray-400 text-gray-500"
                      onClick={onClickAddTask}
                    >
                      <Plus size={20} /> Add Task
                    </button>
                  </div>
                </ul>
              </>
            </motion.div>
          </GroupFrame>

          <EditingGroupModal
            group={group}
            isOpen={isEditingGroupModalOpen}
            setIsOpen={setIsEditingGroupModalOpen}
          />
        </div>
      )}
    </Droppable>
  );
};

// This component can really hamper app performance if there are too many re-renders.
// This will only re-render the component with prop updates we actually care about in
// normal application usage.
const compareProps = (
  prevProps: GroupProps,
  nextProps: GroupProps
): boolean => {
  const { group } = prevProps;

  const omitKeys = ['onClickTaskHeader'];

  const filteredPrevProps = omit(prevProps, omitKeys);
  const filteredNextProps = omit(nextProps, omitKeys);

  if (isEqual(filteredPrevProps, filteredNextProps)) {
    return true;
  }

  if (prevProps.selectedTasks !== nextProps.selectedTasks) {
    const previousGroupTasks = prevProps.containerTasks
      .filter((task) => task.data.groupIds.includes(group.data.id))
      .map((task) => task.data.id);

    const nextGroupTasks = nextProps.containerTasks
      .filter((task) => task.data.groupIds.includes(group.data.id))
      .map((task) => task.data.id);

    if (
      intersection(previousGroupTasks, prevProps.selectedTasks).length > 0 ||
      intersection(nextGroupTasks, nextProps.selectedTasks).length > 0
    ) {
      return false;
    }
  }

  return isEqual(
    omit(filteredPrevProps, ['selectedTasks', 'selectedTasksLocation']),
    omit(filteredNextProps, ['selectedTasks', 'selectedTasksLocation'])
  );
};

export default memo(Group, compareProps);
