import { BoardFragment } from '../../graphql/generated-types';
import { Board } from '../../types';

import { storeBoardsInDatabase } from './store-boards';

export const storeBoardsFromServerInDatabase = async (
  boards: BoardFragment[]
): Promise<void> => {
  const newBoards: Board[] = boards.map((board) => {
    const data = {
      ...board,
    };

    delete data.projectColumns;

    return {
      data,
      meta: {},
    };
  });

  await storeBoardsInDatabase(newBoards);
};
