import PROJECT_FRAGMENT, { Project } from './project-fragment';

export interface ProjectColumn {
  id: string;
  board: {
    id: string;
  };
  name: string;
  order: number;
  projects: Project[];
}

const PROJECT_COLUMN_FRAGMENT = `
  id
  board {
    id
  }
  name
  order
  projects {
    ${PROJECT_FRAGMENT}
  }
`;

export default PROJECT_COLUMN_FRAGMENT;
