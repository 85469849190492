import { Button, TextField } from '@radix-ui/themes';
import { Link } from 'lucide-react';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { isWebUri } from 'valid-url';

import Modal from '../Modal';

const TaskLinkModal = ({
  link,
  onSubmit,
  open,
  setIsOpen,
}: {
  link: string | null | undefined;
  onSubmit: (link: string | null) => void;
  open: boolean;
  setIsOpen: (open: boolean) => void;
}): JSX.Element => {
  const [linkValue, setLinkValue] = useState(link || '');
  const inputRef = useRef<HTMLInputElement>();

  // Focus the input field without the hotkey character after the modal is opened
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (inputRef?.current) {
          inputRef?.current?.focus();
        }
      }, 0);
    }
  }, [open]);

  const onSubmitForm = (event: FormEvent): void => {
    event.preventDefault();

    onSubmit(linkValue.length ? linkValue : null);

    setIsOpen(false);
  };

  return (
    <Modal open={open} setIsOpen={setIsOpen} title="Set link for task">
      <form onSubmit={onSubmitForm} className="px-4 pb-4">
        <div className="mb-4">
          <TextField.Root>
            <TextField.Slot>
              <Link height="16" width="16" />
            </TextField.Slot>

            <TextField.Input
              ref={inputRef}
              value={linkValue}
              onChange={(event) => setLinkValue(event.target.value)}
              type="url"
              placeholder="https://www.example.com"
              size="3"
            />
          </TextField.Root>
        </div>

        <div className="flex justify-end">
          <Button
            disabled={
              (!isWebUri(linkValue) && !!linkValue) || linkValue === link
            }
            type="submit"
            variant="solid"
          >
            Save link
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default TaskLinkModal;
