import classNames from 'classnames';
import { useLiveQuery } from 'dexie-react-hooks';
import { AnimatePresence, motion } from 'framer-motion';
import orderBy from 'lodash/orderBy';
import { Grid } from 'lucide-react';
import { Sidebar } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { db } from '../../database';
import { storeProjectColumnsFromServerInDatabase } from '../../database/actions';
import { useBoardQuery } from '../../graphql/generated-types';
import useBreakpoints from '../../hooks/use-breakpoints';
import {
  setContainerProjectColumns,
  setContainerProjects,
  setCreatorModalMode,
} from '../../reducers/actions';
import { BetaState } from '../../reducers/beta-types';
import { Project } from '../../types';
import ContainerHeader from '../ContainerHeader';
import ContainerSection from '../ContainerSection';
import { CreatorButton } from '../Creator/CreatorButton';
import CreatorModal from '../Creator/CreatorModal';

import BoardSettings from './BoardSettings';
import ProjectColumn from './ProjectColumn';

const boardSettingsVariants = {
  open: {
    opacity: 1,
    width: 'fit-content',
  },
  closed: { opacity: 0, width: 0 },
};

const BoardContainer = (): JSX.Element | null => {
  const { boardId } = useParams<{ boardId: string }>();
  const dispatch = useDispatch();

  const [boardData] = useBoardQuery({
    variables: { boardId },
    requestPolicy: 'cache-and-network',
  });

  const { breakpoint } = useBreakpoints();
  const isMobile = ['xs', 'sm'].includes(breakpoint);

  const { containerProjectColumns, overrideCreationProjectColumn } =
    useSelector((state: BetaState) => ({
      containerProjectColumns: orderBy(
        state.containerProjectColumns,
        'data.order'
      ),
      overrideCreationProjectColumn: state.overrideCreationProjectColumn,
    }));

  useEffect(() => {
    if (boardData?.data?.board) {
      void storeProjectColumnsFromServerInDatabase(
        boardData.data.board.projectColumns
      );
    }
  }, [boardData.data]);

  const [showSettings, setShowSettings] = useState<boolean>(false);

  const board = useLiveQuery(async () => {
    return await db.boards.get(boardId);
  }, [boardId]);

  const projectColumns = useLiveQuery(async () => {
    return await db.projectColumns
      .where('data.board.id')
      .equals(boardId)
      .toArray();
  }, [boardId]);

  useEffect(() => {
    dispatch(setContainerProjectColumns(projectColumns || []));
  }, [JSON.stringify(projectColumns)]);

  const projectColumnIds =
    containerProjectColumns.map((projectColumn) => projectColumn.data.id) || [];

  const latestProjects = useRef<Project[]>();

  const projects = useLiveQuery(async () => {
    return await db.projects
      .where('data.projectColumnId')
      .anyOf(projectColumnIds)
      .toArray();
  }, [JSON.stringify(projectColumnIds)]);

  useEffect(() => {
    dispatch(setContainerProjects(projects || []));
    latestProjects.current = projects;
  }, [isEqual(projects, latestProjects.current)]);

  if (!board) {
    return null;
  }

  return (
    <div className="flex">
      <div className="relative mt-18 overflow-hidden flex-grow md:h-screen md:overflow-y-scroll pb-40 md:mt-0 md:pb-0">
        <ContainerHeader
          fetching={boardData.fetching || boardData.stale}
          title={
            <div className="flex">
              <div className="mr-3">{board.data.emoji}</div> {board.data.name}
            </div>
          }
          rightControls={
            <>
              {!showSettings && (
                <Sidebar
                  size={20}
                  role="button"
                  onClick={() => setShowSettings(!showSettings)}
                  className="cursor-pointer transition hover:text-violet-500"
                  style={{ transform: 'scaleX(-1)' }}
                />
              )}
            </>
          }
        />

        <div className="mb-20">
          <ContainerSection Icon={Grid} title="Projects">
            <div className="mb-2 flex justify-between px-4 text-xs opacity-70">
              <span className="ml-7">Name</span>

              <div className="hidden md:flex">
                <span className="inline-block w-25">Start date</span>
                <span className="inline-block w-25">End date</span>
              </div>
            </div>
            {containerProjectColumns.map((column) => (
              <ProjectColumn
                projectColumn={column}
                key={`project-column-${column.data.id}`}
              />
            ))}
          </ContainerSection>
        </div>

        <CreatorButton
          sideMenuOpen={showSettings}
          openCreatorModal={() => dispatch(setCreatorModalMode('project'))}
        />

        <CreatorModal
          boardId={board.data.id}
          projectColumnId={overrideCreationProjectColumn ?? undefined}
        />
      </div>

      <AnimatePresence>
        {showSettings && (
          <motion.div
            transition={{ duration: isMobile ? 0 : 0.3 }}
            initial="closed"
            animate="open"
            exit="closed"
            variants={boardSettingsVariants}
          >
            <div
              className={classNames(
                'fixed top-0 right-0 h-screen w-screen flex-shrink-0 border-0 border-l border-solid border-gray-300 bg-white z-map-settings-sidebar dark:border-violet-900 dark:bg-mauve-dark-2 md:w-80 xl:relative',
                {
                  hidden: !showSettings,
                }
              )}
            >
              <BoardSettings
                board={board}
                key={`board-settings-${board.data.id}`}
                projectColumns={containerProjectColumns}
                onClickCloseButton={() => setShowSettings(false)}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BoardContainer;
