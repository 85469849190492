import React from 'react';

import './TimePicker.css';

export const TimePicker = ({
  setValue,
  value,
}: {
  setValue: (value: string) => void;
  value: string;
}): JSX.Element => {
  return (
    <input
      style={{ fontFamily: 'Inter, Roboto, Helvetica, sans-serif' }}
      type="time"
      step="300"
      value={value ?? ''}
      onChange={(event) => setValue(event.target.value)}
      className="dark:[color-scheme:dark] w-44 box-border rounded-md border border-solid border-gray-400 bg-transparent p-2 text-base placeholder-gray-400 shadow-none transition-all selection:bg-violet-500 selection:text-white focus:border-violet-500 focus:outline-none dark:text-white"
    />
  );
};
