import React from 'react';

export const PopoverBody = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      className="w-fit bg-white dark:bg-mauve-dark-3 rounded-md border border-solid border-gray-400 border-opacity-20 shadow-md"
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  );
};
