import * as Dialog from '@radix-ui/react-dialog';
import React, { MutableRefObject, useRef } from 'react';

interface FloatingOverlayProps {
  children: (inputRef: MutableRefObject<HTMLInputElement>) => React.ReactNode;
  onOpenChange: (open: boolean) => void;
  open: boolean;
}

export const FloatingOverlay: React.FC<FloatingOverlayProps> = ({
  children,
  onOpenChange,
  open,
}) => {
  const inputRef = useRef<HTMLInputElement>();

  return (
    <Dialog.Root onOpenChange={onOpenChange} open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed bg-mauve-2 dark:bg-mauve-dark-2 bg-opacity-60 dark:bg-opacity-80 inset-0 w-screen h-screen flex items-center justify-center" />

        <div
          className="fixed flex inset-0 justify-center bg-transparent"
          style={{ padding: 'calc(-0.39px + 13vh) 16px 16px' }}
        >
          <Dialog.Content
            onOpenAutoFocus={(e) => {
              e.preventDefault();

              // Focus the input field without the hotkey character after the modal is opened
              setTimeout(() => {
                if (inputRef?.current) {
                  inputRef?.current?.focus();
                }
              }, 0);
            }}
            className="max-w-[640px] bg-transparent w-full"
          >
            {children(inputRef)}
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
