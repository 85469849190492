import { DraggableLocation } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';

import { Project, ProjectColumn, Task } from '../../types';
import { orderedProjects } from '../project-columns';

export type TaskWithDraggableData = Task & {
  draggableData: {
    destinationGroupId: string | null;
    sourceGroupId: string | null;
  };
};

type OrderType = 'date' | 'inbox' | 'project' | 'priority';

export const reorderDraggables = <DraggableType>(
  draggables: DraggableType[],
  draggedIndices: number[],
  destinationIndex: number,
  orderType?: OrderType
): DraggableType[] => {
  const sortedDraggables = sortBy(
    Array.from(draggables),
    `order.${orderType}` || 'data.order'
  );

  const draggedItems = sortedDraggables.filter((_, index) =>
    draggedIndices.includes(index)
  );

  const updatedDraggables = sortedDraggables.filter(
    (_, index) => !draggedIndices.includes(index)
  );

  updatedDraggables.splice(destinationIndex, 0, ...draggedItems);

  return updatedDraggables;
};

export const reorderDraggableProjects = (
  projects: Project[],
  source: DraggableLocation,
  destination: DraggableLocation,
  destinationProjectColumn: ProjectColumn,
  sourceProjectColumn: ProjectColumn
): Project[] => {
  // Reordering projects in the same column
  if (destinationProjectColumn.data.id === sourceProjectColumn.data.id) {
    const columnProjects = projects.filter(
      (project) =>
        project.data.projectColumnId === destinationProjectColumn.data.id
    );

    const reorderedProjects: Project[] = reorderDraggables(
      orderedProjects(columnProjects, destinationProjectColumn),
      [source.index],
      destination.index
    ).map((project, index: number) => ({
      ...project,
      data: {
        ...project.data,
        order: index + 1,
      },
    }));

    return reorderedProjects;
  } else {
    const [sourceProjects, destinationProjects] = reorderProjectsBetweenColumns(
      projects,
      sourceProjectColumn,
      destinationProjectColumn,
      source.index,
      destination.index
    );

    return [...sourceProjects, ...destinationProjects];
  }
};

export const reorderDraggableTasks = (
  tasks: Task[],
  source: DraggableLocation,
  destination: DraggableLocation,
  orderType: OrderType,
  selectedTasks: string[]
): TaskWithDraggableData[] => {
  const [sourceGroupId, destinationGroupId] = [source, destination].map(
    (location) => extractGroupId(location.droppableId)
  );

  const sanitizedTasks: TaskWithDraggableData[] = tasks
    .filter((task) => !task.data.completed)
    .map((task) => ({
      ...task,
      draggableData: {
        destinationGroupId: null,
        sourceGroupId: null,
      },
    }));

  const sourceTasks = sortBy(
    sanitizedTasks.filter((task) => task.data.groupIds.includes(sourceGroupId)),
    `order.${orderType}`
  );

  if (sourceGroupId === destinationGroupId) {
    const draggedIndices = keys(
      pickBy(sourceTasks, (task) => selectedTasks.includes(task.data.id))
    ).map((index) => Number(index));

    return reorderDraggables(
      sourceTasks,
      draggedIndices,
      destination.index,
      orderType
    ).map((task: Task, index: number) => ({
      ...task,
      draggableData: {
        sourceGroupId,
        destinationGroupId,
      },
      order: {
        ...task.order,
        [orderType]: index,
      },
    }));
  } else {
    const [movedTask] = sourceTasks.splice(source.index, 1);

    // Move task into new group
    movedTask.draggableData.sourceGroupId = sourceGroupId;

    const sourceTasksWithData: TaskWithDraggableData[] = sourceTasks.map(
      (task, index) => ({
        ...task,
        order: {
          ...task.order,
          [orderType]: index,
        },
        draggableData: {
          sourceGroupId,
          destinationGroupId: sourceGroupId,
        },
      })
    );

    const destinationTasks = sortBy(
      sanitizedTasks.filter((task) =>
        task.data.groupIds.includes(destinationGroupId)
      ),
      `order.${orderType}`
    );

    destinationTasks.splice(destination.index, 0, movedTask);

    const destinationTasksWithData: TaskWithDraggableData[] =
      destinationTasks.map((task, index) => ({
        ...task,
        order: {
          ...task.order,
          [orderType]: index,
        },
        draggableData: {
          destinationGroupId,
          sourceGroupId: task.draggableData.sourceGroupId || destinationGroupId,
        },
        data: {
          ...task.data,
          groupIds: [destinationGroupId],
        },
      }));

    return sourceTasksWithData.concat(destinationTasksWithData);
  }
};

const reorderProjectsBetweenColumns = (
  projects: Project[],
  sourceProjectColumn: ProjectColumn,
  destinationProjectColumn: ProjectColumn,
  sourceIndex: number,
  destinationIndex: number
): [Project[], Project[]] => {
  const sourceProjects = orderedProjects(
    projects.filter(
      (project) => project.data.projectColumnId === sourceProjectColumn.data.id
    ),
    sourceProjectColumn
  );

  const [movedProject] = sourceProjects.splice(sourceIndex, 1);

  const sourceProjectsWithData = sourceProjects.map((project, index) => ({
    ...project,
    data: {
      ...project.data,
      order: index + 1,
      projectColumnId: sourceProjectColumn.data.id,
    },
  }));

  const destinationProjects = orderedProjects(
    projects.filter(
      (project) =>
        project.data.projectColumnId === destinationProjectColumn.data.id
    ),
    destinationProjectColumn
  );

  destinationProjects.splice(destinationIndex, 0, movedProject);

  const destinationProjectsWithData = destinationProjects.map(
    (project, index) => ({
      ...project,
      data: {
        ...project.data,
        order: index + 1,
        projectColumnId: destinationProjectColumn.data.id,
      },
    })
  );

  return [sourceProjectsWithData, destinationProjectsWithData];
};

const extractGroupId = (droppableId: string): string => {
  return droppableId.split('-')[1];
};
