import { DraggableLocation } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';

import { NoteFragment, TaskFragment } from '../graphql/generated-types';
import { Group, Note, Project, ProjectColumn, Task } from '../types';

export type CreatorModalMode = 'board' | 'group' | 'note' | 'project' | 'task';
export type CurrentDraggingType = 'group' | 'task' | null;
export type OrderType = 'date' | 'inbox' | 'project';

export type BetaAction =
  | {
      type: 'REMOVE_TASKS';
      taskIds: string[];
    }
  | {
      type: 'REORDER_PROJECTS';
      source: DraggableLocation;
      destination: DraggableLocation;
    }
  | {
      type: 'REMOVE_GROUPS';
      groupIds: string[];
    }
  | {
      type: 'REMOVE_NOTES';
      noteIds: string[];
    }
  | {
      type: 'REORDER_GROUPS';
      source: DraggableLocation;
      destination: DraggableLocation;
    }
  | {
      type: 'REORDER_TASKS';
      source: DraggableLocation;
      destination: DraggableLocation;
      orderType: OrderType;
    }
  | { type: 'REORDER_TASKS_RESPONSE'; tasks: TaskFragment[] }
  | { type: 'SET_ACTIVE_SIDEBAR'; activeSidebar: Sidebar }
  | { type: 'SET_ADD_TASK_GROUP'; addTaskGroup: string | null }
  | {
      type: 'SET_ADD_TASK_GROUP_PLACEHOLDER';
      addTaskGroupPlaceholder: string | null;
    }
  | {
      type: 'SET_CONTAINER_GROUPS';
      containerGroups: Group[];
    }
  | {
      type: 'SET_CONTAINER_PROJECT_COLUMNS';
      containerProjectColumns: ProjectColumn[];
    }
  | {
      type: 'SET_CONTAINER_PROJECTS';
      containerProjects: Project[];
    }
  | {
      type: 'SET_CONTAINER_TASKS';
      containerTasks: Task[];
    }
  | {
      type: 'SET_PRIORITY_TASKS';
      priorityTasks: Task[];
    }
  | {
      type: 'SET_CREATOR_MODAL_MODE';
      creatorModalMode: CreatorModalMode | null;
    }
  | {
      type: 'SET_CURRENT_DRAGGING_TYPE';
      currentDraggingType: CurrentDraggingType;
    }
  | {
      type: 'SET_DAY_CALENDAR_ZOOM_LEVEL';
      dayCalendarZoomLevel: number;
    }
  | {
      type: 'SET_DRAGGING_PROJECT_COLUMN_ID';
      draggingProjectColumnId: string | null;
    }
  | { type: 'SET_DRAGGING_TASK'; draggingTask: string | null }
  | {
      type: 'SET_FLOATING_PROJECT_SELECTOR_TASK_IDS';
      taskIds: string[];
    }
  | {
      type: 'SET_FOCUSED_TASK';
      focusedTask: boolean;
    }
  | { type: 'SET_HIDE_MOBILE_ELEMENTS'; hideMobileElements: boolean }
  | { type: 'SET_IS_DRAGGING'; isDragging: boolean }
  | { type: 'SET_IS_HOVERING_TASK_DROPZONE'; isHoveringTaskDropzone: boolean }
  | { type: 'SET_LAST_SELECTED_TASK'; lastSelectedTask: string | null }
  | { type: 'SET_MOBILE_SIDEBAR_VISIBLE'; mobileSidebarVisible: boolean }
  | { type: 'SET_MOBILE_TOOLBAR_VISIBLE'; mobileToolbarVisible: boolean }
  | {
      type: 'SET_OVERRIDE_CREATION_GROUP';
      overrideCreationGroup: string | null;
    }
  | {
      type: 'SET_OVERRIDE_CREATION_PROJECT_COLUMN';
      overrideCreationProjectColumn: string | null;
    }
  | { type: 'SET_PAUSE_CONTAINER_STORES'; pauseContainerStores: boolean }
  | { type: 'SET_SEARCH_OPEN'; searchOpen: boolean }
  | { type: 'SET_SELECTED_DATE'; date: string | null }
  | { type: 'SET_SELECTED_PROJECT'; projectId: string | null }
  | {
      type: 'SET_SELECTED_TASKS';
      selectedTasks: string[];
      selectedTasksLocation: string | null;
    }
  | { type: 'SET_TIME_ZONE'; timeZone: string }
  | {
      type: 'SET_TOAST';
      toast: Toast | null;
    }
  | { type: 'STORE_NOTES'; notes: Note[] }
  | {
      type: 'STORE_NOTES_FROM_SERVER';
      notes: NoteFragment[];
    }
  | {
      type: 'UPDATE_CONTAINER_TASKS';
      containerTasks: Task[];
    };

export interface Toast {
  title: string;
  subtitle: string;
  path?: string;
}

export interface BetaState {
  activeSidebar: Sidebar;
  containerGroups: Group[];
  containerProjectColumns: ProjectColumn[];
  containerProjects: Project[];
  containerTasks: Task[];
  creatorModalMode: CreatorModalMode | null;
  currentDraggingType: 'group' | 'task' | null;
  dayCalendarZoomLevel: number;
  draggingProjectColumnId: string | null;
  draggingTask: string | null;
  floatingProjectSelectorTaskIds: string[];
  focusedTask: boolean;
  hideMobileElements: boolean;
  isDragging: boolean;
  isHoveringTaskDropzone: boolean;
  lastActionTakenAt: Date;
  lastSelectedTask: string | null;
  mobileSidebarVisible: boolean;
  mobileToolbarVisible: boolean;
  overrideCreationGroup: string | null;
  overrideCreationProjectColumn: string | null;
  notes: Note[];
  pauseContainerStores: boolean;
  priorityTasks: Task[];
  searchOpen: boolean;
  selectedDate: string | null;
  selectedProject: string | null;
  selectedTasks: string[];
  selectedTasksLocation: string | null;
  timeZone: string;
  toast: Toast | null;
}

export enum Sidebar {
  Boards = 'Boards',
  Calendar = 'Calendar',
  DayView = 'DayView',
  Notes = 'Notes',
  Priorities = 'Priorities',
  Search = 'Search',
  Settings = 'Settings',
}
