import RECURRENCE_FRAGMENT, { Recurrence } from './recurrence-fragment';

export interface Task {
  id: string;
  completed: boolean;
  completedAt: number | null;
  date: string | null;
  description: string | null;
  destinationGroupId?: string;
  groupIds: string[];
  isRecurring: boolean;
  link: string | null;
  meta?: {
    recentlyChangedGroups?: boolean;
  };
  name: string;
  order?: number | null;
  priorityOrder: number | null;
  project: {
    id: string;
    name: string;
  };
  recurrence: Recurrence | null;
  spring: boolean;
  sourceGroupId?: string;
}

const TASK_FRAGMENT = `
    id
    completed
    completedAt
    date
    description
    groupIds
    isRecurring
    link
    name
    priorityOrder
    project {
      id
      name
    }
    recurrence {
      ${RECURRENCE_FRAGMENT}
    }
    spring
`;

export default TASK_FRAGMENT;
