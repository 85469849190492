import { ChevronRight } from 'lucide-react';
import React from 'react';

export const SidebarList = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  return <ul className="m-0 list-none p-0 ">{children}</ul>;
};

export const SidebarListItem = ({
  Icon,
  onClick,
  text,
}: {
  Icon?: JSX.Element;
  onClick?: () => void;
  text: string;
}): JSX.Element => {
  return (
    <li
      className="group flex cursor-pointer items-center justify-between border-0 border-b border-solid border-gray-200 bg-white p-5 text-lg hover:bg-violet-200 hover:text-violet-700 dark:border-gray-900 dark:bg-mauve-dark-3 dark:hover:bg-violet-700 dark:hover:bg-opacity-30 dark:hover:text-violet-200"
      onClick={onClick}
    >
      <div className="flex items-center">
        {Icon || <div />}

        {text}
      </div>

      <ChevronRight size="16" />
    </li>
  );
};
