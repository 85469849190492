import { Root, Thumb } from '@radix-ui/react-switch';
import classnames from 'classnames';
import React from 'react';

interface SizeMeasurement {
  heightClass: string;
  thumbCheckedTransform: string;
  thumbUncheckedTransform: string;
  thumbHeightClass: string;
  thumbWidthClass: string;
  widthClass: string;
}

const sizeMeasurements = (size: 'small' | 'regular'): SizeMeasurement => {
  switch (size) {
    case 'small':
      return {
        heightClass: 'h-5',
        thumbCheckedTransform: 'translateX(13px)',
        thumbUncheckedTransform: 'translateX(1px)',
        thumbHeightClass: 'h-4',
        thumbWidthClass: 'w-4',
        widthClass: 'w-8',
      };
    case 'regular':
      return {
        heightClass: 'h-6',
        thumbCheckedTransform: 'translateX(17px)',
        thumbUncheckedTransform: 'translateX(1px)',
        thumbHeightClass: 'h-5',
        thumbWidthClass: 'w-5',
        widthClass: 'w-10',
      };
  }
};

const Switch = ({
  checked,
  id,
  onCheckedChange,
  size = 'regular',
}: {
  checked: boolean;
  id: string;
  onCheckedChange: () => void;
  size?: 'regular' | 'small';
}): JSX.Element => {
  const {
    heightClass,
    thumbCheckedTransform,
    thumbUncheckedTransform,
    thumbHeightClass,
    thumbWidthClass,
    widthClass,
  } = sizeMeasurements(size);

  return (
    <Root
      onCheckedChange={onCheckedChange}
      checked={checked}
      className={classnames(
        'relative cursor-pointer rounded-xl border-0 p-0 focus:outline-violet-600',
        heightClass,
        widthClass,
        {
          'bg-opacity-50 dark:bg-opacity-30 bg-gray-300 dark:bg-gray-500':
            !checked,
          'bg-opacity-100 bg-violet-600': checked,
        }
      )}
      id={id}
    >
      <Thumb
        className={classnames(
          'block rounded-full border border-solid border-gray-900 bg-white outline-offset-1 transition-all',
          thumbHeightClass,
          thumbWidthClass
        )}
        style={{
          transform: checked ? thumbCheckedTransform : thumbUncheckedTransform,
        }}
      />
    </Root>
  );
};

export default Switch;
