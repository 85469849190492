// @ts-strict-ignore

import { addMonths, format, subMonths } from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  setMobileSidebarVisible,
  setSelectedDate,
} from '../../reducers/actions';
import { BetaState } from '../../reducers/beta-types';
import { pathForDate } from '../../utils/date';

import { CurrentMonthDay } from './CurrentMonthDay';
import { OtherMonthDay } from './OtherMonthDay';
import {
  daysOfCurrentMonth,
  daysOfNextMonth,
  daysOfPreviousMonth,
} from './utils';

const CalendarGrid = ({
  calendarDate,
}: {
  calendarDate: Date;
}): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const previousMonth = subMonths(calendarDate, 1);
  const nextMonth = addMonths(calendarDate, 1);

  const selectedDateString = useSelector((state: BetaState) => {
    return state.selectedDate;
  });

  const selectedDate = new Date(selectedDateString);

  const onDayClick = (date: Date): void => {
    history.push(pathForDate(date));
    dispatch(setSelectedDate(date.toDateString()));
    dispatch(setMobileSidebarVisible(false));
  };

  return (
    <>
      {daysOfPreviousMonth(calendarDate, previousMonth).map((day) => (
        <OtherMonthDay
          day={day}
          key={`${format(previousMonth, 'MM-DDDD')}-${day}`}
          month={previousMonth}
          onClick={onDayClick}
          selectedDate={selectedDate}
        />
      ))}

      {daysOfCurrentMonth(calendarDate).map((day) => (
        <CurrentMonthDay
          day={day}
          calendarDate={calendarDate}
          key={`${format(calendarDate, 'MM-DDDD')}-${day}`}
          onClick={onDayClick}
          selectedDate={selectedDate}
        />
      ))}

      {daysOfNextMonth(calendarDate).map((day) => (
        <OtherMonthDay
          day={day}
          key={`${format(nextMonth, 'MM-DDDD')}-${day}`}
          month={nextMonth}
          onClick={onDayClick}
          selectedDate={selectedDate}
        />
      ))}
    </>
  );
};

export default CalendarGrid;
