import format from 'date-fns/format';
import React, { useRef } from 'react';

import { CalendarEvent } from './types';

interface EventProps {
  className?: string;
  style?: React.CSSProperties;
  event: CalendarEvent;
}

export const Event: React.FC<EventProps> = (args) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  const parentWidth = parentRef.current?.offsetWidth;
  const childWidth = childRef.current?.offsetWidth;

  const displayStartTime = format(
    args.event.start,
    args.event.start.getMinutes() === 0 ? 'ha' : 'h:mm a'
  );

  const displayEndTime = format(
    args.event.end,
    args.event.end.getMinutes() === 0 ? 'ha' : 'h:mm a'
  );

  return (
    <div ref={parentRef} className={args.className} style={args.style}>
      <div className="line-clamp-1 flex gap-2">
        <span ref={childRef} className="font-medium px-2">
          {args.event.title}
        </span>

        <span className="day-event-time">{displayStartTime}</span>
      </div>

      {parentWidth && childWidth && childWidth >= parentWidth - 30 && (
        <div className="day-event-time line-clamp-1 px-2 flex gap-2">
          <span>{displayStartTime}</span>
        </div>
      )}

      <div className="day-event-preview-time px-2">
        {displayStartTime} - {displayEndTime}
      </div>
    </div>
  );
};
