/* eslint-disable react/no-unescaped-entities */
import { Box, Button } from '@radix-ui/themes';
import { Trash } from 'lucide-react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  useDeleteGroupMutation,
  useUpdateGroupMutation,
} from '../../graphql/generated-types';
import { removeGroups } from '../../reducers/actions';
import { Group } from '../../types';
import TextInput from '../library/TextInput';
import Modal from '../Modal';
import Checkbox from '../shared/Checkbox';

interface EditingGroupModalProps {
  group: Group;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const EditingGroupModal = ({
  group,
  isOpen,
  setIsOpen,
}: EditingGroupModalProps): JSX.Element => {
  const dispatch = useDispatch();

  const [groupName, setGroupName] = useState(group.data.name);
  const [keepTasks, setKeepTasks] = useState(group.data.keepTasks);
  const [modalMode, setModalMode] = useState<'edit' | 'delete'>('edit');

  const [, deleteGroup] = useDeleteGroupMutation();
  const [, updateGroup] = useUpdateGroupMutation();

  const handleDeleteGroup = async (): Promise<void> => {
    await deleteGroup({ groupId: group.data.id });

    dispatch(removeGroups([group.data.id]));

    setIsOpen(false);
  };

  const handleUpdateGroup = async (): Promise<void> => {
    await updateGroup({
      groupId: group.data.id,
      name: groupName,
      keepTasks,
    });

    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      setIsOpen={setIsOpen}
      title={modalMode === 'edit' ? 'Group settings' : 'Delete group'}
    >
      <Box p="4">
        {modalMode === 'edit' && (
          <>
            <TextInput
              autoFocus
              className="mb-4"
              placeholder="Group name"
              value={groupName}
              onChange={(event) => setGroupName(event.target.value)}
            />

            <div className="mb-4 rounded-md border border-solid border-mauve-6 bg-mauve-3 p-2 pt-5 pr-4 dark:border-mauve-dark-6 dark:bg-mauve-dark-3 dark:text-white">
              <div className="flex items-center">
                <Checkbox
                  checked={keepTasks}
                  id="keep-tasks"
                  onChange={() => setKeepTasks(!keepTasks)}
                />

                <label
                  htmlFor="keep-tasks"
                  className="ml-2 text-base font-medium"
                >
                  Keep tasks
                </label>
              </div>

              <p className="ml-10 mt-1 text-sm text-gray-500 dark:text-gray-300">
                Tasks that are moved to or repeat to another date will be kept
                in this group on their new date.
              </p>
            </div>

            <div className="flex justify-between">
              <Button onClick={handleUpdateGroup}>Save changes</Button>

              <Button
                color="gray"
                variant="soft"
                onClick={() => setModalMode('delete')}
              >
                <Trash size={14} className="text-red-500" />
                Delete group
              </Button>
            </div>
          </>
        )}

        {modalMode === 'delete' && (
          <>
            <div className="mb-4 rounded-md border border-solid border-mauve-6 bg-mauve-3 p-4 dark:border-mauve-dark-6 dark:bg-mauve-dark-3 dark:text-white">
              <p className=" mt-1 mb-1 text-sm text-gray-500 dark:text-gray-300">
                Are you sure you wish to delete this group? All tasks within{' '}
                <strong className="font-bold">"{group.data.name}"</strong> will
                be removed along with the group.
              </p>
            </div>

            <div className="flex justify-between">
              <Button color="red" variant="solid" onClick={handleDeleteGroup}>
                Delete group
              </Button>

              <Button
                color="gray"
                variant="soft"
                onClick={() => setModalMode('edit')}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};
