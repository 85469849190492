import React from 'react';
import { useSelector } from 'react-redux';

import {
  BetaState,
  Sidebar as SidebarType,
} from '../../../reducers/beta-types';

import BoardsSidebar from './BoardsSidebar';
import CalendarSidebar from './CalendarSidebar';
import NotesSidebar from './NotesSidebar';
import PrioritiesSidebar from './PrioritiesSidebar';
import SettingsSidebar from './SettingsSidebar';
import { DayViewSidebar } from './DayViewSidebar';

const Sidebar = (): JSX.Element => {
  const activeSidebar = useSelector((state: BetaState) => state.activeSidebar);

  let sidebar;

  switch (activeSidebar) {
    case SidebarType.Boards: {
      sidebar = <BoardsSidebar />;

      break;
    }

    case SidebarType.Calendar: {
      sidebar = <CalendarSidebar />;

      break;
    }

    case SidebarType.DayView: {
      sidebar = <DayViewSidebar />;

      break;
    }

    case SidebarType.Notes: {
      sidebar = <NotesSidebar />;

      break;
    }

    case SidebarType.Priorities: {
      sidebar = <PrioritiesSidebar />;

      break;
    }

    case SidebarType.Search: {
      sidebar = <div>Search Sidebar</div>;

      break;
    }

    case SidebarType.Settings: {
      sidebar = <SettingsSidebar />;

      break;
    }
  }

  return (
    <div className="h-full overflow-x-hidden overflow-y-scroll">{sidebar}</div>
  );
};

export default Sidebar;
