import { Command } from 'cmdk';
import React, { useState } from 'react';

import { FloatingOverlay } from '../shared/FloatingOverlay';

import { BoardSelector } from './BoardSelector';

interface FloatingBoardSelectorProps {
  onSelect: (boardId: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const FloatingBoardSelector: React.FC<FloatingBoardSelectorProps> = ({
  onSelect,
  open,
  setOpen,
}) => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  return (
    <FloatingOverlay onOpenChange={setOpen} open={open}>
      {(inputRef) => (
        <Command>
          <Command.Input
            autoFocus={false}
            value={search}
            onValueChange={setSearch}
            ref={inputRef}
            placeholder="Move to board..."
          />

          <Command.List>
            <BoardSelector
              loading={loading}
              onSelect={onSelect}
              setLoading={setLoading}
            />
          </Command.List>
        </Command>
      )}
    </FloatingOverlay>
  );
};
