import classNames from 'classnames';
import { useLiveQuery } from 'dexie-react-hooks';
import { AnimatePresence, motion } from 'framer-motion';
import last from 'lodash/last';
import { CheckCircle, Sidebar } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { db } from '../../database';
import {
  storeGroupsFromServerInDatabase,
  storeProjectsFromServerInDatabase,
} from '../../database/actions';
import {
  useContainerQuery,
  useProjectQuery,
  useUpdateContainerMutation,
} from '../../graphql/generated-types';
import useBreakpoints from '../../hooks/use-breakpoints';
import {
  setContainerGroups,
  setContainerTasks,
  setCreatorModalMode,
  setSelectedProject,
} from '../../reducers/actions';
import { BetaState } from '../../reducers/beta-types';
import ContainerHeader from '../ContainerHeader';
import ContainerSection from '../ContainerSection';
import { CreatorButton } from '../Creator/CreatorButton';
import CreatorModal from '../Creator/CreatorModal';
import GroupList from '../GroupList';
import { Notepad } from '../Notepad';
import ProjectProgress from '../ProjectProgress';

import ProjectSettings from './ProjectSettings';

const projectSettingsVariants = {
  open: {
    opacity: 1,
    width: 'fit-content',
  },
  closed: { opacity: 0, width: 0 },
};

const ProjectContainer = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const { projectId } = useParams<{ projectId: string }>();

  const [showSettings, setShowSettings] = useState<boolean>(false);
  const { breakpoint } = useBreakpoints();
  const isMobile = ['xs', 'sm'].includes(breakpoint);

  const [containerData, refetchContainerData] = useContainerQuery({
    variables: { projectId },
    requestPolicy: 'network-only',
  });

  const [{ fetching }, updateContainer] = useUpdateContainerMutation();

  const [projectData] = useProjectQuery({
    variables: { projectId },
  });

  useEffect(() => {
    dispatch(setSelectedProject(projectId));
  }, []);

  useEffect(() => {
    if (containerData.data?.container) {
      const { groups } = containerData.data.container;

      void storeGroupsFromServerInDatabase(groups);
    }
  }, [containerData.data]);

  useEffect(() => {
    if (projectData.data?.project) {
      void storeProjectsFromServerInDatabase([projectData.data.project]);
    }
  }, [projectData.data]);

  const {
    containerGroups,
    containerTasks,
    overrideCreationGroup,
    selectedTasks,
  } = useSelector(
    (state: BetaState) => ({
      containerGroups: state.containerGroups,
      containerTasks: state.containerTasks,
      overrideCreationGroup: state.overrideCreationGroup,
      selectedTasks: state.selectedTasks,
    }),
    isEqual
  );

  const project = useLiveQuery(async () => {
    return await db.projects.get(projectId);
  }, [projectId]);

  const databaseTasks = useLiveQuery(async () => {
    if (project?.data.id) {
      return await db.tasks
        .where('data.projectId')
        .equals(project.data.id)
        .toArray();
    }
  }, [project?.data.id]);

  const databaseGroups = useLiveQuery(async () => {
    if (project?.data.id) {
      return await db.groups
        .where('data.projectId')
        .equals(project.data.id)
        .toArray();
    }
  }, [project?.data.id]);

  useEffect(() => {
    dispatch(setContainerGroups(databaseGroups || []));
  }, [databaseGroups]);

  useEffect(() => {
    dispatch(setContainerTasks(databaseTasks || []));
  }, [databaseTasks]);

  const completedTasks = containerTasks.filter((task) => task.data.completed);
  const completionProgress =
    (completedTasks.length / containerTasks.length) * 100;

  const lastSelectedTask = containerTasks.find(
    (task) => task.data.id === last(selectedTasks)
  );

  const selectedGroupId =
    overrideCreationGroup ||
    lastSelectedTask?.data.groupIds.find((groupId) =>
      containerGroups.map((group) => group.data.id).includes(groupId)
    );

  if (!project) {
    return null;
  }

  return (
    <div className="flex">
      <div className="relative grow overflow-hidden bg-gray-50 pb-0 pt-18 dark:bg-mauve-dark-2 md:h-screen md:overflow-y-scroll md:pt-0">
        <ContainerHeader
          fetching={containerData.fetching || containerData.stale}
          title={
            <div className="flex items-center gap-1">
              <ProjectProgress
                completionProgress={completionProgress}
                project={project}
              />

              <span className="leading-7 transition-all line-clamp-2">
                {project.data.name}
              </span>
            </div>
          }
          rightControls={
            <>
              {!showSettings && (
                <Sidebar
                  size={20}
                  role="button"
                  onClick={() => setShowSettings(!showSettings)}
                  className="shrink-0 cursor-pointer transition hover:text-violet-500"
                  style={{ transform: 'scaleX(-1)' }}
                />
              )}
            </>
          }
        />

        <ContainerSection
          className="pt-25 md:pt-4"
          Icon={CheckCircle}
          title="Tasks"
        >
          <div className="py-2">
            <GroupList projectId={projectId} containerTasks={containerTasks} />
          </div>
        </ContainerSection>

        {containerData.data?.container && (
          <Notepad
            content={containerData?.data?.container.noteBody}
            key={`notepad-container-${containerData.data?.container.id}`}
            isSaving={fetching}
            onChange={async (noteBody) => {
              await updateContainer({
                noteBody,
                projectId,
              });
            }}
          />
        )}

        <CreatorButton
          openCreatorModal={() => dispatch(setCreatorModalMode('task'))}
          sideMenuOpen={showSettings}
        />

        <CreatorModal
          containerId={containerData.data?.container?.id}
          containerType={containerData.data?.container?.containerType}
          groupId={selectedGroupId}
          project={project}
          refetchContainerData={refetchContainerData}
        />
      </div>

      <AnimatePresence>
        {showSettings && (
          <motion.div
            transition={{ duration: isMobile ? 0 : 0.3 }}
            initial="closed"
            animate="open"
            exit="closed"
            variants={projectSettingsVariants}
          >
            <div
              className={classNames(
                'fixed top-0 right-0 h-screen w-screen flex-shrink-0 border-0 border-l border-solid border-gray-300 bg-white z-map-settings-sidebar dark:border-violet-900 dark:bg-mauve-dark-2 md:w-80 xl:relative',
                {
                  hidden: !showSettings,
                }
              )}
            >
              <ProjectSettings
                project={project}
                onClickCloseButton={() => setShowSettings(false)}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProjectContainer;
