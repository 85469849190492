import {
  Box,
  Button,
  Popover,
  ScrollArea,
  Text,
  TextField,
  Tooltip,
} from '@radix-ui/themes';
import { useLiveQuery } from 'dexie-react-hooks';
import { AnimatePresence, motion } from 'framer-motion';
import orderBy from 'lodash/orderBy';
import { Archive, ChevronLeft, ChevronRight } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { PuffLoader } from 'react-spinners';

import { db } from '../../database';
import { storeBoardsFromServerInDatabase } from '../../database/actions';
import {
  BoardFragment,
  useBoardQuery,
  useBoardsQuery,
} from '../../graphql/generated-types';
import useBreakpoints from '../../hooks/use-breakpoints';
import ActionButton from '../library/ActionButton';

interface ProjectOption {
  value: string;
  displayValue: string;
}

interface BoardOption {
  id: string;
  emoji?: string;
  lastViewedAt?: string;
  name: string;
}

const boardMenuVariants = {
  hidden: {
    x: '-200px',
    opacity: 0,
  },
  visible: {
    x: '0',
    opacity: 1,
  },
};

const projectMenuVariants = {
  hidden: {
    x: '200px',
    opacity: 0,
  },
  visible: {
    x: '0',
    opacity: 1,
  },
};

const ProjectSelector = ({
  activeProject,
  id,
  onChange,
  onClear,
}: {
  activeProject: ProjectOption | null;
  id?: string;
  onChange: (
    project: ProjectOption,
    newProjectName?: string | undefined,
    newProjectBoardId?: string | undefined
  ) => void;
  onClear?: () => void;
}): JSX.Element => {
  const [open, setOpen] = useState(false);

  const { breakpoint } = useBreakpoints();
  const showMobile = ['xs', 'sm'].includes(breakpoint);

  return (
    <Popover.Root open={open} onOpenChange={(open) => setOpen(open)}>
      <Tooltip content="Project">
        <Popover.Trigger>
          <ActionButton
            collapseOnMobile={false}
            Icon={Archive}
            isActive={!!activeProject}
            noShrink
            text={activeProject?.displayValue || 'Add project'}
            onClear={onClear}
          />
        </Popover.Trigger>
      </Tooltip>

      {showMobile ? (
        <>
          {open && (
            <div className="absolute inset-0 box-border flex h-screen flex-col justify-between bg-white pb-20 z-map-modal-dropdown dark:bg-mauve-dark-3">
              <SelectorDropdown onChange={onChange} setOpen={setOpen} />

              <Button
                onClick={() => setOpen(false)}
                color="gray"
                variant="soft"
              >
                Cancel
              </Button>
            </div>
          )}
        </>
      ) : (
        <Popover.Content
          className="w-50 z-map-modal-dropdown md:static !p-0.5"
          sideOffset={10}
          id={id}
        >
          <ScrollArea type="scroll" className="max-h-60">
            <SelectorDropdown onChange={onChange} setOpen={setOpen} />
          </ScrollArea>
        </Popover.Content>
      )}
    </Popover.Root>
  );
};

const SelectorDropdown = ({
  onChange,
  setOpen,
}: {
  onChange: (
    project: ProjectOption,
    newProjectName?: string | undefined,
    newProjectBoardId?: string | undefined
  ) => void;
  setOpen: (open: boolean) => void;
}): JSX.Element | null => {
  const [activeBoard, setActiveBoard] = useState<BoardOption | null>(null);
  const [activeBoardData, setActiveBoardData] = useState<BoardFragment | null>(
    null
  );
  const [filter, setFilter] = useState('');

  const [boardsData] = useBoardsQuery();

  const [boardData] = useBoardQuery({
    variables: { boardId: activeBoard?.id ?? '' },
    pause: !activeBoard,
  });

  useEffect(() => {
    if (boardsData?.data) {
      void storeBoardsFromServerInDatabase(boardsData.data.boards);
    }
  }, [boardsData]);

  useEffect(() => {
    if (!activeBoard) {
      return setActiveBoardData(null);
    }

    if (boardData?.data?.board) {
      setActiveBoardData(boardData.data.board);
    }
  }, [activeBoard, boardData]);

  const boards = useLiveQuery(async () => {
    return await db.boards.filter((board) => !board.data.archivedAt).toArray();
  });

  useEffect(() => {
    setActiveBoard(null);
  }, [open]);

  const filteredProjectColumns =
    filter.length > 0 && activeBoardData?.projectColumns
      ? activeBoardData.projectColumns
          .map((projectColumn) => ({
            ...projectColumn,
            projects: projectColumn.projects?.filter((project) =>
              project.name.toLowerCase().includes(filter.toLowerCase())
            ),
          }))
          .filter(
            (projectColumn) =>
              projectColumn.projects && projectColumn.projects.length > 0
          )
      : activeBoardData?.projectColumns;

  if (!boards) {
    return null;
  }

  return (
    <ul className="m-0 list-none overflow-x-hidden p-1">
      <AnimatePresence exitBeforeEnter>
        {activeBoardData ? (
          <motion.div
            transition={{ duration: 0.2 }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            key="project-menu"
            variants={projectMenuVariants}
          >
            <Button
              size="1"
              mt="1"
              mx="2"
              mb="3"
              className="!pl-0.5 !pr-1"
              variant="ghost"
              onClick={() => setActiveBoard(null)}
            >
              <ChevronLeft size={12} /> Boards - {activeBoardData.name}
            </Button>

            <TextField.Root>
              <TextField.Input
                autoFocus
                spellCheck={false}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </TextField.Root>

            <Box mt="2">
              {orderBy(filteredProjectColumns, 'order')
                .filter(
                  (column) => column.projects && column.projects.length > 0
                )
                .map((column) => (
                  <>
                    <span className="my-1 block px-1 text-sm text-gray-600 dark:text-gray-400 md:text-xs">
                      {column.name}
                    </span>

                    {orderBy(column.projects, 'order').map((project) => (
                      <li
                        key={`project-selector-item-${project.id}`}
                        className="group flex cursor-pointer items-center justify-between gap-1 rounded-sm px-1 py-2 pr-3 text-base hover:bg-violet-500 hover:text-white dark:text-white md:py-1 md:text-sm"
                        onClick={() => {
                          onChange({
                            value: project.id,
                            displayValue: project.name,
                          });

                          setOpen(false);
                        }}
                      >
                        <Text size="1">{project.name}</Text>
                      </li>
                    ))}
                  </>
                ))}

              {filter && filteredProjectColumns.length === 0 && (
                <Button
                  ml="1"
                  my="1"
                  variant="ghost"
                  size="1"
                  onClick={() => {
                    onChange(
                      { value: 'new_project', displayValue: filter },
                      filter,
                      activeBoardData.id
                    );

                    setOpen(false);
                  }}
                >
                  Create: {filter}
                </Button>
              )}
            </Box>
          </motion.div>
        ) : (
          <motion.div
            transition={{ duration: 0.2 }}
            initial={false}
            animate="visible"
            exit="hidden"
            key="board-menu"
            variants={boardMenuVariants}
          >
            <span className="mb-2 mt-3 block pl-3 text-sm font-bold text-violet-800 dark:text-violet-400 md:mt-1 md:text-xs">
              Boards
            </span>

            {!boards.length && boardsData.fetching && (
              <div className="flex h-40 w-full items-center justify-center">
                <PuffLoader color="#7D4CDB" size={50} />
              </div>
            )}

            {orderBy(boards, 'lastViewedAt', 'desc').map((board) => (
              <li
                key={`project-selector-board-${board.data.id}`}
                className="group flex cursor-pointer items-center justify-between gap-1 rounded-sm px-1 py-2 pr-3 text-base hover:bg-violet-500 hover:text-white dark:text-white md:py-1 md:text-sm"
                onClick={() => {
                  return setActiveBoard({
                    id: board.data.id,
                    name: board.data.name,
                    emoji: board.data.emoji ?? undefined,
                    lastViewedAt: board.data.lastViewedAt,
                  });
                }}
              >
                <Text as="span" size="1" className="inline-flex gap-2">
                  <span className="inline-block w-4">{board.data.emoji}</span>{' '}
                  {board.data.name}
                </Text>

                <ChevronRight
                  size={14}
                  className="group-hover:inline-block group-hover:opacity-100 md:opacity-0"
                />
              </li>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </ul>
  );
};

export default ProjectSelector;
