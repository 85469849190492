import { Button } from '@radix-ui/themes';
import classNames from 'classnames';
import { Plus } from 'lucide-react';
import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSelector } from 'react-redux';

import { BetaState } from '../../reducers/beta-types';

export const CreatorButton = ({
  openCreatorModal,
  sideMenuOpen = false,
}: {
  openCreatorModal: () => void;
  sideMenuOpen?: boolean;
}): JSX.Element => {
  const [isDragging, setIsDragging] = useState(false);

  const { hideMobileElements, toast } = useSelector((state: BetaState) => ({
    hideMobileElements: state.hideMobileElements,
    toast: state.toast,
  }));

  useHotkeys(
    'c',
    () => {
      openCreatorModal();
    },
    {
      keyup: true,
    },
    [openCreatorModal]
  );

  return (
    <Button
      size="4"
      radius="full"
      variant="solid"
      className={classNames(
        'fixed right-4 flex !h-18 !w-18 items-center justify-center transition-all duration-300',
        {
          'scale-50 opacity-0': isDragging,
          'hover:scale-105': !isDragging,
          hidden: hideMobileElements,
          'xl:right-84': sideMenuOpen,
          'bottom-23 md:bottom-4': !toast,
          'bottom-38 md:bottom-25': !!toast,
        }
      )}
      onClick={openCreatorModal}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={() => setIsDragging(false)}
      draggable
    >
      <Plus size={36} className="text-white" />
    </Button>
  );
};
