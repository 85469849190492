import { Smile } from 'lucide-react';
import React from 'react';

const EmojiDisplay = ({ emoji }: { emoji: string | null }): JSX.Element => {
  return (
    <div
      className={`flex h-7 w-7 items-center justify-center rounded bg-opacity-90 p-0_5 text-lg hover:bg-violet-300 dark:hover:bg-violet-900`}
    >
      {emoji || (
        <Smile
          className="hidden text-violet-400 group-hover:block dark:text-violet-200"
          size="16"
        />
      )}
    </div>
  );
};

export default EmojiDisplay;
