import { AlertTriangle, HelpCircle } from 'lucide-react';
import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import { useUnauthenticatedRoute } from '../../hooks/use-authenticated-route';

import { MINIMUM_PASSWORD_LENGTH } from './fields/utils';
import { AuthLayout } from './AuthLayout';
import { Email, Password, PasswordConfirmation } from './fields';

export type EmailErrors = Array<'account_already_exists' | 'invalid_email'>;
export type PasswordErrors = Array<'not_matching' | 'too_short'>;
export type InvitationCodeErrors = Array<'invalid_code'>;

interface SignUpSuccessResponse {
  code: 'success';
  message: string;
  status: 200;
}

interface SignUpFailureResponse {
  code: 'invalid_code' | 'not_matching' | 'account_already_exists' | 'error';
  message: string;
  status: 401;
}

interface Errors {
  email: EmailErrors;
  password: PasswordErrors;
  passwordConfirmation: PasswordErrors;
  invitationCode: InvitationCodeErrors;
}

type SignUpResponse = SignUpSuccessResponse | SignUpFailureResponse;

export const SignUp = (): JSX.Element => {
  const params = useParams<{ code: string }>();

  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [invitationCode, setInvitationCode] = useState(params.code);

  const [errors, setErrors] = useState<Errors>({
    email: [],
    password: [],
    passwordConfirmation: [],
    invitationCode: [],
  });

  useUnauthenticatedRoute();

  const signUpUser = async ({
    email,
    password,
    passwordConfirmation,
    invitationCode,
  }: {
    email: string;
    password: string;
    passwordConfirmation: string;
    invitationCode: string;
  }): Promise<SignUpResponse> => {
    return await fetch('/api/auth/sign_up', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        password,
        password_confirmation: passwordConfirmation,
        invitation_code: invitationCode,
      }),
    }).then(async (response) => await response.json());
  };

  const onSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    if (password.length < MINIMUM_PASSWORD_LENGTH) {
      setErrors({
        email: [],
        password: ['too_short'],
        passwordConfirmation: [],
        invitationCode: [],
      });

      return;
    }

    if (password !== passwordConfirmation) {
      setErrors({
        email: [],
        password: ['not_matching'],
        passwordConfirmation: ['not_matching'],
        invitationCode: [],
      });

      return;
    }

    if (passwordConfirmation.length < MINIMUM_PASSWORD_LENGTH) {
      setErrors({
        email: [],
        password: [],
        passwordConfirmation: ['too_short'],
        invitationCode: [],
      });

      return;
    }

    await signUpUser({
      email,
      password,
      passwordConfirmation,
      invitationCode,
    }).then((data) => {
      if (data.code === 'success') {
        history.push('/login');
      } else if (data.code === 'invalid_code') {
        setErrors({
          email: [],
          password: [],
          passwordConfirmation: [],
          invitationCode: ['invalid_code'],
        });
      } else if (data.code === 'account_already_exists') {
        setErrors({
          email: ['account_already_exists'],
          password: [],
          passwordConfirmation: [],
          invitationCode: [],
        });
      }
    });
  };

  const resetErrors = (): void =>
    setErrors({
      email: [],
      password: [],
      passwordConfirmation: [],
      invitationCode: [],
    });

  return (
    <AuthLayout
      footer={
        <Link to="/login" className="text-violet-200 no-underline">
          Sign in
        </Link>
      }
    >
      <form
        onSubmit={onSubmit}
        className="my-9 flex w-full sm:w-[485px] flex-col items-center rounded-lg border border-solid border-violet-200 border-opacity-20 bg-slate-900 bg-opacity-60 p-10 shadow-auth-box"
      >
        <h1 className="mt-0 mb-8 text-2xl font-semibold text-violet-300 drop-shadow-auth-header">
          Sign up for Blips
        </h1>

        <div className="w-full mb-6">
          <Email
            errors={errors.email}
            email={email}
            onChange={(event) => {
              setEmail(event.target.value);
              resetErrors();
            }}
          />
        </div>

        <div className="w-full mb-6">
          <Password
            errors={errors.password}
            password={password}
            onChange={(event) => {
              setPassword(event.target.value);
              resetErrors();
            }}
          />
        </div>

        <div className="w-full mb-6">
          <PasswordConfirmation
            errors={errors.passwordConfirmation}
            onChange={(event) => {
              setPasswordConfirmation(event.target.value);
              resetErrors();
            }}
            password={password}
            passwordConfirmation={passwordConfirmation}
          />
        </div>

        <div className="w-full mb-6">
          <div className="mb-2 flex items-center justify-between">
            <label htmlFor="invitationCode" className="text-violet-200">
              Invitation code
            </label>

            {errors.invitationCode.includes('invalid_code') && (
              <span className="text-xs text-red-500">
                The invitation code you provided is not valid.
              </span>
            )}
          </div>

          <div className="mt-2 flex w-full rounded border border-solid border-violet-500 bg-gray-900">
            <label
              htmlFor="invitationCode"
              className="flex cursor-pointer items-center justify-center p-4"
            >
              <>
                {errors.invitationCode.length > 0 ? (
                  <AlertTriangle
                    size={20}
                    className="text-red-500 drop-shadow-auth-icon-error"
                  />
                ) : (
                  <HelpCircle
                    size={20}
                    className="text-violet-600 drop-shadow-auth-icon"
                  />
                )}
              </>
            </label>

            <input
              id="invitationCode"
              name="invitationCode"
              value={invitationCode}
              onChange={(event) => {
                setInvitationCode(event.target.value);
                resetErrors();
              }}
              className="w-full border-0 border-l border-solid border-violet-500 bg-gray-900 bg-transparent p-3 text-lg font-semibold text-white transition-all focus:bg-violet-900 focus:bg-opacity-20 focus:outline-none"
            />
          </div>
        </div>

        <button
          type="submit"
          disabled={
            !email || !password || !passwordConfirmation || !invitationCode
          }
          className="mt-12 flex w-[292px] cursor-pointer items-center justify-center rounded-lg border border-solid border-violet-500 bg-violet-900 bg-opacity-50 py-3 text-base font-semibold text-white transition-all hover:drop-shadow-auth-icon"
        >
          Sign up
        </button>
      </form>
    </AuthLayout>
  );
};
