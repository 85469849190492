import startOfDay from 'date-fns/startOfDay';
import subMinutes from 'date-fns/subMinutes';
import { DateTime, Settings } from 'luxon';
import React, { useMemo } from 'react';
import { Calendar, luxonLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { useSelector } from 'react-redux';

import {
  useCreateTaskScheduleMutation,
  useDeleteTaskScheduleMutation,
  useTaskSchedulesQuery,
  useUpdateTaskScheduleMutation,
} from '../../graphql/generated-types';
import { BetaState } from '../../reducers/beta-types';

import { Event } from './Event';
import { EventContainerWrapper } from './EventContainerWrapper';
import { CalendarEvent } from './types';

import './DayCalendar.css';

const DragAndDropCalendar = withDragAndDrop<CalendarEvent>(Calendar);

export const DayCalendar = () => {
  const { dayCalendarZoomLevel, selectedDate, selectedTasks, timeZone } =
    useSelector((state: BetaState) => ({
      dayCalendarZoomLevel: state.dayCalendarZoomLevel,
      selectedDate: state.selectedDate,
      selectedTasks: state.selectedTasks,
      timeZone: state.timeZone,
    }));

  const [taskSchedulesData] = useTaskSchedulesQuery({
    variables: {
      date: selectedDate ?? startOfDay(new Date()).toISOString(),
    },
    requestPolicy: 'network-only',
  });

  const [, createTaskSchedule] = useCreateTaskScheduleMutation();
  const [, deleteTaskSchedule] = useDeleteTaskScheduleMutation();
  const [, updateTaskSchedule] = useUpdateTaskScheduleMutation();

  const events: CalendarEvent[] = (
    taskSchedulesData.data?.taskSchedules || []
  ).map((taskSchedule) => ({
    id: taskSchedule.id,
    title: taskSchedule.task.name,
    start: new Date(taskSchedule.startAt),
    end: new Date(taskSchedule.endAt),
  }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const components: any = useMemo(
    () => ({
      event: Event,
      dayColumnWrapper: EventContainerWrapper,
      toolbar: () => null,
    }),
    []
  );

  const { defaultDate, getNow, calendarEvents, localizer, scrollToTime } =
    useMemo(() => {
      Settings.defaultZone = timeZone;
      return {
        defaultDate: new Date(),
        getNow: () => DateTime.local().toJSDate(),
        localizer: luxonLocalizer(DateTime),
        calendarEvents: [...events],
        scrollToTime: subMinutes(DateTime.local().toJSDate(), 30),
      };
    }, [events, timeZone]);

  return (
    <DragAndDropCalendar
      components={components}
      onDropFromOutside={async (event) => {
        await createTaskSchedule({
          taskId: selectedTasks[0],
          startAt: Math.floor(new Date(event.start).getTime() / 1000),
          endAt: Math.floor(new Date(event.end).getTime() / 1000),
        });
      }}
      onEventDrop={async ({ event, start, end }) => {
        await updateTaskSchedule({
          id: event.id,
          startAt: Math.floor(new Date(start).getTime() / 1000),
          endAt: Math.floor(new Date(end).getTime() / 1000),
        });
      }}
      onEventResize={async ({ event, start, end }) => {
        await updateTaskSchedule({
          id: event.id,
          startAt: Math.floor(new Date(start).getTime() / 1000),
          endAt: Math.floor(new Date(end).getTime() / 1000),
        });
      }}
      onDoubleClickEvent={async (event) =>
        await deleteTaskSchedule({ id: event.id })
      }
      onView={() => null}
      view="day"
      views={['day']}
      events={calendarEvents}
      defaultDate={defaultDate}
      getNow={getNow}
      step={5}
      selectable
      timeslots={12}
      localizer={localizer}
      scrollToTime={scrollToTime}
      enableAutoScroll
      dayLayoutAlgorithm="no-overlap"
      slotPropGetter={() => ({
        style: {
          height: `${dayCalendarZoomLevel * 2}px`,
        },
        className: 'transition-all duration-50 ease-in-out',
      })}
    />
  );
};
