import TASK_FRAGMENT, { Task } from './task-fragment';

export interface PersistTaskOrderData {
  persistTaskOrder: Task[];
}

const PERSIST_TASK_ORDER_MUTATION = `
  mutation persistTaskOrder($taskOrder: [OrderInput!]) {
    persistTaskOrder(taskOrder: $taskOrder) {
      ${TASK_FRAGMENT}
    }
  }
`;

export default PERSIST_TASK_ORDER_MUTATION;
