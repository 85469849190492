import React, { FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useCreateNoteMutation } from '../../graphql/generated-types';
import { storeNotesFromServer } from '../../reducers/actions';

import { CreatorForm } from './CreatorForm';
import { CreatorFormProps } from './CreatorModal';

export const NoteCreatorForm = (
  props: CreatorFormProps & {
    handleModalChange: (isOpen: boolean) => void;
  }
): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { handleModalChange, itemName } = props;

  const [, createNote] = useCreateNoteMutation();

  const onCreateNote = async (event?: FormEvent): Promise<void> => {
    event?.preventDefault();

    void createNote({
      name: itemName,
    }).then(({ data }) => {
      if (data?.createNote) {
        dispatch(storeNotesFromServer({ notes: [data.createNote] }));

        history.push(`/notes/${data.createNote.id}`);
      }
    });

    handleModalChange(false);
  };

  return (
    <CreatorForm
      {...props}
      allowCreateMultiple={false}
      onCreate={onCreateNote}
      placeholder="Note title"
      submitButtonText="Create note"
    />
  );
};
