export interface Project {
  id: string;
  endDate: string | null;
  date: string | null;
  name: string;
  order: number | null;
  projectColumn: {
    board: {
      id: string;
      name: string;
    };
  };
  springEnabled: boolean;
  tasks: {
    completedCount: number;
    totalCount: number;
  };
}

const PROJECT_FRAGMENT = `
  id
  endDate
  date
  name
  order
  projectColumn {
    board {
      id
      name
    }
  }
  springEnabled
  tasks {
    completedCount
    totalCount
  }
`;

export default PROJECT_FRAGMENT;
