import React from 'react';
import { ClipLoader } from 'react-spinners';

const ContainerHeader = ({
  fetching,
  rightControls,
  title,
}: {
  fetching: boolean;
  rightControls?: React.ReactNode;
  title: React.ReactNode | null;
}): JSX.Element => {
  return (
    <div
      className={
        'fixed inset-x-0 top-0 flex h-18 w-full select-none items-center border-0 border-b border-solid border-gray-300 bg-white !bg-opacity-70 z-map-container-header dark:border-violet-900 dark:bg-mauve-dark-2 md:sticky'
      }
      style={{
        WebkitBackdropFilter: 'blur(12px)',
        backdropFilter: 'blur(12px)',
        boxShadow: '0px 0px 2px rgba(133, 133, 133, 0.32)',
      }}
    >
      <div className="flex w-full items-center justify-between px-4 md:px-8">
        <h2 className="my-0 flex items-center text-2xl font-semibold text-violet-500 dark:text-violet-400">
          {title}

          {fetching && (
            <div data-testid="header-loading-spinner" className="ml-2">
              <ClipLoader color="#4C1D95" size="16px" />
            </div>
          )}
        </h2>

        {rightControls}
      </div>
    </div>
  );
};

export default ContainerHeader;
