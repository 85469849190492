import React from 'react';

export enum ButtonColor {
  Danger,
  Gray,
  Primary,
  Secondary,
}

export enum ButtonSize {
  Small,
  Medium,
}

export enum ButtonWeight {
  Bold,
  Regular,
  Semibold,
}

export enum ButtonWidth {
  Fit,
  Full,
}

const Button = ({
  children,
  color = ButtonColor.Gray,
  disabled,
  onClick,
  size = ButtonSize.Medium,
  type = 'button',
  weight = ButtonWeight.Semibold,
  width = ButtonWidth.Fit,
}: {
  children: React.ReactNode;
  color?: ButtonColor;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => any;
  size?: ButtonSize;
  type?: 'button' | 'submit' | 'reset';
  weight?: ButtonWeight;
  width?: ButtonWidth;
}): JSX.Element => {
  return (
    <button
      className={[
        buttonColorClasses(color),
        buttonSizeClasses(size),
        buttonWeightClass(weight),
        buttonWidthClass(width),
        'border-none',
        'cursor-pointer',
        'focus:outline-none',
        'flex',
        'items-center',
        'gap-1',
        'focus:ring-offset-1',
        'focus:ring-2',
        'focus:ring-violet-500',
        'rounded',
        'transition',
      ].join(' ')}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

const buttonColorClasses = (color: ButtonColor): string => {
  switch (color) {
    case ButtonColor.Danger:
      return 'active:bg-red-300 bg-red-600 disabled:bg-red-300 text-white';
    case ButtonColor.Secondary:
      return 'active:bg-violet-300 bg-violet-200 disabled:bg-gray-300 text-violet-600';
    case ButtonColor.Primary:
      return 'active:bg-violet-700 bg-violet-600 disabled:bg-violet-300 dark:disabled:bg-violet-400 text-white dark:disabled:opacity-50';
    default:
      return 'active:bg-gray-200 dark:active:bg-gray-900 bg-gray-100 dark:bg-gray-800 text-violet-600 dark:text-gray-200';
  }
};

const buttonSizeClasses = (size: ButtonSize): string => {
  switch (size) {
    case ButtonSize.Small:
      return 'px-2 py-2';
    default:
      return 'px-4 py-3';
  }
};

const buttonWeightClass = (weight: ButtonWeight): string => {
  switch (weight) {
    case ButtonWeight.Regular:
      return 'font-normal';
    case ButtonWeight.Semibold:
      return 'font-semibold';
    case ButtonWeight.Bold:
      return 'font-bold';
    default:
      return 'font-normal';
  }
};

const buttonWidthClass = (width: ButtonWidth): string =>
  width === ButtonWidth.Fit ? 'max-content' : 'w-full';

export default Button;
