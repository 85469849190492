import { CalendarClock } from 'lucide-react';
import { Calendar, Flag, Link, Repeat, Trash } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';

import { TaskFragment } from '../../graphql/generated-types';
import { Task } from '../../types';
import { timeToLuxon } from '../../utils/date';
import { Option } from '../BetaTask';
import ActionButton from '../library/ActionButton';
import ProjectSelector from '../ProjectSelector';
import Checkbox from '../shared/Checkbox';

import TaskRepeatModal from './TaskRepeatModal';
import { dueDateIconColor, formattedDate, formattedDueDate } from './utils';

import './Description.css';

const FocusedTask = ({
  nameRef,
  onCompletionToggled,
  onDeleteTask,
  onOpenDateCalendarModal,
  onOpenDueDateCalendarModal,
  onOpenTaskLinkModal,
  onTogglePriority,
  onUpdateTask,
  task,
  timeZone,
  unfocusTask,
}: {
  nameRef: React.MutableRefObject<string>;
  onCompletionToggled: () => void;
  onDeleteTask: () => void;
  onOpenDateCalendarModal: () => void;
  onOpenDueDateCalendarModal: () => void;
  onOpenTaskLinkModal: () => void;
  onTogglePriority: () => void;
  onUpdateTask: <K extends keyof TaskFragment>(
    key: K,
    value: TaskFragment[K] | Option
  ) => void;
  task: Task;
  timeZone: string;
  unfocusTask: () => void;
}): JSX.Element => {
  // Need to use a ref for name before react-contenteditable is having a hard time with
  // React Hooks. https://github.com/lovasoa/react-contenteditable/issues/161 I was having
  // this issue with Redux too, but so it goes.
  const descriptionRef = useRef<string | null | undefined>(
    task.data.description
  );

  useEffect(() => {
    descriptionRef.current = task.data.description;
  }, [task.data.description]);

  const [taskName, setTaskName] = useState<string>(task.data.name);
  const [repeatModalOpen, setRepeatModalOpen] = useState(false);

  useEffect(() => {
    nameRef.current = taskName;
  }, [taskName]);

  const ref = useRef<HTMLLIElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const saveNameAndDescription = (): void => {
    if (nameRef.current !== task.data.name) {
      onUpdateTask('name', nameRef.current);
    }

    if (descriptionRef.current !== task.data.description) {
      onUpdateTask('description', descriptionRef.current);
    }
  };

  const handleClickOutside = (event: MouseEvent): void => {
    const modalElement = document.getElementsByClassName('rt-DialogOverlay')[0];
    const popoverElement = document.getElementById(
      `task-project-selector-${task.data.id}`
    );
    const taskRepeatRule = document.getElementById('task-repeat-rule-select');

    if (
      (ref.current && event.composedPath().includes(ref.current)) ||
      (modalElement && event.composedPath().includes(modalElement)) ||
      (popoverElement && event.composedPath().includes(popoverElement)) ||
      (taskRepeatRule && event.composedPath().includes(taskRepeatRule)) ||
      (event.target as HTMLElement).tagName === 'BODY'
    ) {
      return;
    }

    if (inputRef.current !== null) {
      inputRef.current.blur();
    }

    unfocusTask();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    return () => {
      saveNameAndDescription();
    };
  }, []);

  return (
    <li ref={ref}>
      <div className="box-border flex border-0 border-l-[3px] border-solid border-purple-600 pl-3 pt-4 md:pr-4">
        <div style={{ marginTop: '0.425rem' }} className="shrink-0">
          <Checkbox
            checked={task.data.completed}
            id={`task-checkbox-${task.data.id}`}
            onChange={onCompletionToggled}
            testId={`focused-task-${task.data.id}`}
          />
        </div>

        <div className="flex w-full flex-col overflow-x-hidden">
          <input
            className="w-full break-words border-0 bg-transparent py-1 px-0 text-lg selection:bg-violet-700 focus:outline-none dark:text-white"
            value={taskName}
            onChange={(event) => {
              setTaskName(event.target.value);
            }}
            onBlur={saveNameAndDescription}
            ref={inputRef}
          />

          <ContentEditable
            html={descriptionRef.current || ''}
            onPaste={(event) => {
              event.preventDefault();

              const text = event.clipboardData.getData('text/plain');
              const temp = document.createElement('div');
              temp.innerHTML = text;

              if (temp.textContent) {
                document.execCommand('insertHTML', false, temp.textContent);
              }
            }}
            placeholder="Description"
            className="mt-2 mb-4 flex-grow cursor-text outline-none"
            onChange={(event) => (descriptionRef.current = event.target.value)}
            onBlur={saveNameAndDescription}
          />

          <div className="flex overflow-x-hidden pb-1">
            <div className="flex gap-2 overflow-x-scroll whitespace-nowrap pb-3 pr-2">
              <ActionButton
                noShrink
                collapseOnMobile={false}
                Icon={Calendar}
                isActive={!!task.data.date}
                onClick={onOpenDateCalendarModal}
                text={
                  task.data.date
                    ? [
                        formattedDate(task.data.date),
                        task.data.startTime
                          ? timeToLuxon({
                              time: task.data.startTime,
                              inputZone: 'utc',
                              outputZone: timeZone,
                            }).toFormat('h:mm a')
                          : null,
                      ]
                        .filter(Boolean)
                        .join(' ')
                    : 'Add Date'
                }
              />

              <ProjectSelector
                activeProject={
                  task.data.project
                    ? {
                        value: task.data.project.id,
                        displayValue: task.data.project.name,
                      }
                    : null
                }
                id={`task-project-selector-${task.data.id}`}
                onChange={(project) => {
                  onUpdateTask('project', project);
                }}
              />

              <ActionButton
                Icon={Link}
                isActive={!!task.data.link}
                onClick={onOpenTaskLinkModal}
              />

              <ActionButton
                Icon={Flag}
                isActive={!!task.data.priorityOrder}
                onClick={onTogglePriority}
              />

              <ActionButton
                noShrink
                collapseOnMobile={false}
                Icon={CalendarClock}
                iconColor={dueDateIconColor(task.data.dueDate)}
                isActive={!!task.data.dueDate}
                onClick={onOpenDueDateCalendarModal}
                text={formattedDueDate(task.data.dueDate)}
              />

              <ActionButton
                Icon={Repeat}
                isActive={task.data.isRecurring}
                onClick={() => setRepeatModalOpen(true)}
              />

              <ActionButton
                Icon={Trash}
                isActive={false}
                onClick={onDeleteTask}
              />
            </div>
          </div>
        </div>
      </div>

      <TaskRepeatModal
        task={task}
        open={repeatModalOpen}
        setIsOpen={setRepeatModalOpen}
      />
    </li>
  );
};

export default FocusedTask;
