import React from 'react';
import { useSelector } from 'react-redux';

import { BetaState } from '../../../reducers/beta-types';
import Content from '../Content';
import Sidebar from '../Sidebar';

import Toolbar from './Toolbar';

const MobileFrame = (): JSX.Element => {
  const { mobileSidebarVisible, mobileToolbarVisible } = useSelector(
    (state: BetaState) => ({
      mobileSidebarVisible: state.mobileSidebarVisible,
      mobileToolbarVisible: state.mobileToolbarVisible,
    })
  );

  return (
    <div className="relative grid h-screen w-screen grid-rows-mobile">
      {mobileSidebarVisible ? <Sidebar /> : <Content />}

      {mobileToolbarVisible && <Toolbar />}
    </div>
  );
};

export default MobileFrame;
