import TASK_FRAGMENT, { Task } from './task-fragment';

export interface TaskWithOrderEdge {
  order: number | null;
  node: Task;
}

export interface Group {
  id: string;
  collapsed: boolean | null;
  date: string | null;
  keepTasks: boolean;
  name: string;
  order: number | null;
  projectId: string | null;
  tasks: {
    edges: TaskWithOrderEdge[];
  };
}

const GROUP_FRAGMENT = `
  id
  collapsed
  date
  keepTasks
  name
  order
  projectId
  tasks {
    edges {
      order
      node {
        ${TASK_FRAGMENT}
      }
    }
  }
`;

export default GROUP_FRAGMENT;
