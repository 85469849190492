import Dexie, { Table } from 'dexie';

import { Board, Group, Project, ProjectColumn, Task } from '../types';

export class BlipsDatabase extends Dexie {
  boards!: Table<Board>;
  groups!: Table<Group>;
  projectColumns!: Table<ProjectColumn>;
  projects!: Table<Project>;
  tasks!: Table<Task>;

  constructor() {
    super('blipsDatabase');

    this.version(1).stores({
      groups: 'data.id, data.date, data.projectId',
      tasks:
        'data.id, data.projectId, data.date, data.dueDate, order.priority, *data.groupIds',
    });

    this.version(2).stores({
      boards: 'data.id',
      groups: 'data.id, data.date, data.projectId',
      tasks:
        'data.id, data.projectId, data.date, data.dueDate, order.priority, *data.groupIds',
    });

    this.version(3).stores({
      boards: 'data.id',
      groups: 'data.id, data.date, data.projectId',
      projects: 'data.id, data.projectColumnId, data.date, data.endDate',
      tasks:
        'data.id, data.projectId, data.date, data.dueDate, order.priority, *data.groupIds',
    });

    this.version(4).stores({
      boards: 'data.id',
      groups: 'data.id, data.date, data.projectId',
      projectColumns: 'data.id, data.board.id',
      projects: 'data.id, data.projectColumnId, data.date, data.endDate',
      tasks:
        'data.id, data.projectId, data.date, data.dueDate, order.priority, *data.groupIds',
    });
  }
}

export const db = new BlipsDatabase();
