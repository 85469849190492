import { Button, Text } from '@radix-ui/themes';
import React from 'react';

import { useDuplicateProjectMutation } from '../../graphql/generated-types';
import { Project } from '../../types';
import Modal from '../Modal';

export const DuplicateProjectModal = ({
  open,
  project,
  setIsOpen,
}: {
  open: boolean;
  project: Project;
  setIsOpen: (open: boolean) => void;
}) => {
  const [, duplicateProject] = useDuplicateProjectMutation();

  const onDuplicateProject = async () => {
    await duplicateProject({
      projectId: project.data.id,
    });

    setIsOpen(false);
  };

  return (
    <Modal open={open} setIsOpen={setIsOpen} title="Duplicate project">
      <div className="flex flex-col px-4 pb-4">
        <Text as="p" size="2" my="2" className="dark:text-gray-200">
          Are you sure you want to duplicate{' '}
          <strong>{project.data.name}</strong>? All tasks will be duplicated and
          the new project will be added to the project&apos;s board.
        </Text>

        <div className="mt-4 flex justify-end gap-2">
          <Button variant="soft" color="gray" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>

          <Button variant="solid" onClick={onDuplicateProject}>
            Duplicate project
          </Button>
        </div>
      </div>
    </Modal>
  );
};
