import { AlertTriangle, Check } from 'lucide-react';
import React from 'react';

import { ProgressIndicator } from '../library/ProgressIndicator';

export const PasswordStrengthIndicator = ({
  matchingPassword,
  minPasswordLength,
  password,
  passwordProgress,
}: {
  matchingPassword?: string;
  minPasswordLength: number;
  password: string;
  passwordProgress: number;
}): JSX.Element => {
  if (
    matchingPassword &&
    matchingPassword.length === password.length &&
    matchingPassword !== password &&
    password.length >= minPasswordLength
  ) {
    return (
      <AlertTriangle
        size={20}
        className="drop-shadow-auth-icon-error text-red-500"
      />
    );
  }

  return (
    <ProgressIndicator
      classes="mr-0.5"
      completed={password.length >= minPasswordLength}
      completionProgress={passwordProgress}
      size="4"
      style={{
        boxShadow: `0px 0px 10px rgba(124, 58, 237, ${passwordProgress / 100})`,
      }}
    >
      {password.length >= minPasswordLength && (
        <div className="flex h-full w-full items-center justify-center rounded-full bg-transparent">
          <Check className="text-white" size="12" strokeWidth={4} />
        </div>
      )}
    </ProgressIndicator>
  );
};
