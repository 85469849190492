import React from 'react';
import { Link } from 'react-router-dom';

export const AuthLayout = ({
  children,
  footer,
}: {
  children: React.ReactNode;
  footer?: JSX.Element;
}): JSX.Element => {
  return (
    <div className="flex min-h-screen bg-gray-900 w-screen items-center justify-center bg-purple-dark-gradient">
      <div className="flex flex-col items-center gap-2">
        <Link to="/">
          <img
            src="https://dtqddwmz0j63d.cloudfront.net/header-logo.png"
            className="w-[120px]"
          />
        </Link>

        {children}
        {footer}
      </div>
    </div>
  );
};
