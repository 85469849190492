import React, { useState } from 'react';
import { useParams } from 'react-router';

import { MINIMUM_PASSWORD_LENGTH } from './fields/utils';
import { AuthLayout } from './AuthLayout';
import { Password, PasswordConfirmation } from './fields';

interface PasswordResetSuccessResponse {
  code: 'success';
  message: string;
  status: 200;
}

interface PasswordResetFailureResponse {
  code: 'not_matching' | 'token_expired';
  message: string;
  status: 401;
}

type PasswordResetResponse =
  | PasswordResetSuccessResponse
  | PasswordResetFailureResponse;

export type PasswordErrors = Array<
  'not_matching' | 'token_expired' | 'too_short'
>;

interface Errors {
  password: PasswordErrors;
  passwordConfirmation: PasswordErrors;
}

export const PasswordReset = (): JSX.Element => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const params = useParams<{ token: string }>();

  const [errors, setErrors] = useState<Errors>({
    password: [],
    passwordConfirmation: [],
  });

  const resetPassword = async ({
    password,
    passwordConfirmation,
    token,
  }: {
    password: string;
    passwordConfirmation: string;
    token: string;
  }): Promise<PasswordResetResponse> => {
    return await fetch('/api/auth/reset_password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        password,
        password_confirmation: passwordConfirmation,
        token,
      }),
    }).then(async (response) => await response.json());
  };

  const onSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    if (password.length < MINIMUM_PASSWORD_LENGTH) {
      setErrors({
        password: ['too_short'],
        passwordConfirmation: [],
      });

      return;
    }

    if (password !== passwordConfirmation) {
      setErrors({
        password: ['not_matching'],
        passwordConfirmation: ['not_matching'],
      });

      return;
    }

    if (passwordConfirmation.length < MINIMUM_PASSWORD_LENGTH) {
      setErrors({
        password: [],
        passwordConfirmation: ['too_short'],
      });

      return;
    }

    await resetPassword({
      password,
      passwordConfirmation,
      token: params.token,
    }).then((data) => {
      if (data.code === 'success') {
        window.location.href = '/login';
      }

      if (data.code === 'not_matching') {
        setErrors({
          password: ['not_matching'],
          passwordConfirmation: ['not_matching'],
        });
      }

      if (data.code === 'token_expired') {
        setErrors({
          password: ['token_expired'],
          passwordConfirmation: ['token_expired'],
        });
      }
    });
  };

  return (
    <AuthLayout>
      <form
        onSubmit={onSubmit}
        className="my-9 flex w-full sm:w-[485px] flex-col items-center rounded-lg border border-solid border-violet-200 border-opacity-20 bg-slate-900 bg-opacity-60 p-10 shadow-auth-box"
      >
        <h1 className="mt-0 mb-6 text-2xl font-semibold text-violet-300 drop-shadow-auth-header">
          Reset your password
        </h1>

        <div className="w-full mb-6">
          <Password
            errors={errors.password}
            onChange={(event) => {
              setPassword(event.target.value);
              setErrors({ password: [], passwordConfirmation: [] });
            }}
            password={password}
          />
        </div>

        <div className="w-full">
          <PasswordConfirmation
            errors={errors.passwordConfirmation}
            onChange={(event) => {
              setPasswordConfirmation(event.target.value);
              setErrors({ password: [], passwordConfirmation: [] });
            }}
            password={password}
            passwordConfirmation={passwordConfirmation}
          />
        </div>

        <button
          type="submit"
          disabled={!password || !passwordConfirmation}
          className="mt-12 flex w-[292px] cursor-pointer items-center justify-center rounded-lg border border-solid border-violet-500 bg-violet-900 bg-opacity-50 py-3 text-base font-semibold text-white transition-all hover:drop-shadow-auth-icon"
        >
          Reset password
        </button>
      </form>
    </AuthLayout>
  );
};
