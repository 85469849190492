import classNames from 'classnames';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import omit from 'lodash/omit';
import { AlertTriangle, AlignLeft, Clock } from 'lucide-react';
import { Flag, Link as LinkIcon, Repeat, Zap } from 'lucide-react';
import React, { memo, MouseEvent, MutableRefObject } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { setSelectedTasks } from '../../reducers/actions';
import { Task } from '../../types';
import { dateInUtc, pathForDate, timeToLuxon } from '../../utils/date';
import Tooltip from '../library/Tooltip';
import Checkbox from '../shared/Checkbox';

import { dueDateIconColor, formattedDate, formattedDueDate } from './utils';

interface TaskHeaderProps {
  focusTask: () => void;
  isBeingSwiped: boolean;
  isDragging: boolean;
  isGhosting: boolean;
  isHoveringTaskDropzone: boolean;
  isNextTaskSelected: boolean;
  isPreviousTaskSelected: boolean;
  isSelected: boolean;
  multiselectCount: number;
  nameRef: React.MutableRefObject<string>;
  onClickTaskHeader: (
    id: string,
    taskLocation: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: MouseEvent<HTMLElement, any>
  ) => void;
  onCompletionToggled: () => void;
  parentId: string;
  selectedDate?: string | null;
  selectedProject?: string | null;
  showDate: boolean;
  showPriority: boolean;
  showProject: boolean;
  showStartTime: boolean;
  task: Task;
  taskLocation: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  taskRef: MutableRefObject<any>;
  textClass: string;
  timeZone: string;
}

const TaskHeader = ({
  focusTask,
  isBeingSwiped,
  isDragging,
  isGhosting,
  isHoveringTaskDropzone,
  isNextTaskSelected,
  isPreviousTaskSelected,
  isSelected,
  multiselectCount,
  nameRef,
  onClickTaskHeader,
  onCompletionToggled,
  parentId,
  selectedDate,
  selectedProject,
  showDate,
  showPriority,
  showProject,
  showStartTime,
  task,
  taskLocation,
  taskRef,
  textClass,
  timeZone,
}: TaskHeaderProps): JSX.Element => {
  const dispatch = useDispatch();

  const testId = task.data.completed
    ? 'completed-task-checkbox'
    : 'uncompleted-task-checkbox';

  const isOverCalendar =
    isHoveringTaskDropzone && isDragging && isSelected && !isGhosting;
  const displayPriority = showPriority && !!task.order.priority;
  const displayDate =
    showDate && !isOverCalendar && task.data.date && !selectedDate;
  const displayProject =
    showProject && !isOverCalendar && task.data.project && !selectedProject;

  const DueDateIcon =
    task.data.dueDate &&
    isBefore(dateInUtc(task.data.dueDate), startOfDay(new Date()))
      ? AlertTriangle
      : Clock;

  // Somehow this is getting reversed and I hate it but at least this makes the code readable.
  const isSwiping = !isBeingSwiped;

  return (
    <li
      id={`task-header-${task.data.id}`}
      className={classNames(
        'cursor-grab',
        'duration-200',
        'relative',
        'select-none',
        'transition-transform',
        {
          'bg-violet-10 dark:bg-violet-dark-4 bg-opacity-80 dark:text-violet-300':
            isSelected && !isGhosting,
          'hover-hover:hover:bg-gray-200 hover-hover:hover:bg-opacity-30 hover-hover:dark:hover:bg-mauve-dark-4 hover-hover:dark:hover:bg-opacity-30':
            !isSelected && showPriority,
          'hover:bg-violet-500 hover:bg-opacity-20':
            !isSelected && !showPriority,
          '!bg-violet-8 dark:!bg-violet-dark-5': isSelected && !showPriority,
          '!bg-opacity-100': isSwiping,
          'rounded-lg': isDragging && !isGhosting,
          'w-1/4 animate-shrink text-xs': isOverCalendar,
          'opacity-25': isGhosting,
        }
      )}
      ref={taskRef}
      key={`group-${parentId}-task-${task.data.id}`}
      onClick={(event) => onClickTaskHeader(task.data.id, taskLocation, event)}
      onDoubleClick={() => {
        dispatch(setSelectedTasks([], null));
        focusTask();
      }}
      style={
        isSelected && isDragging
          ? { WebkitBackdropFilter: 'blur(4px)', backdropFilter: 'blur(4px)' }
          : undefined
      }
    >
      {displayPriority && !task.data.completed && !(isDragging && isSelected) && (
        <div
          className={classNames('absolute left-0 top-0 h-full w-[3px]', {
            'bg-teal-500': !isSelected,
            'bg-teal-300': isSelected,
          })}
        />
      )}

      <div
        className={classNames(
          'flex items-center border-0 border-t border-b border-solid border-transparent px-4',
          displayDate || displayProject ? 'py-1' : 'py-2 md:py-1_5',
          {
            '!border-t-opacity-10 !border-t-violet-10 dark:!border-t-violet-dark-10':
              isSelected && !isGhosting && !isPreviousTaskSelected,
            '!border-b-opacity-10 !border-b-violet-10 dark:!border-b-violet-dark-10':
              isSelected && !isGhosting && !isNextTaskSelected,
            'rounded-lg': isSelected && isDragging && !isGhosting,
          }
        )}
      >
        <Checkbox
          checked={task.data.completed}
          id={`task-checkbox-${task.data.id}`}
          onChange={onCompletionToggled}
          testId={testId}
        />

        <div className="flex grow flex-col justify-between">
          {(displayDate || displayProject) && (
            <div className="flex flex-row items-center">
              {displayDate && (
                <Link
                  to={pathForDate(dateInUtc(task.data.date))}
                  className={classNames(
                    'flex',
                    'font-medium',
                    'items-center',
                    'mr-1.5',
                    'no-underline',
                    'text-xs',
                    {
                      'text-violet-600 dark:text-violet-300': !isSelected,
                      'text-violet-100': isSelected,
                    }
                  )}
                >
                  {formattedDate(task.data.date)}
                </Link>
              )}

              {task.data.spring && (
                <Zap
                  className={classNames('mr-1.5', {
                    'text-violet-600 dark:text-violet-300': !isSelected,
                    'text-violet-200': isSelected,
                  })}
                  data-testid="spring-icon"
                  size={14}
                  strokeWidth={2.5}
                />
              )}

              {displayProject && (
                <Link
                  to={`/projects/${task.data.project?.id}`}
                  className={classNames(
                    'font-medium',
                    'mr-1.5',
                    'items-center',
                    'no-underline',
                    'line-clamp-1',
                    'text-xs',
                    {
                      'text-violet-600 dark:text-violet-300': !isSelected,
                      'text-violet-100': isSelected,
                    }
                  )}
                >
                  {task.data.project?.name}
                </Link>
              )}
            </div>
          )}

          <span
            className={classNames(
              'flex',
              'items-center',
              'leading-6',
              'justify-between',
              textClass,
              {
                '!text-white': isSelected && !isGhosting,
                'dark:text-white': !task.data.completed,
                'text-gray-500 dark:text-gray-400': task.data.completed,
                'text-lg md:text-base': !isOverCalendar,
                'text-xs line-clamp-1': isOverCalendar,
              }
            )}
            style={{
              wordBreak: 'break-word',
            }}
          >
            <div>
              <span className="inline-flex gap-1 items-center">
                {showStartTime && (
                  <>
                    <strong
                      className={classNames('font-semibold text-base ', {
                        'dark:text-violet-300 text-violet-600': !isSelected,
                        'text-violet-300': isSelected,
                      })}
                    >
                      {timeToLuxon({
                        time: task.data.startTime,
                        outputZone: timeZone,
                      }).toFormat('h:mm a')}
                    </strong>
                  </>
                )}
                {nameRef.current}
              </span>

              {task.data.dueDate && !task.data.completedAt && (
                <Tooltip
                  side="right"
                  text={formattedDueDate(task.data.dueDate)}
                  textSize="sm"
                >
                  <DueDateIcon
                    className={classNames('ml-1.5 shrink-0 cursor-default', {
                      'text-violet-600 dark:text-violet-300':
                        !isSelected && !dueDateIconColor(task.data.dueDate),
                      'text-violet-200': isSelected,
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                      [dueDateIconColor(task.data.dueDate) as string]:
                        !!dueDateIconColor(task.data.dueDate) && !isSelected,
                    })}
                    data-testid="link-icon"
                    size={14}
                    strokeWidth={2.5}
                  />
                </Tooltip>
              )}

              {displayPriority && isSelected && (
                <Flag
                  className={classNames('ml-1.5 shrink-0', {
                    'text-violet-600 dark:text-violet-300': !isSelected,
                    'text-violet-200': isSelected,
                  })}
                  size={14}
                  strokeWidth={2.5}
                />
              )}

              {task.data.isRecurring && isSelected && (
                <Repeat
                  className={classNames('ml-1.5 shrink-0', {
                    'text-violet-600 dark:text-violet-300': !isSelected,
                    'text-violet-200': isSelected,
                  })}
                  size={14}
                  strokeWidth={2.5}
                />
              )}

              {task.data.description && (
                <Tooltip side="right" text="Task description" textSize="sm">
                  <AlignLeft
                    className={classNames('ml-1.5 shrink-0 cursor-pointer', {
                      'text-violet-600 dark:text-violet-300': !isSelected,
                      'text-violet-200': isSelected,
                    })}
                    onClick={focusTask}
                    size={14}
                    strokeWidth={2.5}
                  />
                </Tooltip>
              )}
            </div>

            <div className="flex shrink-0">
              {task.data.link && (
                <a
                  href={task.data.link}
                  target="blank"
                  className="flex items-center"
                >
                  <LinkIcon
                    className={classNames('ml-1.5', {
                      'text-violet-600 dark:text-violet-300': !isSelected,
                      'text-violet-200': isSelected,
                    })}
                    data-testid="link-icon"
                    size={14}
                    strokeWidth={2.5}
                  />
                </a>
              )}
            </div>
          </span>
        </div>

        {multiselectCount > 1 && isSelected && !isGhosting && isDragging && (
          <div className="absolute -right-2 -bottom-2 rounded-full border-2 border-solid border-violet-500 bg-white px-2 py-1 text-xs text-violet-500">
            {multiselectCount}
          </div>
        )}
        <div></div>
      </div>
    </li>
  );
};

// This component can really hamper app performance if there are too many re-renders.
// This will only re-render the component with prop updates we actually care about in
// normal application usage.
const compareProps = (
  prevProps: TaskHeaderProps,
  nextProps: TaskHeaderProps
): boolean => {
  const omitKeys = [
    'focusTask',
    'multiselectCount',
    'onClickTaskHeader',
    'onCompletionToggled',
    'onTogglePriority',
  ];

  const filteredPrevProps = omit(prevProps, omitKeys);
  const filteredNextProps = omit(nextProps, omitKeys);

  return isEqual(filteredPrevProps, filteredNextProps);
};

export default memo(TaskHeader, compareProps);
