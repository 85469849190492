import GROUP_FRAGMENT, { Group } from './group-fragment';

export interface PersistGroupOrderData {
  persistGroupOrder: Group[];
}

const PERSIST_GROUP_ORDER_MUTATION = `
  mutation persistGroupOrder($order: [OrderInput!]) {
    persistGroupOrder(order: $order) {
      ${GROUP_FRAGMENT}
    }
  }
`;

export default PERSIST_GROUP_ORDER_MUTATION;
