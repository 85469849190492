/* eslint-disable react/no-unescaped-entities */
import { motion } from 'framer-motion';
import { Calendar, CornerLeftDown, Moon, Repeat, Zap } from 'lucide-react';
import React, { useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link, useHistory } from 'react-router-dom';

import { TaglineAction } from './TaglineAction';

import './MarketingSite.css';

const fadeInVariants = {
  hidden: {
    opacity: 0,
    top: 500,
  },
  visible: {
    opacity: 1,
    top: 0,
  },
};

const imageFadeInVariants = {
  hidden: {
    opacity: 0,
    top: 500,
  },
  visible: {
    opacity: 1,
    top: 0,
  },
};

export const MarketingSite = (): JSX.Element => {
  const waitlistHeadingRef = useRef<HTMLHeadingElement>(null);
  const waitlistInputRef = useRef<HTMLInputElement>(null);

  const history = useHistory();

  const onSubmitWaitlist = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    await fetch('/waitlist_users', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        waitlist_user: { email: e.currentTarget.email.value },
      }),
    }).then((response) => {
      if (response.ok) {
        history.push('/waitlist-joined');
      } else {
        alert('There was an error with your email address. Try again!');
      }
    });
  };

  return (
    <div
      className="h-screen w-screen overflow-x-clip overflow-y-scroll"
      style={{ background: '#131928' }}
    >
      <div className="page-background w-full">
        <div className="header-shield">
          <div className="flex justify-between p-4 md:p-12">
            <div className="flex items-center gap-2">
              <img
                src="https://dtqddwmz0j63d.cloudfront.net/header-logo.png"
                className="purple-neon w-11 md:w-[88px]"
              />

              <h1 className="m-0 text-xl md:text-4xl font-bold text-white">
                Blips Planner
              </h1>
            </div>

            <div className="flex items-center gap-7">
              <Link
                to="/login"
                className="text-base md:text-lg text-white no-underline"
              >
                Sign In
              </Link>

              <button
                onClick={() => {
                  waitlistHeadingRef.current?.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth',
                  });

                  waitlistInputRef.current?.focus();
                }}
                className="cursor-pointer rounded border-0 bg-[#A855F7] py-1 px-4 text-lg hidden sm:block text-white"
              >
                Join Waitlist
              </button>
            </div>
          </div>

          <motion.div
            className="relative z-10 flex justify-center"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={fadeInVariants}
            transition={{
              delay: 0.7,
              opacity: { duration: 1.5 },
              top: { duration: 0.8 },
            }}
          >
            <div className="mt-8 flex flex-col">
              <div className="mb-5 flex flex-col items-center md:flex-row gap-0 md:gap-4">
                <h2 className="tagline-1 m-0 inline font-bold text-5xl md:text-6xl">
                  Everything on
                </h2>
                <h2 className="tagline-2 m-0 inline font-bold text-5xl md:text-6xl">
                  your radar
                </h2>
              </div>

              <div className="flex justify-start">
                <span className="text-2xl md:text-4xl flex flex-col md:flex-row md:gap-2 items-center w-full font-bold text-white">
                  Track everything you're <TaglineAction />
                </span>
              </div>
            </div>
          </motion.div>
        </div>

        <div className="flex justify-center">
          <motion.img
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={imageFadeInVariants}
            whileHover={{ scale: 1.01 }}
            className="relative z-20 w-11/12 aspect-[1103/723] md:w-10/12 mt-8 mb-[140px] md:mb-[100px] max-w-[820px] md:mt-20"
            transition={{ opacity: { duration: 1 }, top: { duration: 0.8 } }}
            src="https://dtqddwmz0j63d.cloudfront.net/hero.png"
          />
        </div>
      </div>

      <div className="main-section-container z-30">
        <div className="main-section relative z-40 flex flex-col items-center">
          <div className="flex w-full max-w-[820px] flex-col border-0 border-b border-solid border-violet-500 border-opacity-30 pb-20">
            <div className="relative left-[-30px] mb-7 flex justify-center gap-2">
              <CornerLeftDown size={32} className="mt-4 text-white" />
              <h3
                ref={waitlistHeadingRef}
                className="m-0 text-3xl font-semibold text-white"
              >
                Be the first in line
              </h3>
            </div>

            <form
              onSubmit={onSubmitWaitlist}
              className="mb-16 md:mb-30 flex gap-1.5 mx-4"
            >
              <input
                ref={waitlistInputRef}
                type="text"
                className="border-1 purple-input grow rounded-lg border border-solid border-violet-500 bg-transparent py-2 px-5 text-lg md:text-2xl text-white placeholder:text-white placeholder:text-opacity-75 focus:outline-none"
                placeholder="Enter your email"
                id="name"
                name="email"
              />

              <button
                type="submit"
                className="purple-cta-button cursor-pointer flex-nowrap rounded-lg border text-base border-solid border-violet-500 bg-violet-500 bg-opacity-20 px-2 md:px-4 md:text-2xl text-white transition"
              >
                Join the Waitlist
              </button>
            </form>

            <div className="mb-7 flex gap-7 mx-4 md:mx-0 flex-col-reverse sm:flex-row">
              <Priorities />

              <div className="flex min-w-0 basis-2/3 flex-col items-center justify-end">
                <h2 className="purple-spotlight-text m-0 mb-7 w-full text-center text-3xl sm:text-5xl font-bold">
                  Full of powerful tools
                </h2>

                <Projects />
              </div>
            </div>

            <div className="flex gap-7 flex-col-reverse sm:flex-row mx-4 md:mx-0">
              <Integrations />

              <Boards />
            </div>
          </div>

          <div className="footer-section flex w-full justify-center">
            <div className="flex w-full max-w-[820px] flex-col gap-12 sm:gap-18 pt-20 pb-0 md:py-20">
              <div className="flex flex-col sm:flex-row mx-4 md:mx-0 justify-between gap-12">
                <div className="flex basis-1/2 gap-4">
                  <Calendar
                    size={48}
                    className="purple-neon shrink-0 stroke-1 text-violet-500"
                  />

                  <div className="text-white">
                    <span className="text-violet-500">
                      Drag and drop calendar view.{' '}
                    </span>
                    It’s perfectly OK you’re not going to do that today. Move it
                    to a better date with a flick of the wrist.
                  </div>
                </div>

                <div className="flex basis-1/2 gap-4">
                  <Moon
                    size={48}
                    className="purple-neon shrink-0 stroke-1 text-violet-500"
                  />

                  <div className="text-white">
                    <span className="text-violet-500">Dark mode first. </span>
                    Not an after thought here. Blips looks best at night — but
                    the light mode looks great too.
                  </div>
                </div>
              </div>

              <div className="flex flex-col px-4 md:px-0 sm:flex-row justify-between gap-12">
                <div className="flex basis-1/2 gap-4">
                  <Zap
                    size={48}
                    className="teal-neon shrink-0 stroke-1 text-teal-300"
                  />

                  <div className="text-white">
                    <span className="text-teal-300">Automated projects. </span>
                    Keep one Task from a Project in view at a time. Complete it
                    and the next one will magically appear.
                  </div>
                </div>

                <div className="flex basis-1/2 gap-4">
                  <Repeat
                    size={48}
                    className="teal-neon shrink-0 stroke-1 text-teal-300"
                  />

                  <div className="text-white">
                    <span className="text-teal-300">Recurring tasks. </span>
                    Build your habits and become the sort of person who actually
                    replaces their kitchen sponge on time.
                  </div>
                </div>
              </div>

              <h2 className="teal-spotlight-text m-0 w-full text-center text-3xl sm:text-5xl font-bold">
                Give your brain a break
              </h2>

              <form className="flex gap-1.5 px-4">
                <input
                  type="text"
                  className="teal-input border-1 grow rounded-lg border border-solid border-teal-300 bg-transparent py-2 px-4 md:px-5 text-lg md:text-2xl text-white placeholder:text-white placeholder:text-opacity-75 focus:outline-none"
                  placeholder="Enter your email"
                  id="email"
                  name="email"
                />

                <button
                  type="submit"
                  className="teal-cta-button cursor-pointer rounded-lg border border-solid border-teal-300 bg-teal-300 bg-opacity-20 px-2 md:px-4 text-base md:text-2xl text-white transition"
                >
                  Join the Waitlist
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Boards = (): JSX.Element => {
  const { ref, inView } = useInView({ triggerOnce: false });

  return (
    <div
      ref={ref}
      className="relative flex min-w-0 basis-1/3 flex-col rounded-2xl"
    >
      <div className="p-5">
        <h4 className="m-0 mb-1 text-2xl font-semibold text-white">
          Get on board
        </h4>

        <p className="text-white">
          Stop being overwhelmed by a long to-do list. Separate the work that’s
          important to do first away from the pack.
        </p>
      </div>

      <img
        src="https://dtqddwmz0j63d.cloudfront.net/Boards.png"
        className="rounded-br-2xl pl-2"
      />

      <div
        className="spotlight-cover pointer-events-none absolute inset-0 rounded-2xl border border-solid border-violet-500 border-opacity-30"
        style={{
          backdropFilter: inView ? 'blur(0px)' : 'blur(10px)',
          WebkitBackdropFilter: inView ? 'blur(0px)' : 'blur(10px)',
          transition: 'backdrop-filter 800ms',
        }}
      />
    </div>
  );
};

const Integrations = (): JSX.Element => {
  const { ref, inView } = useInView({ triggerOnce: false });

  return (
    <div
      ref={ref}
      className="flex min-w-0 basis-2/3 flex-col items-center justify-start"
    >
      <div className="relative flex w-full flex-col rounded-2xl">
        <img
          src="https://dtqddwmz0j63d.cloudfront.net/Projects.png"
          className="rounded-t-2xl"
        />

        <div className="p-5">
          <h4 className="m-0 mb-1 text-2xl font-semibold text-white">
            A central hub for your day
          </h4>

          <p className="text-white">
            The things we need to do are fragmented across dozens of apps. Blips
            brings them all together in one place. And we’re always looking to
            integrate more.
          </p>
        </div>

        <div
          className="spotlight-cover pointer-events-none absolute inset-0 rounded-2xl border border-solid border-violet-500 border-opacity-30"
          style={{
            backdropFilter: inView ? 'blur(0px)' : 'blur(10px)',
            WebkitBackdropFilter: inView ? 'blur(0px)' : 'blur(10px)',
            transition: 'backdrop-filter 800ms',
          }}
        />
      </div>
    </div>
  );
};

const Priorities = (): JSX.Element => {
  const { ref, inView } = useInView({ triggerOnce: false });

  return (
    <div
      ref={ref}
      className="relative flex items-end min-w-0 basis-1/3 flex-col rounded-2xl"
    >
      <div className="p-5">
        <h4 className="m-0 mb-1 text-2xl font-semibold text-white">
          Focus on now
        </h4>

        <p className="text-white">
          Stop being overwhelmed by a long to-do list. Separate the work that’s
          important to do first away from the pack.
        </p>
      </div>

      <img
        src="https://dtqddwmz0j63d.cloudfront.net/Priorities.png"
        className="rounded-br-2xl sm:pl-5 w-10/12 sm:w-full"
      />

      <div
        className="spotlight-cover pointer-events-none absolute inset-0 rounded-2xl border border-solid border-violet-500 border-opacity-30"
        style={{
          backdropFilter: inView ? 'blur(0px)' : 'blur(10px)',
          WebkitBackdropFilter: inView ? 'blur(0px)' : 'blur(10px)',
          transition: 'backdrop-filter 800ms',
        }}
      />
    </div>
  );
};

const Projects = (): JSX.Element => {
  const { ref, inView } = useInView({ triggerOnce: false });

  return (
    <div ref={ref} className="relative flex w-full flex-col rounded-2xl">
      <img
        src="https://dtqddwmz0j63d.cloudfront.net/Projects.png"
        className="rounded-t-2xl"
      />

      <div className="p-5">
        <h4 className="m-0 mb-1 text-2xl font-semibold text-white">
          For the bigger things in life
        </h4>

        <p className="text-white">
          Break time consuming endeavors up into small pieces and store them in
          Projects. Save due dates and watch your progression towards your
          goals.
        </p>
      </div>

      <div
        className="spotlight-cover pointer-events-none absolute inset-0 rounded-2xl border border-solid border-violet-500 border-opacity-30"
        style={{
          backdropFilter: inView ? 'blur(0px)' : 'blur(10px)',
          WebkitBackdropFilter: inView ? 'blur(0px)' : 'blur(10px)',
          transition: 'backdrop-filter 800ms',
        }}
      />
    </div>
  );
};
