import {
  Draggable,
  Droppable,
} from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import orderBy from 'lodash/orderBy';
import { Archive, Plus } from 'lucide-react';
import React, { useEffect, useState } from 'react';

import {
  storeBoardsFromServerInDatabase,
  storeBoardsInDatabase,
  storeProjectColumnsFromServerInDatabase,
} from '../../database/actions';
import {
  useArchiveBoardMutation,
  useCreateProjectColumnMutation,
  useUnarchiveBoardMutation,
  useUpdateBoardMutation,
} from '../../graphql/generated-types';
import { ProjectColumn } from '../../types';
import Board from '../../types/Board';
import SettingsSidebar, {
  DeleteButton,
  Label,
  TextInput,
} from '../SettingsSidebar';
import { Dropdown } from '../shared/Dropdown';

import DeleteBoardModal from './DeleteBoardModal';
import { ProjectColumnSetting } from './ProjectColumnSetting';

const BoardSettings = ({
  onClickCloseButton,
  board,
  projectColumns,
}: {
  onClickCloseButton: () => void;
  board: Board;
  projectColumns: ProjectColumn[];
}): JSX.Element | null => {
  if (!board) {
    return null;
  }

  const [, createProjectColumn] = useCreateProjectColumnMutation();
  const [, updateBoard] = useUpdateBoardMutation();

  const [name, setName] = useState<string>(board.data.name);
  const [showNewProjectColumn, setShowNewProjectColumn] = useState(false);

  const [taskCompletedColumnId, setTaskCompletedColumnId] = useState<string>(
    board.data.taskCompletedProjectColumnId || 'no-starting-column'
  );

  const [projectCompletedColumnId, setProjectCompletedColumnId] =
    useState<string>(
      board.data.projectCompletedProjectColumnId || 'no-completed-column'
    );

  const taskCompletedColumnOptions = [
    {
      value: 'no-starting-column',
      label: 'Do nothing',
    },
    ...orderBy(projectColumns, 'data.order').map((projectColumn) => ({
      value: projectColumn.data.id,
      label: `Move project to ${projectColumn.data.name}`,
    })),
  ];

  const projectCompletedColumnOptions = [
    {
      value: 'no-completed-column',
      label: 'Do nothing',
    },
    ...orderBy(projectColumns, 'data.order').map((projectColumn) => ({
      value: projectColumn.data.id,
      label: `Move project to ${projectColumn.data.name}`,
    })),
  ];

  const [, archiveBoard] = useArchiveBoardMutation();
  const [, unarchiveBoard] = useUnarchiveBoardMutation();

  const onArchiveBoard = async (): Promise<void> => {
    await storeBoardsInDatabase([
      { ...board, data: { ...board.data, archivedAt: new Date() } },
    ]);

    const { data } = await archiveBoard({
      boardId: board.data.id,
    });

    if (data?.archiveBoard) {
      await storeBoardsFromServerInDatabase(data.archiveBoard);
    }
  };

  const onUnarchiveBoard = async (): Promise<void> => {
    await storeBoardsInDatabase([
      { ...board, data: { ...board.data, archivedAt: null } },
    ]);

    const { data } = await unarchiveBoard({
      boardId: board.data.id,
    });

    if (data?.unarchiveBoard) {
      await storeBoardsFromServerInDatabase(data.unarchiveBoard);
    }
  };

  useEffect(() => {
    setName(board.data.name);
  }, [board.data.name]);

  const [deleteBoardModalOpen, setDeleteBoardModalOpen] =
    useState<boolean>(false);

  const onUpdateBoard = async <K extends keyof Board['data']>(
    key: K,
    value: Board['data'][K]
  ): Promise<void> => {
    await storeBoardsInDatabase([
      { ...board, data: { ...board.data, [key]: value } },
    ]);

    await updateBoard({ boardId: board.data.id, [key]: value });
  };

  const isArchived = !!board.data.archivedAt;

  return (
    <SettingsSidebar
      onClickCloseButton={onClickCloseButton}
      title="Board Settings"
    >
      <>
        <div className="flex h-full flex-col justify-between pb-30 md:pb-0">
          <div>
            <Label htmlFor="name" text="Name" />
            <TextInput
              id="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              onBlur={async () => await onUpdateBoard('name', name)}
            />

            <div className="flex justify-between items-center mr-2">
              <div className="mb-2">
                <Label text="Groups" />
              </div>

              {!showNewProjectColumn && (
                <Plus
                  size={12}
                  className="cursor-pointer p-2 hover:bg-gray-300 dark:hover:bg-mauve-dark-4 rounded-md"
                  onClick={() => setShowNewProjectColumn(true)}
                />
              )}
            </div>

            <Droppable
              droppableId={`project-column-settings-${board.data.id}`}
              type="project-column-settings"
            >
              {(provided) => (
                <div
                  className="flex flex-col mx-2 mt-2 mb-4 gap-1"
                  ref={provided.innerRef}
                >
                  {orderBy(projectColumns, 'data.order').map(
                    (projectColumn, index) => (
                      <Draggable
                        key={projectColumn.data.id}
                        index={index}
                        draggableId={`project-column-${projectColumn.data.id}`}
                      >
                        {(provided) => (
                          <ProjectColumnSetting
                            draggableProvided={provided}
                            projectColumn={projectColumn}
                          />
                        )}
                      </Draggable>
                    )
                  )}

                  {provided.placeholder}

                  {showNewProjectColumn && (
                    <ProjectColumnSetting
                      initialMode="edit"
                      onCancel={() => setShowNewProjectColumn(false)}
                      onSave={async (name: string) => {
                        await createProjectColumn({
                          boardId: board.data.id,
                          name,
                        }).then(async ({ data }) => {
                          if (data?.createProjectColumn) {
                            await storeProjectColumnsFromServerInDatabase([
                              data.createProjectColumn,
                            ]);

                            setShowNewProjectColumn(false);
                          }
                        });
                      }}
                      projectColumn={undefined}
                    />
                  )}
                </div>
              )}
            </Droppable>

            <Label text="Automations" />
            <div className="flex flex-col gap-6 p-2 text-sm">
              <div className="flex flex-col gap-2">
                <div>When a task is completed</div>
                <Dropdown
                  value={taskCompletedColumnId}
                  options={taskCompletedColumnOptions}
                  onValueChange={async (value) => {
                    setTaskCompletedColumnId(value);

                    await onUpdateBoard(
                      'taskCompletedProjectColumnId',
                      value === 'no-starting-column' ? null : value
                    );
                  }}
                />
              </div>

              <div className="flex flex-col gap-2">
                <div>When a project is completed</div>

                <Dropdown
                  value={projectCompletedColumnId}
                  options={projectCompletedColumnOptions}
                  onValueChange={async (value) => {
                    setProjectCompletedColumnId(value);

                    await onUpdateBoard(
                      'projectCompletedProjectColumnId',
                      value === 'no-completed-column' ? null : value
                    );
                  }}
                />
              </div>
              {/* <div>When the last task is completed</div>
              <div>Automatic project archiving</div> */}
            </div>
          </div>

          <div className="flex flex-col">
            <ArchiveButton
              onClick={isArchived ? onUnarchiveBoard : onArchiveBoard}
              text={`${isArchived ? 'Restore' : 'Archive'} board...`}
            />

            {isArchived && (
              <DeleteButton
                onClick={() => setDeleteBoardModalOpen(true)}
                text="Delete board..."
              />
            )}
          </div>
        </div>

        <DeleteBoardModal
          board={board}
          open={deleteBoardModalOpen}
          setIsOpen={setDeleteBoardModalOpen}
        />
      </>
    </SettingsSidebar>
  );
};

const ArchiveButton = ({
  onClick,
  text,
}: {
  onClick: () => void;
  text: string;
}): JSX.Element => (
  <button
    className="group mt-4 flex cursor-pointer items-center justify-center rounded-md border border-solid bg-transparent py-1 text-base transition hover:border-white hover:text-white dark:border-gray-400 dark:text-white"
    onClick={onClick}
  >
    <Archive className="mr-1 transition group-hover:text-white" size={16} />
    {text}
  </button>
);

export default BoardSettings;
