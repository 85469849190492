import { Box, IconButton } from '@radix-ui/themes';
import { Minus, Plus } from 'lucide-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDayCalendarZoomLevel } from '../../reducers/actions';
import { BetaState } from '../../reducers/beta-types';

const minZoomLevel = 3;
const maxZoomLevel = 12;

interface EventContainerWrapperProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const EventContainerWrapper: React.FC<EventContainerWrapperProps> = ({
  children,
  className,
  style,
}) => {
  const { dayCalendarZoomLevel } = useSelector((state: BetaState) => ({
    dayCalendarZoomLevel: state.dayCalendarZoomLevel,
  }));

  const dispatch = useDispatch();

  const onPlusZoom = () =>
    dispatch(setDayCalendarZoomLevel(dayCalendarZoomLevel + 1));

  const onMinusZoom = () =>
    dispatch(setDayCalendarZoomLevel(dayCalendarZoomLevel - 1));

  return (
    <Box className={className} style={style}>
      <Box className="sticky items-end top-0 right-0 z-map-day-view-controls flex flex-col gap-2">
        <IconButton
          size="1"
          variant="surface"
          highContrast
          color="gray"
          onClick={onPlusZoom}
          disabled={dayCalendarZoomLevel === maxZoomLevel}
        >
          <Plus size="12" />
        </IconButton>

        <IconButton
          size="1"
          variant="surface"
          highContrast
          color="gray"
          onClick={onMinusZoom}
          disabled={dayCalendarZoomLevel === minZoomLevel}
        >
          <Minus size="12" />
        </IconButton>
      </Box>
      {children}
    </Box>
  );
};
