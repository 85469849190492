import { Command } from 'cmdk';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { storeTasksFromServerInDatabase } from '../../database/actions';
import {
  BoardFragment,
  useBoardQuery,
  useUpdateTasksProjectMutation,
} from '../../graphql/generated-types';
import { setFloatingProjectSelectorTaskIds } from '../../reducers/actions';
import { BetaState } from '../../reducers/beta-types';
import { FloatingOverlay } from '../shared/FloatingOverlay';

import { BoardSelector } from './BoardSelector';

import '../../css/cmdk.css';

interface FloatingProjectSelectorProps {}

export const FloatingProjectSelector: React.FC<
  FloatingProjectSelectorProps
> = () => {
  const [boardId, setBoardId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');

  const [, updateTasksProject] = useUpdateTasksProjectMutation();

  const [activeBoardData, setActiveBoardData] = useState<BoardFragment | null>(
    null
  );

  const { floatingProjecSelectorTaskIds } = useSelector((state: BetaState) => ({
    floatingProjecSelectorTaskIds: state.floatingProjectSelectorTaskIds,
  }));

  const [boardData] = useBoardQuery({
    variables: { boardId: boardId ?? '' },
    pause: !boardId,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!boardId) {
      return setActiveBoardData(null);
    }

    if (boardData?.data?.board) {
      setActiveBoardData(boardData.data.board);
    }
  }, [boardId, boardData]);

  return (
    <FloatingOverlay
      open={floatingProjecSelectorTaskIds.length > 0}
      onOpenChange={(open) => {
        if (!open) {
          dispatch(setFloatingProjectSelectorTaskIds([]));
        }
      }}
    >
      {(inputRef) => (
        <Command>
          <Command.Input
            autoFocus={false}
            value={search}
            onValueChange={setSearch}
            ref={inputRef}
            placeholder="Move to project..."
            onKeyDown={(event) => {
              if (boardId && event.key === 'Escape') {
                event.stopPropagation();
                setSearch('');
                setBoardId(null);
                setActiveBoardData(null);
              }
            }}
          />
          <Command.List>
            {!boardId && (
              <BoardSelector
                loading={loading}
                onSelect={(boardId) => {
                  setSearch('');
                  setBoardId(boardId);
                }}
                setLoading={setLoading}
              />
            )}

            {boardId && (
              <>
                {loading && !activeBoardData && (
                  <Command.Loading>Loading...</Command.Loading>
                )}

                {activeBoardData?.projectColumns?.map((projectColumn) => (
                  <Command.Group
                    key={projectColumn.id}
                    heading={projectColumn.name}
                  >
                    {projectColumn.projects?.map((project) => (
                      <Command.Item
                        onSelect={async () => {
                          await updateTasksProject({
                            projectId: project.id,
                            taskIds: floatingProjecSelectorTaskIds,
                          }).then((response) => {
                            if (response.data.updateTasksProject) {
                              void storeTasksFromServerInDatabase(
                                response.data.updateTasksProject
                              );
                            }
                          });

                          setSearch('');
                          setBoardId(null);
                          dispatch(setFloatingProjectSelectorTaskIds([]));
                        }}
                        value={project.name.replace(/"/g, '')}
                        key={project.id}
                      >
                        {project.name}
                      </Command.Item>
                    ))}
                  </Command.Group>
                ))}
              </>
            )}
          </Command.List>
        </Command>
      )}
    </FloatingOverlay>
  );
};
