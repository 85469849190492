import { TaskResultFragment } from '../../graphql/generated-types';
import { dateInUtc, pathForDate } from '../../utils/date';
import { SearchResultType } from '../SearchOverlay/SearchResults';

export const resultPath = (result: SearchResultType): string => {
  switch (result.__typename) {
    case 'Board': {
      return `/boards/${result.id}`;
    }
    case 'Project': {
      return `/projects/${result.id}`;
    }
    case 'TaskSearchResult': {
      return taskPath(result);
    }
    case 'Note': {
      return `/notes/${result.id}`;
    }
    default: {
      return '';
    }
  }
};

const taskPath = (result: TaskResultFragment): string => {
  if (result.task.date) {
    return `${pathForDate(dateInUtc(result.task.date))}`;
  } else if (result.task.project) {
    return `/projects/${result.task.project.id}`;
  } else {
    return '/inbox';
  }
};
