import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';

import { setSearchOpen } from '../../reducers/actions';

export const GlobalKeyboardShortcutsProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const dispatch = useDispatch();

  useHotkeys(
    'command+k',
    () => {
      dispatch(setSearchOpen(true));
    },
    { filter: () => true, enableOnTags: ['INPUT', 'TEXTAREA'] }
  );

  return children;
};
