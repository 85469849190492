// @ts-strict-ignore

import classNames from 'classnames';
import { Check } from 'lucide-react';
import React, { useEffect, useState } from 'react';

import { storeProjectsInDatabase } from '../../database/actions';
import {
  useCompleteProjectMutation,
  useUncompleteProjectMutation,
} from '../../graphql/generated-types';
import { Project } from '../../types';

const ProjectProgress = ({
  completionProgress,
  project,
}: {
  completionProgress: number;
  project: Project;
}): JSX.Element => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isRecentlyClicked, setIsRecentlyClicked] = useState<boolean>(false);

  const [, completeProject] = useCompleteProjectMutation();
  const [, uncompleteProject] = useUncompleteProjectMutation();

  useEffect(() => {
    if (isRecentlyClicked) {
      setTimeout(() => {
        setIsRecentlyClicked(false);
      }, 200);
    }
  }, [isRecentlyClicked]);

  if (!project) {
    return null;
  }

  const { completed } = project.data;

  const onClick = async (): Promise<void> => {
    if (project.data.completed) {
      await storeProjectsInDatabase([
        {
          ...project,
          data: {
            ...project.data,
            completed: false,
            completedAt: null,
          },
        },
      ]);

      await uncompleteProject({ projectId: project.data.id });
    } else {
      await storeProjectsInDatabase([
        {
          ...project,
          data: {
            ...project.data,
            completed: true,
            completedAt: new Date().getTime(),
          },
        },
      ]);

      await completeProject({ projectId: project.data.id });
    }
  };

  return (
    <div
      className={classNames(
        'mr-2 h-5 w-5 shrink-0 cursor-pointer rounded-full border border-solid border-violet-600 bg-opacity-100 transition-all duration-200 hover:bg-opacity-0 dark:border-violet-400 dark:hover:border-violet-500',
        { 'hover:scale-120': !isRecentlyClicked }
      )}
      key={`project-progress-${project.data.id}-${String(
        project.data.completed
      )}`}
      style={{
        background: completed
          ? '#894DEE'
          : `conic-gradient(#8B5CF6 ${completionProgress}%, transparent 0)`,
      }}
      onClick={async () => {
        setIsRecentlyClicked(true);
        await onClick();
      }}
    >
      <div
        className="duration-400 flex h-full w-full items-center justify-center rounded-full bg-transparent transition-all hover:bg-violet-600"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Check
          className={classNames('transition-all', 'duration-100', {
            'opacity-0': !completed && !isHovering,
            'opactity-100': completed || isHovering,
            'text-white': completed,
            'text-violet-400': isHovering && !completed,
          })}
          size="12"
          strokeWidth={4}
        />
      </div>
    </div>
  );
};

export default ProjectProgress;
