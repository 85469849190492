import React, { useEffect, useState } from 'react';

import {
  BadgeCountModeEnum,
  useUpdateUserSettingsMutation,
  useUserQuery,
} from '../../graphql/generated-types';
import ContainerHeader from '../ContainerHeader';
import { Dropdown, DropdownOption } from '../shared/Dropdown';

import { SettingContainer } from './SettingContainer';

const badgeCountModeOptions: DropdownOption[] = [
  { label: 'Tasks that are due', value: 'DUE' },
  { label: 'Tasks that are prioritized', value: 'PRIORITY' },
  { label: 'Tasks that are due or prioritized', value: 'PRIORITY_DUE' },
  { label: 'Do not display badge count', value: 'NONE' },
];

export const NotificationSettings = (): JSX.Element => {
  const [userData] = useUserQuery();

  const [badgeCountMode, setBadgeCountMode] = useState();

  const [, updateUserSettings] = useUpdateUserSettingsMutation();

  useEffect(() => {
    if (userData?.data) {
      setBadgeCountMode(userData.data.currentUser.settings.badgeCountMode);
    }
  }, [userData?.data]);

  return (
    <div className="flex">
      <div className="relative mt-18 overflow-hidden flex-grow md:h-screen md:overflow-y-scroll pb-40 md:mt-0 md:pb-0">
        <ContainerHeader
          fetching={userData.fetching || userData.stale}
          title={<div className="flex">Notifications Settings</div>}
        />

        <div className="mb-20 px-4 md:px-8">
          <p className="dark:text-gray-200 text-gray-800 my-8">
            Configure the types of notifications you will receive while using
            Blips.
          </p>

          <SettingContainer
            title="Badge application icon"
            subtitle="Displays as a number in a red dot on the icon for your desktop
          application. Configure which tasks to display."
          >
            <div className="flex grow">
              <Dropdown
                value={badgeCountMode}
                options={badgeCountModeOptions}
                onValueChange={async (value) => {
                  setBadgeCountMode(value);

                  await updateUserSettings({
                    badgeCountMode: value as BadgeCountModeEnum,
                  });
                }}
              />
            </div>
          </SettingContainer>
        </div>
      </div>
    </div>
  );
};
