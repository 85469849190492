import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, HashRouter } from 'react-router-dom';

import GridContainer from './GridContainer';
import { RadixTheme } from './RadixTheme';

import './App.css';
import './react-contextmenu.css';
import '@radix-ui/themes/styles.css';

// const enhancers = compose(
//   window.navigator.userAgent.includes('Chrome')
//     ? (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
//         (window as any).__REDUX_DEVTOOLS_EXTENSION__()
//     : {},
//   install()
// );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Router: any = (window as any).navigator.standalone
  ? HashRouter
  : BrowserRouter;

const App = (): JSX.Element => {
  return (
    <RadixTheme>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blips</title>
      </Helmet>

      <Router>
        <GridContainer />
      </Router>
    </RadixTheme>
  );
};

export default App;
