import { Button, Text } from '@radix-ui/themes';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { useDeleteNoteMutation } from '../../graphql/generated-types';
import { removeNotes } from '../../reducers/actions';
import { Note } from '../../types';
import Modal from '../Modal';

const DeleteNoteModal = ({
  note,
  open,
  setIsOpen,
}: {
  note: Note;
  open: boolean;
  setIsOpen: (open: boolean) => void;
}): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [, deleteNote] = useDeleteNoteMutation();

  const onDeleteNote = async (): Promise<void> => {
    await deleteNote({
      noteId: note.data.id,
    }).then((result) => {
      if (result.data?.deleteNote?.id) {
        dispatch(removeNotes([result.data.deleteNote.id]));
      }
    });

    setIsOpen(false);

    history.push(`/`);
  };

  return (
    <Modal open={open} setIsOpen={setIsOpen} title="Confirm note deletion">
      <div className="flex flex-col px-4 pb-4 pt-2">
        <div>
          <Text as="p" size="2" className="mb-8 dark:text-gray-200">
            Are you sure you want to delete this note? It will not be able to be
            recovered later.
          </Text>
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <Button color="gray" variant="soft" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>

          <Button color="red" variant="solid" onClick={onDeleteNote}>
            Delete note
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteNoteModal;
