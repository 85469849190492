import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';

import { useSearchQuery } from '../../graphql/generated-types';

import { useCommands } from './commands';

export const useSearch = (search: string) => {
  const [query, setQuery] = useState(search);

  const delayedSetQuery = useRef(
    debounce((q: string) => setQuery(q), 200)
  ).current;

  useEffect(() => {
    delayedSetQuery(search);
  }, [search]);

  const [searchData] = useSearchQuery({
    variables: { query },
    pause: !query,
  });

  const commands = useCommands(search);

  return {
    ...(searchData.data?.search ?? {
      tasks: [],
      notes: [],
      boards: [],
      projects: [],
    }),
    commands,
  };
};
