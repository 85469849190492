import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

const variants = {
  enter: { opacity: 0, transition: { duration: 1.3 } },
  entered: { opacity: 1 },
  exit: {
    opacity: 0,
    transition: { duration: 0.5 },
  },
};

const WORDS = [
  'doing',
  'building',
  'watching',
  'reading',
  'listening to',
  'brainstorming',
  'writing',
  'playing',
  'learning',
  'working on',
  'planning',
  'creating',
  'designing',
  'developing',
];

const CHANGE_SPEED_IN_MS = 3_500;

export const TaglineAction = (): JSX.Element => {
  const [wordIndex, setWordIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (wordIndex === WORDS.length - 1) {
        setWordIndex(0);

        return;
      }

      setWordIndex(wordIndex + 1);
    }, CHANGE_SPEED_IN_MS);

    return () => clearTimeout(timeout);
  }, [wordIndex]);

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.span
        variants={variants}
        initial="enter"
        animate="entered"
        exit="exit"
        key={wordIndex}
        className="tagline-action text-teal-300"
        style={{ textShadow: '0px 0px 11px rgba(94, 234, 212, 0.35)' }}
      >
        {WORDS[wordIndex]}
      </motion.span>
    </AnimatePresence>
  );
};
