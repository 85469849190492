import {
  Draggable,
  Droppable,
} from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import { Label } from '@radix-ui/react-label';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import {
  ArrowDown,
  ArrowUpDown,
  ChevronDown,
  ChevronRight,
  Plus,
  Settings,
} from 'lucide-react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  storeProjectColumnsFromServerInDatabase,
  storeProjectColumnsInDatabase,
} from '../../database/actions';
import {
  ProjectColumnSortDirectionEnum,
  ProjectColumnSortTypeEnum,
  useUpdateProjectColumnMutation,
} from '../../graphql/generated-types';
import {
  setCreatorModalMode,
  setMobileToolbarVisible,
  setOverrideCreationProjectColumn,
} from '../../reducers/actions';
import { BetaState } from '../../reducers/beta-types';
import { ProjectColumn as ProjectColumnType } from '../../types';
import { orderedProjects } from '../../utils/project-columns';
import Popover from '../library/Popover';
import { PopoverBody } from '../library/Popover/PopoverBody';
import { Dropdown, DropdownOption } from '../shared/Dropdown';

import Project from './Project';

const sortTypeOptions: DropdownOption[] = [
  {
    label: 'Manual',
    value: ProjectColumnSortTypeEnum.Manual,
  },
  {
    label: 'Alphabetical',
    value: ProjectColumnSortTypeEnum.Name,
  },
  {
    label: 'Creation',
    value: ProjectColumnSortTypeEnum.CreatedAt,
  },
  {
    label: 'Progress',
    value: ProjectColumnSortTypeEnum.Progress,
  },
  {
    label: 'Start Date',
    value: ProjectColumnSortTypeEnum.StartDate,
  },
  {
    label: 'End Date',
    value: ProjectColumnSortTypeEnum.EndDate,
  },
];

const projectColumnVariants = {
  enter: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
  },
  entered: {
    opacity: 1,
    height: 'auto',
  },
  exit: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
    transition: { opacity: { duration: 0.3 } },
  },
};

const ProjectColumn = ({
  projectColumn,
}: {
  projectColumn: ProjectColumnType;
}): JSX.Element => {
  const dispatch = useDispatch();

  const { collapsed, id, sortType, sortDirection } = projectColumn.data;

  const [groupSortType, setGroupSortType] =
    useState<ProjectColumnSortTypeEnum>(sortType);

  const [groupSortDirection, setGroupSortDirection] = useState<
    ProjectColumnSortDirectionEnum | undefined
  >(sortDirection);

  const [isSettingsPopoverOpen, setIsSettingsPopoverOpen] = useState(false);

  const [, updateProjectColumn] = useUpdateProjectColumnMutation();

  const { draggingProjectColumnId, projects } = useSelector(
    (state: BetaState) => ({
      draggingProjectColumnId: state.draggingProjectColumnId,
      projects: state.containerProjects.filter(
        (project) => project.data.projectColumnId === id
      ),
    })
  );

  const toggleProjectColumnCollapsed = async (): Promise<void> => {
    await storeProjectColumnsInDatabase([
      {
        ...projectColumn,
        data: { ...projectColumn.data, collapsed: !collapsed },
      },
    ]);

    await updateProjectColumn({
      projectColumnId: id,
      collapsed: !collapsed,
    }).then(async (response) => {
      if (response.data) {
        await storeProjectColumnsFromServerInDatabase([
          response.data.updateProjectColumn,
        ]);
      }
    });
  };

  const Chevron = collapsed ? ChevronRight : ChevronDown;

  const onClickAddProject = (): void => {
    dispatch(setOverrideCreationProjectColumn(id));
    dispatch(setCreatorModalMode('project'));
    dispatch(setMobileToolbarVisible(false));
  };

  const sortDirectionOptions: DropdownOption[] = [
    {
      label: getAscendingLabel(groupSortType),
      value: ProjectColumnSortDirectionEnum.Asc,
    },
    {
      label: getDescendingLabel(groupSortType),
      value: ProjectColumnSortDirectionEnum.Desc,
    },
  ];

  return (
    <Droppable
      droppableId={`project-column-${id}`}
      isDropDisabled={
        (!draggingProjectColumnId && sortType !== 'MANUAL') ||
        (draggingProjectColumnId === id && sortType !== 'MANUAL')
      }
    >
      {(provided) => (
        <div ref={provided.innerRef} className="group">
          <button
            className="mb-px flex w-full cursor-pointer items-center justify-between border-0 border-t border-b border-solid border-gray-300 border-opacity-50 bg-gray-300 bg-opacity-10 px-3.5 py-2 text-sm text-gray-700 outline-none dark:border-gray-700 dark:border-opacity-70 dark:bg-mauve-dark-5 dark:text-violet-100 md:px-4"
            onClick={async () => {
              await toggleProjectColumnCollapsed();
            }}
          >
            <div className="flex items-center">
              <Chevron
                size={18}
                className="mr-2 text-gray-700 dark:text-violet-100"
              />

              {projectColumn.data.name}

              <span className={['ml-2', 'text-gray-400', 'text-xs'].join(' ')}>
                {projects?.length || ''}
              </span>
            </div>

            <Popover
              collisionPadding={20}
              side="left"
              modal
              onOpenAutoFocus={() => setIsSettingsPopoverOpen(true)}
              onCloseAutoFocus={() => setIsSettingsPopoverOpen(false)}
              content={
                <PopoverBody>
                  <div className="w-70 flex flex-col">
                    <div className="p-2 bg-gray-400 bg-opacity-10 dark:text-white border-0 border-b border-solid border-opacity-50 border-gray-600 text-xs font-semibold">
                      Sort options
                    </div>

                    <div className="px-2 py-1 flex gap-2 items-center">
                      <Label className="text-xs text-violet-600 dark:text-violet-300 py-4 w-1/3 inline-flex gap-1 items-center">
                        <ArrowUpDown size={14} />
                        Sort by
                      </Label>

                      <div className="grow">
                        <Dropdown
                          fullWidth
                          value={groupSortType}
                          options={sortTypeOptions}
                          onValueChange={async (
                            value: ProjectColumnSortTypeEnum
                          ) => {
                            setGroupSortType(value);

                            if (!groupSortDirection && value !== 'MANUAL') {
                              setGroupSortDirection(
                                ProjectColumnSortDirectionEnum.Asc
                              );
                            }

                            if (value === 'MANUAL') {
                              setGroupSortDirection(null);
                            }

                            storeProjectColumnsInDatabase([
                              {
                                ...projectColumn,
                                data: {
                                  ...projectColumn.data,
                                  sortType: value,
                                  sortDirection: groupSortDirection,
                                },
                              },
                            ]);

                            await updateProjectColumn({
                              projectColumnId: id,
                              sortDirection: groupSortDirection,
                              sortType: value,
                            }).then(async (response) => {
                              if (response.data) {
                                await storeProjectColumnsFromServerInDatabase([
                                  response.data.updateProjectColumn,
                                ]);
                              }
                            });
                          }}
                        />
                      </div>
                    </div>

                    {groupSortType !== ProjectColumnSortTypeEnum.Manual && (
                      <div className="px-2 py-1 flex gap-2 items-center">
                        <Label className="text-xs text-violet-600 dark:text-violet-300 py-4 w-1/3 inline-flex gap-1 items-center">
                          <ArrowDown size={14} />
                          Direction
                        </Label>

                        <div className="grow">
                          <Dropdown
                            fullWidth
                            value={groupSortDirection}
                            options={sortDirectionOptions}
                            onValueChange={async (
                              value: ProjectColumnSortDirectionEnum
                            ) => {
                              setGroupSortDirection(value);

                              storeProjectColumnsInDatabase([
                                {
                                  ...projectColumn,
                                  data: {
                                    ...projectColumn.data,
                                    sortDirection: value,
                                  },
                                },
                              ]);

                              await updateProjectColumn({
                                projectColumnId: id,
                                sortDirection: value,
                              }).then(async (response) => {
                                if (response.data) {
                                  await storeProjectColumnsFromServerInDatabase(
                                    [response.data.updateProjectColumn]
                                  );
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </PopoverBody>
              }
            >
              <Settings
                className={classNames(
                  'cursor-pointer transition-all group-hover:dark:text-white hover:scale-120 hover:text-violet-500',
                  {
                    'opacity-100 dark:text-white': isSettingsPopoverOpen,
                    '[@media(pointer:fine)]:opacity-0 [@media(pointer:fine)]:group-hover:opacity-100':
                      !isSettingsPopoverOpen,
                  }
                )}
                size={14}
              />
            </Popover>
          </button>

          <AnimatePresence>
            {!collapsed && (
              <motion.div
                variants={projectColumnVariants}
                initial="enter"
                animate="entered"
                exit="exit"
                transition={{
                  x: {
                    type: 'spring',
                    stiffness: 300,
                    damping: 30,
                    duration: 0.3,
                  },
                }}
              >
                <ul className="m-0 list-none p-0">
                  {orderedProjects(projects, projectColumn).map(
                    (project, index) => {
                      return (
                        <Draggable
                          draggableId={`draggable-project-${project.data.id}`}
                          index={index}
                          key={project.data.id}
                        >
                          {(provided) => (
                            <li
                              className="border-0 border-b border-solid border-gray-600 border-opacity-30 pl-3.5 last:border-b-0 dark:bg-mauve-dark-3 hover:bg-mauve-4 hover:dark:bg-mauve-dark-4"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Project project={project} />
                            </li>
                          )}
                        </Draggable>
                      );
                    }
                  )}

                  {provided.placeholder}

                  <div className="pl-3.5 ml-px my-2">
                    <button
                      className="flex border-0 bg-transparent p-0 transition-all duration-200 cursor-pointer hover:text-purple-500 items-center gap-2 dark:text-gray-400 text-gray-500"
                      onClick={onClickAddProject}
                    >
                      <Plus size={20} /> Add Project
                    </button>
                  </div>
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </Droppable>
  );
};

const getAscendingLabel = (sortType: ProjectColumnSortTypeEnum): string => {
  switch (sortType) {
    case ProjectColumnSortTypeEnum.Name:
      return 'A-Z';
    case ProjectColumnSortTypeEnum.CreatedAt:
    case ProjectColumnSortTypeEnum.StartDate:
    case ProjectColumnSortTypeEnum.EndDate:
      return 'Latest first';
    case ProjectColumnSortTypeEnum.Progress:
      return 'Least progress first';
    default:
      return 'Ascending';
  }
};

const getDescendingLabel = (sortType: ProjectColumnSortTypeEnum): string => {
  switch (sortType) {
    case ProjectColumnSortTypeEnum.Name:
      return 'Z-A';
    case ProjectColumnSortTypeEnum.CreatedAt:
    case ProjectColumnSortTypeEnum.StartDate:
    case ProjectColumnSortTypeEnum.EndDate:
      return 'Earliest first';
    case ProjectColumnSortTypeEnum.Progress:
      return 'Most progress first';
    default:
      return 'Descending';
  }
};

export default ProjectColumn;
