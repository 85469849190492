import * as chrono from 'chrono-node';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { setActiveSidebar, setCreatorModalMode } from '../../reducers/actions';
import { Sidebar } from '../../reducers/beta-types';
import { dateDisplayFor, pathForDate } from '../../utils/date';

export const useCommands = (search: string) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const date = chrono.parseDate(search);

  return [
    {
      label: 'Boards',
      value: 'boards',
      key: 'projects',
      command: () => dispatch(setActiveSidebar(Sidebar.Boards)),
    },
    {
      label: 'Calendar',
      value: 'calendar',
      key: 'calendar',
      command: () => dispatch(setActiveSidebar(Sidebar.Calendar)),
    },
    {
      label: 'Create Note',
      value: 'create note',
      key: 'create-note',
      command: () => dispatch(setCreatorModalMode('note')),
    },
    {
      label: 'Create Project',
      value: 'create project',
      key: 'create-project',
      command: () => dispatch(setCreatorModalMode('project')),
    },
    {
      label: 'Create Task',
      value: 'create task',
      key: 'create-task',
      command: () => dispatch(setCreatorModalMode('task')),
    },
    {
      label: 'Inbox',
      value: 'inbox',
      key: 'inbox',
      command: () => history.push('/inbox'),
    },
    {
      label: 'Notes',
      value: 'notes',
      key: 'notes',
      command: () => dispatch(setActiveSidebar(Sidebar.Notes)),
    },
    {
      label: 'Priorities',
      value: 'priorities',
      key: 'projects',
      command: () => dispatch(setActiveSidebar(Sidebar.Priorities)),
    },
    {
      label: 'Settings',
      value: 'settings',
      key: 'settings',
      command: () => dispatch(setActiveSidebar(Sidebar.Settings)),
    },
    ...(date
      ? [
          {
            label: dateDisplayFor(date.toDateString()),
            value: search,
            key: 'date',
            command: () => history.push(pathForDate(date)),
          },
        ]
      : [
          {
            label: 'Today',
            value: 'today',
            key: 'today',
            command: () => history.push(pathForDate(new Date())),
          },
          {
            label: 'Tomorrow',
            value: 'tomorrow',
            key: 'tomorrow',
            command: () => history.push(pathForDate(addDays(new Date(), 1))),
          },
          {
            label: 'Yesterday',
            value: 'yesterday',
            key: 'yesterday',
            command: () => history.push(pathForDate(subDays(new Date(), 1))),
          },
        ]),
  ].filter(Boolean);
};
