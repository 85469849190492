// @ts-strict-ignore

import useBreakpoint from 'use-breakpoint';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export const BREAKPOINTS: Record<Breakpoint, number> = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

const useBreakpoints = (): {
  breakpoint: Breakpoint;
  minWidth: number;
  maxWidth?: number | null;
} => {
  return useBreakpoint(BREAKPOINTS);
};

export default useBreakpoints;
