import { Label } from '@radix-ui/react-label';
import { RadioGroup as RadixRadioGroup, Text } from '@radix-ui/themes';
import React from 'react';

export interface RadioGroupItem {
  label: string;
  id: string;
  value: string;
}

const RadioGroup = ({
  items,
  setValue,
  value,
}: {
  items: RadioGroupItem[];
  setValue: (value: string) => void;
  value: string;
}): JSX.Element => {
  return (
    <RadixRadioGroup.Root onValueChange={setValue} value={value}>
      {items.map((item) => (
        <div className="my-3 flex cursor-pointer items-center" key={item.id}>
          <RadixRadioGroup.Item
            id={item.id}
            value={item.value}
            className="mr-2 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-solid border-transparent"
          />

          <Text asChild size="2">
            <Label htmlFor={item.id}>{item.label}</Label>
          </Text>
        </div>
      ))}
    </RadixRadioGroup.Root>
  );
};

export default RadioGroup;
