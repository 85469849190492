import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Sidebar } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  NoteFragment,
  useNoteQuery,
  useUpdateNoteMutation,
} from '../../graphql/generated-types';
import useBreakpoints from '../../hooks/use-breakpoints';
import {
  setCreatorModalMode,
  storeNotes,
  storeNotesFromServer,
} from '../../reducers/actions';
import { BetaState } from '../../reducers/beta-types';
import Button, { ButtonColor } from '../Button';
import ContainerHeader from '../ContainerHeader';
import { CreatorButton } from '../Creator/CreatorButton';
import CreatorModal from '../Creator/CreatorModal';
import { Notepad } from '../Notepad';

import NoteSettings from './NoteSettings';

const noteSettingsVariants = {
  open: {
    opacity: 1,
    width: 'fit-content',
  },
  closed: { opacity: 0, width: 0 },
};

const NoteContainer = (): JSX.Element | null => {
  const { noteId } = useParams<{ noteId: string }>();
  const dispatch = useDispatch();
  const [moreRecentNote, setMoreRecentNote] = useState<NoteFragment>();

  const [{ fetching: updateNoteFetching }, updateNote] =
    useUpdateNoteMutation();

  const [noteData, refetchNoteData] = useNoteQuery({
    variables: { noteId },
    requestPolicy: 'network-only',
  });

  const { note } = useSelector((state: BetaState) => ({
    note: state.notes.find((note) => note.data.id === noteData.data?.note?.id),
  }));

  const { breakpoint } = useBreakpoints();
  const isMobile = ['xs', 'sm'].includes(breakpoint);

  useEffect(() => {
    if (noteData.data?.note) {
      dispatch(
        storeNotesFromServer({
          notes: [noteData.data.note],
        })
      );
    }
  }, [noteData.data]);

  const [showSettings, setShowSettings] = useState<boolean>(false);

  if (!noteData?.data?.note || !note) {
    return null;
  }

  return (
    <div className="flex">
      <div className="relative mt-18 flex-grow pb-40 md:mt-0 md:pb-0">
        <ContainerHeader
          fetching={noteData.fetching || noteData.stale || updateNoteFetching}
          title={noteData.data.note.name}
          rightControls={
            <>
              {!showSettings && (
                <Sidebar
                  size={20}
                  role="button"
                  onClick={() => setShowSettings(!showSettings)}
                  className="cursor-pointer transition hover:text-violet-500"
                  style={{ transform: 'scaleX(-1)' }}
                />
              )}
            </>
          }
        />

        <div className="mb-20 ">
          {moreRecentNote && (
            <div className="m-4 flex items-center justify-between rounded-md bg-violet-600 bg-opacity-60 p-4 text-white">
              This note is out of sync with the server. Would you like to load
              the latest saved version?
              <Button
                color={ButtonColor.Primary}
                onClick={() => {
                  refetchNoteData();

                  dispatch(storeNotesFromServer({ notes: [moreRecentNote] }));
                }}
              >
                Load last version
              </Button>
            </div>
          )}

          <Notepad
            content={noteData.data.note.body}
            displayHeader={false}
            editable={!moreRecentNote}
            key={`notepad-container-${noteData.data.note.id}`}
            isSaving={false}
            onChange={async (noteBody) => {
              dispatch(
                storeNotes({
                  notes: [{ ...note, data: { ...note.data, body: noteBody } }],
                })
              );

              await updateNote({
                body: noteBody,
                lastUpdatedAt: note.data.updatedAt,
                noteId,
              }).then((result) => {
                if (result.data?.updateNote.__typename === 'NoteUpdated') {
                  dispatch(
                    storeNotesFromServer({
                      notes: [result.data.updateNote.note],
                    })
                  );
                }

                if (
                  result.data?.updateNote.__typename === 'NoteUpdateOutdated'
                ) {
                  setMoreRecentNote(result.data.updateNote.note);
                }
              });
            }}
          />
        </div>
      </div>

      <CreatorButton
        sideMenuOpen={showSettings}
        openCreatorModal={() => dispatch(setCreatorModalMode('note'))}
      />

      <CreatorModal />

      <AnimatePresence>
        {showSettings && (
          <motion.div
            transition={{ duration: isMobile ? 0 : 0.3 }}
            initial="closed"
            animate="open"
            exit="closed"
            variants={noteSettingsVariants}
          >
            <div
              className={classNames(
                'fixed top-0 right-0 h-screen w-screen flex-shrink-0 border-0 border-l border-solid border-gray-300 bg-white z-map-settings-sidebar dark:border-violet-900 dark:bg-mauve-dark-2 md:w-80 xl:relative',
                {
                  hidden: !showSettings,
                }
              )}
            >
              <NoteSettings
                note={note}
                onClickCloseButton={() => setShowSettings(false)}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NoteContainer;
