// @ts-strict-ignore

import { isSameDay, setDate, startOfDay } from 'date-fns';
import React from 'react';

import { CalendarDay } from './CalendarDay';

export const CurrentMonthDay = ({
  day,
  calendarDate,
  onClick,
  selectedDate,
}: {
  day: number;
  calendarDate: Date;
  onClick: (date: Date) => void;
  selectedDate: Date;
}): JSX.Element => {
  const date = startOfDay(setDate(calendarDate, day));
  const isSelectedDate = isSameDay(date, selectedDate);
  const isCurrentDay = isSameDay(date, new Date());

  return (
    <CalendarDay
      calendarDate={date}
      classes={[
        isSelectedDate ? 'text-white' : 'text-gray-600',
        isCurrentDay && 'current-day font-bold',
        isCurrentDay && !isSelectedDate && 'text-violet-700',
        'dark:hover:text-white',
        'dark:text-white',
        'relative',
      ]}
      day={day}
      isSelectedDate={isSelectedDate}
      onClick={() => {
        onClick(date);
      }}
    />
  );
};
