import classNames from 'classnames';
import { LucideProps } from 'lucide-react';
import React, { FC } from 'react';

const ContainerSection = ({
  children,
  className,
  Icon,
  indicator,
  onClick,
  rightControls,
  title,
}: {
  children: React.ReactNode;
  className?: string;
  Icon: FC<LucideProps>;
  indicator?: string | number;
  onClick?: () => void;
  rightControls?: React.ReactNode;
  title: string;
}): JSX.Element => {
  return (
    <div
      className={[
        'm-0 rounded-none border border-none border-gray-200 bg-white pt-4 shadow-container-section-gray dark:border-violet-500 dark:border-opacity-25 dark:bg-mauve-dark-3 dark:shadow-container-section-purple md:m-4 md:rounded-lg md:border-solid',
        className,
      ].join(' ')}
    >
      <div
        className="flex select-none items-center justify-between px-4 pb-3 md:mb-4 md:pb-0"
        onClick={onClick}
      >
        <div className="flex items-center">
          <Icon
            size="18"
            className={classNames(
              'dark:text-violet-400',
              'mr-2',
              'text-violet-700'
            )}
            data-testid="container-icon"
            strokeWidth="3"
          />

          <span className="text-sm font-semibold text-violet-700 dark:text-violet-400">
            {title}
          </span>

          {indicator && (
            <span
              className={[
                'bg-violet-600',
                'ml-3',
                Number(indicator) > 9 ? 'px-1' : 'px-1.5',
                'py-0.5',
                'rounded-md',
                'text-white',
                'text-xs',
              ].join(' ')}
            >
              {indicator}
            </span>
          )}
        </div>

        {rightControls && <div>{rightControls}</div>}
      </div>

      <div>{children}</div>
    </div>
  );
};

export default ContainerSection;
