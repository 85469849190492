import classNames from 'classnames';
import React from 'react';

const TextInput = ({
  autoFocus = false,
  className,
  disabled = false,
  id,
  onChange,
  placeholder,
  readOnly = false,
  type = 'text',
  value,
}: {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  id?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  type?: string;
  value: string;
}): JSX.Element => {
  return (
    <input
      autoFocus={autoFocus}
      className={classNames(
        'box-border w-full rounded-md border border-solid border-gray-400 bg-transparent p-2 text-base placeholder-gray-400 shadow-none transition-all selection:bg-violet-500 selection:text-white focus:border-violet-500 focus:outline-none dark:text-white',
        className
      )}
      disabled={disabled}
      id={id}
      value={value}
      onChange={onChange}
      readOnly={readOnly}
      placeholder={placeholder}
      type={type}
    />
  );
};

export default TextInput;
