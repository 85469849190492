import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { isLoggedIn } from '../utils/authentication';

export const useAuthenticatedRoute = (): void => {
  const loggedIn = isLoggedIn();
  const history = useHistory();

  useEffect(() => {
    if (!loggedIn) {
      history.push('/login');
    }
  }, [loggedIn]);
};

export const useUnauthenticatedRoute = (): void => {
  const loggedIn = isLoggedIn();
  const history = useHistory();

  useEffect(() => {
    if (loggedIn) {
      history.push('/');
    }
  }, [loggedIn]);
};
