import gql from 'graphql-tag';

import PROJECT_COLUMN_FRAGMENT, {
  ProjectColumn,
} from './project-column-fragment';
import { OrderInput } from './types';

export interface PersistProjectOrderData {
  persistProjectOrder: ProjectColumn[];
}

interface ProjectOrderInput extends OrderInput {
  projectColumnId?: string;
}

export interface PersistProjectOrderVars {
  order: ProjectOrderInput[];
}

export const PERSIST_PROJECT_ORDER_MUTATION = gql`
  mutation persistProjectOrder($order: [OrderInput!]) {
    persistProjectOrder(order: $order) {
      ${PROJECT_COLUMN_FRAGMENT}
    }
  }
`;

export const PERSIST_PROJECT_ORDER_MUTATION_2 = `
  mutation persistProjectOrder($order: [OrderInput!]) {
    persistProjectOrder(order: $order) {
      ${PROJECT_COLUMN_FRAGMENT}
    }
  }
`;

export default PERSIST_PROJECT_ORDER_MUTATION;
