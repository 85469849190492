import { Button } from '@radix-ui/themes';
import React from 'react';
import { useHistory } from 'react-router';

import { removeBoardsFromDatabase } from '../../database/actions';
import { useDeleteBoardMutation } from '../../graphql/generated-types';
import Board from '../../types/Board';
import Modal from '../Modal';

const DeleteBoardModal = ({
  board,
  open,
  setIsOpen,
}: {
  board: Board;
  open: boolean;
  setIsOpen: (open: boolean) => void;
}): JSX.Element => {
  const history = useHistory();

  const [, deleteBoard] = useDeleteBoardMutation();

  const onDeleteBoard = async (): Promise<void> => {
    await removeBoardsFromDatabase([board.data.id]);

    await deleteBoard({
      boardId: board.data.id,
    });

    setIsOpen(false);

    history.push(`/`);
  };

  return (
    <Modal open={open} setIsOpen={setIsOpen} title="Confirm project deletion">
      <div className="flex flex-col px-4">
        <div>
          <p className="mb-8 dark:text-gray-200">
            Are you sure you want to delete this board:{' '}
            <strong>{board.data.name}</strong>? All projects and the tasks
            contained within them will be permanently deleted.
          </p>
        </div>

        <div className="mb-4 flex justify-end gap-2">
          <Button variant="soft" color="gray" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>

          <Button color="red" variant="solid" onClick={onDeleteBoard}>
            Delete board
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteBoardModal;
