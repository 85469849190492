import flatten from 'lodash/flatten';
import omit from 'lodash/omit';

import { ProjectColumnFragment } from '../../graphql/generated-types';
import { Project, ProjectColumn } from '../../types';
import { db } from '..';

export const storeProjectColumnsFromServerInDatabase = async (
  projectColumns: ProjectColumnFragment[]
): Promise<void> => {
  const projects: Project[] = flatten(
    projectColumns.map((projectColumn) =>
      (projectColumn?.projects || []).map((project) => {
        const data = {
          ...project,
          projectColumnId: project.projectColumn.id,
          boardName: project.projectColumn.board.name,
        };

        delete data.projectColumn;

        return {
          data,
          meta: {},
        };
      })
    )
  );

  const projectColumnsWithState: ProjectColumn[] = projectColumns.map(
    (projectColumn) => ({
      data: omit(projectColumn, ['projects']),
    })
  );

  // If we query for a project column we want to check the project cache to see if there were
  // any projects previously in that column (but may have been moved to another board or
  // deleted). If we find any we can assume they are out of date and invalidate the task cache.
  const outdatedProjects: Project[] = await db.projects
    .where('data.projectColumnId')
    .anyOf(projectColumns.map((pc) => pc.id))
    .filter(
      (project) =>
        !projects.map((project) => project.data.id).includes(project.data.id)
    )
    .toArray();

  const outdatedProjectIds = outdatedProjects.map((project) => project.data.id);

  await db.projects.bulkDelete(outdatedProjectIds);
  await db.projects.bulkPut(projects);

  await db.projectColumns.bulkPut(projectColumnsWithState);
};
