import { useEffect } from 'react';

import {
  storeGroupsFromServerInDatabase,
  storeTasksFromServerInDatabase,
} from '../../database/actions';
import {
  useGroupsUpdatedSubscription,
  useTasksCreatedSubscription,
  useTasksUpdatedSubscription,
} from '../../graphql/generated-types';

const SubscriptionsProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [groupsUpdatedResponse] = useGroupsUpdatedSubscription();
  const [tasksCreatedResponse] = useTasksCreatedSubscription();
  const [tasksUpdatedResponse] = useTasksUpdatedSubscription();

  useEffect(() => {
    if (groupsUpdatedResponse?.data) {
      void storeGroupsFromServerInDatabase(
        groupsUpdatedResponse.data.groupsUpdated
      );
    }
  }, [groupsUpdatedResponse.data]);

  useEffect(() => {
    if (tasksCreatedResponse?.data) {
      void storeTasksFromServerInDatabase(
        tasksCreatedResponse.data.tasksCreated
      );
    }
  }, [tasksCreatedResponse.data]);

  useEffect(() => {
    if (tasksUpdatedResponse?.data) {
      void storeTasksFromServerInDatabase(
        tasksUpdatedResponse.data.tasksUpdated
      );
    }
  }, [tasksUpdatedResponse.data]);

  return children;
};

export default SubscriptionsProvider;
