import { addHours, format, isToday, isTomorrow, isYesterday } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { History } from 'history';
import { DateTime } from 'luxon';

export const dateInUtc = (date: string | number | Date): Date => {
  return zonedTimeToUtc(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
};

export const dateDisplayFor = (
  dateString: string,
  dateFormat = 'MMMM do, uu'
): string => {
  const date = addHours(new Date(dateString), 12);

  if (isToday(date)) {
    return 'Today';
  } else if (isTomorrow(date)) {
    return 'Tomorrow';
  } else if (isYesterday(date)) {
    return 'Yesterday';
  } else {
    return format(new Date(date), dateFormat);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dateFromParams = (match: any): string => {
  if (match?.params?.year && match?.params?.month && match?.params?.day) {
    return new Date(
      match.params.year,
      match.params.month - 1, // must go down
      match.params.day
    ).toDateString();
  } else {
    return new Date().toDateString();
  }
};

const paramsFromDate = (
  date: Date
): {
  year: number;
  month: number;
  day: number;
} => ({
  year: date.getFullYear(),
  month: date.getMonth() + 1, // what goes up
  day: date.getDate(),
});

export const pathForDate = (date: Date): string => {
  const { day, month, year } = paramsFromDate(date);

  return `/date/${year}/${month}/${day}`;
};

export const redirectToDate = (date: Date, history: History): void => {
  const { day, month, year } = paramsFromDate(date);

  return history.push(`/legacy/date/${year}/${month}/${day}`);
};

export const timeToLuxon = ({
  time,
  inputFormat = 'HH:mm:ss',
  inputZone = 'utc',
  outputZone = 'utc',
}: {
  time: string;
  inputFormat?: string;
  inputZone?: string;
  outputZone?: string;
}): DateTime => {
  return DateTime.fromFormat(time, inputFormat, {
    zone: inputZone,
  }).setZone(outputZone);
};

export const formattedDate = (dateString: string, timeZone: string): string => {
  const date = new Date(dateString);

  const year = new Intl.DateTimeFormat('default', {
    year: 'numeric',
    timeZone,
  }).format(date);
  const month = new Intl.DateTimeFormat('default', {
    month: '2-digit',
    timeZone,
  }).format(date);
  const day = new Intl.DateTimeFormat('default', {
    day: '2-digit',
    timeZone,
  }).format(date);

  return `${year}-${month}-${day}`;
};
