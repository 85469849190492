import React, { FormEvent } from 'react';

import { storeBoardsFromServerInDatabase } from '../../database/actions';
import { useCreateBoardsMutation } from '../../graphql/generated-types';

import { CreatorForm } from './CreatorForm';
import { CreatorFormProps } from './CreatorModal';

export const BoardCreatorForm = (
  props: CreatorFormProps & {
    handleModalChange: (isOpen: boolean) => void;
  }
): JSX.Element => {
  const { createMultiple, handleModalChange, itemName, itemsToCreate } = props;

  const [, createBoards] = useCreateBoardsMutation();

  const onCreateBoards = async (event?: FormEvent): Promise<void> => {
    event?.preventDefault();

    let names: string[];

    if (createMultiple) {
      names = itemsToCreate.filter(Boolean);
    } else {
      names = [itemName];
    }

    void createBoards({
      names,
    }).then(({ data }) => {
      if (data) {
        void storeBoardsFromServerInDatabase(data.createBoards);
      }
    });

    handleModalChange(false);
  };

  return (
    <CreatorForm
      {...props}
      onCreate={onCreateBoards}
      placeholder="Board title"
      submitButtonText={
        createMultiple
          ? `Create ${itemsToCreate.filter(Boolean).length} board${
              itemsToCreate.filter(Boolean).length === 1 ? '' : 's'
            }`
          : 'Create board'
      }
    />
  );
};
