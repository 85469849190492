import { AlertTriangle, Lock } from 'lucide-react';
import React from 'react';

import { PasswordErrors as PasswordResetPasswordErrors } from '../PasswordReset';
import { PasswordStrengthIndicator } from '../PasswordStrengthIndicator';

import { MINIMUM_PASSWORD_LENGTH } from './utils';

export const PasswordConfirmation = ({
  errors,
  onChange,
  password,
  passwordConfirmation,
}: {
  errors: PasswordResetPasswordErrors;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  password: string;
  passwordConfirmation: string;
}): JSX.Element => {
  const passwordConfirmationProgress =
    password.length > 0
      ? Math.min(
          100,
          (passwordConfirmation.length / MINIMUM_PASSWORD_LENGTH) * 100
        )
      : 0;

  return (
    <>
      <div className="mb-2 flex items-center justify-between">
        <label htmlFor="password_confirmation" className="text-violet-200">
          Confirm password
        </label>

        {errors.length > 0 && (
          <span className="text-xs text-red-500">
            {errors.includes('not_matching') &&
              'Password and confirmation do not match'}
          </span>
        )}
      </div>

      <div className="mt-2 flex w-full rounded border border-solid border-violet-500 bg-gray-900">
        <label
          htmlFor="password_confirmation"
          className="flex cursor-pointer items-center justify-center p-4"
        >
          <>
            {errors.length > 0 ? (
              <AlertTriangle
                size={20}
                className="text-red-500 drop-shadow-auth-icon-error"
              />
            ) : passwordConfirmationProgress > 0 ? (
              <PasswordStrengthIndicator
                matchingPassword={password}
                minPasswordLength={MINIMUM_PASSWORD_LENGTH}
                password={passwordConfirmation}
                passwordProgress={passwordConfirmationProgress}
              />
            ) : (
              <Lock
                size={20}
                className="text-violet-600 drop-shadow-auth-icon"
              />
            )}
          </>
        </label>

        <input
          name="password_confirmation"
          id="password_confirmation"
          value={passwordConfirmation}
          onChange={onChange}
          type="password"
          className="w-full border-0 border-l border-solid border-violet-500 bg-transparent p-3 text-lg font-semibold text-white transition-all focus:bg-violet-900 focus:bg-opacity-20 focus:outline-none"
        />
      </div>
    </>
  );
};
