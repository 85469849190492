import { Droppable } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

export const CalendarDay = ({
  calendarDate,
  day,
  classes = [],
  isSelectedDate,
  onClick,
}: {
  calendarDate: Date;
  day: number;
  classes?: string[];
  isSelectedDate: boolean;
  onClick: () => void;
}): JSX.Element => {
  const [hasBeenSelected, setHasBeenSelected] = useState<boolean>(false);
  const [isBeingClicked, setIsBeingClicked] = useState<boolean>(false);
  const [isBeingHovered, setIsBeingHovered] = useState<boolean>(false);

  // Set hasBeenSelected when the date has been selected (whether by click or other means). This is
  // used to power the animation when the calendar day has been selected.
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isSelectedDate) {
      setHasBeenSelected(true);

      // Remove the selected animation 300ms after the day has been selected. This is to prevent the
      // animation from accidentally firing again.
      timer = setTimeout(() => {
        setHasBeenSelected(false);
      }, 300);
    }

    return () => clearTimeout(timer);
  }, [isSelectedDate]);

  return (
    <Droppable
      droppableId={`day.${format(calendarDate, 'yyyy-MM-dd')}`}
      isDropDisabled={!isBeingHovered}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={[
            ...classes,
            hasBeenSelected && 'animate-jiggle-once',
            isBeingClicked && 'scale-90',
            isSelectedDate && 'active bg-violet-10 dark:bg-violet-dark-10',
            !isSelectedDate &&
              !snapshot.isDraggingOver &&
              'hover:text-violet-4 dark:hover:bg-violet-dark-4',
            !isSelectedDate && !isBeingClicked && 'hover:animate-shrink',
            !isSelectedDate && snapshot.isDraggingOver && 'hover:bg-green-300',
            'cursor-pointer',
            'flex',
            'font-semibold',
            'items-center',
            'justify-center',
            'w-full',
            'pt-3',
            'pb-4',
            'rounded-full',
            'transition',
          ]
            .filter(Boolean)
            .join(' ')}
          data-testid={
            isSelectedDate ? 'selected-calendar-day' : 'unselected-calendar-day'
          }
          role="button"
          onClick={onClick}
          onDragOver={() => setIsBeingHovered(true)}
          onMouseDown={() => setIsBeingClicked(true)}
          onMouseLeave={() => {
            setIsBeingClicked(false);
            setIsBeingHovered(false);
          }}
          onDragEnd={() => setIsBeingHovered(false)}
          onMouseUp={() => setIsBeingClicked(false)}
        >
          {day}
        </div>
      )}
    </Droppable>
  );
};
