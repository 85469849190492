import classNames from 'classnames';
import React from 'react';

export const ProgressIndicator = ({
  children,
  classes = '',
  completed,
  completionProgress,
  isRecentlyClicked,
  onClick,
  size = '5',
  style,
}: {
  classes?: string;
  children?: React.ReactNode;
  completed: boolean;
  completionProgress: number;
  isRecentlyClicked?: boolean;
  onClick?: () => void;
  size?: '4' | '5';
  style?: React.CSSProperties;
}): JSX.Element => {
  return (
    <div
      className={classNames(
        'shrink-0 rounded-full border border-solid border-violet-600 bg-opacity-100 hover:bg-opacity-0 dark:border-violet-400 dark:hover:border-violet-500',
        classes,
        {
          'cursor-pointer': onClick,
          'hover:scale-120': onClick && !isRecentlyClicked,
          'h-4 w-4': size === '4',
          'h-5 w-5': size === '5',
        }
      )}
      style={{
        ...style,
        background: completed
          ? '#894DEE'
          : `conic-gradient(#8B5CF6 ${completionProgress}%, transparent 0)`,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
