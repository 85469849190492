import { format } from 'date-fns';
import { Layers } from 'lucide-react';
import { Zap } from 'lucide-react';
import React, { useState } from 'react';

import { storeProjectsInDatabase } from '../../database/actions';
import {
  ProjectFragment,
  useSpringProjectMutation,
  useUnspringProjectMutation,
  useUpdateProjectMutation,
} from '../../graphql/generated-types';
import { Project } from '../../types';
import { dateInUtc } from '../../utils/date';
import DateCalendarModal from '../DateCalendarModal';
import SettingsSidebar, {
  Button,
  DeleteButton,
  Label,
  TextInput,
} from '../SettingsSidebar';
import Switch from '../Switch';

import DeleteProjectModal from './DeleteProjectModal';
import { DuplicateProjectModal } from './DuplicateProjectModal';

const ProjectSettings = ({
  onClickCloseButton,
  project,
}: {
  onClickCloseButton: () => void;
  project: Project;
}): JSX.Element | null => {
  if (!project) {
    return null;
  }

  const [, springProject] = useSpringProjectMutation();
  const [, unspringProject] = useUnspringProjectMutation();
  const [, updateProject] = useUpdateProjectMutation();

  const [name, setName] = useState<string>(project.data.name);
  const [startDateModalOpen, setStartDateModalOpen] = useState<boolean>(false);
  const [endDateModalOpen, setEndDateModalOpen] = useState<boolean>(false);
  const [deleteProjectModalOpen, setDeleteProjectModalOpen] =
    useState<boolean>(false);
  const [duplicateProjectModalOpen, setDuplicateProjectModalOpen] =
    useState(false);

  const onToggleSpring = async (): Promise<void> => {
    await storeProjectsInDatabase([
      {
        ...project,
        data: {
          ...project.data,
          springEnabled: !project.data.springEnabled,
        },
      },
    ]);

    if (project.data.springEnabled) {
      await unspringProject({
        projectId: project.data.id,
      });
    } else {
      await springProject({
        projectId: project.data.id,
      });
    }
  };

  const onUpdateProject = async <K extends keyof ProjectFragment>(
    key: K,
    value: ProjectFragment[K]
  ): Promise<void> => {
    await storeProjectsInDatabase([
      {
        ...project,
        data: {
          ...project.data,
          [key]: value,
        },
      },
    ]);

    await updateProject({ projectId: project.data.id, [key]: value });
  };

  return (
    <SettingsSidebar
      onClickCloseButton={onClickCloseButton}
      title="Project Settings"
    >
      <>
        <div className="flex h-full flex-col justify-between pb-30 md:pb-0">
          <div>
            <Label htmlFor="name" text="Name" />

            <TextInput
              id="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              onBlur={async () => await onUpdateProject('name', name)}
            />

            <Label htmlFor="start-date" text="Start Date" />

            <Button id="start-date" onClick={() => setStartDateModalOpen(true)}>
              {project.data.date
                ? format(dateInUtc(project.data.date), 'MMMM dd, yyyy')
                : 'Set Start Date'}
            </Button>

            <Label htmlFor="end-date" text="End Date" />

            <Button id="end-date" onClick={() => setEndDateModalOpen(true)}>
              {project.data.endDate
                ? format(dateInUtc(project.data.endDate), 'MMMM dd, yyyy')
                : 'Set End Date'}
            </Button>

            <div className="flex items-center px-2 pt-1">
              <Switch
                checked={project.data.springEnabled}
                id="spring-enabled"
                onCheckedChange={onToggleSpring}
              />
              <label
                htmlFor="spring-enabled"
                className="ml-2 text-base font-medium"
              >
                Auto-Dispense Tasks
              </label>
              <Zap size={16} className="ml-2 text-violet-600" />
            </div>
          </div>

          <div className="flex flex-col">
            <Button
              centered
              withBorder
              id="project-settings-duplicate-project"
              onClick={() => setDuplicateProjectModalOpen(true)}
            >
              <>
                <Layers size={16} className="mr-2" />
                Duplicate project...
              </>
            </Button>

            <DeleteButton
              onClick={() => setDeleteProjectModalOpen(true)}
              text="Delete project..."
            />
          </div>
        </div>

        <DateCalendarModal
          onSave={async (date) => {
            await onUpdateProject('date', date?.toISOString() || null);
            setStartDateModalOpen(false);
          }}
          open={startDateModalOpen}
          initialDate={project.data.date ? new Date(project.data.date) : null}
          setIsOpen={setStartDateModalOpen}
          title="Set Start Date"
        />

        <DateCalendarModal
          onSave={async (date) => {
            await onUpdateProject('endDate', date?.toISOString() || null);
            setEndDateModalOpen(false);
          }}
          open={endDateModalOpen}
          initialDate={
            project.data.endDate ? new Date(project.data.endDate) : null
          }
          setIsOpen={setEndDateModalOpen}
          title="Set End Date"
        />

        <DeleteProjectModal
          project={project}
          open={deleteProjectModalOpen}
          setIsOpen={setDeleteProjectModalOpen}
        />

        <DuplicateProjectModal
          project={project}
          open={duplicateProjectModalOpen}
          setIsOpen={setDuplicateProjectModalOpen}
        />
      </>
    </SettingsSidebar>
  );
};

export default ProjectSettings;
