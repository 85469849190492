import orderBy from 'lodash/orderBy';

import { Project, ProjectColumn } from '../types';

export const orderedProjects = (
  projects: Project[],
  projectColumn: ProjectColumn
): Project[] => {
  switch (projectColumn.data.sortType) {
    case 'CREATED_AT':
      return orderBy(projects, 'data.createdAt', sortDirection(projectColumn));
    case 'COMPLETED_AT':
    case 'END_DATE':
      return orderBy(
        projects,
        (project) =>
          project.data.endDate
            ? new Date(project.data.endDate)
            : project.data.completedAt
            ? new Date(project.data.completedAt)
            : null,
        sortDirection(projectColumn)
      );
    case 'NAME':
      return orderBy(
        projects,
        (project) => project.data.name.toLowerCase(),
        sortDirection(projectColumn)
      );
    case 'PROGRESS':
      return orderBy(
        projects,
        (project) =>
          project.data.tasks.totalCount > 0
            ? project.data.tasks.completedCount / project.data.tasks.totalCount
            : 0,
        sortDirection(projectColumn)
      );
    case 'START_DATE':
      return orderBy(projects, 'data.date', sortDirection(projectColumn));
    default:
      return orderBy(projects, 'data.order');
  }
};

export const sortDirection = (projectColumn: ProjectColumn): 'asc' | 'desc' => {
  switch (projectColumn.data.sortDirection) {
    case 'ASC':
      return 'asc';
    case 'DESC':
      return 'desc';
    default:
      return 'asc';
  }
};
