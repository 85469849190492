import { db } from '../../database';
import { TaskFragment } from '../../graphql/generated-types';
import { StoreTasksFromServerOptions } from '../../reducers/actions';
import { Task } from '../../types';

import { storeTasksInDatabase } from './store-tasks';

interface TaskFromServer extends TaskFragment {
  orders?: {
    dateOrder?: number | null;
    projectOrder?: number | null;
  };
}

export const storeTasksFromServerInDatabase = async (
  tasks: TaskFromServer[],
  options?: StoreTasksFromServerOptions
): Promise<void> => {
  const taskIds = tasks.map((task) => task.id);

  const existingTasks = await db.tasks.bulkGet(taskIds);

  const newTasks: Task[] = tasks.map((task) => {
    const existingTask = existingTasks.find(
      (existing) => existing?.data.id === task.id
    );

    if (existingTask) {
      return {
        data: {
          ...task,
          projectId: task.project?.id,
        },
        order: options?.overrideOrder || {
          ...existingTask.order,
          date: task.orders?.dateOrder ?? existingTask.order.date,
          priority: task.priorityOrder,
          project: task.orders?.projectOrder ?? existingTask.order.project,
        },
        meta: {
          ...existingTask.meta,
          awaitingSpringTask: options?.clearCompletionMetadata
            ? false
            : existingTask.meta.awaitingSpringTask,
          recentlyCompleted: options?.clearCompletionMetadata
            ? false
            : existingTask.meta.recentlyCompleted,
        },
      };
    } else {
      return {
        data: {
          ...task,
          projectId: task.project?.id,
        },
        order: options?.overrideOrder || {
          date: null,
          inbox: null,
          priority: task.priorityOrder,
          project: null,
        },
        meta: {
          awaitingSpringTask: false,
          recentlyCompleted: false,
        },
      };
    }
  });

  await storeTasksInDatabase(newTasks);
};
