import { useLiveQuery } from 'dexie-react-hooks';
import last from 'lodash/last';
import { CheckCircle } from 'lucide-react';
import React, { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';

import { db } from '../../database';
import { storeGroupsFromServerInDatabase } from '../../database/actions';
import { useContainerQuery } from '../../graphql/generated-types';
import {
  setContainerGroups,
  setContainerTasks,
  setCreatorModalMode,
  setSelectedDate,
} from '../../reducers/actions';
import { BetaState } from '../../reducers/beta-types';
import ContainerHeader from '../ContainerHeader';
import ContainerSection from '../ContainerSection';
import { CreatorButton } from '../Creator/CreatorButton';
import CreatorModal from '../Creator/CreatorModal';
import GroupList from '../GroupList';

const InboxContainer = (): JSX.Element => {
  const dispatch = useDispatch();

  const { containerGroups, containerTasks, selectedTasks } = useSelector(
    (state: BetaState) => ({
      containerGroups: state.containerGroups,
      containerTasks: state.containerTasks,
      selectedTasks: state.selectedTasks,
    }),
    isEqual
  );

  const [containerData, refetchContainerData] = useContainerQuery({
    variables: { inbox: true },
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    dispatch(setSelectedDate(null));
  }, []);

  useEffect(() => {
    window.addEventListener('online', refetchContainerData);
    window.addEventListener('focus', refetchContainerData);

    return () => window.removeEventListener('online', refetchContainerData);
  }, []);

  useEffect(() => {
    if (containerData.data?.container) {
      const { groups } = containerData.data.container;

      void storeGroupsFromServerInDatabase(groups);
    }
  }, [containerData.data]);

  const databaseTasks = useLiveQuery(async () => {
    return await db.tasks
      .filter((task) => task.data.date === null && task.data.project === null)
      .toArray();
  }, []);

  const databaseGroups = useLiveQuery(async () => {
    return await db.groups
      .filter(
        (group) => group.data.date === null && group.data.projectId === null
      )
      .toArray();
  }, []);

  useEffect(() => {
    dispatch(setContainerTasks(databaseTasks || []));
  }, [databaseTasks]);

  useEffect(() => {
    dispatch(setContainerGroups(databaseGroups || []));
  }, [databaseGroups]);

  const lastSelectedTask = containerTasks.find(
    (task) => task.data.id === last(selectedTasks)
  );

  const selectedGroupId = lastSelectedTask?.data.groupIds.find((groupId) =>
    containerGroups.map((group) => group.data.id).includes(groupId)
  );

  return (
    <div className="relative bg-gray-50 pb-40 dark:bg-mauve-dark-2 md:pb-0">
      <ContainerHeader
        fetching={containerData.fetching || containerData.stale}
        title="Inbox"
      />

      <ContainerSection
        className="pb-4 md:pb-8"
        Icon={CheckCircle}
        title="Tasks"
      >
        <div className="py-2">
          <GroupList containerTasks={containerTasks} />
        </div>
      </ContainerSection>

      <CreatorButton
        openCreatorModal={() => dispatch(setCreatorModalMode('task'))}
      />

      <CreatorModal
        containerId={containerData.data?.container?.id}
        containerType={containerData.data?.container?.containerType}
        groupId={selectedGroupId}
        refetchContainerData={refetchContainerData}
      />
    </div>
  );
};

export default InboxContainer;
