import * as Select from '@radix-ui/react-select';
import { Button, Tabs, Text } from '@radix-ui/themes';
import values from 'lodash/values';
import { Repeat, X } from 'lucide-react';
import React, { useState } from 'react';

import { useUpdateTaskMutation } from '../../graphql/generated-types';
import Task from '../../types/Task';
import Modal from '../Modal';

import './NumberInput.css';

const RULES = ['hours', 'days', 'weeks', 'months', 'years'];

const SCHEDULE_ATTRIBUTES = [
  {
    title: 'Sun',
    attribute: 'sunday',
  },
  {
    title: 'Mon',
    attribute: 'monday',
  },
  {
    title: 'Tues',
    attribute: 'tuesday',
  },
  {
    title: 'Weds',
    attribute: 'wednesday',
  },
  {
    title: 'Thurs',
    attribute: 'thursday',
  },
  {
    title: 'Fri',
    attribute: 'friday',
  },
  {
    title: 'Sat',
    attribute: 'saturday',
  },
];

const TaskRepeatModal = ({
  task,
  open,
  setIsOpen,
}: {
  task: Task;
  open: boolean;
  setIsOpen: (open: boolean) => void;
}): JSX.Element => {
  const [kind, setKind] = useState(task.data.recurrence?.kind ?? 'completion');

  const [separation, setSeparation] = useState(
    task.data.recurrence?.separation ?? 1
  );

  const [schedule, setSchedule] = useState({
    sunday: task.data.recurrence?.sunday ?? false,
    monday: task.data.recurrence?.monday ?? false,
    tuesday: task.data.recurrence?.tuesday ?? false,
    wednesday: task.data.recurrence?.wednesday ?? false,
    thursday: task.data.recurrence?.thursday ?? false,
    friday: task.data.recurrence?.friday ?? false,
    saturday: task.data.recurrence?.saturday ?? false,
  });

  const [rule, setRule] = useState(task.data.recurrence?.rule ?? 'days');

  const [, updateTask] = useUpdateTaskMutation();

  const onSubmitForm = async (event): Promise<void> => {
    event.preventDefault();

    await updateTask({
      taskId: task.data.id,
      recurrence: {
        kind,
        separation: kind === 'completion' ? separation : null,
        rule: kind === 'completion' ? rule : null,
        ...schedule,
      },
    });

    setIsOpen(false);
  };

  const removeRecurrence = async (): Promise<void> => {
    await updateTask({
      taskId: task.data.id,
      recurrence: {
        kind: 'never',
        separation: null,
        rule: null,
      },
    });

    setIsOpen(false);
  };

  return (
    <Modal
      open={open}
      setIsOpen={setIsOpen}
      title={
        <div className="flex items-center gap-2 ml-2">
          <Repeat className="text-white" size={14} /> Repeat task by...
        </div>
      }
      noTitleBorder
    >
      <form onSubmit={onSubmitForm}>
        <div className="flex mb-4">
          <Tabs.Root
            onValueChange={(value) => setKind(value)}
            value={kind}
            className="grow"
          >
            <Tabs.List>
              <Tabs.Trigger value="completion">Cadence</Tabs.Trigger>
              <Tabs.Trigger value="schedule">Days of week</Tabs.Trigger>
            </Tabs.List>

            <div className="m-4 rounded-md border border-solid border-mauve-6 bg-mauve-3 dark:bg-mauve-dark-3 p-4 leading-6 dark:border-mauve-dark-6">
              <Tabs.Content value="completion">
                <Text as="span" size="2">
                  Repeat this task
                </Text>
                <input
                  type="number"
                  min="1"
                  max="999"
                  value={separation}
                  onChange={(event) => {
                    let value = parseInt(event.target.value);

                    if (value > 999) {
                      value = 999;
                    }

                    if (value < 1) {
                      value = 1;
                    }

                    return setSeparation(value);
                  }}
                  className="mx-1 w-10 cursor-pointer border-0 border-b border-dashed border-violet-400 bg-transparent text-center text-base font-semibold !text-violet-600 hover:border-violet-500 hover:!text-violet-500 focus:outline-none dark:!text-violet-400 "
                />

                <div className="inline-block">
                  <Select.Root
                    value={rule}
                    onValueChange={(value) => setRule(value)}
                  >
                    <Select.Trigger className="group mx-1 flex cursor-pointer  items-center gap-2 rounded-none border-0 border-b border-dashed border-violet-400 bg-transparent px-2 text-base font-semibold !text-violet-600 hover:border-violet-500 hover:!text-violet-500 dark:!text-violet-400 ">
                      <Select.Value>
                        {separation === 1 ? rule.slice(0, -1) : rule}
                      </Select.Value>
                    </Select.Trigger>

                    <Select.Portal>
                      <Select.Content
                        id="task-repeat-rule-select"
                        className="rounded-md border border-solid border-gray-100 bg-white p-1 drop-shadow-xl z-map-modal-dropdown  dark:border-gray-700 dark:bg-mauve-dark-3"
                      >
                        <Select.Viewport>
                          {RULES.map((rule) => (
                            <Select.Item
                              value={rule}
                              key={`${task.data.id}-repeat-${rule}`}
                              className="cursor-pointer gap-2 rounded-sm py-1 pl-2 pr-4 text-base hover:bg-violet-500 hover:text-white focus:bg-violet-500 focus:text-white focus:outline-none dark:text-white"
                            >
                              <Select.ItemText>
                                {separation === 1 ? rule.slice(0, -1) : rule}
                              </Select.ItemText>
                            </Select.Item>
                          ))}
                        </Select.Viewport>
                      </Select.Content>
                    </Select.Portal>
                  </Select.Root>
                </div>

                <Text as="span" size="2">
                  after it is completed. This task will continue to repeat on
                  this cadence until it is either deleted or the repetition rule
                  removed.
                </Text>
              </Tabs.Content>

              <Tabs.Content value="schedule">
                <Text as="span" size="2">
                  This task will repeat on the selected days of the week after
                  it is completed.
                </Text>

                <div className="mt-5 flex gap-2">
                  {SCHEDULE_ATTRIBUTES.map(({ attribute, title }) => {
                    const isActive = schedule[attribute];

                    return (
                      <Button
                        radius="full"
                        variant={isActive ? 'solid' : 'soft'}
                        key={`${task.data.id}-attributes-button-${attribute}`}
                        type="button"
                        onClick={() =>
                          setSchedule({
                            ...schedule,
                            [attribute]: !schedule[attribute],
                          })
                        }
                      >
                        {title}
                      </Button>
                    );
                  })}
                </div>
              </Tabs.Content>
            </div>
          </Tabs.Root>
        </div>

        <div className="flex justify-between px-4 pb-4">
          <Button
            type="submit"
            disabled={
              (kind === 'completion' && (!separation || !rule)) ||
              (kind === 'schedule' &&
                values(schedule).filter(Boolean).length < 1)
            }
          >
            {task.data.isRecurring ? 'Save changes' : 'Repeat task'}
          </Button>

          {task.data.isRecurring && (
            <Button onClick={removeRecurrence} variant="surface">
              <X size={14} className="text-red-500" />
              Stop repeating task
            </Button>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default TaskRepeatModal;
