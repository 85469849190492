import classNames from 'classnames';
import {
  addDays,
  addMinutes,
  endOfDay,
  format,
  isBefore,
  isPast,
  isSameDay,
  subDays,
} from 'date-fns';
import { useLiveQuery } from 'dexie-react-hooks';
import { AnimatePresence, motion } from 'framer-motion';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import {
  Book,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  Circle,
} from 'lucide-react';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { db } from '../../database';
import {
  storeGroupsFromServerInDatabase,
  storeProjectsFromServerInDatabase,
} from '../../database/actions';
import {
  useContainerQuery,
  useProjectsQuery,
  useUpdateContainerMutation,
} from '../../graphql/generated-types';
import {
  setContainerGroups,
  setContainerProjects,
  setContainerTasks,
  setCreatorModalMode,
  setMobileToolbarVisible,
  setSelectedDate,
  setSelectedTasks,
} from '../../reducers/actions';
import { BetaState } from '../../reducers/beta-types';
import { Group } from '../../types';
import {
  dateDisplayFor,
  dateFromParams,
  dateInUtc,
  formattedDate,
  pathForDate,
  timeToLuxon,
} from '../../utils/date';
import Task, { taskVariants } from '../BetaTask';
import ContainerHeader from '../ContainerHeader';
import ContainerSection from '../ContainerSection';
import { CreatorButton } from '../Creator/CreatorButton';
import CreatorModal from '../Creator/CreatorModal';
import GroupList from '../GroupList';
import { GroupFrame } from '../library/GroupFrame';
import { Notepad } from '../Notepad';

import ProjectList from './ProjectList';

interface DateMatchParams {
  day: string;
  month: string;
  year: string;
}

const DateContainer = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    containerGroups,
    containerProjects,
    containerTasks,
    draggingTask,
    isDragging,
    isHoveringTaskDropzone,
    lastActionTakenAt,
    overrideCreationGroup,
    pauseContainerStores,
    selectedDate,
    selectedTasks,
    selectedTasksLocation,
    timeZone,
  } = useSelector(
    (state: BetaState) => ({
      containerGroups: state.containerGroups,
      containerProjects: state.containerProjects,
      containerTasks: state.containerTasks,
      draggingTask: state.draggingTask,
      isDragging: state.isDragging,
      isHoveringTaskDropzone: state.isHoveringTaskDropzone,
      lastActionTakenAt: state.lastActionTakenAt,
      overrideCreationGroup: state.overrideCreationGroup,
      pauseContainerStores: state.pauseContainerStores,
      selectedDate: state.selectedDate,
      selectedTasks: state.selectedTasks,
      selectedTasksLocation: state.selectedTasksLocation,
      timeZone: state.timeZone,
    }),
    isEqual
  );

  const [showLaterTasks, setShowLaterTasks] = useState<boolean>(false);

  const [completedTasks, uncompletedTasks] = partition(
    containerTasks,
    (task) => task.data.completed
  );

  const databaseTasks = useLiveQuery(async () => {
    if (selectedDate) {
      return await db.tasks
        .where('data.date')
        .equals(formattedDate(selectedDate, timeZone))
        .toArray();
    }
  }, [selectedDate]);

  const [laterTasks, currentTasks] = partition(containerTasks, (task) => {
    if (!task.data.startTime) return false;

    // Extracting the elements of the start time so that we can combine them
    // with the selected date below.
    const { hour, minute, second } = timeToLuxon({
      time: task.data.startTime,
      outputZone: timeZone,
    }).toObject();

    return (
      DateTime.fromFormat(selectedDate, 'ccc MMM dd yyyy').set({
        hour,
        minute,
        second,
      }) > DateTime.now().setZone(timeZone)
    );
  });

  const databaseGroups = useLiveQuery(async () => {
    if (selectedDate) {
      return await db.groups
        .where('data.date')
        .equals(format(dateInUtc(new Date(selectedDate)), 'yyyy-MM-dd'))
        .toArray();
    }
  }, [selectedDate]);

  const databaseProjects = useLiveQuery(async () => {
    if (selectedDate) {
      const filterSelectedDate = dateInUtc(new Date(selectedDate));

      return await db.projects
        .where('data.date')
        .belowOrEqual(format(filterSelectedDate, 'yyyy-MM-dd'))
        .and((project) => {
          const startDate = dateInUtc(project.data.date);
          const endDate = dateInUtc(project.data.endDate);

          return (
            isBefore(filterSelectedDate, addDays(endDate, 1)) ||
            (project.data.endDate === null &&
              isSameDay(filterSelectedDate, startDate)) ||
            (databaseTasks || [])
              .filter(
                (task) => task.data.completedAt === null && task.data.spring
              )
              .map((task) => task.data.projectId)
              .includes(project.data.id)
          );
        })
        .toArray();
    }
  }, [selectedDate, databaseTasks]);

  useEffect(() => {
    dispatch(setContainerTasks(databaseTasks || []));
  }, [databaseTasks]);

  useEffect(() => {
    dispatch(setContainerGroups(databaseGroups || []));
  }, [databaseGroups]);

  useEffect(() => {
    dispatch(setContainerProjects(databaseProjects || []));
  }, [databaseProjects]);

  const [containerData, refetchContainerData] = useContainerQuery({
    variables: { date: selectedDate },
    requestPolicy: 'network-only',
  });

  const [{ fetching }, updateContainer] = useUpdateContainerMutation();

  const [projectsData] = useProjectsQuery({
    variables: { date: selectedDate },
    requestPolicy: 'cache-and-network',
  });

  const dateMatch = useRouteMatch<DateMatchParams>({
    path: ['/date/:year/:month/:day'],
    exact: true,
  });

  const moveToCurrentDateIfStale = useCallback((): void => {
    if (!selectedDate) return;

    const selectedDateEndOfDay = endOfDay(dateInUtc(new Date(selectedDate)));
    const selectedDateIsPast = isPast(selectedDateEndOfDay);

    const lastActionTakenWasOver30MinutesAgo = isPast(
      addMinutes(lastActionTakenAt, 30)
    );

    if (selectedDateIsPast && lastActionTakenWasOver30MinutesAgo) {
      dispatch(setSelectedDate(dateInUtc(new Date()).toDateString()));
    }
  }, [lastActionTakenAt, selectedDate]);

  useEffect(() => {
    dispatch(setSelectedDate(dateFromParams(dateMatch)));
  }, [dateFromParams(dateMatch)]);

  useEffect(() => {
    window.addEventListener('online', refetchContainerData);
    window.addEventListener('focus', refetchContainerData);

    return () => {
      window.removeEventListener('online', refetchContainerData);
      window.removeEventListener('focus', refetchContainerData);
    };
  }, [selectedDate]);

  useEffect(() => {
    window.addEventListener('focus', moveToCurrentDateIfStale);

    return () => {
      window.removeEventListener('focus', moveToCurrentDateIfStale);
    };
  }, [moveToCurrentDateIfStale]);

  useEffect(() => {
    if (containerData.data?.container && !pauseContainerStores) {
      const { groups } = containerData.data.container;

      void storeGroupsFromServerInDatabase(groups);
    }
  }, [containerData.data?.container?.groups]);

  useEffect(() => {
    if (projectsData.data?.projects) {
      void storeProjectsFromServerInDatabase(projectsData.data.projects);
    }
  }, [projectsData.data]);

  if (!selectedDate) {
    return null;
  }

  const onClickPreviousDay = (): void => {
    const previousDay = subDays(Date.parse(selectedDate), 1);

    history.push(pathForDate(previousDay));
    dispatch(setSelectedDate(previousDay.toDateString()));
  };

  const onClickNextDay = (): void => {
    const nextDay = addDays(Date.parse(selectedDate), 1);

    history.push(pathForDate(nextDay));
    dispatch(setSelectedDate(nextDay.toDateString()));
  };

  const lastSelectedTask = containerTasks.find(
    (task) => task.data.id === last(selectedTasks)
  );

  const selectedGroupId =
    overrideCreationGroup ||
    lastSelectedTask?.data.groupIds.find((groupId) =>
      containerGroups.map((group: Group) => group.data.id).includes(groupId)
    );

  const orderedLaterTasks = orderBy(laterTasks, [
    'data.completed',
    'data.startTime',
  ]);

  return (
    <div className="relative bg-gray-50 pb-0 pt-18 dark:bg-mauve-dark-2 md:pt-0">
      <ContainerHeader
        fetching={containerData.fetching || containerData.stale}
        rightControls={
          <div>
            <ChevronLeft
              className="cursor-pointer transition hover:text-violet-500"
              onClick={() => onClickPreviousDay()}
              role="button"
              size={28}
            />

            <ChevronRight
              className="cursor-pointer transition hover:text-violet-500"
              onClick={() => onClickNextDay()}
              role="button"
              size={28}
            />
          </div>
        }
        title={dateDisplayFor(selectedDate)}
      />

      {containerProjects && containerProjects.length > 0 && (
        <ContainerSection
          Icon={Book}
          title="Projects"
          className="pt-25 md:pt-4"
        >
          <ProjectList projects={containerProjects} />
        </ContainerSection>
      )}

      <ContainerSection
        className={classNames('pb-4 md:pb-0 md:pt-4', {
          'pt-25': containerProjects?.length === 0,
        })}
        Icon={CheckCircle}
        title="Tasks"
        rightControls={
          <div className="flex items-center">
            {uncompletedTasks.length > 0 && (
              <>
                <Circle size={14} className="mx-1 text-violet-500" />
                <span className="text-sm">{uncompletedTasks.length}</span>
              </>
            )}
            {completedTasks.length > 0 && (
              <>
                <CheckCircle size={14} className="mx-1 text-violet-500" />
                <span className="text-sm">{completedTasks.length}</span>
              </>
            )}
          </div>
        }
      >
        <div className="py-2">
          <GroupList
            selectedDate={selectedDate}
            containerTasks={currentTasks}
          />

          {laterTasks.length > 0 && (
            <GroupFrame
              collapsed={!showLaterTasks}
              collapsedCount={laterTasks.length}
              name="Later"
              onClickHeader={() => setShowLaterTasks(!showLaterTasks)}
            >
              <ul className="mt-0 list-none pl-0 pt-3">
                <AnimatePresence initial={false}>
                  {orderedLaterTasks.map((task, index) => (
                    <motion.div
                      variants={taskVariants}
                      initial="enter"
                      animate="entered"
                      exit="exit"
                      key={`later-task-${task.data.id}`}
                      transition={{ duration: 0.3 }}
                    >
                      <Task
                        draggingTask={draggingTask}
                        isDragging={isDragging}
                        isHoveringTaskDropzone={isHoveringTaskDropzone}
                        isNextTaskSelected={
                          orderedLaterTasks[index + 1] &&
                          selectedTasks.includes(
                            orderedLaterTasks[index + 1].data.id
                          )
                        }
                        isPreviousTaskSelected={
                          orderedLaterTasks[index - 1] &&
                          selectedTasks.includes(
                            orderedLaterTasks[index - 1].data.id
                          )
                        }
                        isSelected={
                          selectedTasks.includes(task.data.id) &&
                          selectedTasksLocation === 'group-list'
                        }
                        onClickTaskHeader={() =>
                          dispatch(
                            setSelectedTasks(
                              [...selectedTasks, task.data.id],
                              'group-list'
                            )
                          )
                        }
                        parentId={'later-tasks'}
                        selectedDate={selectedDate}
                        selectedTasks={selectedTasks}
                        showStartTime
                        task={task}
                        taskLocation="group-list"
                        timeZone={timeZone}
                      />
                    </motion.div>
                  ))}
                </AnimatePresence>
              </ul>
            </GroupFrame>
          )}
        </div>
      </ContainerSection>

      {containerData.data?.container && (
        <Notepad
          content={containerData?.data?.container.noteBody}
          key={`notepad-container-date-${containerData.data?.container.id}`}
          isSaving={fetching}
          onChange={async (noteBody) => {
            await updateContainer({
              date: selectedDate,
              noteBody,
            });
          }}
        />
      )}

      <CreatorButton
        openCreatorModal={() => {
          dispatch(setCreatorModalMode('task'));
          dispatch(setMobileToolbarVisible(false));
        }}
      />

      <CreatorModal
        containerId={containerData.data?.container?.id}
        containerType={containerData.data?.container?.containerType}
        date={selectedDate}
        groupId={selectedGroupId}
        refetchContainerData={refetchContainerData}
      />
    </div>
  );
};

export default DateContainer;
