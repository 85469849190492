import { Droppable } from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import groupBy from 'lodash/groupBy';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setIsHoveringTaskDropzone } from '../../reducers/actions';
import { Project as ProjectType } from '../../types';
import Project from '../BoardContainer/Project';

const containerVariants = {
  enter: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
  },
  entered: {
    opacity: 1,
    height: 'auto',
  },
  exit: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
    transition: { opacity: { duration: 0.3 } },
  },
};

const projectVariants = {
  enter: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
  },
  entered: {
    opacity: 1,
    height: 'auto',
  },
  exit: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
    transition: { opacity: { duration: 0.3 } },
  },
};

const ProjectList = ({
  projects,
}: {
  projects: ProjectType[];
}): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setIsHoveringTaskDropzone(false));
    };
  }, []);

  const projectGroups = groupBy(projects, 'data.boardName');

  return (
    <AnimatePresence>
      <motion.div
        variants={containerVariants}
        initial="enter"
        animate="entered"
        exit="exit"
        onMouseEnter={() => dispatch(setIsHoveringTaskDropzone(true))}
        onMouseLeave={() => dispatch(setIsHoveringTaskDropzone(false))}
      >
        <AnimatePresence initial={false}>
          <div className="flex flex-wrap pb-0 md:pb-4">
            {Object.keys(projectGroups).map((boardName) => (
              <>
                <button className="mb-px flex w-full cursor-pointer items-center justify-between border-0 border-t border-b border-solid border-gray-300 border-opacity-50 bg-mauve-3 bg-opacity-10 px-3.5 py-2 text-sm text-gray-700 outline-none dark:border-gray-700 dark:border-opacity-70 dark:bg-mauve-dark-5 dark:text-violet-100 md:px-4">
                  <div className="flex items-center ml-[30px]">{boardName}</div>
                </button>

                {projectGroups[boardName].map((project) => (
                  <Droppable
                    droppableId={`project.${project.data.id}`}
                    key={`project-list-item-${project.data.id}`}
                  >
                    {(provided, snapshot) => (
                      <motion.div
                        className={classNames(
                          'box-border w-full border border-solid border-transparent px-3.5',
                          {
                            'rounded-md hover:border-violet-700 hover:bg-violet-11 hover:bg-opacity-30':
                              snapshot.isDraggingOver,
                          }
                        )}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        variants={projectVariants}
                        initial="enter"
                        animate="entered"
                        exit="exit"
                      >
                        <Project project={project} />
                      </motion.div>
                    )}
                  </Droppable>
                ))}
              </>
            ))}
          </div>
        </AnimatePresence>
      </motion.div>
    </AnimatePresence>
  );
};

export default ProjectList;
