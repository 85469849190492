// @ts-strict-ignore

import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { compose, createStore } from 'redux';
import { install } from 'redux-loop';

import { useAuthenticatedRoute } from '../../hooks/use-authenticated-route';
import useBreakpoints from '../../hooks/use-breakpoints';
import betaReducer, { initialBetaState } from '../../reducers/beta';
import CommandPalette from '../CommandPalette';
import { FloatingProjectSelector } from '../FloatingSelector';
import { NewTaskExtension } from '../NewTaskExtension';
import { Toast } from '../shared/Toast';
import SubscriptionsProvider from '../SubscriptionsProvider';

import { BadgeCountProvider } from './BadgeCountProvider';
import DesktopFrame from './DesktopFrame';
import DragDropProvider from './DragDropProvider';
import { GlobalKeyboardShortcutsProvider } from './GlobalKeyboardShortcutsProvider';
import MobileFrame from './MobileFrame';
import { TimeZoneChecker } from './TimeZoneChecker';

import './ApplicationFrame.css';

const store = createStore(
  betaReducer,
  initialBetaState,
  compose(
    install(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).__REDUX_DEVTOOLS_EXTENSION__()
      : (v) => v
  )
);

const ApplicationFrame = (): JSX.Element => {
  useAuthenticatedRoute();

  const { breakpoint } = useBreakpoints();
  const showMobile = ['xs', 'sm'].includes(breakpoint);

  return (
    <Provider store={store}>
      <SubscriptionsProvider>
        <GlobalKeyboardShortcutsProvider>
          <TimeZoneChecker>
            <Switch>
              <Route path="/tasks/new" component={NewTaskExtension} />

              <BadgeCountProvider>
                <DragDropProvider>
                  <>
                    {showMobile ? <MobileFrame /> : <DesktopFrame />}

                    <CommandPalette />
                    <FloatingProjectSelector />

                    <Toast />
                  </>
                </DragDropProvider>
              </BadgeCountProvider>
            </Switch>
          </TimeZoneChecker>
        </GlobalKeyboardShortcutsProvider>
      </SubscriptionsProvider>
    </Provider>
  );
};

export default ApplicationFrame;
