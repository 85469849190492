import { ArrowLeft, ArrowRight } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const NavigationButtons = () => {
  const history = useHistory();
  const [forwardDisabled, setForwardDisabled] = useState(true);
  const [backDisabled, setBackDisabled] = useState(true);

  const entries = [];
  let currentIndex = -1;

  useEffect(() => {
    history.listen((location, action) => {
      if (action === 'PUSH') {
        entries.splice(currentIndex + 1);
        entries.push(location.pathname);

        currentIndex = entries.length - 1;
      } else if (action === 'POP') {
        entries.indexOf(location.pathname) < currentIndex
          ? currentIndex--
          : currentIndex++;
      }

      setForwardDisabled(currentIndex === entries.length - 1);
      setBackDisabled(currentIndex < 0);
    });
  }, [history]);

  const goBack = () => {
    if (!backDisabled) history.goBack();
  };

  const goForward = () => {
    if (!forwardDisabled) history.goForward();
  };

  return (
    <div className="flex justify-center">
      <button
        className="bg-transparent border-0 p-1 text-white disabled:opacity-50 cursor-pointer disabled:cursor-default"
        onClick={goBack}
        disabled={backDisabled}
      >
        <ArrowLeft />
      </button>

      <button
        className="bg-transparent border-0 p-1 text-white disabled:opacity-50 cursor-pointer disabled:cursor-default"
        onClick={goForward}
        disabled={forwardDisabled}
      >
        <ArrowRight />
      </button>
    </div>
  );
};

export default NavigationButtons;
