import { cx } from '@remirror/core';
import { FloatingWrapper } from '@remirror/react';
import React, { useEffect, useState } from 'react';

import {
  NoteFragment,
  useCreateNoteMutation,
  useNotesQuery,
} from '../../graphql/generated-types';

import { NoteEmbedNodeAttributes } from './extensions/NoteEmbedExtension';
import { useNoteEmbed } from './hooks/use-note-embed';

const newNote = { id: 'new', label: 'Create new note' };

export const NoteSuggester: React.FC = () => {
  const [options, setOptions] = useState<NoteEmbedNodeAttributes[]>([newNote]);

  const [, createNote] = useCreateNoteMutation();

  const onCreateNewNote = async (
    label: string
  ): Promise<NoteFragment | undefined> => {
    const response = await createNote({ name: label });

    if (!response.data) {
      return;
    }

    return response.data.createNote;
  };

  const { state, getMenuProps, getItemProps, indexIsHovered, indexIsSelected } =
    useNoteEmbed({
      items: options,
      onNewNoteSelected: onCreateNewNote,
    });

  const [notesData] = useNotesQuery({
    variables: { query: state?.query.full.toLowerCase() },
  });

  useEffect(() => {
    if (!notesData.data) {
      return;
    }

    const filteredOptions =
      notesData.data.notes?.map((note) => ({
        id: note.id,
        label: note.name,
      })) || [];

    setOptions([newNote, ...filteredOptions]);
  }, [notesData.data]);

  const enabled = Boolean(state);

  if (options.length === 0) {
    return null;
  }

  return (
    <FloatingWrapper
      positioner="cursor"
      enabled={enabled}
      placement="bottom-start"
      renderOutsideEditor
    >
      <div
        {...getMenuProps()}
        className="flex max-h-72 w-max flex-col gap-1 overflow-y-scroll rounded-md border border-solid border-gray-600 bg-white p-1 dark:bg-mauve-dark-3"
      >
        {enabled &&
          options.map((suggestion, index) => {
            const isHighlighted = indexIsSelected(index);
            const isHovered = indexIsHovered(index);

            return (
              <div
                key={suggestion.id}
                className={cx(
                  'p-1',
                  'cursor-pointer',
                  'rounded-md',
                  'text-sm',
                  suggestion.id === 'new' &&
                    !isHighlighted &&
                    !isHovered &&
                    'text-gray-500',
                  isHighlighted && 'bg-violet-600 text-white',
                  isHovered &&
                    !isHighlighted &&
                    'bg-violet-600 bg-opacity-70 text-white dark:bg-opacity-50'
                )}
                {...getItemProps({
                  item: suggestion,
                  index,
                })}
              >
                {suggestion.id === 'new'
                  ? `Create new note: "${state?.query.full}"`
                  : suggestion.label}
              </div>
            );
          })}
      </div>
    </FloatingWrapper>
  );
};
