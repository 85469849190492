// @ts-strict-ignore

import {
  Content,
  Item,
  ItemText,
  Portal,
  Root,
  Trigger,
  Value,
  Viewport,
} from '@radix-ui/react-select';
import orderBy from 'lodash/orderBy';
import { ArrowRight, Calendar, List } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import {
  useContainerQuery,
  useCreateTasksMutation,
} from '../../graphql/generated-types';
import { formattedDate } from '../BetaTask/utils';
import Button, { ButtonColor } from '../Button';
import DateCalendarModal from '../DateCalendarModal';
import ActionButton from '../library/ActionButton';
import TextInput from '../library/TextInput';

interface CreationAttribute {
  value: string | null;
  displayValue: string;
}

export const NewTaskExtension = (): JSX.Element => {
  const { search } = useLocation();
  const params = React.useMemo(() => new URLSearchParams(search), [search]);

  const title = params.get('title');
  const link = params.get('link');

  const [name, setName] = useState<string>(title);

  const [, createTasks] = useCreateTasksMutation();

  const [creationDate, setCreationDate] = useState(new Date());
  const [calendarModalOpen, setCalendarModalOpen] = useState(false);
  const [dateCreationGroup, setDateCreationGroup] =
    useState<CreationAttribute>();
  const [dateCreationGroups, setDateCreationGroups] = useState<
    CreationAttribute[]
  >([]);

  const [dateContainerData] = useContainerQuery({
    variables: { date: creationDate?.toISOString() },
  });

  const onSubmit = async (): Promise<void> => {
    await createTasks({
      names: [name],
      link,
      date: creationDate.toISOString(),
      groups: {
        diaryGroupId: dateCreationGroup?.value,
      },
    });

    window.close();
  };

  useEffect(() => {
    if (dateContainerData?.data) {
      const groups = orderBy(dateContainerData.data.container.groups, 'order');

      let creationGroups: CreationAttribute[];

      creationGroups = groups.map((group) => ({
        value: group.id,
        displayValue: group.name,
      }));

      if (!creationGroups.find((group) => group.displayValue === 'Tasks')) {
        creationGroups = [
          ...creationGroups,
          {
            value: null,
            displayValue: 'Tasks',
          },
        ];
      }

      setDateCreationGroups(creationGroups);
      setDateCreationGroup(creationGroups[0]);
    }
  }, [dateContainerData]);

  return (
    <form className="flex h-screen w-screen items-center justify-center">
      <div className="w-[400px]">
        <TextInput
          autoFocus
          className="mb-4"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <div className="mb-4">
          <div className="flex w-fit items-center rounded-md border border-solid border-gray-300 p-1 dark:border-gray-700 dark:bg-mauve-dark-3">
            <ActionButton
              collapseOnMobile={false}
              Icon={Calendar}
              isActive={!!creationDate}
              noShrink
              onClick={() => setCalendarModalOpen(true)}
              text={
                creationDate
                  ? formattedDate(creationDate.toISOString())
                  : 'Add date'
              }
            />

            {creationDate && dateCreationGroups && dateCreationGroup && (
              <>
                <ArrowRight
                  size={16}
                  className="mx-2 shrink-0 dark:text-white"
                />

                <Root
                  value={dateCreationGroup.value}
                  onValueChange={(value: string) =>
                    setDateCreationGroup(
                      dateCreationGroups.find((group) => group.value === value)
                    )
                  }
                >
                  <Trigger className="border-0 bg-transparent p-0">
                    <ActionButton
                      collapseOnMobile={false}
                      Icon={List}
                      isActive={true}
                      text={<Value />}
                    />
                  </Trigger>

                  <Portal className="z-map-modal-dropdown">
                    <Content className="rounded-md border border-solid border-transparent bg-white p-1 drop-shadow-xl dark:border-gray-700 dark:bg-gray-800">
                      <Viewport>
                        <span className="my-2 inline-block pl-4 text-sm text-gray-500 dark:text-gray-400">
                          Set task group
                        </span>

                        {dateCreationGroups.map((group) => (
                          <Item
                            key={`date-creation-group-${group.value}`}
                            value={group.value}
                            className="min-w-50 max-w-100 cursor-pointer rounded-sm py-1_5 pl-4 pr-4 text-sm leading-4 hover:bg-violet-500 hover:text-white focus:bg-violet-500 focus:text-white focus:outline-none dark:text-white"
                          >
                            <ItemText>{group.displayValue}</ItemText>
                          </Item>
                        ))}
                      </Viewport>
                    </Content>
                  </Portal>
                </Root>
              </>
            )}
          </div>
        </div>

        <Button type="submit" onClick={onSubmit} color={ButtonColor.Primary}>
          Create Task
        </Button>
      </div>

      <DateCalendarModal
        onSave={(date) => {
          setCreationDate(date);
          setCalendarModalOpen(false);
        }}
        open={calendarModalOpen}
        initialDate={creationDate}
        setIsOpen={setCalendarModalOpen}
        title="Set task date"
      />
    </form>
  );
};
