import { ProjectFragment } from '../../graphql/generated-types';
import { Project } from '../../types';

import { storeProjectsInDatabase } from './store-projects';

export const storeProjectsFromServerInDatabase = async (
  projects: ProjectFragment[]
): Promise<void> => {
  const newProjects: Project[] = projects.map((project) => {
    const data = {
      ...project,
      projectColumnId: project.projectColumn.id,
      boardName: project.projectColumn.board.name,
    };

    delete data.projectColumn;

    return {
      data,
      meta: {},
    };
  });

  await storeProjectsInDatabase(newProjects);
};
