import { Button, Text } from '@radix-ui/themes';
import { useLiveQuery } from 'dexie-react-hooks';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { db } from '../../database';
import { useDeleteProjectMutation } from '../../graphql/generated-types';
import { Project } from '../../types';
import RadioGroup, { RadioGroupItem } from '../library/RadioGroup';
import Modal from '../Modal';

const radioOptions: RadioGroupItem[] = [
  {
    label: 'Delete all tasks in project',
    id: 'delete-all-tasks',
    value: 'delete-all-tasks',
  },
  {
    label: 'Save project tasks',
    id: 'save-tasks',
    value: 'save-tasks',
  },
];

const DeleteProjectModal = ({
  project,
  open,
  setIsOpen,
}: {
  project: Project;
  open: boolean;
  setIsOpen: (open: boolean) => void;
}): JSX.Element => {
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState('delete-all-tasks');

  const [, deleteProject] = useDeleteProjectMutation();

  const projectColumn = useLiveQuery(async () => {
    return await db.projectColumns.get(project.data.projectColumnId);
  }, [project.data.projectColumnId]);

  const onDeleteProject = async (): Promise<void> => {
    await deleteProject({
      projectId: project.data.id,
      deleteTasks: selectedOption === 'delete-all-tasks',
    });

    setIsOpen(false);

    history.push(`/boards/${projectColumn?.data.board.id}`);
  };

  return (
    <Modal open={open} setIsOpen={setIsOpen} title="Confirm project deletion">
      <div className="flex flex-col px-4 pb-4">
        <div>
          <Text as="p" size="2" className="dark:text-gray-200" mb="4">
            Are you sure you want to delete this project? It will not be able to
            be recovered later.
          </Text>

          <RadioGroup
            items={radioOptions}
            setValue={(value) => setSelectedOption(value)}
            value={selectedOption}
          />
        </div>
        <div className="mt-4 flex justify-end gap-2">
          <Button color="gray" variant="soft" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>

          <Button color="red" variant="solid" onClick={onDeleteProject}>
            Delete project
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteProjectModal;
