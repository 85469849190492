import { Flex, Select, Tooltip } from '@radix-ui/themes';
import { format, isToday } from 'date-fns';
import { useLiveQuery } from 'dexie-react-hooks';
import orderBy from 'lodash/orderBy';
import { AlignLeft, CalendarClock } from 'lucide-react';
import { ArrowRight, Calendar, Flag, List } from 'lucide-react';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { useDispatch } from 'react-redux';

import { db } from '../../database';
import {
  storeGroupsFromServerInDatabase,
  storeTasksFromServerInDatabase,
} from '../../database/actions';
import {
  TaskFragment,
  useContainerQuery,
  useCreateTasksMutation,
} from '../../graphql/generated-types';
import { setSelectedTasks, setToast } from '../../reducers/actions';
import { Toast } from '../../reducers/beta-types';
import { Project } from '../../types';
import { dateInUtc, pathForDate } from '../../utils/date';
import { scrollToTask } from '../../utils/scroll';
import {
  dueDateIconColor,
  formattedDate,
  formattedDueDate,
} from '../BetaTask/utils';
import DateCalendarModal from '../DateCalendarModal';
import ActionButton from '../library/ActionButton';
import ProjectSelector from '../ProjectSelector';

import { CreatorForm } from './CreatorForm';
import { CreatorFormProps } from './CreatorModal';

interface CreationAttribute {
  value: string | null;
  displayValue: string;
}

interface ProjectToCreate {
  name: string;
  boardId: string;
}

export const TaskCreatorForm = (
  props: CreatorFormProps & {
    date?: string;
    groupId: string | null | undefined;
    handleModalChange: (isOpen: boolean) => void;
    project?: Project;
    refetchContainerData?: () => void;
  }
): JSX.Element => {
  const dispatch = useDispatch();

  const {
    createMultiple,
    date,
    groupId,
    handleModalChange,
    itemName,
    itemsToCreate,
    project,
    refetchContainerData,
  } = props;

  const [creationDate, setCreationDate] = useState(
    date ? new Date(date) : null
  );

  const [prioritized, setPrioritized] = useState(false);
  const [dueDate, setDueDate] = useState<Date | null>(null);

  const [creationProject, setCreationProject] = useState<CreationAttribute>();
  const [projectToCreate, setProjectToCreate] =
    useState<ProjectToCreate | null>();

  const [dateCreationGroup, setDateCreationGroup] =
    useState<CreationAttribute>();

  const [projectCreationGroup, setProjectCreationGroup] =
    useState<CreationAttribute>();

  const [dateCreationGroups, setDateCreationGroups] = useState<
    CreationAttribute[]
  >([]);

  const [projectCreationGroups, setProjectCreationGroups] = useState<
    CreationAttribute[]
  >([]);

  const [showDescription, setShowDescription] = useState(false);
  const [dateCalendarModalOpen, setDateCalendarModalOpen] = useState(false);
  const [dueDateCalendarModalOpen, setDueDateCalendarModalOpen] =
    useState(false);

  const descriptionRef = useRef<string | null | undefined>('');
  const descriptionContentEditableRef = useRef(null);

  const [dateContainerData] = useContainerQuery({
    variables: { date: creationDate?.toISOString() },
    pause: creationDate === null,
    requestPolicy: 'cache-and-network',
  });

  const [projectContainerData] = useContainerQuery({
    variables: { projectId: creationProject?.value },
    pause: creationProject === null || creationProject?.value === 'new_project',
    requestPolicy: 'cache-and-network',
  });

  const [, createTasks] = useCreateTasksMutation();

  const onCreateTasks = async (event?: FormEvent): Promise<void> => {
    event?.preventDefault();

    let names: string[];

    if (createMultiple) {
      names = itemsToCreate.filter(Boolean);
    } else {
      names = [itemName];
    }

    void createTasks({
      date: creationDate?.toISOString(),
      description: showDescription ? descriptionRef.current : undefined,
      dueDate: dueDate?.toISOString(),
      groups: {
        diaryGroupId: dateCreationGroup?.value,
        projectGroupId: projectCreationGroup?.value,
      },
      newProject: projectToCreate,
      names,
      prioritized,
      projectId:
        creationProject?.value === 'new_project'
          ? null
          : creationProject?.value,
    }).then(async ({ data }) => {
      if (data?.createTasks) {
        await storeTasksFromServerInDatabase(data.createTasks);

        dispatch(
          setSelectedTasks(
            data.createTasks.map((task) => task.id),
            'groupListTask'
          )
        );

        if (data.createTasks.length > 0) {
          setTimeout(() => {
            scrollToTask(data.createTasks[0].id);
          }, 100);
        }

        dispatch(setToast(creationToast(data.createTasks)));
      }

      if (refetchContainerData) {
        refetchContainerData();
      }
    });

    handleModalChange(false);
  };

  const dateGroups = useLiveQuery(async () => {
    if (creationDate) {
      return await db.groups
        .where('data.date')
        .equals(format(dateInUtc(new Date(creationDate)), 'yyyy-MM-dd'))
        .sortBy('data.order');
    }
  }, [creationDate]);

  const projectGroups = useLiveQuery(async () => {
    if (creationProject) {
      return await db.groups
        .where('data.projectId')
        .equals(creationProject.value)
        .sortBy('data.order');
    }
  }, [creationProject]);

  useEffect(() => {
    if (dateGroups) {
      let creationGroups = dateGroups.map((group) => ({
        value: group.data.id,
        displayValue: group.data.name,
      }));

      const targetGroup = creationGroups.find(
        (group) => group.value === groupId
      );

      if (!creationGroups.find((group) => group.displayValue === 'Tasks')) {
        creationGroups = [
          ...creationGroups,
          {
            value: null,
            displayValue: 'Tasks',
          },
        ];
      }

      setDateCreationGroups(creationGroups);
      setDateCreationGroup(targetGroup || creationGroups[0]);
    }
  }, [dateGroups]);

  useEffect(() => {
    if (projectGroups) {
      const creationGroups = projectGroups.map((group) => ({
        value: group.data.id,
        displayValue: group.data.name,
      }));

      const targetGroup = creationGroups.find(
        (group) => group.value === groupId
      );

      if (creationGroups.length === 0) {
        creationGroups.push({
          value: null,
          displayValue: 'Tasks',
        });
      }

      setProjectCreationGroups(creationGroups);
      setProjectCreationGroup(targetGroup || creationGroups[0]);
    }
  }, [projectGroups]);

  useEffect(() => {
    if (dateContainerData?.data) {
      const groups = orderBy(dateContainerData.data.container.groups, 'order');

      void storeGroupsFromServerInDatabase(groups);
    }
  }, [dateContainerData]);

  useEffect(() => {
    if (projectContainerData?.data) {
      const groups = orderBy(
        projectContainerData.data.container.groups,
        'order'
      );

      void storeGroupsFromServerInDatabase(groups);
    }
  }, [projectContainerData]);

  useEffect(() => {
    if (project) {
      setCreationProject({
        value: project.data.id,
        displayValue: project.data.name,
      });
    }
  }, []);

  useEffect(() => {
    if (showDescription) {
      focusDescription(descriptionContentEditableRef.current);
    }
  }, [showDescription]);

  return (
    <>
      <CreatorForm
        {...props}
        onCreate={onCreateTasks}
        placeholder="Task title"
        submitButtonText={
          createMultiple
            ? `Create ${itemsToCreate.filter(Boolean).length} task${
                itemsToCreate.filter(Boolean).length === 1 ? '' : 's'
              }`
            : 'Create task'
        }
      >
        <div className="flex flex-wrap items-center gap-2 px-2 pb-3">
          <div className="flex items-center rounded-md border border-solid border-mauve-6 p-1 dark:border-mauve-dark-6 bg-mauve-2 dark:bg-mauve-dark-3">
            <Tooltip content="Task date">
              <ActionButton
                collapseOnMobile={false}
                Icon={Calendar}
                isActive={!!creationDate}
                noShrink
                onClick={() => setDateCalendarModalOpen(true)}
                onClear={() => setCreationDate(null)}
                text={
                  creationDate
                    ? formattedDate(creationDate.toISOString())
                    : 'Add date'
                }
              />
            </Tooltip>

            {creationDate && dateCreationGroups && dateCreationGroup && (
              <>
                <ArrowRight size={16} className="mx-2 shrink-0" />

                <Select.Root
                  size="1"
                  value={dateCreationGroup.value}
                  onValueChange={(value: string) => {
                    if (value) {
                      return setDateCreationGroup(
                        dateCreationGroups.find(
                          (group) => group.value === value
                        )
                      );
                    }
                  }}
                >
                  <Tooltip content="Task date group">
                    <Select.Trigger className="border-0 bg-transparent !h-fit-content !py-1.5">
                      <ActionButton
                        collapseOnMobile={false}
                        Icon={List}
                        isActive={true}
                        textSize="xs"
                        text={dateCreationGroup.displayValue}
                      />
                    </Select.Trigger>
                  </Tooltip>

                  <Select.Content className="z-map-modal-dropdown">
                    <Select.Group>
                      <Select.Label>Set task group</Select.Label>

                      {dateCreationGroups.map((group) => (
                        <Select.Item
                          key={`date-creation-group-${group.value}`}
                          value={group.value}
                        >
                          {group.displayValue}
                        </Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
              </>
            )}
          </div>

          <Flex
            color="mauve-1"
            className="flex items-center rounded-md border border-solid border-mauve-6 p-1 dark:border-mauve-dark-6 bg-mauve-2 dark:bg-mauve-dark-3"
          >
            <ProjectSelector
              activeProject={creationProject}
              onChange={(option, newProjectName, newProjectBoardId) => {
                setCreationProject(option);

                if (option.value === 'new_project') {
                  setProjectToCreate({
                    name: newProjectName,
                    boardId: newProjectBoardId,
                  });
                }
              }}
              onClear={() => setCreationProject(null)}
            />

            {creationProject && projectCreationGroups && projectCreationGroup && (
              <>
                <ArrowRight size={16} className="mx-2 shrink-0" />

                <Select.Root
                  size="1"
                  value={projectCreationGroup.value}
                  onValueChange={(value: string) => {
                    if (value) {
                      return setProjectCreationGroup(
                        projectCreationGroups.find(
                          (group) => group.value === value
                        )
                      );
                    }
                  }}
                >
                  <Select.Trigger className="border-0 bg-transparent !h-fit-content !py-1.5">
                    <ActionButton
                      collapseOnMobile={false}
                      Icon={List}
                      isActive={true}
                      text={projectCreationGroup.displayValue}
                    />
                  </Select.Trigger>

                  <Select.Content className="z-map-modal-dropdown">
                    <Select.Group>
                      <Select.Label>Set task group</Select.Label>

                      {projectCreationGroups.map((group) => (
                        <Select.Item
                          key={`project-creation-group-${group.value}`}
                          value={group.value}
                        >
                          {group.displayValue}
                        </Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
              </>
            )}
          </Flex>

          <Tooltip content="Prioritize">
            <ActionButton
              Icon={Flag}
              isActive={prioritized}
              onClick={() => {
                const newPrioritizedValue = !prioritized;

                if (newPrioritizedValue) {
                  setCreationDate(dateInUtc(new Date()));
                }

                setPrioritized(newPrioritizedValue);
              }}
            />
          </Tooltip>

          <Tooltip content="Due date">
            <ActionButton
              noShrink
              collapseOnMobile={false}
              Icon={CalendarClock}
              iconColor={
                dueDate ? dueDateIconColor(dueDate.toISOString()) : undefined
              }
              isActive={!!dueDate}
              onClick={() => setDueDateCalendarModalOpen(true)}
              onClear={() => setDueDate(null)}
              text={
                dueDate ? formattedDueDate(dueDate.toISOString()) : undefined
              }
            />
          </Tooltip>

          <Tooltip content="Add description">
            <ActionButton
              collapseOnMobile={false}
              singleLine={!showDescription}
              grow={showDescription}
              Icon={AlignLeft}
              isActive={showDescription}
              onClick={() => {
                setShowDescription(true);

                focusDescription(descriptionContentEditableRef.current);
              }}
              onClear={() => setShowDescription(false)}
              text={
                showDescription ? (
                  <ContentEditable
                    innerRef={descriptionContentEditableRef}
                    html={descriptionRef.current || ''}
                    onPaste={(event) => {
                      event.preventDefault();

                      const text = event.clipboardData.getData('text/plain');
                      const temp = document.createElement('div');
                      temp.innerHTML = text;

                      if (temp.textContent) {
                        document.execCommand(
                          'insertHTML',
                          false,
                          temp.textContent
                        );
                      }
                    }}
                    placeholder="Description"
                    className="flex flex-col flex-grow cursor-text outline-none max-h-56 overflow-y-scroll"
                    onChange={(event) =>
                      (descriptionRef.current = event.target.value)
                    }
                  />
                ) : undefined
              }
            />
          </Tooltip>
        </div>
      </CreatorForm>

      <DateCalendarModal
        onSave={(date) => {
          if (!isToday(date)) {
            setPrioritized(false);
          }

          setCreationDate(date);
          setDateCalendarModalOpen(false);
        }}
        open={dateCalendarModalOpen}
        initialDate={creationDate}
        setIsOpen={setDateCalendarModalOpen}
        title="Set task date"
      />

      <DateCalendarModal
        onSave={(date) => {
          setDueDate(date);
          setDueDateCalendarModalOpen(false);
        }}
        open={dueDateCalendarModalOpen}
        initialDate={dueDate}
        setIsOpen={setDueDateCalendarModalOpen}
        title="Set task due date"
      />
    </>
  );
};

const creationToast = (tasks: TaskFragment[]): Toast => {
  const firstTask = tasks[0];

  const title = tasks.length === 1 ? firstTask.name : `${tasks.length} tasks`;

  let subtitle: string;
  let path: string;

  if (firstTask.date) {
    subtitle = `Created for ${formattedDate(firstTask.date)}`;
    path = pathForDate(dateInUtc(firstTask.date));
  } else if (firstTask.project?.id) {
    subtitle = `Created in ${firstTask.project.name}`;
    path = `/projects/${firstTask.project.id}`;
  } else {
    subtitle = 'Created in Inbox';
    path = '/inbox';
  }

  return {
    title,
    subtitle,
    path,
  };
};

const focusDescription = (element: HTMLInputElement | undefined) => {
  if (element) {
    element.focus();

    if (element.value) {
      element.setSelectionRange(element.value.length, element.value.length);
    }
  }
};
