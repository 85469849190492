import { ArrowLeft } from 'lucide-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { AuthLayout } from './AuthLayout';
import { Email } from './fields';

interface ForgotPasswordResponse {
  code: 'success';
  message: string;
  status: 200;
}

export const ForgotPassword = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [mode, setMode] = useState<'form' | 'success'>('form');

  const sendRestPasswordRequest = async (): Promise<ForgotPasswordResponse> => {
    return await fetch('/api/auth/request_password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    }).then(async (response) => await response.json());
  };

  const onSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    await sendRestPasswordRequest().then((data) => {
      if (data.code === 'success') {
        setMode('success');
      }
    });
  };

  return (
    <AuthLayout
      footer={
        <Link
          to="/login"
          className="flex items-center gap-1 text-violet-200 no-underline"
        >
          <ArrowLeft size={16} /> Back
        </Link>
      }
    >
      <form
        onSubmit={onSubmit}
        className="my-9 flex sm:w-[485px] flex-col items-center rounded-lg border border-solid border-violet-200 border-opacity-20 bg-slate-900 bg-opacity-60 p-10 shadow-auth-box"
      >
        {mode === 'form' && (
          <>
            <h1 className="mt-0 mb-6 text-2xl font-semibold text-violet-300 drop-shadow-auth-header">
              Reset your password
            </h1>

            <p className="m-0 mb-8 text-sm text-violet-200">
              Enter the email address associated with your account and we will
              email you a link to reset your password.
            </p>

            <div className="w-full">
              <Email
                errors={[]}
                email={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>

            <button
              type="submit"
              disabled={!email}
              className="mt-12 flex w-[292px] cursor-pointer items-center justify-center rounded-lg border border-solid border-violet-500 bg-violet-900 bg-opacity-50 py-3 text-base font-semibold text-white transition-all hover:drop-shadow-auth-icon"
            >
              Request password reset
            </button>
          </>
        )}

        {mode === 'success' && (
          <>
            <h1 className="mt-0 mb-6 text-2xl font-semibold text-violet-300 drop-shadow-auth-header">
              Password reset requested
            </h1>

            <p className="m-0 mb-8 text-sm text-violet-200">
              If an account exists that matches this email, we will send you an
              email. Follow the link in the email to reset your password.
            </p>
          </>
        )}
      </form>
    </AuthLayout>
  );
};
