import React from 'react';

import Priorities from '../../Priorities';

const PrioritiesSidebar = (): JSX.Element => {
  return (
    <div className="flex h-full select-none flex-col overflow-y-scroll border-0 border-l border-r border-solid border-violet-900 bg-violet-11 dark:bg-violet-dark-1 md:h-screen">
      <div className="flex h-18 shrink-0 items-center justify-between px-5">
        <h2 className="my-0 text-2xl font-semibold text-white">Priorities</h2>
      </div>

      <div className="flex flex-auto flex-col border-0 border-t border-solid border-violet-900 pb-40 pt-9 md:pb-0">
        <Priorities />
      </div>
    </div>
  );
};

export default PrioritiesSidebar;
