import omit from 'lodash/omit';
import { Calendar, Flag } from 'lucide-react';
import React, {
  Dispatch,
  memo,
  MutableRefObject,
  SetStateAction,
  useState,
} from 'react';
import isEqual from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { EventData, Swipeable } from 'react-swipeable';

import { BetaState } from '../../reducers/beta-types';
import { Task } from '../../types';

import './TaskSwiper.css';

const MAX_DRAG = 55;

interface TaskSwiperProps {
  children: ({ isSwiping }: { isSwiping: boolean }) => React.ReactNode;
  onOpenCalendar: () => void;
  onTogglePriority: () => void;
  selectTask: () => void;
  task: Task;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  taskRef: MutableRefObject<any>;
}

const TaskSwiper = ({
  children,
  onOpenCalendar,
  onTogglePriority,
  selectTask,
  task,
  taskRef,
}: TaskSwiperProps): JSX.Element => {
  const [translateActive, setTranslateActive] = useState<boolean>(true);
  const [leftIconProgress, setLeftIconProgress] = useState<number>(0);
  const [lockedSide, setLockedSide] = useState<'left' | 'right' | null>(null);
  const [rightIconProgress, setRightIconProgress] = useState<number>(0);

  const { isDragging, selectedTasks } = useSelector((state: BetaState) => ({
    isDragging: state.isDragging,
    selectedTasks: state.selectedTasks,
  }));

  const isSelected = selectedTasks.includes(task.data.id);

  const handleSwipe = (
    eventData: EventData,
    element: HTMLElement,
    setTranslateActive: Dispatch<SetStateAction<boolean>>,
    translateActive: boolean
  ): void => {
    const { absX, dir, event } = eventData;

    if (isDragging || !translateActive) {
      setTranslateActive(false);
      element.style.transform = `translateY(0) translateX(0)`;
      setLeftIconProgress(0);
      setRightIconProgress(0);
      return;
    }

    if (absX > 10) {
      event.preventDefault();
    }

    const translateX = absX > MAX_DRAG ? MAX_DRAG : absX;

    if (dir === 'Right' && lockedSide !== 'right') {
      if (!isSelected) {
        selectTask();
      }

      element.style.transform = `translateY(0) translateX(${translateX * 1}px)`;

      setLockedSide('left');
      setLeftIconProgress(translateX / MAX_DRAG);
    } else if (dir === 'Left' && lockedSide !== 'left') {
      if (!isSelected) {
        selectTask();
      }

      element.style.transform = `translateY(0) translateX(${
        -translateX * 1
      }px)`;

      setLockedSide('right');
      setRightIconProgress(translateX / MAX_DRAG);
    }
  };

  const handleSwiped = (
    element: HTMLElement,
    setTranslateActive: Dispatch<SetStateAction<boolean>>
  ): void => {
    if (!translateActive) {
      setTranslateActive(true);
      return;
    }

    if (!element) {
      return;
    }

    element.style.transform = `translateX(0)`;

    if (leftIconProgress === 1) {
      onTogglePriority();
    } else if (rightIconProgress === 1) {
      onOpenCalendar();
    }

    setLockedSide(null);
    setRightIconProgress(0);
    setLeftIconProgress(0);
  };

  const isSwiping = leftIconProgress + rightIconProgress === 0;

  return (
    <Swipeable
      onSwiping={(eventData: EventData) =>
        handleSwipe(
          eventData,
          taskRef.current,
          setTranslateActive,
          translateActive
        )
      }
      onSwiped={() => handleSwiped(taskRef.current, setTranslateActive)}
    >
      <div className="relative select-none">
        <DragIcon opacity={leftIconProgress} side="left">
          <Flag
            size={16}
            style={{
              transform: `scale(${leftIconProgress})`,
              opacity: leftIconProgress,
            }}
          />
        </DragIcon>

        <DragIcon opacity={rightIconProgress} side="right">
          <Calendar
            size={16}
            style={{
              transform: `scale(${rightIconProgress})`,
              opacity: rightIconProgress,
            }}
          />
        </DragIcon>

        {children({ isSwiping })}
      </div>
    </Swipeable>
  );
};

const DragIcon = ({
  children,
  side,
  opacity,
}: {
  children: React.ReactChild;
  side: 'left' | 'right';
  opacity: number;
}): JSX.Element => {
  return (
    <div
      className="drag-icon bg-teal-500"
      style={{
        left: side === 'left' ? '0' : 'auto',
        right: side === 'right' ? '0' : 'auto',
        opacity: opacity > 0 ? 100 : 0,
        width: MAX_DRAG,
      }}
    >
      {children}
    </div>
  );
};

const compareProps = (
  prevProps: TaskSwiperProps,
  nextProps: TaskSwiperProps
): boolean => {
  const omitKeys = ['onOpenCalendar', 'onTogglePriority', 'selectTask'];

  const filteredPrevProps = omit(prevProps, omitKeys);
  const filteredNextProps = omit(nextProps, omitKeys);

  return isEqual(filteredPrevProps, filteredNextProps);
};

export default memo(TaskSwiper, compareProps);
