// @ts-strict-ignore

import { isSameDay, setDate } from 'date-fns';
import React from 'react';

import { CalendarDay } from './CalendarDay';

export const OtherMonthDay = ({
  day,
  month,
  onClick,
  selectedDate,
}: {
  day: number;
  month: Date;
  onClick: (date: Date) => void;
  selectedDate: Date;
}): JSX.Element => {
  const date = setDate(month, day);
  const isCurrentDay = isSameDay(date, new Date());
  const isSelectedDate = isSameDay(date, selectedDate);

  return (
    <CalendarDay
      calendarDate={date}
      classes={[
        'text-gray-400',
        isCurrentDay && 'current-day',
        isSelectedDate
          ? 'text-violet-700 dark:text-white dark:bg-gray-600'
          : 'dark:hover:text-violet-300',
        'bg-opacity-30',
        'other-month',
        'relative',
      ]}
      day={day}
      isSelectedDate={isSelectedDate}
      onClick={() => onClick(date)}
    />
  );
};
