/* eslint-disable @typescript-eslint/no-explicit-any */

const method =
  (propName: any, ...args: any) =>
  (x: any) =>
    x[propName](...args);

export const csrfToken = (): string =>
  document.querySelector('meta[name=csrf-token]')?.getAttribute('content') ||
  'csrf-token';

export interface GraphError {
  message: string;
  locations?: GraphLocation[];
  path?: string[];
  details?: Record<string, string[]>;
}

export interface GraphLocation {
  line: number;
  column: number;
}

export interface GraphResponse<Data> {
  data?: Data;
  errors?: GraphError[];
}

export const fetchGraph = async <Data>(
  query: string,
  variables?: object,
  params?: object
): Promise<GraphResponse<Data>> => {
  if (!params) {
    params = {};
  }

  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify({ query, variables, params }),
    credentials: 'include' as RequestCredentials,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken(),
    },
  };

  return await fetch('/query', fetchOptions)
    .then(async (response) => {
      // GraphQL always returns 200s.
      if (response.status === 200) {
        return await Promise.resolve(response);
      } else {
        // log out error in the console
        console.error('GraphQL Network Error:', response.statusText);
      }
    })
    .then(method('json'));
};
