import { AlertTriangle } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { useDispatch } from 'react-redux';

import {
  useEnableOtpMutation,
  useGenerateNewOtpMutation,
  useUserQuery,
} from '../../graphql/generated-types';
import { setToast } from '../../reducers/actions';
import Button from '../Button';
import ContainerHeader from '../ContainerHeader';
import TextInput from '../library/TextInput';
import Modal from '../Modal';

import { SettingContainer } from './SettingContainer';

import './totp.css';

interface TotpData {
  darkQrCode: string;
  lightQrCode: string;
  otpSecret: string;
}

export const AccountSettings = (): JSX.Element => {
  const [userData] = useUserQuery();

  const [, generateNewOtp] = useGenerateNewOtpMutation();

  const [isMfaEnabled, setIsMfaEnabled] = useState(false);
  const [enableMfaModalOpen, setEnableMfaModalOpen] = useState(false);
  const [totpData, setTotpData] = useState<TotpData | null>(null);

  useEffect(() => {
    setIsMfaEnabled(userData.data?.currentUser.isMfaEnabled || false);
  }, [userData.data?.currentUser.isMfaEnabled]);

  const onClickEnableMfa = async (): Promise<void> => {
    await generateNewOtp().then(({ data }) => {
      if (data?.generateNewOtp.__typename === 'NewOtpGenerated') {
        setEnableMfaModalOpen(true);

        setTotpData(data.generateNewOtp);
      } else if (data?.generateNewOtp.__typename === 'UserAlreadyHasOtp') {
        setIsMfaEnabled(true);
      }
    });
  };

  const onOtpEnabled = (): void => {
    setIsMfaEnabled(true);
  };

  return (
    <div className="flex">
      <div className="relative mt-18 overflow-hidden flex-grow md:h-screen md:overflow-y-scroll pb-40 md:mt-0 md:pb-0">
        <ContainerHeader
          fetching={userData.fetching || userData.stale}
          title={<div className="flex">Account Settings</div>}
        />

        <div className="mb-20 px-4 md:px-8">
          <p className="dark:text-gray-200 text-gray-800 my-8">
            Manage your Blips account settings.
          </p>

          <SettingContainer
            title="Email address"
            subtitle="Your email address is used to log in to Blips and receive notifications."
          >
            <div className="w-full">
              <div className="flex gap-4 w-full h-auto justify-between">
                <TextInput
                  className="md:min-w-88 max-w-100"
                  disabled
                  value={userData.data?.currentUser.email || ''}
                />

                <button className="bg-transparent border-0 text-violet-500 text-sm font-semibold">
                  Edit
                </button>
              </div>
            </div>
          </SettingContainer>

          <SettingContainer
            title="Password"
            subtitle="Must be at least 10 characters."
          >
            <div className="w-full">
              <div className="flex gap-4 w-full h-auto justify-between">
                <TextInput
                  className="md:min-w-88 max-w-100"
                  disabled
                  type="password"
                  value={'****************'}
                />

                <button className="bg-transparent border-0 text-violet-500 text-sm font-semibold">
                  Edit
                </button>
              </div>
            </div>
          </SettingContainer>

          <SettingContainer
            title="Multi-Factor Authentication"
            subtitle="Enable multi-factor authentication to protect your account."
          >
            <div className="w-full">
              {isMfaEnabled ? (
                <Button>Disable Multi-Factor Authentication</Button>
              ) : (
                <Button onClick={onClickEnableMfa}>
                  Enable Multi-Factor Authentication
                </Button>
              )}
            </div>
          </SettingContainer>
        </div>
      </div>

      <EnableMfaModal
        onOtpEnabled={onOtpEnabled}
        open={enableMfaModalOpen}
        setOpen={setEnableMfaModalOpen}
        totpData={totpData}
      />
    </div>
  );
};

const EnableMfaModal = ({
  onOtpEnabled,
  open,
  setOpen,
  totpData,
}: {
  onOtpEnabled: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  totpData: TotpData | null;
}): JSX.Element | null => {
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const AuthCodetRef = useRef<AuthCodeRef>(null);

  const [, enableOtp] = useEnableOtpMutation();

  useEffect(() => {
    if (open) {
      AuthCodetRef.current?.clear();
      setError('');
    }
  }, [open]);

  if (!totpData) {
    return null;
  }

  return (
    <Modal fitContentWidth open={open} setIsOpen={setOpen} title="Scan QR Code">
      <div className="flex flex-col gap-4 items-center p-4">
        <p className="w-72 text-sm mb-0">
          You will need an application that supports{' '}
          <a
            className="no-underline dark:text-violet-400"
            href="https://www.nytimes.com/wirecutter/reviews/best-two-factor-authentication-app/"
            target="_blank"
            rel="noreferrer"
          >
            multi-factor authentication
          </a>{' '}
          to complete this process.
        </p>

        <p className="text-xs font-semibold mb-0">
          Scan the QR code below into your app.
        </p>

        <img className="my-2" src={totpData.lightQrCode} />

        {error ? (
          <p className="text-xs font-semibold mb-0 text-red-500 flex items-center gap-1">
            <AlertTriangle size={14} />
            {error}
          </p>
        ) : (
          <p className="text-xs font-semibold mb-0">
            Enter the code generated by your app:
          </p>
        )}

        <AuthCode
          ref={AuthCodetRef}
          containerClassName="totp-container"
          inputClassName="totp-input"
          autoFocus
          allowedCharacters="numeric"
          onChange={async (value) => {
            if (value.length === 6) {
              await enableOtp({
                otpAttempt: value,
                otpSecret: totpData.otpSecret,
              }).then(({ data }) => {
                if (
                  data?.enableOtp.__typename === 'OtpEnabled' ||
                  data?.enableOtp.__typename === 'UserAlreadyHasOtp'
                ) {
                  dispatch(
                    setToast({
                      title: 'Multi-Factor Authentication enabled.',
                      subtitle: 'You will be asked for a code when logging in.',
                    })
                  );
                  setOpen(false);
                  onOtpEnabled();
                } else if (data?.enableOtp.__typename === 'InvalidOtpAttempt') {
                  setError('Invalid code entered. Try again.');
                  AuthCodetRef.current?.clear();
                }
              });
            } else if (value.length === 1) {
              setError('');
            }
          }}
        />
      </div>
    </Modal>
  );
};
