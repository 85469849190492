import { getDay } from 'date-fns';
import React from 'react';

const CalendarHeader = (): JSX.Element => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const indexOfCurrentDay = getDay(new Date());

  return (
    <>
      {days.map((day, index) => {
        const isCurrentDay = index === indexOfCurrentDay;

        return (
          <div
            className={[
              'font-semibold',
              'text-sm',
              'mb-2',
              isCurrentDay
                ? 'text-violet-800 dark:text-violet-500'
                : 'text-gray-400',
            ].join(' ')}
            key={`calendar-header-${day}`}
          >
            {day}
          </div>
        );
      })}
    </>
  );
};

export default CalendarHeader;
