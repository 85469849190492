/* eslint-disable no-case-declarations */
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import unionBy from 'lodash/unionBy';
import { Cmd, Loop, loop, LoopReducer } from 'redux-loop';

import {
  removeGroupsFromDatabase,
  removeTasksFromDatabase,
  storeGroupsInDatabase,
  storeProjectsInDatabase,
  storeTasksInDatabase,
} from '../database/actions';
import { Note } from '../types';
import {
  reorderDraggableProjects,
  reorderDraggables,
  reorderDraggableTasks,
} from '../utils/beta/draggable';

import { BetaAction, BetaState, Sidebar } from './beta-types';
import {
  persistGroupOrder,
  persistOrder,
  persistProjectOrder,
} from './sideEffects';

export const initialBetaState: BetaState = {
  activeSidebar: Sidebar.Calendar,
  containerGroups: [],
  containerProjectColumns: [],
  containerProjects: [],
  containerTasks: [],
  creatorModalMode: null,
  currentDraggingType: null,
  dayCalendarZoomLevel: 6,
  draggingProjectColumnId: null,
  draggingTask: null,
  floatingProjectSelectorTaskIds: [],
  focusedTask: false,
  hideMobileElements: false,
  isDragging: false,
  isHoveringTaskDropzone: false,
  lastActionTakenAt: new Date(),
  lastSelectedTask: null,
  mobileSidebarVisible: false,
  mobileToolbarVisible: true,
  notes: [],
  overrideCreationGroup: null,
  overrideCreationProjectColumn: null,
  pauseContainerStores: false,
  priorityTasks: [],
  searchOpen: false,
  selectedDate: null,
  selectedProject: null,
  selectedTasks: [],
  selectedTasksLocation: null,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  toast: null,
};

const betaReducer: LoopReducer<BetaState, BetaAction> = (
  state = initialBetaState,
  action: BetaAction
): BetaState | Loop<BetaState, BetaAction> => {
  switch (action.type) {
    case 'REMOVE_GROUPS': {
      const { groupIds } = action;

      const containerGroups = state.containerGroups.filter(
        (group) => !groupIds.includes(group.data.id)
      );

      return loop(
        updateState({
          ...state,
          containerGroups,
        }),
        Cmd.run(removeGroupsFromDatabase, {
          args: [groupIds],
        })
      );
    }

    case 'REMOVE_NOTES': {
      const { noteIds } = action;

      const notes = state.notes.filter(
        (note) => !noteIds.includes(note.data.id)
      );

      return updateState({
        ...state,
        notes,
      });
    }

    case 'REMOVE_TASKS':
      const { taskIds } = action;

      const containerTasks = state.containerTasks.filter(
        (t) => !taskIds.includes(t.data.id)
      );

      const priorityTasks = state.priorityTasks.filter(
        (t) => !taskIds.includes(t.data.id)
      );

      return loop(
        updateState({
          ...state,
          containerTasks,
          priorityTasks,
        }),
        Cmd.run(removeTasksFromDatabase, {
          args: [taskIds],
        })
      );

    case 'REORDER_GROUPS':
      const reorderedGroups = reorderDraggables(
        orderBy(state.containerGroups, 'data.order'),
        [action.source.index],
        action.destination.index
      ).map((group, index) => ({
        ...group,
        data: {
          ...group.data,
          order: index,
        },
      }));

      return loop(
        updateState({
          ...state,
          containerGroups: reorderedGroups,
        }),
        Cmd.list([
          Cmd.run(storeGroupsInDatabase, {
            args: [reorderedGroups],
          }),
          Cmd.run(persistGroupOrder, {
            args: [
              reorderedGroups.map((group) => ({
                id: group.data.id,
                order: group.data.order,
              })),
            ],
          }),
        ])
      );

    case 'REORDER_PROJECTS':
      const { destination, source } = action;

      const destinationProjectColumnId = last(
        destination.droppableId.split('-')
      );
      const sourceProjectColumnId = last(source.droppableId.split('-'));

      const destinationProjectColumn = state.containerProjectColumns.find(
        (projectColumn) => projectColumn.data.id === destinationProjectColumnId
      );

      const sourceProjectColumn = state.containerProjectColumns.find(
        (projectColumn) => projectColumn.data.id === sourceProjectColumnId
      );

      const containerProjects = reorderDraggableProjects(
        state.containerProjects,
        action.source,
        action.destination,
        destinationProjectColumn,
        sourceProjectColumn
      );

      return loop(
        updateState({
          ...state,
          containerProjects: unionBy(
            containerProjects,
            state.containerProjects,
            'data.id'
          ),
        }),
        Cmd.list([
          Cmd.run(storeProjectsInDatabase, {
            args: [containerProjects],
          }),
          Cmd.run(persistProjectOrder, {
            args: [
              containerProjects.map((project) => ({
                id: project.data.id,
                order: project.data.order,
                projectColumnId: project.data.projectColumnId,
              })),
            ],
          }),
        ])
      );

    case 'REORDER_TASKS': {
      const tasks = reorderDraggableTasks(
        state.containerTasks,
        action.source,
        action.destination,
        action.orderType,
        state.selectedTasks
      );

      return loop(
        updateState({
          ...state,
          containerTasks: unionBy(tasks, state.containerTasks, 'data.id'),
        }),
        Cmd.list([
          Cmd.run(storeTasksInDatabase, {
            args: [tasks],
          }),
          Cmd.run(persistOrder, {
            args: [
              tasks.map((task) => ({
                id: task.data.id,
                destinationGroupId: task.draggableData.destinationGroupId,
                order: task.order[action.orderType],
                sourceGroupId: task.draggableData.sourceGroupId,
              })),
            ],
          }),
        ])
      );
    }

    case 'SET_ACTIVE_SIDEBAR': {
      return updateState({ ...state, activeSidebar: action.activeSidebar });
    }

    case 'SET_CONTAINER_GROUPS': {
      return updateState({
        ...state,
        containerGroups: action.containerGroups,
      });
    }

    case 'SET_CONTAINER_PROJECT_COLUMNS': {
      return updateState({
        ...state,
        containerProjectColumns: action.containerProjectColumns,
      });
    }

    case 'SET_CONTAINER_PROJECTS': {
      return updateState({
        ...state,
        containerProjects: action.containerProjects,
      });
    }

    case 'SET_CONTAINER_TASKS': {
      return updateState({
        ...state,
        containerTasks: action.containerTasks,
      });
    }

    case 'SET_DAY_CALENDAR_ZOOM_LEVEL': {
      const withinRange = (newLevel: number): boolean =>
        newLevel >= 3 && newLevel <= 12;
      const newLevel = action.dayCalendarZoomLevel;

      return updateState({
        ...state,
        dayCalendarZoomLevel: withinRange(newLevel)
          ? action.dayCalendarZoomLevel
          : state.dayCalendarZoomLevel,
      });
    }

    case 'SET_FLOATING_PROJECT_SELECTOR_TASK_IDS': {
      return updateState({
        ...state,
        floatingProjectSelectorTaskIds: action.taskIds,
      });
    }

    case 'SET_PRIORITY_TASKS': {
      return updateState({
        ...state,
        priorityTasks: orderBy(action.priorityTasks, 'order.priority'),
      });
    }

    case 'UPDATE_CONTAINER_TASKS': {
      return updateState({
        ...state,
        containerTasks: unionBy(
          action.containerTasks,
          state.containerTasks,
          'data.id'
        ),
      });
    }

    case 'SET_CREATOR_MODAL_MODE': {
      return updateState({
        ...state,
        creatorModalMode: action.creatorModalMode,
      });
    }

    case 'SET_CURRENT_DRAGGING_TYPE': {
      return updateState({
        ...state,
        currentDraggingType: action.currentDraggingType,
      });
    }

    case 'SET_DRAGGING_PROJECT_COLUMN_ID': {
      return updateState({
        ...state,
        draggingProjectColumnId: action.draggingProjectColumnId,
      });
    }

    case 'SET_DRAGGING_TASK': {
      return updateState({ ...state, draggingTask: action.draggingTask });
    }

    case 'SET_FOCUSED_TASK': {
      return updateState({
        ...state,
        focusedTask: action.focusedTask,
      });
    }

    case 'SET_HIDE_MOBILE_ELEMENTS': {
      return updateState({
        ...state,
        hideMobileElements: action.hideMobileElements,
      });
    }

    case 'SET_IS_DRAGGING': {
      return updateState({ ...state, isDragging: action.isDragging });
    }

    case 'SET_IS_HOVERING_TASK_DROPZONE': {
      return updateState({
        ...state,
        isHoveringTaskDropzone: action.isHoveringTaskDropzone,
      });
    }

    case 'SET_LAST_SELECTED_TASK': {
      return updateState({
        ...state,
        lastSelectedTask: action.lastSelectedTask,
      });
    }

    case 'SET_MOBILE_SIDEBAR_VISIBLE': {
      return updateState({
        ...state,
        mobileSidebarVisible: action.mobileSidebarVisible,
        isHoveringTaskDropzone: false,
      });
    }

    case 'SET_MOBILE_TOOLBAR_VISIBLE': {
      return updateState({
        ...state,
        mobileToolbarVisible: action.mobileToolbarVisible,
      });
    }

    case 'SET_OVERRIDE_CREATION_GROUP': {
      return updateState({
        ...state,
        overrideCreationGroup: action.overrideCreationGroup,
      });
    }

    case 'SET_OVERRIDE_CREATION_PROJECT_COLUMN': {
      return updateState({
        ...state,
        overrideCreationProjectColumn: action.overrideCreationProjectColumn,
      });
    }

    case 'SET_PAUSE_CONTAINER_STORES': {
      const { pauseContainerStores } = action;

      return {
        ...state,
        pauseContainerStores,
      };
    }

    case 'SET_SEARCH_OPEN': {
      return updateState({ ...state, searchOpen: action.searchOpen });
    }

    case 'SET_SELECTED_DATE': {
      return updateState({
        ...state,
        selectedDate: action.date,
        selectedProject: null,
      });
    }

    case 'SET_SELECTED_PROJECT': {
      return updateState({
        ...state,
        selectedDate: null,
        selectedProject: action.projectId,
      });
    }

    case 'SET_SELECTED_TASKS': {
      return updateState({
        ...state,
        selectedTasks: action.selectedTasks,
        selectedTasksLocation: action.selectedTasksLocation,
      });
    }

    case 'SET_TIME_ZONE': {
      return updateState({
        ...state,
        timeZone: action.timeZone,
      });
    }

    case 'SET_TOAST': {
      return updateState({
        ...state,
        toast: action.toast,
      });
    }

    case 'STORE_NOTES': {
      return updateState({
        ...state,
        notes: unionBy(action.notes, state.notes, 'data.id'),
      });
    }

    case 'STORE_NOTES_FROM_SERVER': {
      const { notes } = action;

      const newNotes: Note[] = notes.map((note) => {
        return {
          data: note,
        };
      });

      return {
        ...state,
        notes: unionBy(newNotes, state.notes, 'data.id'),
      };
    }

    default:
      return state;
  }
};

const updateState = (state: BetaState): BetaState => ({
  ...state,
  lastActionTakenAt: new Date(),
});

export default betaReducer;
