import {
  Draggable,
  Droppable,
} from '@atlaskit/pragmatic-drag-and-drop-react-beautiful-dnd-migration';
import { format } from 'date-fns';
import { useLiveQuery } from 'dexie-react-hooks';
import { AnimatePresence, motion } from 'framer-motion';
import orderBy from 'lodash/orderBy';
import { Clock, Flag } from 'lucide-react';
import React, { MouseEvent, useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';

import { db } from '../../database';
import { storeTasksFromServerInDatabase } from '../../database/actions';
import { usePrioritiesQuery } from '../../graphql/generated-types';
import {
  setLastSelectedTask,
  setPriorityTasks,
  setSelectedTasks,
} from '../../reducers/actions';
import { BetaState } from '../../reducers/beta-types';
import { Task as TaskType } from '../../types';
import { dateInUtc } from '../../utils/date';
import Task from '../BetaTask';

const taskVariants = {
  enter: {
    opacity: 0,
    height: 0,
  },
  entered: {
    opacity: 1,
    height: 'auto',
  },
  exit: {
    opacity: 0,
    height: 0,
  },
};

const Priorities = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const todayDate = new Date().toDateString();

  const dueTasks =
    useLiveQuery(
      async () =>
        await db.tasks
          .where('data.dueDate')
          .belowOrEqual(format(dateInUtc(new Date()), 'yyyy-MM-dd'))
          .and((task) => task && !task.data.completed && !task.order.priority)
          .toArray()
    ) || [];

  const [prioritiesData] = usePrioritiesQuery({
    variables: {
      date: todayDate,
      includedTaskIds: dueTasks.map((task) => task.data.id),
    },
    requestPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (prioritiesData.data?.prioritizedTasks) {
      void storeTasksFromServerInDatabase(prioritiesData.data.prioritizedTasks);
    }

    if (prioritiesData.data?.dueTasks) {
      void storeTasksFromServerInDatabase(prioritiesData.data.dueTasks);
    }

    if (prioritiesData.data?.includedTasks) {
      void storeTasksFromServerInDatabase(prioritiesData.data.includedTasks);
    }
  }, [prioritiesData.data]);

  const {
    draggingTask,
    isDragging,
    isHoveringTaskDropzone,
    lastSelectedTask,
    priorityTasks,
    selectedDate,
    selectedProject,
    selectedTasks,
    selectedTasksLocation,
    timeZone,
  } = useSelector(
    (state: BetaState) => ({
      draggingTask: state.draggingTask,
      isDragging: state.isDragging,
      isHoveringTaskDropzone: state.isHoveringTaskDropzone,
      lastSelectedTask: state.lastSelectedTask,
      priorityTasks: orderBy(state.priorityTasks, 'order.priority'),
      selectedDate: state.selectedDate,
      selectedProject: state.selectedProject,
      selectedTasks: state.selectedTasks,
      selectedTasksLocation: state.selectedTasksLocation,
      timeZone: state.timeZone,
    }),
    isEqual
  );

  const databasePriorityTasks = useLiveQuery(
    async () =>
      await db.tasks
        .where('data.date')
        .equals(format(dateInUtc(new Date()), 'yyyy-MM-dd'))
        .and(
          (task) =>
            task &&
            !!task.order.priority &&
            task.order.priority >= 0 &&
            !task.data.completed
        )
        .toArray()
  );

  useEffect(() => {
    dispatch(setPriorityTasks(databasePriorityTasks || []));
  }, [databasePriorityTasks]);

  const taskLocation = 'priorityTask';

  const onClickTaskHeader = (
    id: string,
    taskLocation: string,
    event: MouseEvent
  ): void => {
    if (
      (!event.shiftKey && !event.metaKey) ||
      selectedTasksLocation !== taskLocation
    ) {
      dispatch(setLastSelectedTask(id));
      dispatch(setSelectedTasks([id], taskLocation));

      return;
    }

    const clickedTaskIndex = priorityTasks.findIndex(
      (task) => task.data.id === id
    );

    const lastSelectedTaskIndex = priorityTasks.findIndex(
      (task) => task.data.id === lastSelectedTask
    );

    if (event.shiftKey) {
      const newSelectedTasks = priorityTasks
        .slice(
          Math.min(clickedTaskIndex, lastSelectedTaskIndex),
          Math.max(clickedTaskIndex, lastSelectedTaskIndex) + 1
        )
        .map((task) => task.data.id);

      dispatch(setSelectedTasks(newSelectedTasks, taskLocation));

      return;
    }

    if (event.metaKey) {
      if (selectedTasks.includes(id)) {
        dispatch(
          setSelectedTasks(
            selectedTasks.filter((taskId) => taskId !== id),
            taskLocation
          )
        );
      } else {
        dispatch(setSelectedTasks([...selectedTasks, id], taskLocation));
      }
    }
  };

  return (
    <>
      {dueTasks.length > 0 && (
        <>
          <div className="flex items-center px-5 font-semibold">
            <Clock size="18" className="mr-2 text-violet-300" strokeWidth="3" />

            <span className="text-sm text-violet-300">Due Tasks</span>
          </div>

          <ul className="flex list-none flex-col px-0 md:px-1">
            <AnimatePresence initial={false}>
              {dueTasks.map((task, index) => (
                <motion.div
                  variants={taskVariants}
                  initial="enter"
                  exit="exit"
                  animate="entered"
                  key={`due-task-${task.data.id}`}
                  transition={{ duration: 0.3 }}
                >
                  <Task
                    contextMenuIdentifier={`due-task-${task.data.id}`}
                    draggingTask={draggingTask}
                    isDragging={isDragging}
                    isHoveringTaskDropzone={isHoveringTaskDropzone}
                    isNextTaskSelected={
                      dueTasks[index + 1] &&
                      selectedTasks.includes(dueTasks[index + 1].data.id)
                    }
                    isPreviousTaskSelected={
                      dueTasks[index - 1] &&
                      selectedTasks.includes(dueTasks[index - 1].data.id)
                    }
                    isSelected={
                      selectedTasks.includes(task.data.id) &&
                      selectedTasksLocation === taskLocation
                    }
                    onClickTaskHeader={onClickTaskHeader}
                    parentId="priorities"
                    selectedDate={selectedDate}
                    selectedProject={selectedProject}
                    selectedTasks={selectedTasks}
                    showDate={false}
                    showDue={false}
                    showProject={false}
                    showPriority={false}
                    task={task}
                    taskLocation={taskLocation}
                    textClass="text-white"
                    timeZone={timeZone}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </ul>
        </>
      )}
      <div className="flex items-center px-5 font-semibold">
        <Flag size="18" className="mr-2 text-violet-300" strokeWidth="3" />

        <span className="text-sm text-violet-300">Prioritized Tasks</span>
      </div>

      <Droppable droppableId="priorities">
        {(provided) => (
          <ul
            className="flex-auto list-none px-0 md:px-1"
            ref={provided.innerRef}
          >
            <AnimatePresence initial={false}>
              {priorityTasks.map((task: TaskType, index: number) => (
                <Draggable
                  draggableId={`${taskLocation}-${task.data.id}`}
                  index={index}
                  key={`priority-task-${task.data.id}`}
                >
                  {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {(provided: any) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <motion.div
                        variants={taskVariants}
                        initial="enter"
                        exit="exit"
                        animate="entered"
                        key={`priority-task-${task.data.id}`}
                        transition={{ duration: 0.3 }}
                      >
                        <Task
                          contextMenuIdentifier={`priority-task-${task.data.id}`}
                          draggingTask={draggingTask}
                          isDragging={isDragging}
                          isHoveringTaskDropzone={isHoveringTaskDropzone}
                          isNextTaskSelected={
                            priorityTasks[index + 1] &&
                            selectedTasks.includes(
                              priorityTasks[index + 1].data.id
                            )
                          }
                          isPreviousTaskSelected={
                            priorityTasks[index - 1] &&
                            selectedTasks.includes(
                              priorityTasks[index - 1].data.id
                            )
                          }
                          isSelected={
                            selectedTasks.includes(task.data.id) &&
                            selectedTasksLocation === taskLocation
                          }
                          onClickTaskHeader={onClickTaskHeader}
                          parentId="priorities"
                          selectedDate={selectedDate}
                          selectedProject={selectedProject}
                          selectedTasks={selectedTasks}
                          showDate={false}
                          showProject={false}
                          showPriority={false}
                          task={task}
                          taskLocation={taskLocation}
                          textClass="text-white"
                          timeZone={timeZone}
                        />
                      </motion.div>
                    </div>
                  )}
                </Draggable>
              ))}
            </AnimatePresence>

            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </>
  );
};

export default Priorities;
