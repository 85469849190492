import { Text } from '@radix-ui/themes';
import { Command } from 'cmdk';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { TaskResultFragment } from '../../graphql/generated-types';
import { setSearchOpen, setSelectedTasks } from '../../reducers/actions';
import { BetaState } from '../../reducers/beta-types';
import { scrollToTask } from '../../utils/scroll';
import { FloatingOverlay } from '../shared/FloatingOverlay';

import { useSearch } from './useSearch';
import { resultPath } from './utils';

export const CommandPalette: React.FC = () => {
  const [search, setSearch] = useState('');

  const { searchOpen } = useSelector((state: BetaState) => ({
    searchOpen: state.searchOpen,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const results = useSearch(search);

  return (
    <FloatingOverlay
      open={searchOpen}
      onOpenChange={(open) => {
        if (!open) {
          dispatch(setSearchOpen(false));
        }
      }}
    >
      {(inputRef) => (
        <Command>
          <Command.Input
            autoFocus={false}
            value={search}
            onValueChange={setSearch}
            ref={inputRef}
            placeholder="Enter command or search..."
          />

          <Command.List>
            {results && (
              <>
                {search && results.commands.length > 0 && (
                  <Command.Group>
                    {results.commands.map((command) => (
                      <Command.Item
                        value={command.value}
                        key={command.key}
                        onSelect={() => {
                          setSearch('');
                          dispatch(setSearchOpen(false));

                          command.command();
                        }}
                      >
                        <Text as="span">{command.label}</Text>
                      </Command.Item>
                    ))}
                  </Command.Group>
                )}
                {search && results.tasks.length > 0 && (
                  <Command.Group heading="Tasks">
                    {results.tasks.map((taskResult: TaskResultFragment) => (
                      <Command.Item
                        keywords={['task']}
                        value={[taskResult.task.name, taskResult.task.id].join(
                          ' '
                        )}
                        key={taskResult.task.id}
                        onSelect={() => {
                          setSearch('');

                          dispatch(setSearchOpen(false));
                          dispatch(
                            setSelectedTasks(
                              [taskResult.task.id],
                              'groupListTask'
                            )
                          );

                          history.push(resultPath(taskResult));

                          setTimeout(() => {
                            scrollToTask(taskResult.task.id);
                          }, 200);
                        }}
                        className="flex justify-between items-center"
                      >
                        <Text
                          as="span"
                          className="flex items-center line-clamp-1"
                        >
                          {taskResult.task.name}
                        </Text>

                        <Text as="span" className="ml-2 text-sm">
                          {taskResult.task.date}
                        </Text>
                      </Command.Item>
                    ))}
                  </Command.Group>
                )}
              </>
            )}
            {search && results.projects.length > 0 && (
              <Command.Group heading="Projects">
                {results.projects.map((project) => (
                  <Command.Item
                    keywords={['project']}
                    value={[project.name, project.id].join(' ')}
                    key={project.id}
                    onSelect={() => {
                      setSearch('');
                      dispatch(setSearchOpen(false));

                      history.push(resultPath(project));
                    }}
                  >
                    <Text as="span">{project.name}</Text>
                  </Command.Item>
                ))}
              </Command.Group>
            )}
            {search && results.boards.length > 0 && (
              <Command.Group heading="Boards">
                {results.boards.map((board) => (
                  <Command.Item
                    keywords={['board']}
                    value={[board.name, board.id].join(' ')}
                    key={board.id}
                    onSelect={() => {
                      setSearch('');
                      dispatch(setSearchOpen(false));

                      history.push(resultPath(board));
                    }}
                  >
                    <Text as="span">{board.name}</Text>
                  </Command.Item>
                ))}
              </Command.Group>
            )}
            {search && results.notes.length > 0 && (
              <Command.Group heading="Notes">
                {results.notes.map((note) => (
                  <Command.Item
                    keywords={['note']}
                    value={[note.name, note.id].join(' ')}
                    key={note.id}
                    onSelect={() => {
                      setSearch('');
                      dispatch(setSearchOpen(false));

                      history.push(resultPath(note));
                    }}
                  >
                    <Text as="span">{note.name}</Text>
                  </Command.Item>
                ))}
              </Command.Group>
            )}
          </Command.List>
        </Command>
      )}
    </FloatingOverlay>
  );
};
